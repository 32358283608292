import CandidateConversation from '../candidate_pages/conversation'
import EmployerConversation from '../employer_pages/conversation'
import  { useAuths }  from '../AuthContext';

export default function Dashboard(props) {
    const {currentUser} = useAuths()

    if(currentUser.isAdmin){
        return(
            <div>Admin</div>
        )
    }else if(currentUser.isEmployer){
        return(
            <EmployerConversation locations={props}/>
        )
    }else{
        return(
            <CandidateConversation/>
        )
    }
}