import React, {useEffect, useState} from 'react'
import axios from 'axios'
import { Spinner } from 'reactstrap';
import Navbar from '../shared-components/navbar'
import  { useAuths }  from '../AuthContext';
import {Link, useHistory, useParams} from 'react-router-dom'
import { Button, Modal } from 'react-bootstrap';
import moment from 'moment'
import parse from 'html-react-parser'
import ReactLoading from "react-loading";
import { toast, Slide } from 'react-toastify'
import profile from "../image/profile/building.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { Helmet } from 'react-helmet'
import 'react-toastify/dist/ReactToastify.css';
import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";
const url = process.env.REACT_APP_SERVER_URL



toast.configure()



function ApplicationModal(){
const {currentUser} = useAuths()

   
}


function FormModal({jobId}){
 


  const [showPassword, setShowPassword] = useState(false)
  const [btn_loading, set_btn_loading] = useState(false)
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const { signIn, currentUser } = useAuths()
  const history = useHistory()

  
  const passwordError = () => {
   toast.error('Password Not Correct', {position: toast.POSITION.TOP_CENTER, theme: "colored", transition: Slide,  hideProgressBar: true})
  }

  const disabled_account = () => {
   toast.error('This account has be disabled, Please contact the administrator', {position: toast.POSITION.TOP_RIGHT, theme: "colored", transition: Slide,  hideProgressBar: true})
  }

  const emailError = () => {
   toast.error('Email Not Found', {position: toast.POSITION.TOP_CENTER, theme: "colored", transition: Slide,  hideProgressBar: true})
  }

  const serverError = () => {
   toast.error('Could Not Sign You In', {position: toast.POSITION.TOP_CENTER, theme: "colored", transition: Slide,  hideProgressBar: true})
  }

  const required = () => {
    toast.error('All Fields Are Required', {position: toast.POSITION.TOP_CENTER, theme: "colored", transition: Slide,  hideProgressBar: true})
  }

    const handlesubmit = async (e) => {
        e.preventDefault();
        set_btn_loading(true)
        if(!email || !password ){
            required()
            set_btn_loading(false)
        }else{
             try { 
            await signIn({ password, email}) 
            if(currentUser && !currentUser.isCandidate){
            await history.push(`/dashboard`)
            }else{
            await history.push(`/job-questions/${jobId}`)
          }
       } catch (error) {
           if (error.response.status === 401) {
             passwordError()
              set_btn_loading(false)
         } else if(error.response.status === 404){
            emailError()
             set_btn_loading(false)
         }else if(error.response.status === 400){
             disabled_account()
             set_btn_loading(false)
         }else{
           serverError()
            set_btn_loading(false)
         }
       }}
        }
    const viewPassword = () => {
        showPassword ? setShowPassword(false) : setShowPassword(true)
    }

    const reveilPassword = () => {
        if( showPassword){
            return "text"
        }else{
           return "password"}
    }

  return(
    <div>
       <Helmet>
            <title> Job Detail - Job Board | StriveHR </title>
            </Helmet>
          <div className="login-modal-main bg-white rounded-8 overflow-hidden">
            <div className=" no-gutters">
              <div>
                <div className="bg-white-2 h-100 px-10 pt-10 pb-7">
                  <div className="row">
                    <div className="col-xs-12 text-center">
                      <p>Sign in to apply.</p>
                    </div>
                  </div>
                  <div className="or-devider">
                    <span className="font-size-3 line-height-reset">Sign in</span>
                  </div>
                  <form action="/">
                    <div className="form-group">
                      <label
                        className="
                          font-size-4
                          text-black-2
                          font-weight-semibold
                          line-height-reset
                        "
                        >E-mail</label>
                      <input
                      onChange={(e) => setEmail(e.target.value)}
                        type="email"
                        className="form-control"
                        placeholder="example@gmail.com"
                        id="email"
                      />
                    </div>
                    <div className="form-group">
                      <label
                        className="
                          font-size-4
                          text-black-2
                          font-weight-semibold
                          line-height-reset
                        "
                        >Password</label>
                      <div className="position-relative">
                        <input
                        onChange={(e) => setPassword(e.target.value)}
                          type={reveilPassword()}
                          className="form-control"
                          id="password"
                          placeholder="Enter password"
                        />
                        {!showPassword ? <FontAwesomeIcon 
                          className="
                              show-password
                              pos-abs-cr
                              fas
                              mr-6
                              text-black-2
                            "
                          onClick={() => {viewPassword()}}
                            icon={faEye}/> : 
                             <FontAwesomeIcon 
                          className="
                              show-password
                              pos-abs-cr
                              fas
                              mr-6
                              text-black-2
                            "
                          onClick={() => {viewPassword()}}
                            icon={faEyeSlash}/>}
                      </div>
                    </div>
                    <div
                      className="
                        form-group
                        d-flex
                        flex-wrap
                        justify-content-between
                      "
                    >
                      <label
                        className="gr-check-input d-flex mr-3"
                      >
                        <input
                          className="d-none"
                          type="checkbox"
                          id="terms-check"
                        />
                        <span className="checkbox mr-5"></span>
                        <span
                          className="
                            font-size-3
                            mb-0
                            line-height-reset
                            mb-1
                            d-block
                          "
                          >Remember password</span>
                      </label>
                      <Link
                        to="/forgot-password"
                        className="font-size-3 text-green line-height-reset"
                        >Forgot Password</Link>
                    </div>
                    <div className="form-group mb-8">
                      {!btn_loading ? <button
                        onClick={(e) => {handlesubmit(e)}}
                        className="
                          btn btn-primary btn-medium
                          w-100
                          rounded-5
                          text-uppercase
                        "
                      >
                        Log in
                      </button> : 
                       <Button className="btn btn-primary btn-medium
                                    w-100
                                    rounded-5
                                    text-uppercase" disabled>
                                  <Spinner
                                  as="span"
                                  variant="light"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                  animation="border"/>
                                  
                              </Button>}
                    </div>
                    <p className="font-size-4 text-center heading-default-color">
                      Don’t have an account?
                      <Link to="/candidate-sign-up" className="text-primary"
                        >Create an account now</Link>
                    </p>
                  </form>
                </div>
               
              </div>
            </div>
          </div>
    </div>
  )
}




export default function JobDetail({location}){
    const [applicant, setApplicant] = React.useState([])
    const [job_description, set_job_description] = React.useState("")
    const param = useParams()
    const param_token = param.token
    const param_data = param.data
    const query_data = param_token+'/'+param_data
    const link_data = query_data.split(" ")
    const display = link_data.join("%20")
    const link = `https://jobboard.strivehumanresources.com/JobDetails/${display}`
    const [modalShow, setModalShow] = React.useState(false)
    const [career_level, set_career_level] = React.useState({
        title: ""
    })
    const [category, set_category] = React.useState({
        category: ""
    })
    const [industry, setIndustry] = React.useState({
        name: ""
    })
    const [owner, set_owner] = React.useState({
        company_name: "",
        company_email: "",
        industry: "",
        image: "",
        userId: "",
        _id: ""

    })
    const [type, setType] = React.useState({
        type: ""
    })
    const [skill, setSkill] = React.useState([])
    const [jobDetail, setJobDetail] = React.useState({   
        createdAt: "",
        information: "",
        jobrole: "",
        jobId: "",
        state: "",
        country: "",
        payment: "",
        updatedAt: "",
        _id: "",
        anonymous: true
    })
    const [loading, setLoading] = React.useState(true)
    const [showShareButton, setShowShareButton] = React.useState(false)
    const [applyModal, setApplyModal] = React.useState(false)
    const {isLoggedIn} = useAuths()
    const [bookmarks, setBookmarks] = React.useState([])
    const history = useHistory()
     

   const getBookMarkjobs = () => {
   const token = localStorage.getItem('token')
    axios.get(`${url}get-user-bookmark`, {
      headers: {
        'Authorization': `Bearer ${token}`
      },
    }).then(res => {
      setBookmarks(res.data)
    })
  }

  function DisplayShareButton(){
  return (
   <div>
   <FacebookShareButton url={link}><FacebookIcon size={35} round={true}/></FacebookShareButton>
   <WhatsappShareButton url={link}><WhatsappIcon size={35} round={true}/></WhatsappShareButton>
   <LinkedinShareButton url={link}><LinkedinIcon size={35} round={true}/></LinkedinShareButton>
   </div>
  )
}
  

   const createBookMark = ({e, job_id}) => {
    e.preventDefault();
    const token = localStorage.getItem('token')
    axios.post(`${url}create-bookmark`, {job_id},{
      headers: {
        'Authorization': `Bearer ${token}`
      },

    }).then(
      res => {
        setBookmarks(res.data)
       saveJobSuccess()
       
      }
    ).catch(error => {
       saveError()
    })
  }

  const deleteBookMark = ({e, id}) => {
    e.preventDefault();
    const token = localStorage.getItem('token')
    axios.delete(`${url}deleteBookMark/${id}`,{
      headers: {
        'Authorization': `Bearer ${token}`
      },

    }).then(
      res => {
        setBookmarks(res.data)
        unSavedJobSuccess()
      }
    ).catch(error => {
      unsaveError()
    })
  }


   const saveJobSuccess = () => {
    toast.success('Job Saved Successfully', {position: toast.POSITION.TOP_RIGHT, theme: "colored", transition: Slide,  hideProgressBar: true})
  }

  const unSavedJobSuccess = () => {
   toast.success('Job Unsaved Successfully', {position: toast.POSITION.TOP_RIGHT, theme: "colored", transition: Slide,  hideProgressBar: true})
  }

  const unsaveError = () => {
   toast.error('Could Not Unsave Job', {position: toast.POSITION.TOP_RIGHT, theme: "colored", transition: Slide,  hideProgressBar: true})
  }

  const saveError = () => {
   toast.error('Could Not Save Job', {position: toast.POSITION.TOP_RIGHT, theme: "colored", transition: Slide,  hideProgressBar: true})
  }

    useEffect(() => {
        const token = localStorage.getItem('token')
        if(isLoggedIn){
        axios.get(`${url}get-one-job`,{
      params: {id: query_data},
      headers: {
        'Authorization': `Bearer ${token}`
      },

    }).then(
            res => {
              getBookMarkjobs()
                const data = res.data
                setJobDetail({
                    ...jobDetail,
                    createdAt: data.createdAt,
                    information: data.information,
                    jobrole: data.jobrole,
                    state: data.state,
                    country: data.country,
                    jobId: data.jobId,
                    payment: data.payment,
                    updatedAt: data.updatedAt,
                    _id: data._id,
                    anonymous: data.anonymous
                })
                setApplicant(data.applicant)
                set_career_level({
                    ...career_level,
                    title: data.career_level.title
                })
                if(data.category){
                set_category({
                    ...category,
                    category: data.category.category
                })}
                if(data.industry){
                setIndustry({
                    ...industry,
                    name: data.industry.name
                })}
                set_owner({
                    ...owner,
                    company_name: data.owner.company_name,
                    company_email: data.owner.company_email,
                    image: data.owner.image,
                    userId: data.owner.userId,
                    _id: data.owner._id
                })
                setType({
                    ...type,
                    type: data.type && data.type.type
                })
                
                setSkill(data.skill)
                set_job_description(data.jobDescription)
                setTimeout(() => {
              setLoading(false)
            }, 1000)
            }
        )}else{
            axios.get(`${url}get-one-job`, {
              params: {id: query_data}
            }).then(
            res => {
              getBookMarkjobs()
                const data = res.data
                setJobDetail({
                    ...jobDetail,
                    createdAt: data.createdAt,
                    information: data.information,
                    jobrole: data.jobrole,
                    state: data.state,
                    jobId: data.jobId,
                    country: data.country,
                    payment: data.payment,
                    updatedAt: data.updatedAt,
                    _id: data._id

                })
                setApplicant(data.applicant)
                set_career_level({
                    ...career_level,
                    title: data.career_level.title
                })
                if(data.category){
                set_category({
                    ...category,
                    category: data.category.category || ""
                })}
                if(data.industry){
                setIndustry({
                    ...industry,
                    name: data.industry.name
                })}
                set_owner({
                    ...owner,
                    company_name: data.owner.company_name,
                    company_email: data.owner.company_email,
                    image: data.owner.image,
                    _id: data.owner._id
                })
                setType({
                    ...type,
                    type: data.type.type
                })
                setSkill(data.skill)
                set_job_description(data.jobDescription)
                setTimeout(() => {
              setLoading(false)
            }, 1000)
            }
        )
        }
    }, [])


    function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      
      <Modal.Body>
      <FormModal jobId={jobDetail.jobId} />
      </Modal.Body>
    </Modal>
  );
}

  function LoadingModal(props) {
  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      bg="transparent"
    >
      
      <Modal.Body className="center-loader">
      <Spinner color="primary" />
      </Modal.Body>
    </Modal>
  );
}

 function ApplyModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      
      <Modal.Body>
      <ApplicationModal />
      </Modal.Body>
    </Modal>
  );
}

 function ShareModal(props) {
  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      
      <Modal.Body>
      <DisplayShareButton />
      </Modal.Body>
    </Modal>
  );
}
     const getAuthUser = () => {
        if(!isLoggedIn){
            return(
              setModalShow(true)
            )
        }else{
           history.push({pathname:`/job-questions/${jobDetail.jobId}`})
        }
    }

    return (
        <div className="site-wrapper overflow-hidden">
      <header
        className="
          site-header site-header--menu-right
          bg-default
          dynamic-sticky-bg
          py-7 py-lg-0
          site-header--absolute site-header--sticky
        "
      >
      <Navbar/>  
      </header>
      {loading ? 
      <div className="center-sign-in-loading dashboard-main-container" style={{backgroundColor: "#fff", width: "100%", height: "100%", justifyContent: "center", alignItems: "center"}}>
      <ReactLoading className="center-sign-in-loading " type="bars" color="#8B3300" height={'20%'} width={'10%'} />
      </div>
      : <div
        className="jobDetails-section bg-default-1 pt-28 pt-lg-27 pb-xl-25 pb-12"
      >
        <div className="container">
          <div className="row justify-content-center">
            <div
              className="
                col-xl-10 col-lg-11
                mt-4
                ml-xxl-32 ml-xl-15
                dark-mode-texts
              "
            >
              <div className="mb-9">
                <a
                onClick={e => {e.preventDefault(); history.goBack()}}
                  className="d-flex align-items-center ml-4"
                  href="search-grid.html"
                >
                  <i
                    className="
                      icon icon-small-left
                      bg-white
                      circle-40
                      mr-5
                      font-size-7
                      text-black
                      font-weight-bold
                      shadow-8
                    "
                  >
                  </i ><span
                    className="
                      text-uppercase
                      font-size-3 font-weight-bold
                      text-gray
                    "
                    >Back to job board</span></a>
              </div>
            </div>
            <div className="col-xl-9 col-lg-11 mb-8 px-xxl-15 px-xl-0">
              <div className="bg-white rounded-4 border border-mercury shadow-9">
                <div
                  className="
                    pt-9
                    pl-sm-9 pl-5
                    pr-sm-9 pr-5
                    pb-8
                    border-bottom border-width-1 border-default-color
                    light-mode-texts
                  "
                >
                  <div className="row">
                    <div className="col-md-6">
                      <div className="media align-items-center">
                        <div className="">
                          {!jobDetail.anonymous ? <img className="square-72 d-block mr-8" src={owner.image ? owner.image : profile} alt="" /> : null}
                        </div>
                        <div>
                          <h3 className="font-size-6 mb-0">{jobDetail.jobrole}</h3>
                          {!jobDetail.anonymous ? <Link  to={{pathname: `/company-profile/${owner.userId}`}}><span className="font-size-3 text-gray line-height-2"
                            >{owner.company_name}</span></Link>: null}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 text-right pt-7 pt-md-0 mt-md-n1">
                      <div className="media justify-content-md-end">
                        <p className="font-size-4 text-gray mb-0">{moment(new Date(jobDetail.createdAt)).format("MMMM Do YYYY hh:mm a")}</p>
                      </div>
                    </div>
                  </div>
                  <div className="row pt-9">
                    <div className="col-12">
                      <div className="card-btn-group">
                        <Button
                        onClick={() => {getAuthUser()}}
                          className="
                            btn btn-green
                            text-uppercase
                            btn-medium
                            rounded-3
                            w-180
                            mr-4
                            mb-5
                          "
                          data-toggle="modal"
                          data-target="#apply"
                          >Apply to this job</Button>
                      {isLoggedIn ? <>{bookmarks.some(b => b === jobDetail._id) ?<button  
                            className="
                              btn btn-outline-mercury
                              text-black-2 text-uppercase
                              btn-medium
                              rounded-3
                            "
                            onClick={e => {const id = jobDetail._id; deleteBookMark({e, id})}}
                            ><div><i
                              className="
                                icon icon-bookmark-2
                                font-weight-bold
                                mr-4
                                font-size-4
                              "
                            ></i>
                            Unsave</div></button>: <button  
                            className="
                              btn btn-outline-mercury
                              text-black-2 text-uppercase
                              btn-medium
                              rounded-3
                            "
                            onClick={e => {const job_id = jobDetail._id; createBookMark({e, job_id})}}
                            ><div><i
                              className="
                                icon icon-bookmark-2
                                font-weight-bold
                                mr-4
                                font-size-4
                              "
                            ></i>
                            Save It</div></button>}</> : null}
                        <button
                        
                         className="
                              btn btn-outline-mercury
                              text-black-2 text-uppercase
                              btn-medium
                              rounded-3
                              ml-4
                            "
                        onClick={() => {setShowShareButton(true)}}><i className="far fa-share-square
                        font-weight-bold
                                mr-4
                                font-size-4"></i> Share</button>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="
                    job-details-content
                    pt-8
                    pl-sm-9 pl-6
                    pr-sm-9 pr-6
                    border-width-1 border-default-color
                    light-mode-texts
                  "
                >
                  <div className="row mb-7">
                    <div className="col-md-4 mb-md-0 mb-6">
                      <div className="media justify-content-md-start">
                      <div className="image mr-5 text-green">
                        <i className="fa fa-money-bill mr-2 font-weight-bold"></i>
                        </div>
                        <p
                          className="
                            font-weight-semibold font-size-5
                            text-black-2
                            mb-0
                          "
                        >
                          <span className="text-green font-weight-semibold"
                            ></span>
                        {jobDetail.payment}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-4 pr-lg-0 pl-lg-10 mb-md-0 mb-6">
                      <div className="media justify-content-md-start">
                        <div className="image mr-5 text-green">
                          <i className="fa fa-briefcase mr-2 font-weight-bold"></i>
                        </div>
                        <p
                          className="
                            font-weight-semibold font-size-5
                            text-black
                            mb-0
                          "
                        >
                          {type.type}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-4 pl-lg-0">
                      <div className="media justify-content-md-start">
                        <div className="image mr-5 text-green">
                          <i className="icon icon-pin-3 mr-2 font-weight-bold"></i>
                        </div>
                        <p
                          className="
                            font-size-5 font-weight-semibold
                            text-black
                            mb-0
                          "
                        >{jobDetail.state+', '+jobDetail.country}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4 mb-lg-0 mb-10">
                      <div className="">
                        <span className="font-size-4 d-block mb-4 text-gray"
                          >Career Level</span>
                        <h6
                          className="
                            font-size-5
                            text-black-2
                            font-weight-semibold
                            mb-9
                          "
                        >
                         {career_level.title}
                        </h6>
                      </div>
                      
                    </div>
                    {!jobDetail.anonymous ? <div className="col-md-4 pr-lg-0 pl-lg-10 mb-lg-0 mb-8">
                      <div className="">
                        <span className="font-size-4 d-block mb-4 text-gray"
                          >Industry</span>
                        <h6
                          className="
                            font-size-5
                            text-black-2
                            font-weight-semibold
                            mb-9
                          "
                        >
                        {industry.name}
                        </h6>
                      </div>
                    </div> : null}
                    <div className="col-md-4 pl-lg-0">
                      {!jobDetail.anonymous ? <div className="">
                        <span className="font-size-4 d-block mb-4 text-gray"
                          >Company size</span>
                        <h6
                          className="
                            font-size-5
                            text-black-2
                            font-weight-semibold
                            mb-0
                          "
                        >
                          11-50 employees
                        </h6>
                      </div> : null}
                    </div>
                  </div>
                </div>
                <div
                  className="
                    job-details-content
                    pt-8
                    pl-sm-9 pl-6
                    pr-sm-9 pr-6
                    pb-10
                    border-bottom border-width-1 border-default-color
                    light-mode-texts
                  "
                >
                   <div className="tags">

                      <p className="font-size-4 text-gray mb-3">

                        Skill Required

                      </p>

                      <ul

                        className="

                          d-flex

                          list-unstyled

                          flex-wrap

                          pr-sm-25 pr-md-0

                        "

                      >

                        {skill.map( i => {return ( <li

                          className="

                            bg-regent-opacity-15

                            mr-3

                            h-px-33

                            text-center

                            flex-all-center

                            rounded-3

                            px-5

                            font-size-3

                            text-black-2

                            mt-2

                          "

                        >

                          {i.title}

                        </li> )})}

                      </ul>

                      </div>

                      </div>

                      <div

                      className="

                      job-details-content

                      pt-8

                      pl-sm-9 pl-6

                      pr-sm-9 pr-6

                      pb-10

                      light-mode-texts

                      "

                      >
                     <MyVerticallyCenteredModal
                show={modalShow}
                onHide={() => setModalShow(false)}
              />
               <ApplyModal
                show={applyModal}
                onHide={() => setApplyModal(false)}
              />
              <ShareModal
                show={showShareButton}
                onHide={() => setShowShareButton(false)}
              />
               <LoadingModal
                show={loading}
                onHide={() => setLoading(false)}
              />
                  <div>
                    <div className="">
                      <div className="">
                        <p className="mb-4 font-size-4 text-gray">
                          Job Description
                        </p>
                      
                        <div className="m-5"><p>{parse(job_description)}</p></div>
                      </div>
                      <div className="">
                        <button
                        onClick={() => { getAuthUser()}}
                          className="
                            btn btn-green
                            text-uppercase
                            btn-medium
                            w-180
                            h-px-48
                            rounded-3
                            mr-4
                            mt-6
                          "
                          data-toggle="modal"
                          data-target="#apply"
                          >Apply to this job</button >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      } </div>
    )
}