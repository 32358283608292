import React, { useEffect } from 'react'
import Navbar from '../shared-components/navbar'
import Sidebar from '../shared-components/sidebar'
import card from '../image/svg/card.svg'
import { Link } from 'react-router-dom'
import moment from 'moment'
import axios from 'axios'
import Paginating from "../pagination"
import numeral from 'numeral'
import { useAuths, typed_plans, ACTIONS } from '../AuthContext'
import { Helmet } from 'react-helmet'
import ReactLoading from "react-loading";
 import { PaystackButton } from 'react-paystack';
 import { toast } from 'react-toastify'
toast.configure()
const url = process.env.REACT_APP_SERVER_URL

export default function EmployerBilling () {
    const {currentUser, loadPaymentData, state} = useAuths()
    const [currentPage, setCurrentPage] = React.useState(1)
  const [dataPerPage] = React.useState(5)
  
  const indexOfLastPost = currentPage * dataPerPage;
  const indexOfFirstPage = indexOfLastPost - dataPerPage;
  const currentData = state.payment_history.slice(indexOfFirstPage, indexOfLastPost);
  
  
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber)
  }
  const previousPage = () => {
    if(currentPage - 1 > 0){
      setCurrentPage(currentPage - 1)
    }
  }
  const nextPage = (dataNumber) => {
    if(currentPage + 1 <= dataNumber){
      setCurrentPage(currentPage + 1)
    }
  }
  


 useEffect(() => {
    let mounted = true
    if(mounted){
        loadPaymentData()
    }
 }, [])

    return (
        <div>
           <Helmet>
            <title> Billing Page - Job Board | StriveHR </title>
            </Helmet>
            <Navbar/>
            <Sidebar/>
            <div className="dashboard-main-container mt-25 mt-lg-31" id="dashboard-body">
       
        <div className="container">
          <div className="mb-14">
            <div className="col-12 col-xl-10 col-lg-8">
              
              <div className="mb-5">
                <h3 className=" mb-9">Billing details</h3>
                <div className="row justify-content-center">
                  <div className="col-lg-6 col-md-6 col-sm-11 mb-9">
                    {/* <!-- Single Featured Job --> */}
                    <div
                      className="
                        pt-9
                        px-xl-9 px-lg-7 px-7
                        pb-7
                        light-mode-texts
                        bg-white
                        rounded
                        hover-shadow-3
                      "
                    >
                      <div className="d-flex">
                            <div>
                                <p className="font-size-5 mb-0">{currentUser.free_trial ? "Free Trial" : null}</p>
                                <h3 className="font-size-10 mb-0 p-0">
                                ₦{currentUser.plan && state.current_plan ? numeral(state.current_plan.price).format('0,0') : 0}
                                </h3>
                                <h3 className="small">{currentUser.plan === "basicplan8000" ? "One off" : `valid till ${moment(currentUser.subscriptionExpiryDate).format("MMMM Do YYYY")}`}</h3>
                            </div>
                            <div className="text-white bg-turquoise ml-auto square-70 rounded-4 mb-7 font-size-7 text-center">
                            <i className="fas fa-star"></i>
                            </div>
                      </div>

                      <div className=" pt-10">
                        <div className="list-unstyled mb-1 d-flex flex-wrap">
                            <Link
                              to="/pricing"
                              className="
                                bg-regent-opacity-15
                                text-denim
                                font-size-5
                                rounded-5
                                min-width-px-100
                                px-10
                                flex-all-center
                                mr-6
                                h-px-33
                                p-8
                              "
                            >
                              Change Plan
                            </Link>
                        </div>
                      </div>
                    </div>
                    {/* <!-- End Single Featured Job --> */}
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-11 mb-9">
                    {/* <!-- Single Featured Job --> */}
                    {/* <div
                      className="
                        pt-9
                        px-xl-9 px-lg-7 px-7
                        pb-7
                        light-mode-texts
                        bg-secondary
                        rounded
                        hover-shadow-3
                      "
                    >
                      <div className="d-flex">
                            <div>
                                <p className="font-size-7 mb-0 text-white">Payment Information</p>
                            </div>
                            <div className="text-white ml-auto font-size-7 ">
                            <div><i className="fas fa-edit text-white"></i></div>
                            </div>
                      </div>
                      <div className="d-flex pt-21">
                        <div className="d-flex mb-1 flex-wrap">
                            <div>
                                <img src={card} alt="" />
                            </div>
                            <div className="mt-auto p-5 ">
                                <p className="small mb-0 text-white">Mastercard <b>7673</b></p>
                                <p className="small mb-0 text-white">Exp. Date <b>12/24</b></p>
                            </div>
                        </div>
                      </div>
                    </div> */}
                    {/* <!-- End Single Featured Job --> */}
                  </div>
                  
                </div>
              </div>
              {/* <!-- Top End --> */}
              
            </div>
          </div>
          
        </div>
        <div className="container">
          <div className="mb-14">
            <div className="col-12 col-xl-10 col-lg-8">
        <div className="mb-18">
          <div className="row mb-11 align-items-center">
            <div className="col-lg-6 mb-lg-0 mb-4">
              <h3 className="font-size-6 mb-0">Payment History</h3>
            </div>
           
          </div>
          <div className="bg-white shadow-8 pt-7 rounded pb-9 px-11">
            <div className="table-responsive ">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th scope="col" className="pl-0 border-0 font-size-4 font-weight-normal">Amount</th>
                    <th scope="col" className="pl-4 border-0 font-size-4 font-weight-normal">Status</th>
                    <th scope="col" className="pl-4 border-0 font-size-4 font-weight-normal">Date</th>
                    <th scope="col" className="pl-4 border-0 font-size-4 font-weight-normal">Reference</th>
                  </tr>
                </thead>
                <tbody>
                  {state.payment_history.length && currentData.map(i => {return(<tr className="border border-color-2">
                    <th scope="row" className="pl-6 border-0 py-7 min-width-px-125">
                      <div className="">
                        <h3 className="font-size-4 mb-0 font-weight-semibold text-black-2">{numeral(i.amount).format('0,0')}</h3>
                      </div>
                    </th>
                    <td className="table-y-middle py-7 min-width-px-135">
                      <h3 className="font-size-4 font-weight-normal  mb-0 text-turquoise">Success</h3>
                    </td>
                    <td className="table-y-middle py-7 min-width-px-125">
                      <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">{moment(new Date(i.createdAt)).format("MMMM Do YYYY")}</h3>
                    </td>
                    <td className="table-y-middle py-7 min-width-px-100">
                      <div to="payme_detail" className="font-size-3 font-weight-bold text-green text-uppercase" data-toggle="modal" data-target="#receipt">{i.refnumber}</div>
                    </td>
                  </tr>)})}
                </tbody>
              </table>

              <Paginating dataPerPage={dataPerPage} totalData={state.payment_history.length} paginate={paginate} currentPage={currentPage} nextPage={nextPage} previousPage={previousPage} />
            </div>
          </div>
        </div>
        </div>
        </div>
        </div>
      </div>
    </div>
    ) 
}