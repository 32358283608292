import React from 'react'
import Navbar from '../shared-components/navbar'
import SideBar from '../shared-components/sidebar'
import Career from './Careers'
import Industry from './Industries'
import Skill from './Skills'
import Jobtype from './Job_types'
import { Helmet } from 'react-helmet'
import JobCategory from './JobCategory'


export default function Jobs() {
    return(
        <div>
          <Helmet>
            <title>Datas - Job Board | StriveHR </title>
            </Helmet>
            <Navbar/>
            <SideBar/>
            <div className="dashboard-main-container mt-25 mt-lg-31" id="dashboard-body">
              <div className=" container">
                <div className="row">
                <Jobtype/>
                <Career/>
                <Skill/>
                <Industry/>
                <JobCategory/>
                </div>
              </div>
            </div>
        </div>
    )
}