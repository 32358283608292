import React, { useEffect, useRef, useState } from 'react'
import Navbar from '../shared-components/navbar'
import Sidebar from '../shared-components/sidebar'
import io from "socket.io-client";
import axios from 'axios'
import moment from 'moment'
import ScrollToBottom from 'react-scroll-to-bottom';
import  { useAuths }  from '../AuthContext';
import { Spinner } from 'reactstrap';
import ReactLoading from "react-loading";
import { Modal } from 'react-bootstrap';
import ConvoList from './convo_list'
import { Helmet } from 'react-helmet'
import { format } from 'timeago.js';
const url = process.env.REACT_APP_SERVER_URL


export default function Messaging ({location}) {
   const [conversations, setConversations] = useState([]);
   const [loading, setLoading] = React.useState(true)
  const [currentChat, setCurrentChat] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [otherChatter, setOtherChatter] = useState({
    image: "",
    isCandidate: true,
    isEmployer: false,
    first_name: "",
    last_name: "",
    company_name: ""
  })
  const [arrivalMessage, setArrivalMessage] = useState(null);
  const socket = useRef();
  const { currentUser } = useAuths()
  const scrollRef = useRef();

  useEffect(() => {
    socket.current = io(url);
    socket.current.on("getMessage", (data) => {
      setArrivalMessage({
        sender: data.senderId,
        text: data.text,
        createdAt: Date.now(),
      });
    });
   
  
  }, []);

  useEffect(() => {
    arrivalMessage &&
      currentChat?.members.includes(arrivalMessage.sender) &&
      setMessages((prev) => [...prev, arrivalMessage]);
  }, [arrivalMessage, currentChat]);

  useEffect(() => {
    socket.current.emit("addUser", currentUser._id);
    socket.current.on("getUsers", (users) => {
      /*setOnlineUsers(
        user.followings.filter((f) => users.some((u) => u.userId === f))
      );*/
    });
  }, []);

  
   
  useEffect(() => {
    const getConversations = async () => {
      try {
         const token = localStorage.getItem("token")
         await axios.get(`${url}get-user-conversation`, {
      headers: {
                'Authorization': `Bearer ${token}`
            }}).then(res => {
        setConversations(res.data)
         setTimeout(() => {
          setLoading(false)
        }, 2000)
      })
      } catch (err) {
        console.log(err);
      }
    };
    getConversations();
  }, [currentUser._id]);

 /* useEffect(() => {
    const getMessages = async () => {
       const token = localStorage.getItem("token")
      try {
       await axios.get(`${url}messages/${currentChat?._id}`,{
      headers: {
                'Authorization': `Bearer ${token}`
            }}).then(res => {
        setMessages(res.data)})
      } catch (err) {
        console.log(err);
      }
    };
    getMessages();
  }, [currentChat]);*/
  const getOneUserMessage = ({e, chatId, activeUser}) =>{
    e.preventDefault();
    const friendId = activeUser[0]
     const token = localStorage.getItem("token")
    axios(`${url}getoneuser/${friendId}`,{
      headers: {
                'Authorization': `Bearer ${token}`
            }}).then(
      response => {
        setOtherChatter({
          ...otherChatter,
          isCandidate: response.data.isCandidate,
          isEmployer: response.data.isEmployer,
          first_name: response.data.first_name,
          last_name: response.data.last_name,
          company_name: response.data.company_name,
          image: response.data.image
        })
      }
    )
    axios.get(`${url}get-chat/${chatId}`,{
      headers: {
                'Authorization': `Bearer ${token}`
            }}).then(
      response => {
        setMessages(response.data)
      }
    )
  } 

  const handleSubmit = async (e) => {
    e.preventDefault();
    const message = {
      sender: currentUser._id,
      text: newMessage,
      conversationId: currentChat._id,
    };

    const receiverId = currentChat.members.find(
      (member) => member !== currentUser._id
    );

    socket.current.emit("sendMessage", {
      senderId: currentUser._id,
      receiverId,
      text: newMessage,
    });

    try {
       const token = localStorage.getItem("token")
      axios.post(`${url}create-chat`, message, {
      headers: {
                'Authorization': `Bearer ${token}`
            }}).then(
         res =>{
      setMessages([...messages, res.data]);
      setNewMessage("")})
    } catch (err) {
      console.log(err);
    }
  };


  useEffect(() => {
    scrollRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);



    return (
        <div>
           <Helmet>
            <title>Conversation - Job Board | StriveHR </title>
            </Helmet>
            <Navbar/>
            <Sidebar/>


            {loading ? 
            <div className="center-sign-in-loading dashboard-main-container" style={{backgroundColor: "#fff", width: "100%", height: "100%", justifyContent: "center", alignItems: "center"}}>
      <ReactLoading className="center-sign-in-loading " type="bars" color="#8B3300" height={'20%'} width={'10%'} />
      </div>:
            <div className="dashboard-main-container mt-10 mt-lg-10" id="dashboard-body">
            <div className="container">
           
          <div className="mb-14">
            <div className="row mb-11 align-items-center">
              <div className="col-lg-6 mb-lg-0 mb-4">
                <h3 className="mb-0 pl-7">My Messages</h3>
              </div>
            </div>

            <div className="container p-0">
              <div className="card">
                <div className="row g-0">
                
                  <div className="conversation-list col-12 col-lg-5 col-xl-3 border-right">
                    <div className="px-4 d-none d-md-block">
                      <div className="d-flex align-items-center">
                        <div className="flex-grow-1">
                          <input
                            type="text"
                            className="form-control my-3 rounded-pill"
                            placeholder="Search..."
                          />
                        </div>
                      </div>
                    </div>
                    {conversations.sort((a, b) => b.updatedAt > a.updatedAt ? 1:-1).map((c) => (
                      <div onClick={(e)  => {const chatId = c._id; const activeUser = c.members.filter(i => { return i !== currentUser._id}); setCurrentChat(c); getOneUserMessage({e, chatId,  activeUser})}}>
                        <ConvoList currentChat={currentChat} conversation={c}  />
                      </div>
                    ))}
                   
                    <hr className="d-block d-lg-none mt-1 mb-0" />
                  </div>
                  <div className="chat-list col-12 col-lg-7 col-xl-9">
                    <div className="py-2 px-4 border-bottom d-none d-lg-block">
                      <div className="d-flex align-items-center py-1">
                        <div className="position-relative">
                          {otherChatter.image ? <img
                            src={otherChatter.image}
                            className="rounded-circle mr-1"
                            alt="Sharon Lessman"
                            width="40"
                            height="40"
                          /> : <div className="rounded-circle mr-1 bg-primary" style={{ width: 40, height: 40}}></div>}
                        </div>
                        <div className="flex-grow-1 pl-3">
                          <strong>{otherChatter.isCandidate ? otherChatter.first_name+' '+otherChatter.last_name : otherChatter.company_name}</strong>
                          <div className="text-muted small"><em></em></div>
                        </div>
                      </div>
                    </div>

                    <div className="">
                      <div className="chat-messages p-4" ref={scrollRef}>
                        {messages.map ((i) => {return (i.sender === currentUser._id ?<div className="chat-message-right pb-4">
                          <div
                            className="
                              flex-shrink-1
                              bg-light
                              rounded
                              py-2
                              px-3
                              ml-3
                            "
                          >
                            <div className="text-muted small text-nowrap mt-2">
                              {format(i.createdAt)}
                            </div>
                            <p className="font-size-3 pb-0 mb-0">
                             {i.text}
                            </p>
                          </div>
                        </div>:   <div className="chat-message-left pb-4">
                          <div
                            className="
                              flex-shrink-1
                              bg-message
                              rounded
                              py-2
                              px-3
                              mr-3
                            "
                          >
                            <div className="small text-nowrap mt-2"> 
                            {format(i.createdAt)}</div>
                            <p className="font-size-3 pb-0 mb-0 text-white">
                               {i.text}
                            </p>
                          </div>
                        </div>)})}
                      </div>
                    </div>
                          
                    <div className="flex-grow-0 py-3 px-4 border-top">
                      <div className=" text-bar input-group">
                        <label for="fileUpload" className="mb-0 mt-6">
                          <i
                            className="fas fa-paperclip ml-2 mr-6 font-weight-100"
                          ></i>
                        </label>
                        <input
                          type="file"
                          id="fileUpload"
                          className="sr-only mr-6 transparent"
                        />
                        <input
                          onChange={e => {setNewMessage(e.target.value)}}
                          type="text"
                          value={newMessage}
                          className="form-control"
                          placeholder="Type your message"
                        />
                        <button className="btn btn-primary ml-6 mt-3" onClick={e => {handleSubmit(e)}}>Send</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>}</div>
    )
}
