import React, {useEffect, useState} from "react"
import Navbar from '../shared-components/navbar'
import SideBar from '../shared-components/sidebar'
import axios from 'axios'
import {Button, Modal} from 'react-bootstrap';
import { Helmet } from 'react-helmet'
import CreateAdminModal from "./Forms/CreateAdmin";
import { useAuths } from "../AuthContext";
import { toast, Slide } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
const url = process.env.REACT_APP_SERVER_URL


toast.configure()




export default function Users() {
  const [users, set_users] = useState([])
  const [user_id, set_user_id] = React.useState("")
  const [deleteModal, setDeleteModal] = React.useState(false)
  const [createModal, setCreateModal] = React.useState(false)
  const [disableModal, setDisableModal] = React.useState(false)
  const [enableModal, setEnableModal] = React.useState(false)
  const { isLoading } = useAuths()


  const loadData = () => {
  isLoading(true)
  const token = localStorage.getItem('token')
    axios.get(`${url}get-all-sub-admin`,{headers: {
                'Authorization': `Bearer ${token}`
            }}).then(res => {
      set_users(res.data)
      isLoading(false)
    }).catch(err => {
      isLoading(false)
    })
  }

  useEffect(() => {
    let mounted = true
    if(mounted){
      loadData()
    }
    return() => {
      mounted = false
    }
  }, [])

const success = () => {
    toast.success("Activation Link Sent Sub Administrator's Email", {position: toast.POSITION.TOP_RIGHT, theme: "colored", transition: Slide,  hideProgressBar: true})
  }



const error = () => {
    toast.error('Could Not Send Activation Link to Sub Administrator, Please Try Again', {position: toast.POSITION.TOP_RIGHT, theme: "colored", transition: Slide,  hideProgressBar: true})
  }


  
  const send_activation_link = (email) => {
    const token = localStorage.getItem('token')
    axios.post(`${url}get-sub-admin-creation-link`, {email}, {headers: {
                'Authorization': `Bearer ${token}`
            }}).then(
      res => {
        if(res){
        loadData()
        success()}
      }
    ).catch(err => {
       
        error()
    })
  }
  

  const enable_success = () => {
    toast.success('User Enabled Successfully', {position: toast.POSITION.TOP_RIGHT, theme: "colored", transition: Slide,  hideProgressBar: true})
  }

  const enable_error = () => {
      toast.error('Could Not Enable User, Please try again', {position: toast.POSITION.TOP_RIGHT, theme: "colored", transition: Slide,  hideProgressBar: true})
  }

  const disable_success = () => {
    toast.success('User Disabled Successfully', {position: toast.POSITION.TOP_RIGHT, theme: "colored", transition: Slide,  hideProgressBar: true})
  }

  const disable_error = () => {
      toast.error('Could Not Disable User, Please try again', {position: toast.POSITION.TOP_RIGHT, theme: "colored", transition: Slide,  hideProgressBar: true})
  }

  const delete_success = () => {
    toast.success('User Deleted Successfully', {position: toast.POSITION.TOP_RIGHT, theme: "colored", transition: Slide,  hideProgressBar: true})
  }

  const delete_error = () => {
      toast.error('Could Not Delete User, Please try again', {position: toast.POSITION.TOP_RIGHT, theme: "colored", transition: Slide,  hideProgressBar: true})
  }



      function DisableUserModal(props) {
  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      bg="transparent"
    >
      <Modal.Body style={{backgroundColor: "#f2edf3"}}>
          <div className="text-center m-3 h6">Are you sure you want to disable user?.</div>
        <div className="row justify-content-space-between m-3">
    <Button block onClick={(e) => {disableUser(e)}} className="btn btn-danger">Confirm</Button>
    <Button block onClick={() => setDisableModal(false)} className="btn btn-primary">Cancel</Button>
    </div>
      </Modal.Body>
    </Modal>
  );
}

const disableUser = (e) => {
  const token = localStorage.getItem('token')
  e.preventDefault();
  axios.put(`${url}disable-user/${user_id}`,{}, {
    headers: {
        'Authorization': `Bearer ${token}`
      }
  }).then(res => {
    disable_success()
    loadData()
    setDisableModal(false)
  }).catch(err => {
    disable_error()
  })
}

function CreateUserModal(props) {
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      bg="transparent"
    >
      <Modal.Body >
        <CreateAdminModal loadData={loadData}/>
      </Modal.Body>
    </Modal>
  );
}

 function EnableUserModal(props) {
  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      bg="transparent"
    >
      <Modal.Body style={{backgroundColor: "#f2edf3"}}>
          <div className="text-center m-3 h6">Are you sure you want to enable user?.</div>
        <div className="row justify-content-space-between m-3">
    <Button block onClick={(e) => {enableUser(e)}} className="btn btn-danger">Confirm</Button>
    <Button block onClick={() => setEnableModal(false)} className="btn btn-primary">Cancel</Button>
    </div>
      </Modal.Body>
    </Modal>
  );
}

const enableUser = (e) => {
  e.preventDefault();
  const token = localStorage.getItem('token')
  axios.put(`${url}enable-user/${user_id}`,{}, {
    headers: {
        'Authorization': `Bearer ${token}`
      }
  }).then(res => {
    enable_success()
    loadData()
    setEnableModal(false)
  }).catch(err => {
    enable_error()
  })
}

    function DeleteUserModal(props) {
  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      bg="transparent"
    >
      <Modal.Body style={{backgroundColor: "#f2edf3"}}>
          <div className="text-center m-3 h6">Are you sure you want to delete User?. <br/>Note: This action is not reversable </div>
        <div className="row justify-content-space-between m-3">
    <Button block onClick={(e) => {deleteUser(e)}} className="btn btn-danger">Confirm</Button>
    <Button block onClick={() => setDeleteModal(false)} className="btn btn-primary">Cancel</Button>
    </div>
      </Modal.Body>
    </Modal>
  );
}

const deleteUser = (e) => {
  e.preventDefault();
  const token = localStorage.getItem('token')
  axios.delete(`${url}deleteusertemporary/${user_id}`,{
    headers: {
        'Authorization': `Bearer ${token}`
      }
  }).then(data => {
    delete_success()
    loadData()
    setDeleteModal(false)
  }).catch(err => {
    delete_error()
  })
}

  


    return(
        <div>
          <Helmet>
            <title> Sub Administrators - Job Board | StriveHR </title>
            </Helmet>
            <Navbar/>
            <SideBar/>
            <div className="dashboard-main-container mt-25 mt-lg-31" id="dashboard-body">
            <div className="container">
        <div className="mb-14">
          <div className="bg-white shadow-8 pt-7 rounded pb-8 px-11">
          <div className="row mb-11 align-items-center">
            <div className="col-lg-6 mb-lg-0 mb-4">
              <h3 className="font-size-6 mb-0">Admin User(s)</h3>
            </div>
            <div className="col-lg-6">
              <div className="d-flex flex-wrap align-items-center justify-content-lg-end">
                    <a href="" onClick={e => {e.preventDefault(); setCreateModal(true)}} className="btn btn-green">+ Add New Admin User</a>
              </div>
            </div>
          </div>
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th scope="col" className="border-0 font-size-4 font-weight-normal">Name</th>
                    <th scope="col" className="border-0 font-size-4 font-weight-normal">Email</th>
                    <th scope="col" className="border-0 font-size-4 font-weight-normal">Role</th>
                    <th scope="col" className="border-0 font-size-4 font-weight-normal">Action</th>
                    <th scope="col" className="border-0 font-size-4 font-weight-normal"></th>
                  </tr>
                </thead>
                <tbody>
                  {users.map (i => (!i.isDelete ? <tr className="border border-color-2">
                    <td className="table-y-middle py-7 min-width-px-110 pr-0">
                      <h4 className="font-size-4 mb-0 font-weight-semibold text-black-2">{i.first_name+' '+i.last_name}</h4>
                    </td>
                    <td className="table-y-middle py-7 min-width-px-110 pr-0">
                      <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">{i.email}</h3>
                    </td>
                    <td className="table-y-middle py-7 min-width-px-110 pr-0">
                      <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">Sub Administrator</h3>
                    </td>
                    {i.isDisable ? <td className="table-y-middle py-7 min-width-px-60 pr-0">
                      <div className=""><a href="" onClick={e => {e.preventDefault(); set_user_id(i._id); setEnableModal(true)}} className="font-size-3 font-weight-bold text-green text-uppercase">Enable</a></div>
                    </td> : <td className="table-y-middle py-7 min-width-px-60 pr-0">
                      <div className=""><a href="" onClick={e => {e.preventDefault(); set_user_id(i._id); setDisableModal(true)}} className="font-size-3 font-weight-bold text-green text-uppercase">Disable</a></div>
                    </td>}
                    <td className="table-y-middle py-7 min-width-px-70 pr-0">
                      <div className=""><a href="" onClick={e => {e.preventDefault(); set_user_id(i._id); setDeleteModal(true)}} className="font-size-3 font-weight-bold text-red-2 text-uppercase">Delete</a></div>
                    </td>
                     <td className="table-y-middle py-7 min-width-px-70 pr-0">
                      <div className="">{i.isActive ? <i className="fas fa-envelope text-green"></i>
                      : <a href="" onClick={e => {e.preventDefault(); send_activation_link(i.email)}} className="font-size-3 font-weight-bold text-red-2 text-uppercase"><i className="fas fa-envelope text-primary"></i></a> }</div>
                    </td>
                  </tr>: null))}
                </tbody>
              </table>
            </div>
            <CreateUserModal
              show={createModal}
              onHide={() =>{setCreateModal(false)}}
            />
             <DeleteUserModal
              show={deleteModal}
              onHide={() =>{setDeleteModal(false); set_user_id("")}}
            />
            <DisableUserModal
              show={disableModal}
              onHide={() =>{setDisableModal(false); set_user_id("")}}
            />
            <EnableUserModal
              show={enableModal}
              onHide={() =>{setEnableModal(false); set_user_id("")}}
            /> 
          </div>
        </div>
      </div>
            </div>
        </div>
    )
}