import React, { useEffect } from 'react'
import Navbar from '../shared-components/navbar'
import Sidebar from '../shared-components/sidebar'
import axios from 'axios'
import ReactLoading from "react-loading";
import emptybox from '../image/c-home/empty-box.png'
import {Link} from 'react-router-dom'
import { Helmet } from 'react-helmet'
const url = process.env.REACT_APP_SERVER_URL

export default function SavedJobs () {
    const [saved_jobs, setSavedJobs] = React.useState([])
    const [loading, setLoading] = React.useState(true)


     const getBookMarkjobs = () => {
   const token = localStorage.getItem('token')
    axios.get(`${url}get-all-bookmark-jobs`, {
      headers: {
        'Authorization': `Bearer ${token}`
      },
    }).then(res => {
      setSavedJobs(res.data)
    })
  }

  useEffect(() => {
    let mounted = true
    if(mounted){
        getBookMarkjobs()
        setTimeout(() => {
        setLoading(false)
      }, 2000)
  
    }

    return () => {
        mounted = false
    }
  }, [])



  return(
        <div>
          <Helmet>
          <title> Saved Jobs - Job Board | StriveHR </title>
          </Helmet>
        <Navbar/>
        <Sidebar/>
    {loading ? <div className="center-sign-in-loading dashboard-main-container" style={{backgroundColor: "#fff", width: "100%", height: "100%", justifyContent: "center", alignItems: "center"}}>
      <ReactLoading className="center-sign-in-loading " type="bars" color="#8B3300" height={'20%'} width={'10%'} />
      </div> : <div>
        {
          saved_jobs.length < 1 ?
           <div>
        <div className="dashboard-main-container mt-25 mt-lg-31" id="dashboard-body">
        {/* <!-- Messaging System --> */}
        <div className="container">
          <div className="mb-14">
            <div className="col-12 col-xl-10 col-lg-8">
              {/* <!-- Top Start --> */}
              <div className="mb-5">
                <h3 className=" mb-9">Empty tables</h3>
                
                {/* Empty data  */}
                <div className="row justify-content-center">
                  <div className="col-lg-6 col-md-6 col-sm-11 mb-9 text-center">
                    <img className="w-50" src={emptybox} alt="" />
                    <h2 className="pt-15">No data found</h2>
                    <p>Your data will appear here once you start applying.</p>
                  </div>
                </div>

              </div>
              {/* <!-- Top End --> */}
              
            </div>
          </div>
        </div>
      </div>
        </div> :
             <div>
        <div className="dashboard-main-container mt-25 mt-lg-31" id="dashboard-body">
        {/* <!-- Messaging System --> */}
        <div className="container">
          <div className="mb-14">
            <div className="col-12 col-xl-10 col-lg-8">
              {/* <!-- Top Start --> */}
              <div className="mb-5">
                <h3 className=" mb-9">Saved Jobs</h3>
                <div className="row justify-content-center">
                  {saved_jobs.map( job => {return (<div className="col-lg-6 col-md-6 col-sm-11 mb-9">
                    <div
                    
                      className="
                        pt-9
                        px-xl-9 px-lg-7 px-7
                        pb-7
                        light-mode-texts
                        bg-white
                        rounded
                        hover-shadow-3
                      "
                    ><Link  to={{pathname: "/JobDetails", state:{jobId: job._id}}}>
                      <div className="media align-items-center">
                        <div className="pr-5">
                          {job.owner.image ? <Link to={{pathname: "/company-profile", state: {companyId: job.owner._id}}}><img src={job.owner.image} alt=""/></Link> : null}
                        </div>
                        <div>
                          {!job.anonymous ? <Link to={{pathname: "/company-profile", state: {companyId: job.owner._id}}} className="font-size-3 text-default-color line-height-2">{job.owner.company_name}</Link>: null}
                          <h3 className="font-size-6 mb-0">
                            <div className="heading-default-color font-weight-semibold" >{job.jobrole}</div>
                          </h3>
                        </div>
                      </div>
                      <div className="d-flex pt-17">
                        <ul className="list-unstyled mb-1 d-flex flex-wrap">
                          <li>
                            <div
                              className="
                                bg-regent-opacity-15
                                text-denim
                                font-size-3
                                rounded-3
                                min-width-px-100
                                px-3
                                flex-all-center
                                mr-6
                                h-px-33
                                mt-4
                              "
                            >
                              <i
                                className="icon icon-pin-3 mr-2 font-weight-bold"
                              ></i>
                            {job.country}
                            </div>
                          </li>
                          <li>
                            <div
                              className="
                                bg-regent-opacity-15
                                text-orange
                                font-size-3
                                rounded-3
                                min-width-px-100
                                px-3
                                flex-all-center
                                mr-6
                                h-px-33
                                mt-4
                              "
                            >
                              <i
                                className="fa fa-briefcase mr-2 font-weight-bold"
                              ></i>
                              {job.type.type}
                            </div>
                          </li>
                        </ul>
                        <div
                          className="
                            bookmark-button
                            toggle-item
                            font-size-6
                            ml-auto
                            line-height-reset
                            px-0
                            mt-6
                            text-default-color
                            clicked
                          "
                        >
                        </div>
                      </div>
                      </Link>
                    </div>
                  </div>)})}  
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </div>
        </div>
        }
        </div>}
      
      </div>
  )
}