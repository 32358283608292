import React, {useState} from 'react'
import blackstrivehrlogo from '../image/logo-main-black.png';
// import {Link, useHistory} from 'react-router-dom'
// import  { useAuths }  from '../AuthContext';
import { Helmet } from 'react-helmet'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { Button, Spinner, TabContent } from 'react-bootstrap'
// import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
// import { toast, Slide } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import CandidateLogin from './Components/CandidateLogin';
import EmployerLogin from './Components/EmployerLogin';

export default function Login(){
    const [toggleState, setToggleState] = useState(1);

    const toggleTab = (index) => {
        setToggleState(index);
      };

    return(
           <div className="site-wrapper overflow-hidden">
              <Helmet>
            <title> Sign In - Job Board | StriveHR </title>
            </Helmet>
             <div className="">
                <div className=" min-h-100vh flex-all-center pt-lg-15 pt-xxl-17 pt-27 pb-lg-0 pb-18">
                    <div className="container">
                        <div className="row justify-content-center">
                        <div className="col-xxl-6 col-xl-7 col-lg-8 text-center">
                        <img src={blackstrivehrlogo} alt="" className="p-7"/>
                        <div className='bloc-tabs'>
                            <div className='p-5 mb-5'>
                            <button
                            className={toggleState === 1 ? "tabs active-tabs mr-5" : "tabs  mr-5"}
                            onClick={() => toggleTab(1)}
                            >
                            Candidate Login
                            </button>
                            <button
                            className={toggleState === 2 ? "tabs active-tabs" : "tabs"}
                            onClick={() => toggleTab(2)}
                            >
                            Employer Login
                            </button>
                            </div>
                        </div>

                        <div className='content-tab'>
                            <div className={
                                    toggleState === 1 ? 'content active-content' : 'content'
                                    } >
                                <CandidateLogin />
                            </div>

                            <div className={
                                    toggleState === 2 ? 'content active-content' : 'content'
                                    } >
                               
                               <EmployerLogin />
                            </div>
                        
                        </div>
                        </div>
                    </div>
            </div>
    </div>
        </div>
        </div>
    )
    
}
