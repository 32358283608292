import React, {useEffect, useState} from 'react'
import Navbar from '../shared-components/navbar'
import SideBar from '../shared-components/sidebar'
import {Button, Modal} from 'react-bootstrap';
import axios from 'axios'
import CreatePackage from './Forms/CreatePackages'
import { toast, Slide } from 'react-toastify'
import { Helmet } from 'react-helmet'
import 'react-toastify/dist/ReactToastify.css';
import EditPackage from './Forms/EditPackages'
const url = process.env.REACT_APP_SERVER_URL


toast.configure()


export default function Packages() {
const [package_id, set_package_id] = useState("")
const [package_title, set_package_title] = useState("")
const [package_price, set_package_price] = useState("")
const [deleteModal, setDeleteModal] = useState(false)
const [duration, set_duration] = useState("")
const [package_unit, set_package_unit] = useState("")
const [recommended, set_recommended] = useState(false)
const [package_number_of_jobs, set_package_number_of_jobs] = useState("")
const [comments, set_comments] = useState([])
const [create_package, set_create_package] = React.useState(false)
const [edit_package_modal, set_edit_package_modal] = React.useState(false)
const [packages, set_packages] = React.useState([])



 const delete_package_success = () => {
    toast.success('Package Deleted Successfully', {position: toast.POSITION.TOP_RIGHT, theme: "colored", transition: Slide,  hideProgressBar: true})
  }

const delete_package_error = () => {
    toast.error('Could Delete Package, Please Try Again', {position: toast.POSITION.TOP_RIGHT, theme: "colored", transition: Slide,  hideProgressBar: true})
  }

 

const get_packages = () => {
    const token = localStorage.getItem('token')
    axios.get(`${url}get-all-plan`, {headers: {
        'Authorization': `Bearer ${token}`,
      }}).then(res => {
        set_packages(res.data)
        set_create_package(false)
        set_edit_package_modal(false)
    })
}


useEffect(() => {
    let mounted = true
    if(mounted){
        get_packages()
    }
    return () => {
        mounted = false
    }
}, [])


function EditPackageModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title  id="contained-modal-title-vcenter">
         <h5>Edit Package</h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
    <EditPackage package_title_edit={package_title} package_price_edit={package_price} duration_edit={duration} package_unit_edit={package_unit} 
    recommended_edit={recommended} package_number_of_job_edit={package_number_of_jobs} package_id_edit={package_id} comment_edit={comments} get_packages={get_packages} />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}


function CreatePackageModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title  id="contained-modal-title-vcenter">
         <h5>Create New Package</h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
    <CreatePackage  get_packages={get_packages}/>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

function DeletePackageModal(props) {
  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      bg="transparent"
    >
      <Modal.Body style={{backgroundColor: "#f2edf3"}}>
          <div className="text-center m-3 h6">Are you sure you want to delete Package?. <br/>Note: This action is not reversable </div>
        <div className="row justify-content-space-between m-3">
    <Button block onClick={(e) => {deletePackage(e)}} className="btn btn-danger">Confirm</Button>
    <Button block onClick={() => setDeleteModal(false)} className="btn btn-primary">Cancel</Button>
    </div>
      </Modal.Body>
    </Modal>
  );
}

const deletePackage = (e) => {
e.preventDefault();
const token = localStorage.getItem('token')
axios.delete(`${url}delete-plan/${package_id}`, {headers: {'Authorization': `Bearer ${token}`}}).then(res => {delete_package_success(); 
  get_packages(); setDeleteModal(false)}).catch(err => {delete_package_error()})
}

    return(
        <div>
          <Helmet>
            <title> Packages - Job Board | StriveHR </title>
            </Helmet>
            <Navbar/>
            <SideBar/>
            <div className="dashboard-main-container mt-25 mt-lg-31" id="dashboard-body">
            <div className="container">
        <div className="mb-14">
          <div className="bg-white shadow-8 pt-7 rounded pb-8 px-11">
          <div className="row mb-11 align-items-center">
            <div className="col-lg-6 mb-lg-0 mb-4">
              <h3 className="font-size-6 mb-0">Packages</h3>
            </div>
            <div className="col-lg-6">
              <div className="d-flex flex-wrap align-items-center justify-content-lg-end">
                    <a href="" onClick={e => {e.preventDefault(); set_create_package(true)}} className="btn btn-green">+ Create New Package</a>
              </div>
            </div>
          </div>
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th scope="col" className="border-0 font-size-4 font-weight-normal">Package Title</th>
                    <th scope="col" className="border-0 font-size-4 font-weight-normal">Package Price ($)</th>
                    <th scope="col" className="border-0 font-size-4 font-weight-normal">Duration</th>
                    <th scope="col" className="border-0 font-size-4 font-weight-normal">Package Num Jobs</th>
                    <th scope="col" className="border-0 font-size-4 font-weight-normal">Action</th>
                    <th scope="col" className="border-0 font-size-4 font-weight-normal"></th>
                  </tr>
                </thead>
                <tbody>
                  {packages.map(i => (<tr className="border border-color-2">
                    <td className="table-y-middle py-7 min-width-px-140 pr-0">
                    <h4 className="font-size-4 mb-0 font-weight-semibold text-black-2">{i.name}</h4>
                    </td>
                    <td className="table-y-middle py-7 min-width-px-140 pr-0">
                      <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">${i.amount}</h3>
                    </td>
                    <td className="table-y-middle py-7 min-width-px-140 pr-0">
                      <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">{i.package_unit+' '+i.duration}</h3>
                    </td>
                    <td className="table-y-middle py-7 min-width-px-140 pr-0">
                      <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">{i.number_of_posts}</h3>
                    </td>
                    <td className="table-y-middle py-7 min-width-px-80 pr-0">
                      <div className=""><a href=""
                      onClick={e => {e.preventDefault(); set_package_id(i._id); set_package_title(i.name); set_package_unit(i.package_unit); set_duration(i.duration);
                    set_package_price(i.amount); set_comments(i.comments); set_package_number_of_jobs(i.number_of_posts); set_recommended(i.recommended); set_edit_package_modal(true)}}
                       className="font-size-3 font-weight-bold text-green text-uppercase">Edit</a></div>
                    </td>
                    <td className="table-y-middle py-7 min-width-px-90 pr-0">
                      <div className=""><a href="" onClick={e => {e.preventDefault(); set_package_id(i._id); setDeleteModal(true)}} className="font-size-3 font-weight-bold text-red-2 text-uppercase">Delete</a></div>
                    </td>
                  </tr>))}
                </tbody>
              </table>
            </div>
             <CreatePackageModal
                show={create_package}
                onHide={() =>set_create_package(false)}
              />
             <EditPackageModal
                show={edit_package_modal}
                onHide={() =>set_edit_package_modal(false)}
              />
               <DeletePackageModal
                show={deleteModal}
                onHide={() =>{setDeleteModal(false); set_package_id("")}}
                />
            {/*<div className="row ">
                <div className="col-lg-6 mb-lg-0 mb-4">
                <a href="" className="btn btn-link">Load More</a>
                </div>
            </div>*/}
            
          </div>
        </div>
      </div>
            </div>
        </div>
    )
}