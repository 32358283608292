import React from "react"
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';


const Paginating = ({dataPerPage, totalData, paginate, nextPage, previousPage, currentPage}) => {

const dataNumber = [];

for (let i = 1; i <= Math.ceil(totalData / dataPerPage); i++){
    dataNumber.push(i);
}
    return(
        <Pagination aria-label="Page navigation example" >
        <PaginationItem>
          <PaginationLink previous href="#"onClick={(e) => {e.preventDefault(); previousPage(dataNumber)}} />
        </PaginationItem>
        <PaginationItem  className="row">
          {dataNumber.map(number => (<PaginationLink className={number === currentPage ? "bg-primary text-white": ""} onClick={(e) => {e.preventDefault(); paginate(number)}} href="!#" key={number}>
            {number}
          </PaginationLink>))}
        </PaginationItem>
        <PaginationItem>
          <PaginationLink next href="#" onClick={(e) => {e.preventDefault(); nextPage(dataNumber.length)}} />
        </PaginationItem>
      </Pagination>
    )
}

export default Paginating