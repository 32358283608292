import React from 'react'
import profile from "../image/profile/profile-img.png"
import logo from '../image/logo-main-black.png'
import moment from 'moment'
import {useHistory, Link, useLocation} from 'react-router-dom'
import  { useAuths }  from '../AuthContext';


export default function Navbar () {
const location = useLocation();

const history = useHistory()
const logOut = () => {
  localStorage.removeItem('token')
  signOut()
}

const {isLoggedIn, signOut, currentUser} = useAuths()

var given = currentUser && moment(currentUser.subscriptionExpiryDate, "YYYY-MM-DD");
var current = moment().startOf('day');



if(!isLoggedIn){
    return(
         <div className="container">
          <nav
            className="
              navbar
              site-navbar
              offcanvas-active
              navbar-expand-lg
              px-0
              py-0
            "
          >
            <div className="brand-logo">
              <Link to="/">
                <img
                  src={logo}
                  alt=""
                  className="light-version-logo default-logo"
                />
              </Link>
            </div>
            
            <div className="collapse navbar-collapse" id="mobile-menu">
              <div className="navbar-nav-wrapper">
              <ul className="navbar-nav main-menu">
                
                  <li className={location.pathname === "/" ? "nav-item active" : null}>
                    <Link to="/" className="nav-link" >Home</Link>
                  </li>

                  <li className={location.pathname === "/JobSearch" ? "nav-item active" : null}>
                    <Link to="/JobSearch" className="nav-link" >Jobs</Link>
                  </li>

                  <li className={location.pathname === "/pricing" ? "nav-item active" : null}>
                    <Link to="/pricing" className="nav-link" >Pricing</Link>
                  </li>
                  
                  <li className="nav-item">
                  <a className="nav-link" target="_blank" href="https://strivehumanresources.com/contact-us/">Support</a>
                  </li>

                  <li className="nav-item">
                    <Link to="/login" className="nav-link d-lg-none">Log in</Link>
                  </li>

                  <li className="nav-item">
                    <Link to="/candidate-sign-up" className="nav-link d-lg-none">Sign up</Link>
                  </li>
                </ul>
              </div>
              <button
                className="d-block d-lg-none offcanvas-btn-close focus-reset"
                type="button"
                data-toggle="collapse"
                data-target="#mobile-menu"
                aria-controls="mobile-menu"
                aria-expanded="true"
                aria-label="Toggle navigation"
              >
                <i className="gr-cross-icon"></i>
              </button>
            </div>
            <div
              className="
                header-btns header-btn-devider
                ml-auto
                pr-2
                ml-lg-6
                d-none d-xs-flex
              "
            >
              <Link
              to="/login"
                className="
                  btn btn-transparent
                  text-uppercase
                  font-size-3
                  heading-default-color
                  focus-reset
                "
                href="candidate-login.html"
              >
                Log in
              </Link>
              <Link
              to="/candidate-sign-up"
                className="btn btn-primary text-uppercase font-size-3"
                href="candidate-signup.html"
              >
                Sign up
              </Link>
            </div>
            <button
              className="
                navbar-toggler
                btn-close-off-canvas
                hamburger-icon
                border-0
              "
              type="button"
              data-toggle="collapse"
              data-target="#mobile-menu"
              aria-controls="mobile-menu"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="hamburger hamburger--squeeze js-hamburger">
                <span className="hamburger-box">
                  <span className="hamburger-inner"></span>
                </span>
              </span>
            </button>
          </nav>
        </div>
    )
}else{
    return(
       <header
        className="
          site-header site-header--menu-right
          bg-default
          position-fixed
          py-7 py-xs-0
          site-header--absolute site-header--sticky
        "
      >
         <div className="container">
          <nav
            className="
              navbar
              site-navbar
              offcanvas-active
              navbar-expand-lg
              px-0
              py-0
            "
          >
            <div className="brand-logo">
              <Link to="/">
                <img
                  src={logo}
                  alt=""
                  className="light-version-logo default-logo"
                />
              </Link>
            </div>
            {currentUser.free_trial ? <div className='nav-item active'>
               <Link to="" className="trial_btn ml-lg-30 bg-primary">Free trial | {moment.duration(given.diff(current)).asDays()} days left</Link>
                </div>: null }
            <div className="collapse navbar-collapse" id="mobile-menu">
              
              <div className="navbar-nav-wrapper">
              <ul className="navbar-nav  main-menu">
             
                  <li className={location.pathname === "/" ? "nav-item active" : null}>
                    <Link to="/" className="nav-link" >Home</Link>
                  </li>
                  {currentUser.isCandidate ? <li className={location.pathname === "/jobSearch" ? "nav-item active" : null}>
                    <Link to="/jobSearch" className="nav-link" >Jobs</Link>
                  </li>: null}

                  {currentUser.isEmployer ?<li className={location.pathname === "/pricing" ? "nav-item active" : null}>
                    <Link to="/pricing" className="nav-link" >Pricing</Link>
                  </li> : null}

                  <li className="nav-item">
                    <a className="nav-link" target="_blank" href="https://strivehumanresources.com/contact-us/">Support</a>
                  </li>

                  <li className="nav-item">
                  <Link to="/dashboard" className="nav-link d-lg-none">Dashboard </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="setting" className="nav-link d-lg-none">Settings</Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/profile" className="nav-link d-lg-none">Edit Profile</Link>
                  </li>
                  <li className="nav-item">
                    <Link onClick={() => {logOut()}} to="" className="nav-link d-lg-none">Log out</Link>
                  </li>
                </ul>
              </div>
              <button
                className="d-block d-lg-none offcanvas-btn-close focus-reset"
                type="button"
                data-toggle="collapse"
                data-target="#mobile-menu"
                aria-controls="mobile-menu"
                aria-expanded="true"
                aria-label="Toggle navigation"
              >
                <i className="gr-cross-icon"></i>
              </button>
            </div>
            <div
              className="
                header-btn-devider
                ml-auto ml-lg-5
                pl-2
                d-none d-xs-flex
                align-items-center
              "
            >
              <div>
                <div className="dropdown show-gr-dropdown py-5">
                  <a
                    className="proile media ml-7 flex-y-center"
                    href="#"
                    role="button"
                    id="dropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {/* <div className="circle-40 bg-black">
                {currentUser.image ? <img className="img-responsive" width="125%"  src={currentUser.image}  className="circle-40"  /> :
                <img className="img-responsive" width="125%" src={profile} alt="" />}
            </div> */}
                    <div className="circle-40 ">
                    {currentUser.image ?  <img className="img-responsive circle-40" width="125%" src={currentUser.image} alt="" /> : <img className="img-responsive circle-40" width="125%" src={profile} alt="" />}
                    </div>
                    <i
                      className="fas fa-chevron-down heading-default-color ml-6"
                    ></i>
                  </a>
                  <div
                    className="
                      dropdown-menu
                      gr-menu-dropdown
                      dropdown-right
                      border-0 border-width-2
                      py-2
                      w-auto
                      bg-default
                    "
                    aria-labelledby="dropdownMenuLink"
                  >
                    <Link to="/dashboard"
                      className="
                        dropdown-item
                        py-2
                        font-size-3 font-weight-semibold
                        line-height-1p2
                        text-uppercase
                      "
                      >Dashboard
                    </Link>
                    <Link
                    to="setting"
                      className="
                        dropdown-item
                        py-2
                        font-size-3 font-weight-semibold
                        line-height-1p2
                        text-uppercase
                      "
                      >Settings
                    </Link>
                    <Link
                    to="/profile"
                      className="
                        dropdown-item
                        py-2
                        font-size-3 font-weight-semibold
                        line-height-1p2
                        text-uppercase
                      "
                      >Edit Profile</Link>
                    <Link
                    onClick={() => {logOut()}}
                    to="/"
                      className="
                        dropdown-item
                        py-2
                        text-red
                        font-size-3 font-weight-semibold
                        line-height-1p2
                        text-uppercase
                      "
                      href="#"
                      >Log Out</Link>
                  </div>
                </div>
              </div>
            </div>
            <button
              className="
                navbar-toggler
                btn-close-off-canvas
                hamburger-icon
                border-0
              "
              type="button"
              data-toggle="collapse"
              data-target="#mobile-menu"
              aria-controls="mobile-menu"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              {/* <i className="icon icon-simple-remove icon-close"></i> */}
              <span className="hamburger hamburger--squeeze js-hamburger">
                <span className="hamburger-box">
                  <span className="hamburger-inner"></span>
                </span>
              </span>
            </button>
          </nav>
          </div>
          </header>
    )}
} 