import React, {useState} from 'react';
import { Helmet } from 'react-helmet'
import blackstrivehrlogo from '../image/logo-main-black.png';
import {Link, useHistory} from 'react-router-dom'
import { Button, Spinner } from 'react-bootstrap'
import  { useAuths }  from '../AuthContext';
import { toast, Slide } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
toast.configure()



    export default function CandidateSignUp (){
        const [first_name, set_first_name] = useState("")
        const [last_name, set_last_name] = useState("")
        const [email, setEmail] = useState("")
        const [phone, setPhone] = useState("")
        const [password, setPassword] = useState("")
        const [confirmPassword, setConfirmPassword] = useState("")
        const [btn_loading, set_btn_loading] = useState(false)
        const { signUp } = useAuths()
        const history = useHistory()


  const passwordError = () => {
   toast.error('Password Not Correct', {position: toast.POSITION.TOP_CENTER, theme: "colored", transition: Slide,  hideProgressBar: true})
  }

  const passwordToLess = () => {
   toast.error('Password Cannot be Less Than 8 Character', {position: toast.POSITION.TOP_CENTER, theme: "colored", transition: Slide,  hideProgressBar: true})
  }

  const emailError = () => {
   toast.error('Email Already Exist', {position: toast.POSITION.TOP_CENTER, theme: "colored", transition: Slide,  hideProgressBar: true})
  }

  const errors = () => {
   toast.error('Could Not Sign You Up', {position: toast.POSITION.TOP_CENTER, theme: "colored", transition: Slide,  hideProgressBar: true})
  }

  const required = () => {
    toast.error('All Fields Are Required', {position: toast.POSITION.TOP_CENTER, theme: "colored", transition: Slide,  hideProgressBar: true})
  }


        const handlesubmit = async (e) => {
            e.preventDefault();
            set_btn_loading(true)
        if(!first_name || !last_name || !phone || !password || !email || !confirmPassword){
            required()
            set_btn_loading(false)
        }else if (password !== confirmPassword){
            passwordError()
            set_btn_loading(false)
        }else if (password.trim().length < 8 || confirmPassword.trim().length < 8){
            passwordToLess()
            set_btn_loading(false)
        }else{
        try {
         await signUp({first_name, last_name, phone, password, email})
         await history.push('/verify-email')
       } catch (error) {
         if (error.response.status === 400) {
             emailError()
             set_btn_loading(false)
         } else {
           errors()
           set_btn_loading(false)
         }
       }}
        }

        return(
          
            <div className="site-wrapper overflow-hidden">
               <Helmet>
            <title> Candidate Sign Up - Job Board | StriveHR </title>
            </Helmet>
             <div className="">
        <div className="  min-h-100vh flex-all-center pt-lg-15 pt-xxl-17 pt-27 pb-lg-0 pb-18">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xxl-6 col-xl-7 col-lg-8 text-center">
              <img src={blackstrivehrlogo} alt="" className="p-7"/>
                <div className="mb-15 text-center bg-white px-9 pt-9 pb-7 shadow-8 rounded-4" >
                <div className="row">
                      
                      <div className="col-xs-12 mb-8">
                        <h2 className="font-size-9 mb-4">
                          Let's get to know you
                        </h2>
                        <p className="font-size-4  heading-default-color">
                          Want to hire, &nbsp;
                          <Link to="/employer-sign-up" className="text-primary">
                           Create Company Account</Link>
                        </p>
                        <div>
                            <form>
                            <div className="form-group text-left row pt-6">
                              <div className="col-lg-6">
                              <label
                                  className="
                                    font-size-4
                                    text-black-2
                                    font-weight-semibold
                                    line-height-reset
                                  "
                                  >First Name</label>
                               <input
                                  type="text"
                                  className="form-control"
                                  placeholder="First Name"
                                  id="lname"
                                  onChange={e => {set_first_name(e.target.value)}}
                                />
                              </div>
                              <div className="col-lg-6">
                              <label
                                  className="
                                    font-size-4
                                    text-black-2
                                    font-weight-semibold
                                    line-height-reset
                                  "
                                  >Last Name</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Last Name"
                                  id="lname"
                                   onChange={e => {set_last_name(e.target.value)}}
                                />
                              </div>
                            </div>
                            
                              <div className="form-group text-left">
                                <label
                                  className="
                                    font-size-4
                                    text-black-2
                                    font-weight-semibold
                                    line-height-reset
                                  "
                                  >E-mail</label>
                                <input
                                    type="email"
                                    className="form-control"
                                    placeholder="john@gmail.com"
                                    id="email"
                                     onChange={e => {setEmail(e.target.value)}}
                                  />
                                </div>
                                <div className="form-group text-left">
                                      <label
                                    className="
                                      font-size-4
                                      text-black-2
                                      font-weight-semibold
                                      line-height-reset
                                    "
                                    >Phone Number</label>
                                  <input
                                    type="number"
                                    className="form-control"
                                    placeholder="08000000000"
                                    id="number"
                                     onChange={e => {setPhone(e.target.value)}}
                                  />
                                </div>  
                                <div className="form-group text-left">
                                  <label
                                    className="
                                      font-size-4
                                      text-black-2
                                      font-weight-semibold
                                      line-height-reset
                                    "
                                    >Password</label>
                                  <div className="position-relative">
                                    <input
                                      type="password"
                                      className="form-control"
                                      id="password"
                                      placeholder="Enter password"
                                      onChange={e => {setPassword(e.target.value)}}
                                    />
                             
                                  </div>
                                </div>
                                <div className="form-group text-left">
                                  <label
                                    className="
                                      font-size-4
                                      text-black-2
                                      font-weight-semibold
                                      line-height-reset
                                    "
                                    >Confirm Password</label>
                                  <div className="position-relative">
                                  <input
                                      type="password"
                                      className="form-control"
                                      id="password"
                                      placeholder="Confirm password"
                                       onChange={e => {setConfirmPassword(e.target.value)}}
                                    />
                                  </div>
                                </div>
                                <div className="text-center">
                                  <p className="font-size-3  heading-default-color ">
                                    By clicking Create Account, you agree to the 
                                    <a href="https://strivehumanresources.com/terms/" target="_blank" className="text-primary"
                                      >Terms of Use</a> <br/> and
                                    <a href="https://strivehumanresources.com/policy/" target="_blank" className="text-primary"
                                      >Privacy Policy</a> of StriveHR
                                  </p>
                                </div>
                            <div className="form-group mb-8">
                                {!btn_loading ? <button
                                 onClick={(e) => {handlesubmit(e)}}
                                className="
                                    btn btn-primary btn-medium
                                    w-100
                                    rounded-5
                                    text-uppercase
                                "
                                >
                                Create Account
                                </button> : <Button className="btn btn-primary btn-medium
                                    w-100
                                    rounded-5
                                    text-uppercase" disabled>
                                  <Spinner
                                  as="span"
                                  variant="light"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                  animation="border"/>
                                  
                              </Button>}
                            </div>
                            <div className="text-center">
                            <p className="font-size-4  heading-default-color">
                              Already have an account?&nbsp;
                              <Link to="/login" className="text-primary"
                                >Sign in</Link>
                            </p></div>
                            </form>
                        </div>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
    </div>
    </div>
        </div>
        )
    }
  