import React, {useState} from 'react'
//import  { useAuths }  from '../../AuthContext';
import axios from 'axios'
//import {useHistory} from 'react-router-dom'
import { toast, Slide } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
const url = process.env.REACT_APP_SERVER_URL

export default function Profile ({get_packages}) {
    //const {getUser} = useAuths()
    const [package_title, set_package_title] = useState("")
    const [package_price, set_package_price] = useState("")
    const [duration, set_duration] = useState("")
    const [package_unit, set_package_unit] = useState("")
    const [recommended, set_recommended] = useState(false)
    const [form, setForm] = React.useState([]);
    const [package_number_of_jobs, set_package_number_of_jobs] = useState("")
    const [comments, set_comments] = useState([])
    //const history = useHistory()
 

      function update_profile(e){
        e.preventDefault();
    const token = localStorage.getItem('token')
    if(duration === "year"){
      set_package_unit('1')
    }
    if(!package_title || !duration || !package_price || !package_number_of_jobs || comments.length < 1){
      fillError()
    }else if(duration === "month" && !package_unit){
      enter_month()
    }else{
     axios.post(`${url}create-plan`, { package_title, package_price, duration, package_unit, package_number_of_jobs, comments, recommended}, { 
      headers: {
        'Authorization': `Bearer ${token}`,
      },
 }).then(
        res => {success()
                get_packages()}
        ).catch(err => {
          error()
        })}
    }


const prevIsValid = () => {
    if (form.length === 0) {
      return true;
    }

    const someEmpty = form.some(
      (item) => item.text === "" 
    );

    if (someEmpty) {
      form.map((item, index) => {
        const allPrev = [...form];

        if (form[index].text === "") {
           enter_option()
        }

        setForm(allPrev);
      });
    }

    return !someEmpty;
  };
  
  const handleAddLink = (e) => {
    e.preventDefault();
    const inputState = {
      text: "",
      isAnswer: false,
      errors: {
        text: null,
      },
    };

    if (prevIsValid()) {
      setForm((prev) => [...prev, inputState]);
    }
  };

 
  

  const enter_month = () => {
    toast.warn('Please Select Number of Months', {position: toast.POSITION.TOP_CENTER, theme: "colored", transition: Slide,  hideProgressBar: true})
  }

  const enter_answer = () => {
   toast.error('Please input a question and at least two options', {position: toast.POSITION.TOP_CENTER, theme: "colored", transition: Slide,  hideProgressBar: true})
  }

  const pick_answer = () => {
   toast.error('Please select an answer', {position: toast.POSITION.TOP_CENTER, theme: "colored", transition: Slide,  hideProgressBar: true})
  }

  const enter_option = () => {
   toast.warn('Please select a comment', {position: toast.POSITION.TOP_CENTER, theme: "colored", transition: Slide,  hideProgressBar: true})
  }

  const required = () => {
   toast.error('All fields are required', {position: toast.POSITION.TOP_CENTER, theme: "colored", transition: Slide,  hideProgressBar: true})
  }

   /* const addQuestion = (e) => {
    e.preventDefault();
    if(form.length < 2 || !questions){
      enter_answer()
    }else if(checkAnswer.length !== 1){
      pick_answer()
    }else{
    setQuestion((prev) => [...prev, {questions, form : [...form]}])
    setForm([])
    setQuestions("")}
  }
*/
   const onChange = (index, event) => {
    event.preventDefault();
    event.persist();

    setForm((prev) => {
      return prev.map((item, i) => {
        if (i !== index) {
          return item;
        }

        return {
          ...item,
          [event.target.name]: event.target.value,

          errors: {
            ...item.errors,
            [event.target.name]:
              event.target.value.length > 0
                ? null
                : [event.target.name] + " Is required",
          },
        };
      });
    });
    const get_datas = form.map(i => {return i.text})
    set_comments(get_datas)
  };

  const handleRemoveField = (e, index) => {
    e.preventDefault();

    setForm((prev) => prev.filter((item) => item !== prev[index]));
  };

 const success = () => {
    toast.success('Package Created Successfully', {position: toast.POSITION.TOP_RIGHT})
  }

  const fillError = () => {
    toast.warn('All Fields Required', {position: toast.POSITION.TOP_CENTER, theme: "colored", transition: Slide,  hideProgressBar: true})
  }

  const error = () => {
   toast.error('Could Not Create Packages. Please try again', {position: toast.POSITION.TOP_RIGHT})
  }
    return (
        <div>
            <div className="" id="dashboard-body">
        <div className="">
          <div className="">
            <div className="row">
              <div className="col-xxxl-12 px-lg-13 px-6">
                <div
                  className="
                    bg-white
                    rounded-4
                    pl-sm-10 pl-4
                    pr-sm-11 pr-4
                    pt-15
                    pb-13
                  "
                >
                  <form>
                    <fieldset>
                      <div className="row mb-xl-1 mb-9">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label
                              className="
                                d-block
                                text-black-2
                                font-size-4 font-weight-semibold
                                mb-4
                              "
                            >
                             Packge Title</label>
                            <input
                              onChange={e =>  {set_package_title(e.target.value)}}
                              type="text"
                              className="form-control h-px-48"
                              id="namedash"
                              placeholder="eg. Premium"
                            />
                          </div>
                        </div>
                              <div className="col-lg-6">
                          <div className="form-group">
                            <label
                              className="
                                d-block
                                text-black-2
                                font-size-4 font-weight-semibold
                                mb-4
                              "
                            >
                             Package Price</label>
                            <input
                               onChange={e => set_package_price(e.target.value)}
                              type="number"
                              className="form-control h-px-48"
                              placeholder="eg. 100"
                            />
                          </div>
                        </div>
                           <div className="col-lg-6">
                          <div className="form-group">
                            <label
                              className="
                                d-block
                                text-black-2
                                font-size-4 font-weight-semibold
                                mb-4
                              "
                            >
                             Duration</label>
                              <select className="form-control h-px-48" onChange={e => {set_duration(e.target.value)}} aria-label="Default select example">
                              <option value="" selected>Select Duration</option>
                              <option value="month">Month</option>
                              <option value="year">Year</option>
                            </select>
                          </div>
                        </div>
                        {duration === "month" ? <div className="col-lg-6">
                          <div className="form-group">
                            <label
                              className="
                                d-block
                                text-black-2
                                font-size-4 font-weight-semibold
                                mb-4
                              "
                            >
                              Number of Months</label>
                            <input
                               onChange={e => set_package_unit(e.target.value)}
                              type="number"
                              className="form-control h-px-48"
                              placeholder="eg. 10"
                            />
                          </div>
                        </div> : null}
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label
                              className="
                                d-block
                                text-black-2
                                font-size-4 font-weight-semibold
                                mb-4
                              "
                            >
                              Package Number of Jobs</label>
                            <input
                             onChange={e => set_package_number_of_jobs(e.target.value)}
                              type="number"
                              className="form-control h-px-48"
                              placeholder="eg. 20"
                            />
                          </div>
                        </div>
                               <div className="col-lg-6">
                          <div className="form-group">
                            <label
                              className="
                                d-block
                                text-black-2
                                font-size-4 font-weight-semibold
                                mb-4
                              "
                            >
                              Recommended ?</label>
                            
                           <div className="form-check mb-4">
                            <input className="form-check-input" name="nam" type="radio" id="radio-119" onChange={() => {set_recommended(true)}} />
                            <label className="form-check-label" for="radio-119">Yes</label>
                          </div>
                          <div className="form-check mb-4">
                            <input className="form-check-input" name="nam" type="radio" id="radio-119" onChange={() => {set_recommended(false)}} />
                            <label className="form-check-label" for="radio-119">No</label>
                          </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                             <label
                              className="
                                d-block
                                text-black-2
                                font-size-4 font-weight-semibold
                                mb-4
                              "
                            >
                              Comments</label>
                          <div className="form-group">
                         <ol>
                                  {form.map((item, index) => (<li className="mt-4" key={`item-${index}`}>
                                    <div className=" d-flex justify-content-start ">
                                      <div className="col-lg-12">

                                        <input
                                        type="text"
                                        className="form-control h-px-48"
                                        name="text"
                                        placeholder="Enter Comment"
                                        value={item.text}
                                        onChange={(e) => onChange(index, e)}
                                      />
                                      </div>
                                      <div className="pt-5 pl-5" >
                                        <a href="" onClick={(e) => {e.preventDefault(); handleRemoveField(e, index)}}><i className="fas fa-times"></i></a> 
                                      </div>
                                   </div>
                                   </li>
                                   ))}
                                   <div>
                                   <a href="" className="btn btn-link pt-5" onClick={e => {e.preventDefault(); handleAddLink(e)}}><i className="fas fa-plus"></i> Add Comment</a>
                                  
                                   </div>
                                </ol></div></div>
                      </div>
                      <div className="row">
                     
                        <div className="col-md-12">
                     
                              <button
                            onClick={e => {update_profile(e)}}
                            type="button"
                            className="
                              btn btn-green btn-h-60
                              text-white
                              min-width-px-210
                              rounded-5
                              text-uppercase
                              mb-4
                            "
                          >Create Package</button>
                        </div>
                      </div>
                    </fieldset>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        </div>
    )
}