import React, { useEffect } from 'react'
import axios from 'axios'
import Navbar from './shared-components/navbar'
import Footer from './shared-components/footer'
import ReactLoading from "react-loading";
import { PaystackButton } from 'react-paystack';
import { useAuths } from './AuthContext'
import { Helmet } from 'react-helmet'
import {useHistory} from 'react-router-dom'
const paystack_key = process.env.REACT_APP_PAYSTACK_PUBLIC_KEY
const url = process.env.REACT_APP_SERVER_URL


export default function Pricing (){
  const [plans, setPlans] = React.useState([])
  const [loading, setLoading] = React.useState(true)
  const [plan_id, set_plan_id] = React.useState("")
  const [plan, setPlan] = React.useState("")
  const [amount, setAmount] = React.useState(Number)
  const { currentUser } = useAuths()
  const history = useHistory()

  const typed_plans = [
    {
      name: "Basic Plan",
      info: "For a Single Job Post",
      amount: "8,000",
      cond: "/Per Job Post",
      comments: [
        "Access to resumes from qualified candidates",
        "View candidates profile",
        "Post jobs anonymously"
      ],
      canceled: [
        "Receive applications directly in your email address",
        "Have your job ad published on our social platforms",
        "Get your company referred to qualified candidates",
        "Get access to over 1,000 qualified candidates streamlined to various roles"
      ],
      price: 8000,
      recommended: false,
      id: "basicplan8000"
    },
    {
      name: "Standard Plan",
      info: "Unlimited Job Post",
      amount: "55,000",
      cond: "/Month",
      comments: [
        "Access to resumes from qualified candidates",
        "View candidates profile",
        "Post jobs anonymously",
        "Receive applications directly in your email address",
        "Have your job ad published on our social platforms"
      ],
      canceled: [
        "Get your company referred to qualified candidates",
        "Get access to over 1,000 qualified candidates streamlined to various roles"
      ],
      price: 55000,
      recommended: true,
      id: "standardplan55000"
    },
    {
      name: "Unlimited Plan",
      amount: "600,000",
      info: "Unlimited Job Post",
      cond: "/Year",
      comments: [
        "Access to resumes from qualified candidates",
        "View candidates profile",
        "Post jobs anonymously",
        "Receive applications directly in your email address",
        "Have your job ad published on our social platforms",
        "Get your company referred to qualified candidates",
        "Get access to over 1,000 qualified candidates streamlined to various roles"
      ],
      canceled: [
      
      ],
      price: 600000,
      recommended: false,
      id: "unlimited600000"
    }
  ]

  useEffect(() => {
    setPlans(typed_plans)
    axios.get(`${url}get-all-plan`).then(
      res => {
        //setPlans(typed_plans)
        setTimeout(() => {
        setLoading(false)
      }, 2000)
      }
    )
  }, [])


     const handlePaystackSuccessAction = async (reference, planId, paidAmount, packageName) => {
      if(reference.status === "success"){
        console.log(paidAmount)
      const token = localStorage.getItem('token')
      const refnumber = reference.trxref
      const narration = `Payment for ${packageName} by ${currentUser.company_name}`
      await axios.post(`${url}create-payment`,{amount: paidAmount, refnumber, narration, plan_id: planId},
      {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(response => {
          history.push("/Success")
        })}
    };

    // you can call this function anything
    const handlePaystackCloseAction = () => {
      // implementation for  whatever you want to do when the Paystack dialog closed.
      console.log('closed')
    }


    if(loading){
  return(
    <div className="center-sign-in-loading" style={{backgroundColor: "#fff", width: "100%", height: "100%", justifyContent: "center", alignItems: "center"}}>
      <ReactLoading className="center-sign-in-loading" type="bars" color="#8B3300" height={'20%'} width={'10%'} />
      </div>
  )
}

/*
const getMainPlan = (amount) => {
  if(currentUser.plan === "standardplan55000" && currentUser.subsriptionActive === true && amount === 8000){
    alert(`You are already on an active standard ending on ${currentUser.subscriptionExpiryDate}`)
    return null
  }else if(currentUser.plan === "unlimited600000" && currentUser.subsriptionActive === true && amount === 55000){
    alert(`You are already on an active standard ending on ${currentUser.subscriptionExpiryDate}`)
    return null
  }else {
    return amount * 100
  }
}*/

        const getReference = () => {
    		let text = "";
    		let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-.=";
    		for( let i=0; i < 15; i++ )
    		text += possible.charAt(Math.floor(Math.random() * possible.length));
        return text}

    return (
        <div className="site-wrapper overflow-hidden">
          <Helmet>
            <title>Pricing - Job Board | StriveHR </title>
            </Helmet>
      <header
        className="
          site-header site-header--menu-right
          bg-default
          py-7 py-lg-0
          site-header--absolute site-header--sticky
        "
      >
        <Navbar/>
      </header>
      <div className="pt-md pt-17">
        <div className="pricing-area">
          <div className="container pt-12 pt-lg-24 pb-13 pb-lg-25">
            <div className="row justify-content-center">
              <div
                className="col-xxl-6 col-lg-7 col-md-9"
                data-aos="fade-in"
                data-aos-duration="1000"
                data-aos-once="true"
              >
                <div
                  className="
                    section-title
                    text-center
                    mb-12 mb-lg-18 mb-lg-15
                    pb-lg-15 pb-0
                  "
                >
                  <h2 className="mb-9">
                    Check our amazing plans, choose the best one for you.
                  </h2>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-xxl-10 col-xl-11">
                <div className="row justify-content-center">
                  {plans.map(i => { return(<div key={i.id}
                    className="col-lg-4 col-md-6 col-xs-9"
                    data-aos="fade-right"
                    data-aos-duration="1000"
                    data-aos-once="true"
                  >
                    {/* Card start */}
                    <div className={i.recommended ? 'card border-mercury recomended-pricing rounded-8 mb-lg-3 mb-9 px-xl-12 px-lg-8 px-12 pb-12 mt-lg-n13 hover-shadow-hitgray' : 'card border-mercury rounded-8 mb-lg-3 mb-9 px-xl-12 px-lg-8 px-12 pb-12 hover-shadow-hitgray '}
                     
                    >
                      {/* card header */}
                      <div
                        className="
                          border-bottom
                          bg-transparent
                          border-hit-gray-opacity-5
                          text-center
                          pt-11
                          pb-8
                        "
                      >
                        <div className="pricing-title text-center">
                          {
                            i.recommended ?  <span
                            className="
                              font-size-3 font-weight-bold
                              text-uppercase text-dodger
                              mb-9
                              d-inline-block
                            "
                            >RECOMMENDED
                          </span> : null
                          }
                          <h5
                            className="
                              font-weight-semibold font-size-6
                              text-black-2
                            "
                          >
                            {i.name}
                          </h5>
                          <h6 className="font-size-4 text-gray font-weight-normal">
                            {i.info}
                          </h6>
                         
                        </div>
                        <h2 className="mt-11 text-dodger">
                          ₦{i.amount}<span
                            className="font-size-4 text-gray font-weight-normal"
                            >{i.cond} </span>
                        </h2>
                      </div>
                      {/* Card Header end */}
                      <div className="card-body px-0 pt-11 pb-15">
                        <ul className="list-unstyled">
                          {i.comments.map(j => {return (<li key={j._id} className="mb-6 text-black-2 d-flex font-size-4">
                            <i
                              className="fas fa-check font-size-3 text-black-2 mr-3"
                            ></i>
                            {j}
                          </li>)})}
                          {i.canceled.map(j => {return (<li key={j._id} className="mb-6 text-gray d-flex font-size-4">
                            <i
                              className="fas fa-times font-size-3  mr-3"
                            ></i>
                            {j}
                          </li>)})}
                          
                        </ul>
                      </div>
                      <div
                        className="card-footer bg-transparent border-0 px-0 py-0"
                      ><PaystackButton  
                            className="
                            btn btn-green btn-h-60
                            text-white
                            rounded-5
                            btn-block
                            text-uppercase" 
                            text={i.name}
                            publicKey={paystack_key}
                            onSuccess={(success) => {handlePaystackSuccessAction(success, i.id, i.price, i.name)}}
                            onClose={() => {handlePaystackCloseAction()}}
                            reference={() => {getReference()}}
                            email={currentUser ? currentUser.email: null}
                            amount={i.price * 100} /> 
                      </div>
                    </div>
                  </div>)})}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
     <Footer/>
    </div>
    )
}