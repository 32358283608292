import React, {useEffect, useState} from 'react'
import Navbar from '../shared-components/navbar'
import Sidebar from '../shared-components/sidebar'
import backgroundImage from '../image/svg/upload-file.svg'
import  { useAuths }  from '../AuthContext';
import {countries} from '../Countries'
import { Button, Spinner } from 'react-bootstrap'
import ReactLoading from "react-loading";
import axios from 'axios'
import { Helmet } from 'react-helmet'
import 'react-toastify/dist/ReactToastify.css';
const url = process.env.REACT_APP_SERVER_URL

export default function EmployerProfile () {
    const [selectedFile, setSelectedFile] = useState(undefined)
    const [social_btn, set_social_btn] = useState(false)
    const [profile_btn, set_profile_btn] = useState(false)
    const {currentUser, getUser, state, isLoading, notify} = useAuths()
    const [email, set_email] = useState("")
    const [company_name, set_company_name] = useState("") 
    const [business_type, set_business_type] = useState("")
    const [industryId, setIndustryId] = useState("")
    const [business_address, set_business_address] = useState("")
    const [phone, set_phone] = useState("")
    const [country, set_country] = useState("")
    const [web_link, set_web_link] = useState("")
    const [about, set_about] = useState("")
    const [industry, set_industry] = useState("")
    const [num_of_employee, set_number_of_employee] = useState("")
    const [faceBook, setFaceBook] = useState("")
    const [twitter, setTwitter] = useState("")
    const [instagram, setInstagram] = useState("")
    const [linkedIn, setLinkedIn] = useState("")
    //const history = useHistory()

    useEffect(() => {
      isLoading(true)
      let mounted = true
      if(mounted){
      
      set_email(currentUser.email)
      set_company_name(currentUser.company_name)
      set_phone(currentUser.phone)
      if(currentUser.industry){
         set_industry(currentUser.industry.name)
         setIndustryId(currentUser.industry._id)
      }
      set_business_type(currentUser.business_type)
      set_number_of_employee(currentUser.num_of_employee)
      set_country(currentUser.country)
      set_business_address(currentUser.business_address)
      set_web_link(currentUser.web_link)
      set_about(currentUser.about)
      if(currentUser.social_media){
      setFaceBook(currentUser.social_media.faceBook)
      setInstagram(currentUser.social_media.instagram)
      setTwitter(currentUser.social_media.twitter)
      setLinkedIn(currentUser.social_media.linkedIn)}
    }
    isLoading(false)
    return () => {
      mounted = false
    }
    }, [])

   async function update_profile(e){
        e.preventDefault();
    const token = localStorage.getItem('token')
    if(!company_name || !email){
     // fillError()
     notify("inform", "All fields required")
    }else{
      isLoading(true)
     await axios.put(`${url}updateuser`, {
      company_name, email, phone, industry: industryId, business_type, num_of_employee, country, business_address, about, web_link
    }, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },

    }).then(
        res => {
         notify("success", "Profile updated successfully")
        getUser()
        isLoading(false)
      }
        ).catch(err => {
          notify("error", "Could not update profile")
        isLoading(false)
          
         // error()
        })}
    }

  async function upload(e){
        e.preventDefault();
    const token = localStorage.getItem('token')
    let formData = new FormData();
    formData.append("file", selectedFile);
    isLoading(true)
    await axios.post(`${url}upload-image`, formData, {
      headers: {
        'Authorization': `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },

    }).then(
        res => {getUser()
         notify("success", "Profile image updated")
         isLoading(false)
        setSelectedFile(undefined);}
        ).catch(err => {
         // error()
         isLoading(false)
         notify("error", "Could not update profile image")
         alert(err)
        })
    }

   async function createSocialProfile(e){
      e.preventDefault();
      const token = localStorage.getItem("token")
     
        if(!twitter || !faceBook || !instagram || !linkedIn){
          notify("inform", "All fields required")
          set_social_btn(false)
        }else{
      isLoading(true)
         await axios.post(`${url}create-social-media`, {twitter, faceBook, instagram, linkedIn},
         {
           headers: {
        'Authorization': `Bearer ${token}`
      },
         }).then(
          res => {
            notify("success", 'Social profile updated')
          isLoading(false)
            getUser()
          }
        ).catch(
          err => {
      isLoading(false)
      notify("error", 'Could not update social profile')
         
          }
        )}
      }
    
      const setIndu = (e) => {
        e.preventDefault()
        const data = JSON.parse(e.target.value)
        set_industry(data.name)
        setIndustryId(data._id)
      }

  
    return (
        <div>
           <Helmet>
            <title> Profile Page - Job Board | StriveHR </title>
            </Helmet>
            <Navbar/>
            <Sidebar/>

            <div className="dashboard-main-container mt-24 mt-lg-31" id="dashboard-body">
        <div className="container">
          <div className="mb-15 mb-lg-23">
            <div className="row">
              <div className="col-xxxl-9 px-lg-13 px-6">
                <h3 className="mb-11">
                  Edit Profile
                </h3>
                
                <div
                  className="
                    contact-form
                    bg-white
                    shadow-8
                    rounded-4
                    pl-sm-10 pl-4
                    pr-sm-11 pr-4
                    pt-15
                    pb-13
                  "
                >
              <div className="upload-file mb-16 text-center">
                   { !currentUser.image && !selectedFile ? <div id="userActions" className="square-215 m-auto px-6 mb-7" style={{backgroundImage: "url(" + backgroundImage + ")"}}>
                      <label 
                        htmlFor="fileUploadImage"
                        className="mb-0 font-size-4 text-smoke">Add Photo</label>   
                    <input type="file" id="fileUploadImage" hidden onChange={(e) => {setSelectedFile(e.target.files[0])}} className="" />
                    </div>: <div> {selectedFile ? <img className="square-215 m-auto px-6 mb-7" src={URL.createObjectURL(selectedFile)}/> : <a href={currentUser.image} id="userActions" className="square-215 m-auto px-6 mb-7" style={{backgroundImage: "url(" + currentUser.image + ")"}}>
                    </a>}
                     <input type="file" id="updateImage" hidden onChange={(e) => {setSelectedFile(e.target.files[0])}}
                            /><label
                                htmlFor="updateImage"
                               className="
                                mb-0
                                font-size-4
                                btn btn-outline-gray
                                min-width-px-210
                                rounded-pill
                                mb-5
                                mt-5
                              ">Update Image</label> {selectedFile ?
                               <button    className="
                                mb-0
                                font-size-4
                                btn btn-outline-gray
                                min-width-px-210
                                rounded-pill
                                mb-5
                                mt-5
                              " onClick={(e) => upload(e)}>Upload</button> : null }</div>}
                  </div>
              <form action="/">
                    <fieldset>
                      <div className="row mb-xl-1 mb-9">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label
                              for="company Name"
                              className="
                                d-block
                                text-black-2
                                font-size-4 font-weight-semibold
                                mb-4
                              "
                            >
                              Company Name</label>
                            <input
                              onChange={e => {set_company_name(e.target.value)}}
                              type="text"
                              value={company_name}
                              className="form-control h-px-48"
                              id="company Name"
                              placeholder="Strive Human Resources"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label
                              for="companyemail"
                              className="
                                d-block
                                text-black-2
                                font-size-4 font-weight-semibold
                                mb-4
                              "
                            >
                              Official Email</label>
                            <input
                              onChange={e => {set_email(e.target.value)}}
                              value={email}
                              type="email"
                              className="form-control h-px-48"
                              id="companyemail"
                              placeholder="Info@strivehumanresources.com"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label
                              for="phone"
                              className="
                                d-block
                                text-black-2
                                font-size-4 font-weight-semibold
                                mb-4
                              "
                            >
                              Phone Number</label>
                            <input
                            value={phone}
                              onChange={e => {set_phone(e.target.value)}}
                              type="tel"
                              className="form-control h-px-48"
                              id="phone"
                              placeholder=""
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label
                              for="insdustry"
                              className="
                                d-block
                                text-black-2
                                font-size-4 font-weight-semibold
                                mb-4
                              "
                            >
                              Industry</label>
                            <select className="form-control h-px-48" id="businesstype" value={industry ? industryId : ""} onChange={e => {setIndu(e)}}>
                              <option value={industry ? industryId : null} >{industry ? industry : "Choose..."}</option>
                              {state.industries.map( i => (<option key={i._id} value={JSON.stringify(i)}>{i.name}</option>))}
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label
                              for="businesstype"
                              className="
                                d-block
                                text-black-2
                                font-size-4 font-weight-semibold
                                mb-4
                              "
                            >
                              Business Type</label>
                            <select className="form-control h-px-48" id="businesstype" value={business_type} onChange={e => {set_business_type(e.target.value)}}>
                              <option value="" >Choose...</option>
                              <option value="Public Limited Liability Company">Public Limited Liability Company</option>
                              <option value="Private Limited Liability Company">Private Limited Liability Company</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label
                              for="size"
                              className="
                                d-block
                                text-black-2
                                font-size-4 font-weight-semibold
                                mb-4
                              "
                            >
                              Employees</label>
                            <select className="form-control h-px-48" id="size" value={num_of_employee} onChange={e => {set_number_of_employee(e.target.value)}}>
                              <option value ="">Choose...</option>
                              <option value="1-10 Employees">1-10 Employees</option>
                              <option value="11-50 Employees">11-50 Employees</option>
                              <option value="50+ Employees">50+ Employees</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label
                              for="location"
                              className="
                                d-block
                                text-black-2
                                font-size-4 font-weight-semibold
                                mb-4
                              "
                            >
                              Location</label>
                           <select className="form-control h-px-48" id="businesstype" value={country} onChange={e => {set_country(e.target.value)}}>
                              <option selected>Choose...</option>
                              {countries.map( i => (<option key={i.ISO} value={i.value}>{i.country}</option>))}
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label
                              for="address"
                              className="
                                d-block
                                text-black-2
                                font-size-4 font-weight-semibold
                                mb-4
                              "
                            >
                              Business Address</label>
                            <input
                            onChange={e => {set_business_address(e.target.value)}}
                            value={business_address}
                              type="text"
                              className="form-control h-px-48"
                              id="address"
                              placeholder="315B Akin Ogunlewe street,
                              Victoria Island, Lagos"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label
                              for="website"
                              className="
                                d-block
                                text-black-2
                                font-size-4 font-weight-semibold
                                mb-4
                              "
                            >
                              Website Link</label>
                            <input
                              onChange={e => {set_web_link(e.target.value)}}
                              value={web_link}
                              type="text"
                              className="form-control h-px-48"
                              id="website"
                              placeholder="www.strivehumanresources.com"
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <label
                              for="aboutTextarea"
                              className="
                                d-block
                                text-black-2
                                font-size-4 font-weight-semibold
                                mb-4
                              "
                              >About</label >
                            <textarea
                              onChange={e => {set_about(e.target.value)}}
                              value={about}
                              name="textarea"
                              id="aboutTextarea"
                              cols="30"
                              rows="7"
                              className="
                                border border-mercury
                                text-gray
                                w-100
                                pt-4
                                pl-6
                              "
                              placeholder="Describe about the company what make it unique"
                            >
                       
                        </textarea>
                          </div>
                        </div>
                        </div>
                        { !profile_btn ? <button
                            onClick={e => {update_profile(e)}}
                            type="button"
                            className="
                              btn btn-green btn-h-60
                              text-white
                              min-width-px-210
                              rounded-5
                              text-uppercase
                            ">Save Changes</button> : <Button className="btn btn-green btn-h-60
                              text-white
                              min-width-px-210
                              rounded-5
                              text-uppercase" disabled>
                                  <Spinner
                                  as="span"
                                  variant="light"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                  animation="border"/>
                                  
                              </Button>}
                        </fieldset>
                        </form>
              </div>
              {/* contact profile  */}
              <div
                  className="
                    contact-form
                    bg-white
                    shadow-8
                    rounded-4
                    pl-sm-10 pl-4
                    pr-sm-11 pr-4
                    pt-15
                    pb-13
                    mt-15
                  "
                >
                  <h5 className="font-size-6 font-weight-semibold mb-11">
                Social Profile
              </h5>
              <form action="/">
                    <fieldset>
                      <div className="row mb-xl-1 mb-9">
                        <div className="col-lg-6">
                            
                          <div className="form-group">
                            <label
                              for="linkedin"
                              className="
                                d-block
                                text-black-2
                                font-size-4 font-weight-semibold
                                mb-4
                              "
                            >
                              Linkedin</label>
                            <input
                              value={linkedIn}
                              onChange={e => {setLinkedIn(e.target.value)}}
                              type="text"
                              className="form-control h-px-48"
                              id="linkedin"
                              placeholder="www.linkedin.com/john"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label
                              for="twitter"
                              className="
                                d-block
                                text-black-2
                                font-size-4 font-weight-semibold
                                mb-4
                              "
                            >
                              Twitter</label
                            >
                            <input
                              value={twitter}
                              onChange={e => {setTwitter(e.target.value)}}
                              type="text"
                              className="form-control h-px-48"
                              id="twitter"
                              placeholder="www.twitter.com/john"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label
                              for="instagram"
                              className="
                                d-block
                                text-black-2
                                font-size-4 font-weight-semibold
                                mb-4
                              "
                            >
                              Instagram</label>
                            <input
                              value={instagram}
                              onChange={e => {setInstagram(e.target.value)}}
                              type="text"
                              className="form-control h-px-48"
                              id="instagram"
                              placeholder="www.instagram.com/john"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label
                              for="facebook"
                              className="
                                d-block
                                text-black-2
                                font-size-4 font-weight-semibold
                                mb-4
                              "
                            >
                              Facebook</label >
                            <input
                              value={faceBook}
                              onChange={e => {setFaceBook(e.target.value)}}
                              type="email"
                              className="form-control h-px-48"
                              id="facebook"
                              placeholder="www.facebook.com/john"
                            />
                          </div>
                        </div>
                        {!social_btn ? <button
                        onClick={e => {createSocialProfile(e)}}
                            type="button"
                            className="
                              btn btn-green btn-h-60
                              text-white
                              min-width-px-210
                              rounded-5
                              text-uppercase
                            "
                          >Save Changes</button> : <Button className="btn btn-green btn-h-60
                              text-white
                              min-width-px-210
                              rounded-5
                              text-uppercase" disabled>
                                  <Spinner
                                  as="span"
                                  variant="light"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                  animation="border"/>
                                  
                              </Button>}
                        </div>
                        </fieldset>
                        </form>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        </div>
    )
}