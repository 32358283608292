import React, { useEffect, useState } from 'react'
import axios from 'axios'
import backgroundImage from '../../image/svg/upload-file.svg'
import {countries} from '../../Countries'
import { useHistory } from 'react-router-dom'
import { useAuths } from '../../AuthContext'
import { toast, Slide } from 'react-toastify'
import { Helmet } from 'react-helmet'
toast.configure()
//import Touchable from 'rc-touchable';

export default function CreateEmployer () {
   var day = new Date();
   
   const next_days = day.setDate(day.getDate() + 7);
   const [selectedFile, setSelectedFile] = useState(undefined)
   const [email, set_email] = useState("")
   const [company_name, set_company_name] = useState("") 
   const [business_type, set_business_type] = useState("")
   const [business_address, set_business_address] = useState("")
   const [phone, set_phone] = useState("")
   const [num_of_employee, set_num_of_employee] = useState("")
   const [country, set_country] = useState("")
   const [web_link, set_web_link] = useState("")
   const [about, set_about] = useState("")
   const [industry, set_industry] = useState("")
   const {industryData} = useAuths()
   const url = process.env.REACT_APP_SERVER_URL
   const history = useHistory()



   useEffect(() => {
     
  
   }, [])


   const success = () => {
    toast.success('Job Created Successfully', {position: toast.POSITION.TOP_RIGHT, theme: "colored", transition: Slide,  hideProgressBar: true})
  }

  const error = () => {
    toast.error('An error occured', {position: toast.POSITION.TOP_CENTER, theme: "colored", transition: Slide,  hideProgressBar: true})
  }

  const errorProfile = () => {
    toast.error('Please Update Your Profile Before You Can Post Jobs', {position: toast.POSITION.TOP_CENTER, theme: "colored", transition: Slide,  hideProgressBar: true})
  }

  const enter_answer = () => {
   toast.error('Please input a question and at least two options', {position: toast.POSITION.TOP_CENTER, theme: "colored", transition: Slide,  hideProgressBar: true})
  }

  const pick_answer = () => {
   toast.error('Please select an answer', {position: toast.POSITION.TOP_CENTER, theme: "colored", transition: Slide,  hideProgressBar: true})
  }

  const enter_option = () => {
   toast.warn('Please select an answer', {position: toast.POSITION.TOP_CENTER, theme: "colored", transition: Slide,  hideProgressBar: true})
  }

  const required = () => {
   toast.error('All fields are required', {position: toast.POSITION.TOP_CENTER, theme: "colored", transition: Slide,  hideProgressBar: true})
  }
  

  function handleSubmit(e){
        e.preventDefault();
    if(selectedFile){
    const token = localStorage.getItem('token')
    let formData = new FormData();
    formData.append("file", selectedFile);
    return axios.post(`${url}admin-create-company`, formData , {
        params:  { email, company_name, business_type, business_address, phone, num_of_employee, country, web_link, about, industry},
      headers: {
        'Authorization': `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },

    }).then(
        res => {
        alert("done")
        setSelectedFile(undefined);}
        ).catch(err => {
         alert(err)
        })}else{
            const token = localStorage.getItem('token')
    return axios.post(`${url}admin-create-company-without-image`,{email, company_name, business_type, business_address, phone, num_of_employee, country, web_link, about, industry},{
      headers: {
        'Authorization': `Bearer ${token}`
      },

    }).then(
        res => {
        alert("done")}
        ).catch(err => {
         alert(err)
        })   
        }
    }
  

  
 
    return (
        <div>
            <div className="" id="dashboard-body">
        <div className="container">
          <div className="mb-15 mb-lg-23">
            <div className="row">
              <div className="col-xxxl-12 px-lg-13 px-6">
                <div className="">
                  <form action="/">
                    <fieldset>
                         <div className="upload-file mb-16 text-center">
                   {!selectedFile ? <div id="userActions" className="square-215 m-auto px-6 mb-7" style={{backgroundImage: "url(" + backgroundImage + ")"}}>
                      <label 
                        htmlFor="fileUploadImage"
                        className="mb-0 font-size-4 text-smoke">Add Logo</label>   
                    <input type="file" id="fileUploadImage" hidden onChange={(e) => {setSelectedFile(e.target.files[0])}} className="" />
                    </div>: <div> {selectedFile ? <img className="square-215 m-auto px-6 mb-7" src={URL.createObjectURL(selectedFile)}/> : null} </div>}
                  </div>
                      <div className="row mb-xl-1 mb-9">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label
                              className="
                                d-block
                                text-black-2
                                font-size-4 font-weight-semibold
                                mb-4
                              "
                            >
                              Company Name</label>
                            <input
                              onChange={e => {set_company_name(e.target.value)}}
                              type="text"
                              className="form-control h-px-48"
                              id="company Name"
                              placeholder="e.g Strive Human Resources"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label
                              className="
                                d-block
                                text-black-2
                                font-size-4 font-weight-semibold
                                mb-4
                              "
                            >
                              Official Email</label>
                            <input
                              onChange={e => {set_email(e.target.value)}}
                              type="email"
                              className="form-control h-px-48"
                              id="companyemail"
                              placeholder="e.g Info@strivehumanresources.com"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label
                              className="
                                d-block
                                text-black-2
                                font-size-4 font-weight-semibold
                                mb-4
                              "
                            >
                              Phone Number</label>
                            <input
                              onChange={e => {set_phone(e.target.value)}}
                              type="numeric"
                              className="form-control h-px-48"
                              id="phone"
                              placeholder="e.g 0800000000"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label
                              className="
                                d-block
                                text-black-2
                                font-size-4 font-weight-semibold
                                mb-4
                              "
                            >
                              Industry</label>
                            <select className="form-control h-px-48" id="businesstype" onChange={e => {set_industry(e.target.value)}}>
                              <option selected>Choose...</option>
                              {industryData.map( i => (<option key={i._id} value={i._id}>{i.name}</option>))}
                            </select>
                          </div>
                        </div>
                        {/*<div className="col-lg-6">
                          <div className="form-group">
                            <label
                              className="
                                d-block
                                text-black-2
                                font-size-4 font-weight-semibold
                                mb-4
                              "
                            >
                              Business Type</label>
                            <select className="form-control h-px-48" id="businesstype" onChange={e => {set_business_type(e.target.value)}}>
                              <option selected>Choose...</option>
                              <option value="Public Limited Liability Company">Public Limited Liability Company</option>
                              <option value="Private Limited Liability Company">Private Limited Liability Company</option>
                            </select>
                          </div>
                        </div>*/}
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label
                              className="
                                d-block
                                text-black-2
                                font-size-4 font-weight-semibold
                                mb-4
                              "
                            >
                              Employees</label>
                            <select className="form-control h-px-48" id="size"onChange={e => {set_num_of_employee(e.target.value)}}>
                              <option selected>Choose...</option>
                              <option value="1-10 Employees">1-10 Employees</option>
                              <option value="11-50 Employees">11-50 Employees</option>
                              <option value="50+ Employees">50+ Employees</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label
                              className="
                                d-block
                                text-black-2
                                font-size-4 font-weight-semibold
                                mb-4
                              "
                            >
                              Location</label >
                           <select className="form-control h-px-48" id="businesstype" onChange={e => {set_country(e.target.value)}}>
                              <option selected>Choose...</option>
                              {countries.map( i => (<option key={i.ISO} value={i.value}>{i.country}</option>))}
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label
                              className="
                                d-block
                                text-black-2
                                font-size-4 font-weight-semibold
                                mb-4
                              "
                            >
                              Business Address</label
                            >
                            <input
                            onChange={e => {set_business_address(e.target.value)}}
                              type="text"
                              className="form-control h-px-48"
                              id="address"
                              placeholder="e.g 315B Akin Ogunlewe street,
                              Victoria Island, Lagos"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label
                              className="
                                d-block
                                text-black-2
                                font-size-4 font-weight-semibold
                                mb-4
                              "
                            >
                              Website Link</label
                            >
                            <input
                              onChange={e => {set_web_link(e.target.value)}}
                              type="text"
                              className="form-control h-px-48"
                              id="website"
                              placeholder="e.g www.strivehumanresources.com"
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <label
                              className="
                                d-block
                                text-black-2
                                font-size-4 font-weight-semibold
                                mb-4
                              "
                              >About</label
                            >
                            <textarea
                              onChange={e => {set_about(e.target.value)}}
                              name="textarea"
                              id="aboutTextarea"
                              cols="30"
                              rows="7"
                              className="
                                border border-mercury
                                text-gray
                                w-100
                                pt-4
                                pl-6
                              "
                              placeholder="Describe about the company what make it unique"
                            >
                       
                        </textarea>
                          </div>
                        </div>
                        </div>
                        <button
                            onClick={e => {handleSubmit(e)}}
                            type="button"
                            className="
                              btn btn-green btn-h-60
                              text-white
                              min-width-px-210
                              rounded-5
                              text-uppercase
                            ">Save Changes</button>
                        </fieldset>
                        </form>
              </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}