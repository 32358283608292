import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import  { useAuths }  from '../AuthContext';



export default function SideBar() {
    const {getMessage, currentUser} = useAuths()
    const location = useLocation();

    if(currentUser.isCandidate){
    return(
      <div>
      <div className="dashboard-sidebar-wrapper pt-20" id="sidebar">
        <div className="my-15 px-11">
          <Link to="/JobSearch" className="btn btn-primary btn-xl w-100 text-uppercase">
            <span className="mr-5 d-inline-block"></span>Find a new job</Link>
        </div>
        <ul className="list-unstyled dashboard-layout-sidebar">
          <li className={location.pathname === "/dashboard" ? "active" : null}>
            <Link
              to="/dashboard"
              className="
                px-10
                py-1
                my-5
                font-size-4 font-weight-semibold
                flex-y-center
              "
              ><i className="icon icon-layout-11 mr-6"></i>Dashboard</Link>
          </li>
          <li className={location.pathname === "/applied-jobs" ? "active" : null}>
            <Link
            to="/applied-jobs"
              className="
                px-10
                py-1
                my-5
                font-size-4 font-weight-semibold
                flex-y-center
              "
              ><i className="fas fa-briefcase mr-7"></i>Applied Jobs</Link>
          </li>
          {/*<li className={location.pathname === "/conversation" ? "active" : null}>
            <Link
              to="/conversation"
              className="
                px-10
                py-1
                my-5
                font-size-4 font-weight-semibold
                flex-y-center
              "
              ><i className="fas fa-envelope mr-7"></i>Messages
            </Link>
          </li>*/}
          <li className={location.pathname === "/saved-jobs" ? "active" : null}>
            <Link
              to="/saved-jobs"
              className="
                px-10
                py-1
                my-5
                font-size-4 font-weight-semibold
                flex-y-center
              "
              ><i className="fas fa-bookmark mr-7"></i>Saved Jobs</Link>
            
          </li>
          <li className={location.pathname === "/profile" ? "active" : null}>
            <Link
            to="/profile"
              className="
                px-10
                py-1
                my-5
                font-size-4 font-weight-semibold
                flex-y-center
              "
              ><i className="fas fa-user-circle mr-7"></i>Profile</Link>
          </li>
          <li className={location.pathname === "/setting" ? "active" : null}>
            <Link
              to="/setting"
              className="
                px-10
                py-1
                my-5
                font-size-4 font-weight-semibold
                flex-y-center
              "
              ><i className="fas fa-cog mr-7"></i>Settings</Link>
          </li>
        </ul>
      
      </div>
      <div>
              <a
          className="sidebar-mobile-button"
          data-toggle="collapse"
          href="#sidebar"
          role="button"
          aria-expanded="false"
          aria-controls="sidebar"
        >
          
          <i className="icon icon-sidebar-2"></i>
        </a>
        </div>
      </div>
    
    )}else if(currentUser.isEmployer){
       return(
         <div>
        <div>
            <div className="dashboard-sidebar-wrapper pt-20" id="sidebar">
        <div className="my-15 px-11">
        <Link to="/create-jobs" className="btn btn-primary btn-xl w-100 text-uppercase">
            <span className="mr-5 d-inline-block">+</span>Post a new job</Link>
        </div>
        <ul className="list-unstyled dashboard-layout-sidebar">
         <li className={location.pathname === "/dashboard" ? "active" : null}>
            <Link
              to="/dashboard"
              className="
                px-10
                py-1
                my-5
                font-size-4 font-weight-semibold
                flex-y-center
              "
              ><i className="icon icon-layout-11 mr-7"></i>Dashboard</Link>
          </li>
         <li className={location.pathname === "/posted-jobs" ? "active" : null}>
            <Link
              to="/posted-jobs"
              className="
                px-10
                py-1
                my-5
                font-size-4 font-weight-semibold
                flex-y-center
              "
              ><i className="fas fa-briefcase mr-7"></i>Posted Jobs</Link>
          </li>
         <li className={location.pathname === "/applicants" ? "active" : null}>
            <Link
              to="/applicants"
              className="
                px-10
                py-1
                my-5
                font-size-4 font-weight-semibold
                flex-y-center
              "
              ><i className="fas fa-user mr-7"></i>Applicants
              </Link>
          </li>
         {/*<li className={location.pathname === "/conversation" ? "active" : null}>
            <Link
              to="/conversation"
              className="
                px-10
                py-1
                my-5
                font-size-4 font-weight-semibold
                flex-y-center
              "
              ><i className="fas fa-envelope mr-7"></i>Messages
           </Link >
          </li>*/}
         <li className={location.pathname === "/profile" ? "active" : null}>
            <Link
              to="/profile"
              className="
                px-10
                py-1
                my-5
                font-size-4 font-weight-semibold
                flex-y-center
              "
              ><i className="fas fa-user-circle mr-7"></i>Profile</Link>
          </li>
         <li className={location.pathname === "/billing" ? "active" : null}>
            <Link
              to="/billing"
              className="
                px-10
                py-1
                my-5
                font-size-4 font-weight-semibold
                flex-y-center
              "
              ><i className="fas fa-wallet mr-7"></i>Billing</Link>
          </li>
         <li className={location.pathname === "/setting" ? "active" : null}>
            <Link
              to="/setting"
              className="
                px-10
                py-1
                my-5
                font-size-4 font-weight-semibold
                flex-y-center
              "
              ><i className="fas fa-cog mr-7"></i>Settings</Link>
          </li>
        </ul>
        <div>
              <a
          className="sidebar-mobile-button"
          data-toggle="collapse"
          href="#sidebar"
          role="button"
          aria-expanded="false"
          aria-controls="sidebar"
        >
          
          <i className="icon icon-sidebar-2"></i>
        </a>
        </div>
      </div>
        </div>
        <div>
              <a
          className="sidebar-mobile-button"
          data-toggle="collapse"
          href="#sidebar"
          role="button"
          aria-expanded="false"
          aria-controls="sidebar"
        >
          
          <i className="icon icon-sidebar-2"></i>
        </a>
        </div>
      </div>  
    )
    }else if(currentUser.isAdmin){
      return(
        <div>
        <div>
            <div className="dashboard-sidebar-wrapper pt-20" id="sidebar">
        <ul className="list-unstyled dashboard-layout-sidebar my-15 ">
          <li className={location.pathname === "/dashboard" ? "active" : null}>
            <Link
              to="/dashboard"
              className="
                px-10
                py-1
                my-5
                font-size-4 font-weight-semibold
                flex-y-center
              "
              ><i className="fa fa-tachometer-alt mr-7"></i>Dashboard</Link>
          </li>
          <li className={location.pathname === "/Jobs" ? "active" : null}>
            <Link
              to="/Jobs"
              className="
                px-10
                py-1
                my-5
                font-size-4 font-weight-semibold
                flex-y-center
              "
              ><i className="fas fa-briefcase mr-7"></i>Jobs</Link>
          </li>
          
          <li className={location.pathname === "/companies" ? "active" : null}>
            <Link
              to="/companies"
              className="
                px-10
                py-1
                my-5
                font-size-4 font-weight-semibold
                flex-y-center
              "
              ><i className="fas fa-building mr-7"></i>Companies</Link>
          </li>
          <li className={location.pathname === "/candidates" ? "active" : null}>
            <Link
              to="/candidates"
              className="
                px-10
                py-1
                my-5
                font-size-4 font-weight-semibold
                flex-y-center
              "
              ><i className="fas fa-user mr-7"></i>Candidates</Link>
          </li>
          {!currentUser.isSubAdmin ? <li className={location.pathname === "/packages" ? "active" : null}>
            <Link
              to="/packages"
              className="
                px-10
                py-1
                my-5
                font-size-4 font-weight-semibold
                flex-y-center
              "
              ><i className="fas fa-cubes mr-7"></i>Packages</Link>
          </li> : null}
             <li className={location.pathname === "/datas" ? "active" : null}>
            <Link
              to="/datas"
              className="
                px-10
                py-1
                my-5
                font-size-4 font-weight-semibold
                flex-y-center
              "
              ><i className="fas fa-database mr-7"></i>Datas</Link>
          </li>
             {!currentUser.isSubAdmin ? <li className={location.pathname === "/admins" ? "active" : null}>
            <Link
              to="/admins"
              className="
                px-10
                py-1
                my-5
                font-size-4 font-weight-semibold
                flex-y-center
              "
              ><i className="fas fa-users mr-7"></i>Users</Link>
          </li> : null}
        </ul>
        <div>
              <a
          className="sidebar-mobile-button"
          data-toggle="collapse"
          href="#sidebar"
          role="button"
          aria-expanded="false"
          aria-controls="sidebar"
        >
          
          <i className="icon icon-sidebar-2"></i>
        </a>
        </div>
      </div>
        </div>
        <div>
              <a
          className="sidebar-mobile-button"
          data-toggle="collapse"
          href="#sidebar"
          role="button"
          aria-expanded="false"
          aria-controls="sidebar"
        >
          
          <i className="icon icon-sidebar-2"></i>
        </a>
        </div>
      </div>
      )
    }
}