import React, {useState} from 'react';
import loginhero from "../image/c-home/login-hero.png";
import blackstrivehrlogo from '../image/logo-main-black.png';
import {Link, useHistory, useParams} from 'react-router-dom'
import { Helmet } from 'react-helmet'
import  { useAuths }  from '../AuthContext';
import axios from 'axios'
import { toast, Slide } from 'react-toastify'
const url = process.env.REACT_APP_SERVER_URL
toast.configure()



    export default function CandidateSignUp (){
        const [password, setPassword] = useState("")
        const param = useParams()
        const [confirmPassword, setConfirmPassword] = useState("")
        const param_token = param.token


        const history = useHistory()


        const success = () => {
            toast.success('Password Reset Successful', {position: toast.POSITION.BOTTOM_LEFT, theme: "colored", transition: Slide,  hideProgressBar: true})
        }

        const too_less_char = () => {
            toast.warn('Password cannot be less than 8 characters', {position: toast.POSITION.TOP_CENTER, theme: "colored", transition: Slide,  hideProgressBar: true})
        }

        const required = () => {
            toast.warn('All fields are required', {position: toast.POSITION.TOP_CENTER, theme: "colored", transition: Slide,  hideProgressBar: true})
        }

        const equal = () => {
            toast.warn('Password does not match', {position: toast.POSITION.TOP_CENTER, theme: "colored", transition: Slide,  hideProgressBar: true})
        }
        
        const error = () => {
            toast.error('Could not reser your password', {position: toast.POSITION.BOTTOM_LEFT, theme: "colored", transition: Slide,  hideProgressBar: true})
        }

        const handlesubmit = (e) => {
            e.preventDefault();
        if(!password || !confirmPassword){
            required()
        }else if (password !== confirmPassword){
            equal()
        }else if (password.trim().length < 8 || confirmPassword.trim().length < 8){
            too_less_char()
        }else{
        axios.put(`${url}create-sub-admin`, {password},{
      headers: {
        'Authorization': `Bearer ${param_token}`
      },
    }).then( res =>
         {success() 
        history.push('/reset-password-success')
          }).catch(err => {
            error()
            })}}

        return(
            <div className="site-wrapper overflow-hidden">
                 <Helmet>
            <title>Administrator Sign Up - Job Board | StriveHR </title>
            </Helmet>
             <div className="">
        <div className="  min-h-100vh flex-all-center pt-lg-15 pt-xxl-17 pt-27 pb-lg-0 pb-18">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xxl-6 col-xl-7 col-lg-8">
              <h2 className="font-size-9 text-center mb-11">
               
              </h2>
                <div className="mb-15 text-center bg-white px-9 pt-9 pb-7 shadow-8 rounded-4" >
                <div className="row">
                      
                      <div className="col-xs-12 mb-8">
                        <h2 className="font-size-9 mb-4">
                          Set Password
                        </h2>
                        <p className="font-size-4  heading-default-color">
                          Please enter new password and proceed to log in as an administrator
                        </p>
                        <div>
                            <form>
                              
                                <div className="form-group text-left pt-6">
                                  <label
                                    className="
                                      font-size-4
                                      text-black-2
                                      font-weight-semibold
                                      line-height-reset
                                    "
                                    >Password</label>
                                  <div className="position-relative">
                                    <input
                                      type="password"
                                      className="form-control"
                                      id="password"
                                      placeholder="Enter password"
                                      onChange={e => {setPassword(e.target.value)}}
                                    />
                             
                                  </div>
                                </div>
                                <div className="form-group text-left">
                                  <label
                                    className="
                                      font-size-4
                                      text-black-2
                                      font-weight-semibold
                                      line-height-reset
                                    "
                                    >Confirm Password</label>
                                  <div className="position-relative">
                                  <input
                                      type="password"
                                      className="form-control"
                                      id="password"
                                      placeholder="Confirm password"
                                       onChange={e => {setConfirmPassword(e.target.value)}}
                                    />
                                  </div>
                                </div>
                           
                            <div className="form-group mb-8">
                                <button
                                 onClick={(e) => {handlesubmit(e)}}
                                className="
                                    btn btn-primary btn-medium
                                    w-100
                                    rounded-5
                                    text-uppercase
                                "
                                >
                                Reset Password
                                </button>
                                
                            </div>
                            <div className="text-center">
                            <p className="font-size-4  heading-default-color">
                              Remember Password?&nbsp;
                              <Link to="/" className="text-primary"
                                >Sign in</Link>
                            </p></div>
                            </form>
                        </div>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
    </div>
    </div>
        </div>
        )
    }
  