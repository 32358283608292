import React, { useEffect } from 'react'
import woman from "./image/c-home/woman-holding-ipad.png"
import dangote from "./image/c-home/companies/Dangote-logo 1.png"
import google from "./image/c-home/companies/Google_2015_logo 1.png"
import jumia from "./image/c-home/companies/jumia-logo 1.png"
import { ACTIONS, useAuths } from './AuthContext';
import {countries} from './Countries'
import paystack from "./image/c-home/companies/Paystack_Logo 1.png"
import transparent from "./image/c-home/companies/shell-transparent-logo-11 1.png"
import strive from "./image/c-home/companies/strive-logo-1.png"
import womandesk from "./image/c-home/woman-at-desk.png"
import { Helmet } from 'react-helmet'
import profile from "./image/profile/building.png"
import Navbar from './shared-components/navbar'
import Footer from './shared-components/footer'
import {useHistory, Link} from 'react-router-dom'
const url = process.env.REACT_APP_SERVER_URL

export default function HomePage() {
const [text, setText] = React.useState("")
const { getLatestJobs, getFeaturedJobs, state, dispatch, notify } = useAuths()
const [country, setCountry] = React.useState("")
const history = useHistory()


useEffect(() => {
  let mounted = true
  if(mounted){
    getLatestJobs(7)
    getFeaturedJobs()
  }
  return() => {
    mounted = false
  }
}, [])



    return(

         <div className="site-wrapper overflow-hidden bg-white">
            <Helmet>
            <title> Home Page - Job Board | StriveHR </title>
            </Helmet>
      <header
        className="
          site-header site-header--menu-right
          bg-default
          dynamic-sticky-bg
          py-7 py-lg-0
          site-header--absolute site-header--sticky
        "
      >
     <Navbar/>
      </header>
      <div className="position-relative z-index-1 mt-25 mt-md-15 mt-lg-0 mt-xl-22">
        <div className="container">
          <div className="row position-relative align-items-center">
            <div
              className="col-xxl-7 col-xl-8 col-lg-9 pt-lg-26 pb-lg-26 pt-md-20"
              data-aos="fade-right"
              data-aos-duration="800"
              data-aos-once="true"
            >
              <div className="row">
                <div className="col-md-10 col-sm-10">
                  <h1 className="font-size-11 mb-12">
                    Find your next job the easy way
                  </h1>
                  <p className="font-size-6">
                    We connect top talents with top companies in Africa
                  </p>
                </div>
              </div>
              <div className="pt-12">
                <form
                  action="/"
                  className="search-form"
                  data-aos="fade-right"
                  data-aos-duration="800"
                  data-aos-delay="300"
                  data-aos-once="true"
                >
                  <div
                    className="
                      filter-search-form-2
                      bg-white
                      rounded-sm
                      shadow-4
                      pr-8
                      py-8
                      pl-6
                    "
                  >
                    <div className="filter-inputs">
                      <div className="form-group position-relative">
                        <input
                          value={state.job_search_text}
                          onChange={(e) => dispatch({type: ACTIONS.SET_JOB_SEARCH_TEXT, payload: e.target.value})}
                          className="form-control focus-reset pl-13"
                          type="text"
                          id="keyword"
                          placeholder="Type Job title, keywords"
                        />
                        <span
                          className="
                            h-100
                            w-px-50
                            pos-abs-tl
                            d-flex
                            align-items-center
                            justify-content-center
                            font-size-6
                          "
                          ><i
                            className="
                              icon icon-zoom-2
                              text-primary
                              font-weight-bold
                            "
                          ></i></span>
                      </div>
                      <div className="form-group position-relative">
                        <select
                          onChange={(e) => dispatch({type: ACTIONS.SET_COUNTRY_SEARCH_TEXT, payload: e.target.value})}
                          value={state.country_search_text}
                          className=" 
                          border-0
                          shadow-none
                          pl-13
                          h-100
                          arrow-3
                          font-size-4
                          "
                        >
                           <option value="" selected>Select Country</option>
                              {countries.map(i => {return (<option key={i.ISO} selected={i.value === state.country_search_text} value={i.value}>{i.country}</option>)})}
                        </select>
                        <span
                          className="
                            h-100
                            w-px-50
                            pos-abs-tl
                            d-flex
                            align-items-center
                            justify-content-center
                            font-size-6
                          "
                          ><i
                            className="
                              icon icon-pin-3
                              text-primary
                              font-weight-bold
                            "
                          ></i></span>
                      </div>
                    </div>
                    <div className="button-block">
                      <button
                        onClick={(e) => {e.preventDefault(); !state.job_search_text && !state.country_search_text ? notify("inform", "Please input text or select a country to search") : history.push("/JobSearch")}}
                        className="
                          btn btn-primary
                          line-height-reset
                          h-100
                          btn-submit
                          w-100
                          text-uppercase
                        "
                      >
                        Search
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <div
              className="
                col-12
                pos-abs-tr
                z-index-n1
                position-static position-lg-absolute
                ml-auto
                hero-image-positioning-2
              "
            >
              <div className="image-group row justify-content-center">
                <div className="col-sm-6 col-10 mt-10 mt-lg-0">
                  <div
                    className="single-image"
                    data-aos="fade-left"
                    data-aos-duration="800"
                    data-aos-once="true"
                  >
                    <img
                      src={woman}
                      alt="hero-image"
                    />
                  </div>
                </div>
                <div className="col-sm-6 col-10 mt-10 mt-lg-0">
                  <div
                    className="single-image"
                    data-aos="fade-left"
                    data-aos-duration="800"
                    data-aos-delay="300"
                    data-aos-once="true"
                  >
                    {/*<img
                      src={man}
                      alt="hero-image"
                    />*/}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div className="pb-13 pb-lg-25 bg-gradient-2">
        <div className="pt-29 pt-lg-30 pb-10 pb-lg-22">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="section-title mb-9 text-center">
                  <h5 className="font-size-5 font-weight-normal text-gray">
                    Get hired in top companies
                  </h5>
                </div>
            </div>
            </div>

            <div
              className="
                row
                align-items-center
                justify-content-center justify-content-lg-between
                gr-opacity-5
              "
            >
              <div
                className="single-brand-logo mx-5 my-6"
                data-aos="fade-in"
                data-aos-duration="800"
                data-aos-once="true"
              >
                <img src={dangote} alt="" />
               
              </div>
              <div
                className="single-brand-logo mx-5 my-6"
                data-aos="fade-in"
                data-aos-duration="800"
                data-aos-delay="300"
                data-aos-once="true"
              >
                <img
                  src={google}
                  alt=""
                />
                
              </div>
              <div
                className="single-brand-logo mx-5 my-6"
                data-aos="fade-in"
                data-aos-duration="800"
                data-aos-delay="500"
                data-aos-once="true"
              >
                <img src={jumia} alt="" />
                
              </div>
              <div
                className="single-brand-logo mx-5 my-6"
                data-aos="fade-in"
                data-aos-duration="800"
                data-aos-delay="700"
                data-aos-once="true"
              >
                <img
                  src={paystack}
                  alt=""
                />
               
              </div>
              <div
                className="single-brand-logo mx-5 my-6"
                data-aos="fade-in"
                data-aos-duration="800"
                data-aos-delay="900"
                data-aos-once="true"
              >
                <img
                  src={transparent}
                  alt=""
                />
              
              </div>
              <div
                className="single-brand-logo mx-5 my-6"
                data-aos="fade-in"
                data-aos-duration="800"
                data-aos-delay="1200"
                data-aos-once="true"
              >
                <img src={strive} alt="" />
                   
              </div>
            </div>
        </div>
        <div className="">
          <div className="container">
            <hr className="mb-13 " />
            <div className="row align-items-center justify-content-center">
              <div className="col-12 col-xl-6 col-lg-7 col-md-9">
                <div className="text-center mb-12 mb-lg-17">
                  <h2 className="font-size-10 font-weight-bold mb-8">
                    Explore by category
                  </h2>
                  <p className="font-size-5 px-5 px-lg-7 px-xl-9 px-xxl-15 mb-6">
                    Leverage agile frameworks to provide a robust synopsis for
                    high level overviews to start.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="row justify-content-center"
              data-aos="fade-right"
              data-aos-duration="800"
              data-aos-once="true"
            >
              <div className="col-12 col-xl-3 col-lg-4 col-sm-6 col-xs-8">
                <div
                  
                  className="
                    bg-white
                    border border-color-2
                    rounded-4
                    pl-5
                    pt-10
                    pb-3
                    px-2
                    hover-shadow-2
                    mb-9
                    d-block
                    w-100
                    text-center
                  "
                >
                  <div
                    className="
                      text-blue
                      bg-blue-opacity-1
                      square-70
                      rounded-4
                      mb-7
                      font-size-7
                      text-center
                      mx-auto
                    "
                  >
                    <i className="fa fa-briefcase"></i>
                  </div>
                  <div className="">
                    <h5
                      className="
                        font-size-5 font-weight-semibold
                        text-black-2
                        line-height-1
                      "
                    >
                      Business Development
                    </h5>
                    <p className="font-size-4 font-weight-normal text-gray">
                      
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-xl-3 col-lg-4 col-sm-6 col-xs-8">
                <div
                  
                  className="
                    bg-white
                    border border-color-2
                    rounded-4
                    pl-5
                    pt-10
                    pb-3
                    px-2
                    hover-shadow-2
                    mb-9
                    d-block
                    w-100
                    text-center
                  "
                >
                  <div
                    className="
                      text-spray
                      bg-spray-opacity-1
                      square-70
                      rounded-4
                      mb-7
                      font-size-7
                      text-center
                      mx-auto
                    "
                  >
                    <i className="fa fa-headset"></i>
                  </div>
                  <div className="">
                    <h5
                      className="
                        font-size-5 font-weight-semibold
                        text-black-2
                        line-height-1
                      "
                    >
                      Customer Service
                    </h5>
                    <p className="font-size-4 font-weight-normal text-gray">
                      
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-xl-3 col-lg-4 col-sm-6 col-xs-8">
                <div
                  
                  className="
                    bg-white
                    border border-color-2
                    rounded-4
                    pl-5
                    pt-10
                    pb-3
                    px-2
                    hover-shadow-2
                    mb-9
                    d-block
                    w-100
                    text-center
                  "
                >
                  <div
                    className="
                      text-coral
                      bg-coral-opacity-1
                      square-70
                      rounded-4
                      mb-7
                      font-size-7
                      text-center
                      mx-auto
                    "
                  >
                    <i className="fa fa-layer-group"></i>
                  </div>
                  <div className="">
                    <h5
                      className="
                        font-size-5 font-weight-semibold
                        text-black-2
                        line-height-1
                      "
                    >
                      Development
                    </h5>
                    <p className="font-size-4 font-weight-normal text-gray">
                      
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-xl-3 col-lg-4 col-sm-6 col-xs-8">
                <div
                  
                  className="
                    bg-white
                    border border-color-2
                    rounded-4
                    pl-5
                    pt-10
                    pb-3
                    px-2
                    hover-shadow-2
                    mb-9
                    d-block
                    w-100
                    text-center
                  "
                >
                  <div
                    className="
                      text-red
                      bg-red-opacity-1
                      square-70
                      rounded-4
                      mb-7
                      font-size-7
                      text-center
                      mx-auto
                    "
                  >
                    <i className="fa fa-pen-nib"></i>
                  </div>
                  <div className="">
                    <h5
                      className="
                        font-size-5 font-weight-semibold
                        text-black-2
                        line-height-1
                      "
                    >
                      Design
                    </h5>
                    <p className="font-size-4 font-weight-normal text-gray">
                      
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-xl-3 col-lg-4 col-sm-6 col-xs-8">
                <div
                  
                  className="
                    bg-white
                    border border-color-2
                    rounded-4
                    pl-5
                    pt-10
                    pb-3
                    px-2
                    hover-shadow-2
                    mb-9
                    d-block
                    w-100
                    text-center
                  "
                >
                  <div
                    className="
                      text-orange
                      bg-orange-opacity-1
                      square-70
                      rounded-4
                      mb-7
                      font-size-7
                      text-center
                      mx-auto
                    "
                  >
                    <i className="fa fa-rocket"></i>
                  </div>
                  <div className="">
                    <h5
                      className="
                        font-size-5 font-weight-semibold
                        text-black-2
                        line-height-1
                      "
                    >
                      Marketing &amp; Management
                    </h5>
                    <p className="font-size-4 font-weight-normal text-gray">
                      
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-xl-3 col-lg-4 col-sm-6 col-xs-8">
                <div
                  
                  className="
                    bg-white
                    border border-color-2
                    rounded-4
                    pl-5
                    pt-10
                    pb-3
                    px-2
                    hover-shadow-2
                    mb-9
                    d-block
                    w-100
                    text-center
                  "
                >
                  <div
                    className="
                      text-yellow
                      bg-yellow-opacity-1
                      square-70
                      rounded-4
                      mb-7
                      font-size-7
                      text-center
                      mx-auto
                    "
                  >
                    <i className="fa fa-location-arrow"></i>
                  </div>
                  <div className="">
                    <h5
                      className="
                        font-size-5 font-weight-semibold
                        text-black-2
                        line-height-1
                      "
                    >
                      Sales &amp; Communication
                    </h5>
                    <p className="font-size-4 font-weight-normal text-gray">
                      
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-xl-3 col-lg-4 col-sm-6 col-xs-8">
                <div
                  
                  className="
                    bg-white
                    border border-color-2
                    rounded-4
                    pl-5
                    pt-10
                    pb-3
                    px-2
                    hover-shadow-2
                    mb-9
                    d-block
                    w-100
                    text-center
                  "
                >
                  <div
                    className="
                      text-turquoise
                      bg-turquoise-opacity-1
                      square-70
                      rounded-4
                      mb-7
                      font-size-7
                      text-center
                      mx-auto
                    "
                  >
                    <i className="icon icon-sidebar-2"></i>
                  </div>
                  <div className="">
                    <h5
                      className="
                        font-size-5 font-weight-semibold
                        text-black-2
                        line-height-1
                      "
                    >
                      Project Management
                    </h5>
                    <p className="font-size-4 font-weight-normal text-gray">
                      
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-xl-3 col-lg-4 col-sm-6 col-xs-8">
                <div
                  className="
                    bg-white
                    border border-color-2
                    rounded-4
                    pl-5
                    pt-10
                    pb-3
                    px-2
                    hover-shadow-2
                    mb-9
                    d-block
                    w-100
                    text-center
                  "
                >
                  <div
                    className="
                      text-blue
                      bg-blue-opacity-1
                      square-70
                      rounded-4
                      mb-7
                      font-size-7
                      text-center
                      mx-auto
                    "
                  >
                    <i className="fa fa-user"></i>
                  </div>
                  <div className="">
                    <h5
                      className="
                        font-size-5 font-weight-semibold
                        text-black-2
                        line-height-1
                      "
                    >
                      Human Resources
                    </h5>
                    <p className="font-size-4 font-weight-normal text-gray">
                      
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="text-center pt-5 pt-lg-13">
                  <Link
                  to="/JobSearch"
                    className="btn btn-outline-black btn-lg text-uppercase"
                    >Explore All</Link>
                </div>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
      <section className="pt-13 pt-lg-30 pb-13 pb-lg-30">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div
              className="col-lg-6 col-md-8 col-sm-10 col-xs-11 mb-9 mb-lg-0"
              data-aos="fade-right"
              data-aos-duration="800"
              data-aos-once="true"
            >
              <div className="position-relative pr-lg-20 pr-xs-15 pr-9">
                <img
                  src={womandesk}
                  alt=""
                  className="w-100"
                />
                
                <div
                  className="
                    abs-content
                    pos-abs-br
                    bg-white
                    shadow-2
                    pl-7
                    pt-8
                    pb-1
                    pr-11
                    max-width-px-311
                    rounded
                    mb-15 mb-xs-18 mb-lg-15 mb-xl-18
                  "
                >
                  <div className="media mb-5">
                    <span className="check-mark bg-yellow-2 circle-41">
                      <i className="fas fa-check text-white font-size-6"></i>
                    </span>
                    <div className="media-body pl-5">
                      <h6 className="mb-0 font-size-3 text-green text-uppercase">
                        Job alert!
                      </h6>
                      <p className="mb-0 font-size-4 text-black-2">
                        104 new jobs are available in this week!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="col-xl-6 col-lg-7 col-md-8"
              data-aos="fade-left"
              data-aos-duration="800"
              data-aos-once="true"
            >
              <div
                className="
                  pl-lg-10 pl-0
                  d-flex
                  flex-column
                  justify-content-center
                  h-100
                  pt-lg-0 pt-15
                  pr-md-13 pr-xl-15 pr-xxl-25 pr-0
                "
              >
                <h2 className="font-size-9 mb-8 pr-xxl-15">
                  How to apply for a job
                </h2>
                <ul className="list-unstyled pl-0">
                  <li
                    className="
                      font-weight-semibold
                      border-0
                      d-flex
                      mb-7
                      heading-default-color
                    "
                  >
                    <i className="fas fa-check font-size-4 text-green mr-6"></i>
                     Search for jobs
                  </li>
                  <li
                    className="
                      font-weight-semibold
                      border-0
                      d-flex
                      mb-7
                      heading-default-color
                    "
                  >
                    <i className="fas fa-check font-size-4 text-green mr-6"></i>
                    Sign up and apply
                  </li>
                  <li
                    className="
                      font-weight-semibold
                      border-0
                      d-flex
                      mb-7
                      heading-default-color
                    "
                  >
                    <i className="fas fa-check font-size-4 text-green mr-6"></i>
                    Update your profile and upload your resume
                  </li>
                   <li
                    className="
                      font-weight-semibold
                      border-0
                      d-flex
                      mb-7
                      heading-default-color
                    "
                  >
                    <i className="fas fa-check font-size-4 text-green mr-6"></i>
                    Get contacted by employers
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-athens pt-12 pt-lg-24 pb-7 pb-lg-25">
        <div className="container">
          <div className="row justify-content-center mb-lg-16 mb-11">
            <div className="col-xxl-5 col-xl-6 col-lg-7 col-md-10 text-center">
              <h2 className="mb-6 mb-lg-7 text-black font-size-10">
                Latest Jobs
              </h2>
              <p
                className="
                  px-xs-3 px-md-12 px-lg-8 px-xl-8 px-xxl-6
                  font-size-5
                  mb-0
                "
              >
                Get introduced to hiring managers at some of the worlds best and
                fastest growing companies
              </p>
            </div>
          </div>
          
          
            <ul
            className="row justify-content-center list-unstyled"
            data-aos="fade-up"
            data-aos-duration="800"
            data-aos-once="true"
          > {state.latest_jobs.map(i => { return (
            <li className="col-xl-4 col-lg-6 col-md-6 col-sm-11 mb-9"> 
              {/* <!-- Single Featured Job --> */}
              <div
                className="
                  pt-9
                  px-xl-9 px-lg-7 px-7
                  pb-7
                  light-mode-texts
                  bg-white
                  rounded
                  hover-shadow-3
                "
              >
                <div className="media align-items-center">
                {!i.anonymous ? <Link to={{pathname: `/company-profile/${i.owner.userId}`}}><div className="square-72 d-block mr-8">
                        {i.owner.image ? <img
                        className="square-72 d-block"
                          src={i.owner.image}
                          alt=""
                        /> : <img
                        className="square-72 d-block"
                          src={profile}
                          alt=""
                        /> }
                     
                      </div></Link>: null}
                  <div>
                    {!i.anonymous ? <Link to={{pathname: `/company-profile/${i.owner.userId}`}}
                          
                          className="font-size-3 text-default-color line-height-2"
                          >{i.owner.company_name}</Link>: null}
                    <h3 className="font-size-6 mb-0">
                      <Link
                        className="heading-default-color font-weight-semibold"
                        to={{pathname: `/JobDetails/${i.jobId}`}}
                        >{i.jobrole}</Link>
                    </h3>
                  </div>
                </div>
                <div className="d-flex pt-17">
                  <ul className="list-unstyled mb-1 d-flex flex-wrap">
                     <li>
                      <a
                        className="
                          bg-regent-opacity-15
                          text-denim
                          font-size-3
                          rounded-3
                          min-width-px-100
                          px-3
                          flex-all-center
                          mr-6
                          h-px-33
                          mt-4
                        "
                      >
                        <i className="icon icon-pin-3 mr-2 font-weight-bold"></i>
                        {i.state+', '+i.country}
                      </a>
                    </li>
                    <li>
                      <a
                        className="
                          bg-regent-opacity-15
                          text-orange
                          font-size-3
                          rounded-3
                          min-width-px-100
                          px-3
                          flex-all-center
                          mr-6
                          h-px-33
                          mt-4
                        "
                      >
                        <i className="fa fa-briefcase mr-2 font-weight-bold"></i>
                        {i.type ? i.type.type : ""}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
           )})}</ul> 
          <div className="row">
            <div className="col-12 text-center pt-md-11">
              <Link
                className="text-green font-weight-bold text-uppercase font-size-3"
                to="/jobSearch"
                >See 1,294 more jobs <i className="fas fa-arrow-right ml-3"></i>
              </Link>
            </div>
          </div>
          </div>
        </section>
   <Footer/>
    </div>
    )
}