import React, { useEffect } from 'react'
import Navbar from '../shared-components/navbar'
import axios from 'axios'
import moment from 'moment'
import Sidebar from '../shared-components/sidebar'
import { Helmet } from 'react-helmet'
import ViewApplication from './view_application'
import {Link} from 'react-router-dom'
import { Modal, Button, Spinner } from 'react-bootstrap';
import emptybox from '../image/c-home/empty-box.png'
import Paginating from "../pagination"
import { useAuths } from '../AuthContext'
import ReactLoading from "react-loading";
import { toast, Slide } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
const url = process.env.REACT_APP_SERVER_URL


toast.configure()

export default function Appliedjobs () {
const [jobs, setJobs] = React.useState([])
const [showApplication, setShowApplication] = React.useState(false)
const [btn_loading, set_btn_loading] = React.useState(false)
const [limit, setLimit] = React.useState(5)
const [deleteModal, setDeleteModal] = React.useState(false)
const [deleteId, setDeleteId] = React.useState("")
const [applicationId, setApplicationId] = React.useState("")
const [loading, setLoading] = React.useState(true)
const { isLoading } = useAuths()
const [currentPage, setCurrentPage] = React.useState(1)
const [dataPerPage] = React.useState(5)

const indexOfLastPost = currentPage * dataPerPage;
const indexOfFirstPage = indexOfLastPost - dataPerPage;
const currentData = jobs.slice(indexOfFirstPage, indexOfLastPost);


const paginate = (pageNumber) => {
  setCurrentPage(pageNumber)
}
const previousPage = () => {
  if(currentPage - 1 > 0){
    setCurrentPage(currentPage - 1)
  }
}
const nextPage = (dataNumber) => {
  if(currentPage + 1 <= dataNumber){
    setCurrentPage(currentPage + 1)
  }
}


const loadData = () => {
  isLoading(true)
   const token = localStorage.getItem('token')
  axios.get(`${url}get-candidate-application/1000`, {
      headers: {
                'Authorization': `Bearer ${token}`
      }}).then(
    res => {
      setJobs(res.data)
      set_btn_loading(false)
  isLoading(false)

    }
  ).catch(error => {
    isLoading(false)
  })
}

useEffect(() => {
  let mounted = true
  if(mounted){
    loadData()
  }

  return() => {
    mounted = false
  }
}, [])

 const success = () => {
    toast.success('Application Deleted Successfully', {position: toast.POSITION.TOP_RIGHT, theme: "colored", transition: Slide,  hideProgressBar: true})
  }

 

  const error = () => {
   toast.error('Could Not Delete Application. Please try again', {position: toast.POSITION.TOP_RIGHT, theme: "colored", transition: Slide,  hideProgressBar: true})
  }


  const serveError = () => {
   toast.error('An Error Occured. Please try again later', {position: toast.POSITION.TOP_RIGHT, theme: "colored", transition: Slide,  hideProgressBar: true})
  }

  function DeleteModal(props) {
  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      bg="transparent"
    >
      <Modal.Body style={{backgroundColor: "#f2edf3"}}>
          <div className="text-center m-3 h6">Are you sure you want to delete Application?. <br/>Note: This action is not reversable </div>
        <div className="row justify-content-space-between m-3">
    <Button block onClick={() => {deleteApplication(deleteId)}} className="btn btn-danger">Confirm</Button>
    <Button block onClick={() => setDeleteModal(false)} className="btn btn-primary">Cancel</Button>
    </div>
      </Modal.Body>
    </Modal>
  );
}


function ViewJobModal(props) {
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      bg="transparent"
    >
      <Modal.Body style={{backgroundColor: "transparent"}}>
       <ViewApplication id={applicationId} />
      </Modal.Body>
    </Modal>
  );
}


const deleteApplication = (deleteId) => {
  const id = deleteId
   const token = localStorage.getItem('token')
  axios.delete(`${url}delete-application/${id}`, {
      headers: {
                'Authorization': `Bearer ${token}`
      }}).then(
    res => {
      success()
      setDeleteModal(false)
      loadData()
    }
  ).catch(err => {
    if(err.response.status === 500){
      serveError()
    }else{
      error()
    }
  })
}

 
return (
  <div>
     <Helmet>
            <title> Applied Jobs - Job Board | StriveHR </title>
            </Helmet>
   <Navbar/>
        <Sidebar/>
<div>
        {
          jobs.length < 1 ?
           <div>
       
        <div className="dashboard-main-container mt-25 mt-lg-31" id="dashboard-body">
        <div className="container">
          <div className="mb-14">
            <div className="col-12 col-xl-10 col-lg-8">
              {/* <!-- Top Start --> */}
              <div className="mb-5">
                <h3 className=" mb-9">Empty tables</h3>
                
                {/* Empty data  */}
                <div className="row justify-content-center">
                  <div className="col-lg-6 col-md-6 col-sm-11 mb-9 text-center">
                    <img className="w-50" src={emptybox} alt="" />
                    <h2 className="pt-15">No data found</h2>
                    <p>Your data will appear here once you start applying.</p>
                  </div>
                </div>

              </div>
              {/* <!-- Top End --> */}
              
            </div>
          </div>
        </div>
      </div>
        </div> : 
              <div>       
          
        <div className="dashboard-main-container mt-25 mt-lg-31" id="dashboard-body">
        <div className="container">
          <div className="mb-14">
            <div className="row mb-11 align-items-center">
              <div className="col-lg-6 mb-lg-0 mb-4">
                <h3 className="mb-0 pl-7">My Applied Jobs</h3>
              </div>
            </div>
            <div className="bg-white shadow-8 pt-7 rounded pb-8 px-11">
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        className="pl-0 border-0 font-size-4 font-weight-normal"
                      >
                        Position
                      </th>
                      <th
                        scope="col"
                        className="border-0 font-size-4 font-weight-normal"
                      >
                        Applied on
                      </th>
                      <th
                        scope="col"
                        className="border-0 font-size-4 font-weight-normal"
                      >
                        Action
                      </th>
                      
                    </tr>
                  </thead>
                  <tbody>
                    {currentData.map( job => {if(!job.job.anonymous){return (<tr className="border border-color-2">
                      <th scope="row" className="pl-6 border-0 py-7 pr-6">
                        <Link  to={{pathname: `/company-profile/${job.company.userId}`}}
    
                          className="media min-width-px-235 align-items-center"
                        >
                          {job.company.image ? <div className="circle-55 mr-6">
                            <img
                              src={job.company.image}
                              alt=""
                              className="circle-40 mr-6 img-responsive"
                            />
                          </div> : <div className="circle-55 mr-11">
                           <i className="fas fa-user font-size-8"></i>
                          </div>}
                          <div>
                            <h4
                              className="
                                font-size-4
                                mb-0
                                font-weight-semibold
                                text-black-2
                              "
                            >
                              {job.job.jobrole}
                            </h4>
                            <p className="font-size-3 font-weight-light mb-0">{job.company.company_name}</p>
                          </div>
                        </Link>
                      </th>

                      <td className="table-y-middle py-7 min-width-px-170 pr-0">
                        <h3
                          className="
                            font-size-4 font-weight-normal
                            text-black-2
                            mb-0
                          "
                        >
                          {moment(new Date(job.createdAt)).format("MMMM Do YYYY")}
                        </h3>
                      </td>
                      <td className="table-y-middle py-7 min-width-px-100 pr-0">
                        <div className="">
                          <a
                            onClick={e => {e.preventDefault(); setApplicationId(job._id); setShowApplication(true)}}
                            href=""
                            className="
                              font-size-3 font-weight-bold
                              text-green text-uppercase
                            "
                            >View Application
                          </a>
                        </div>
                      </td>
                      <td className="table-y-middle py-7 min-width-px-100 pr-0">
                        <div className="">
                          <label
                          onClick={() => {setDeleteId(job._id); setDeleteModal(true)}}
                            className="
                              font-size-3 font-weight-bold
                              text-red-2 text-uppercase
                            "
                            >Delete</label >
                        </div>
                      </td>
                    </tr>)}})}
                  </tbody>
                </table>
              </div>
              <div className="">
                 <Paginating dataPerPage={dataPerPage} totalData={jobs.length} paginate={paginate} currentPage={currentPage} nextPage={nextPage} previousPage={previousPage} />
              
            </div>
            </div>
          </div>
        </div>
      </div>
         <DeleteModal
                show={deleteModal}
                onHide={() => setDeleteModal(false)}
              />
               <ViewJobModal
                show={showApplication}
                onHide={() => setShowApplication(false)}
              />
      <div
        className="modal fade"
        id="employe-profile"
        tabindex="-1"
        aria-hidden="true"
      >
        <div className="container">
          {/* <!-- back Button --> */}
          <div className="row justify-content-center">
            <div className="col-12 mt-13">
              <div className="mb-9">
                <a
                  className="d-flex align-items-center ml-4"
                  href="javacript:"
                  data-dismiss="modal"
                >
                  <i
                    className="
                      icon icon-small-left
                      bg-white
                      circle-40
                      mr-5
                      font-size-7
                      text-black
                      font-weight-bold
                      shadow-8
                    "
                  >
                  </i><span
                    className="
                      text-uppercase
                      font-size-3 font-weight-bold
                      text-white
                    "
                    >Back</span></a>
              </div>
            </div>
          </div>
        </div>
      </div>
        </div>
        }
      </div>
  </div>
)
}
