import React, {useState} from 'react'
import { toast, Slide } from 'react-toastify'
import axios from 'axios'
import  { useAuths }  from '../../AuthContext';
import 'react-toastify/dist/ReactToastify.css';
const url = process.env.REACT_APP_SERVER_URL


toast.configure()


const required = () => {
    toast.warn('All Field Required', {position: toast.POSITION.TOP_CENTER, theme: "colored", transition: Slide,  hideProgressBar: true})
  }

const industry_success = () => {
    toast.success('Industry Added Successfully', {position: toast.POSITION.TOP_RIGHT, theme: "colored", transition: Slide,  hideProgressBar: true})
  }
const category_success = () => {
    toast.success('Job Category Added Successfully', {position: toast.POSITION.TOP_RIGHT, theme: "colored", transition: Slide,  hideProgressBar: true})
  }

const skill_success = () => {
    toast.success('Skill Added Successfully', {position: toast.POSITION.TOP_RIGHT, theme: "colored", transition: Slide,  hideProgressBar: true})
  }

const category_error = () => {
    toast.error('Could Not Add Job Category, Please Try Again', {position: toast.POSITION.TOP_RIGHT, theme: "colored", transition: Slide,  hideProgressBar: true})
  }

const industry_error = () => {
    toast.error('Could Not Add Industry, Please Try Again', {position: toast.POSITION.TOP_RIGHT, theme: "colored", transition: Slide,  hideProgressBar: true})
  }

const career_success = () => {
    toast.success('Career Level Added Successfully', {position: toast.POSITION.TOP_RIGHT, theme: "colored", transition: Slide,  hideProgressBar: true})
  }

const career_error = () => {
    toast.error('Could Not Add Career Level, Please Try Again', {position: toast.POSITION.TOP_RIGHT, theme: "colored", transition: Slide,  hideProgressBar: true})
  }

const job_type_success = () => {
    toast.success('Job Type Added Successfully', {position: toast.POSITION.TOP_RIGHT, theme: "colored", transition: Slide,  hideProgressBar: true})
  }

const job_type_error = () => {
    toast.error('Could Not Add Job Type, Please Try Again', {position: toast.POSITION.TOP_RIGHT, theme: "colored", transition: Slide,  hideProgressBar: true})
  }


export function CreateSkillModal(){
  const [skill, set_skill] = useState("")
  const {state, isLoading, getSkill} = useAuths()
  
    

  const handle_submit = (e) => {
    e.preventDefault();
    isLoading(true)
    if(!skill){
      required()
    }else{
    const token = localStorage.getItem('token')
    axios.post(`${url}create-skill`, {title: skill}, {headers: {
                'Authorization': `Bearer ${token}`
            }}).then(
      res => {
        if(res){
        skill_success()
        getSkill(state.skills.length+1)
    isLoading(false)
  }
      }
    ).catch(err => {
        industry_error()
    isLoading(false)
    })}
  }

  return(
    <div>
          <div className="login-modal-main bg-white rounded-8 overflow-hidden">
            <div className="no-gutters">
              <div>
                <div className="bg-white-2 h-100 px-11 pt-11 pb-7">
                  <form action="/">
                    <div className="form-group">
                      <label
                        className="
                          font-size-4
                          text-black-2
                          font-weight-semibold
                          line-height-reset
                        "
                        >Enter Skill</label>
                      <input
                        onChange={e => {set_skill(e.target.value)}}
                        type="text"
                        className="form-control"
                        placeholder="e.g Team Player"
                        id="text"
                      />
                    </div>
                 
                    <div className="form-group mb-8">
                      <button
                       onClick={e => {handle_submit(e)}}
                        className="
                          btn btn-primary btn-medium
                          w-100
                          rounded-5
                          text-uppercase
                        "
                      >
                        Create Skill
                      </button>
                    </div>
                  
                  </form>
                </div>
              </div>
            </div>
          </div>
    </div>
  )
}


export function CreatIndustryModal(){
  const [industryT, set_industry] = useState("")
  const {state, isLoading, industry} = useAuths()

  const handle_submit = (e) => {
    e.preventDefault();
    isLoading(true)
    if(!industryT){
      required()
    }else{
    const token = localStorage.getItem('token')
    axios.post(`${url}create-industry`, {name: industryT}, {headers: {
                'Authorization': `Bearer ${token}`
            }}).then(
      res => {
        if(res){
        industry_success()
        industry(state.industries.length+1)
        isLoading(false)}
      }
    ).catch(err => {
        industry_error()
        isLoading(false)
    })}
  }

  return(
    <div>
          <div className="login-modal-main bg-white rounded-8 overflow-hidden">
            <div className="no-gutters">
              <div>
                <div className="bg-white-2 h-100 px-11 pt-11 pb-7">
                  <form action="/">
                    <div className="form-group">
                      <label
                        className="
                          font-size-4
                          text-black-2
                          font-weight-semibold
                          line-height-reset
                        "
                        >Enter Industry Name</label>
                      <input
                        onChange={e => {set_industry(e.target.value)}}
                        type="text"
                        className="form-control"
                        placeholder="e.g Oil & Gas"
                        id="text"
                      />
                    </div>
                 
                    <div className="form-group mb-8">
                      <button
                       onClick={e => {handle_submit(e)}}
                        className="
                          btn btn-primary btn-medium
                          w-100
                          rounded-5
                          text-uppercase
                        "
                      >
                        Create Industry
                      </button>
                    </div>
                  
                  </form>
                </div>
              </div>
            </div>
          </div>
    </div>
  )
}


export function CreateCareerLevel(){
const [career_level, set_career_level] = useState("")
const {experienceLevel, isLoading, state} = useAuths()


const handle_submit = (e) => {
    e.preventDefault();
    isLoading(true)
    if(!career_level){
      required()
    }else{
    const token = localStorage.getItem('token')
    axios.post(`${url}create-career-level`, {title: career_level}, {headers: {
                'Authorization': `Bearer ${token}`
            }}).then(
      res => {
        if(res){
        career_success()
        experienceLevel(state.career_levels.length+1)
        isLoading(false)}
      }
    ).catch(err => {
        career_error()
        isLoading(false)
    })}
  }



  return(
    <div>
          <div className="login-modal-main bg-white rounded-8 overflow-hidden">
            <div className="no-gutters">
              <div>
                <div className="bg-white-2 h-100 px-11 pt-11 pb-7">
                  <form action="/">
                    <div className="form-group">
                      <label
                        className="
                          font-size-4
                          text-black-2
                          font-weight-semibold
                          line-height-reset
                        "
                        >Enter Career Level</label>
                      <input
                        onChange={e => {set_career_level(e.target.value)}}
                        type="text"
                        className="form-control"
                        placeholder="e.g Senior Level"
                        id="text"
                      />
                    </div>
                 
                    <div className="form-group mb-8">
                      <button
                       onClick={e => {handle_submit(e)}}
                        className="
                          btn btn-primary btn-medium
                          w-100
                          rounded-5
                          text-uppercase
                        "
                      >
                        Create Career Level
                      </button>
                    </div>
                  
                  </form>
                </div>
              </div>
            </div>
          </div>
    </div>
  )
}

export function CreateJobType(){
const [job_type, set_job_type] = useState("")
const {jobType, state, isLoading} = useAuths()


const handle_submit = (e) => {
  isLoading(true)
    e.preventDefault();
    if(!job_type){
      required()
    }else{
    const token = localStorage.getItem('token')
    axios.post(`${url}create-job-type`, {type: job_type}, {headers: {
                'Authorization': `Bearer ${token}`
            }}).then(
      res => {
        if(res){
        job_type_success()
        jobType(state.job_types.length+1)
  isLoading(false)
      }
      }
    ).catch(err => {
        job_type_error()
  isLoading(false)

    })}
  }

  return(
    <div>
          <div className="login-modal-main bg-white rounded-8 overflow-hidden">
            <div className="no-gutters">
              <div>
                <div className="bg-white-2 h-100 px-11 pt-11 pb-7">
                  <form action="/">
                    <div className="form-group">
                      <label
                        className="
                          font-size-4
                          text-black-2
                          font-weight-semibold
                          line-height-reset
                        "
                        >Enter Job Type</label>
                      <input
                        onChange={e => {set_job_type(e.target.value)}}
                        type="text"
                        className="form-control"
                        placeholder="e.g Full Time"
                        id="text"
                      />
                    </div>
                 
                    <div className="form-group mb-8">
                      <button
                      onClick={e => {handle_submit(e)}}
                        className="
                          btn btn-primary btn-medium
                          w-100
                          rounded-5
                          text-uppercase
                        "
                      >
                        Create Job Type
                      </button>
                    </div>
                  
                  </form>
                </div>
              </div>
            </div>
          </div>
    </div>
  )
}


export function CreateCategory(){
const [categoryT, set_category] = useState("")
const {category, isLoading, state } = useAuths()


const handle_submit = (e) => {
    e.preventDefault();
    isLoading(true)
    if(!categoryT){
      required()
    }else{
    const token = localStorage.getItem('token')
    axios.post(`${url}create-job-category`, {category: categoryT}, {headers: {
                'Authorization': `Bearer ${token}`
            }}).then(
      res => {
        if(res){
        category_success()
        category(state.job_categories.length+1)
    isLoading(false)}
      }
    ).catch(err => {
        category_error()
    isLoading(false)

    })}
  }

  return(
    <div>
          <div className="login-modal-main bg-white rounded-8 overflow-hidden">
            <div className="no-gutters">
              <div>
                <div className="bg-white-2 h-100 px-11 pt-11 pb-7">
                  <form action="/">
                    <div className="form-group">
                      <label
                        className="
                          font-size-4
                          text-black-2
                          font-weight-semibold
                          line-height-reset
                        "
                        >Enter Job Category</label>
                      <input
                        onChange={e => {set_category(e.target.value)}}
                        type="text"
                        className="form-control"
                        placeholder="e.g Full Time"
                        id="text"
                      />
                    </div>
                 
                    <div className="form-group mb-8">
                      <button
                      onClick={e => {handle_submit(e)}}
                        className="
                          btn btn-primary btn-medium
                          w-100
                          rounded-5
                          text-uppercase
                        "
                      >
                        Create Job Category
                      </button>
                    </div>
                  
                  </form>
                </div>
              </div>
            </div>
          </div>
    </div>
  )
}