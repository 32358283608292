import axios from "axios";
import { useEffect, useState } from "react";
import  { useAuths }  from '../AuthContext';
const url = process.env.REACT_APP_SERVER_URL

export default function Conversation({ conversation, currentChat }) {
    const { currentUser } = useAuths()
  const [user, setUser] = useState({
      first_name: "",
      last_name: "",
      image: "",
      isCandidate: true,
      company_name: ""
  });

  useEffect(() => {
    const friendId = conversation.members.find((m) => m !== currentUser._id);
    const token = localStorage.getItem("token")
    const getUser = async () => {
      try {
      await axios(`${url}getoneuser/${friendId}`,{
      headers: {
                'Authorization': `Bearer ${token}`
            }}).then(res => {
        setUser({
            ...user,
            first_name: res.data.first_name,
            last_name: res.data.last_name,
            isCandidate: res.data.isCandidate,
            company_name: res.data.company_name,
            image: res.data.image
        })})
      } catch (err) {
        console.log(err);
      }
    };
    getUser();
  }, [currentUser, conversation]);

                    return (
                        <label
                      className={currentChat === conversation ?"list-group-item-active list-group-item-action border-0": "list-group-item list-group-item-action border-0"}
                    >
                      {/*<div
                        className="
                          bg-message
                          float-right
                          px-1
                          h-1
                          text-white
                          font-size-3
                          rounded-5
                          max-height-px-18
                          flex-all-center
                        "
                      >
                        5
                      </div>*/}
                      <div className="d-flex align-items-start">
                        <img
                          src={user.image ? user.image : "https://bootdey.com/img/Content/avatar/avatar5.png"}
                          className="rounded-circle mr-1"
                          alt="Vanessa Tucker"
                          width="50"
                          height="50"
                        />
                        <div className="flex-grow-1 ml-3 mt-3">
                          {user.isCandidate ? user.first_name+' '+user.last_name : user.company_name}
                          {/*<div className="small">
                            <span className="fas fa-circle chat-online"></span>
                            Online
                          </div>*/}
                        </div>
                      </div>
                    </label> 
  );
}