import CandidateDashboard from '../candidate_pages/dashboard'
import EmployerDashboard from '../employer_pages/dashboard'
import AdminDashboard from '../administrator_pages/Dashboard'
import  { useAuths }  from '../AuthContext';

export default function Dashboard(props) {
    const {currentUser} = useAuths()

    if(currentUser.isAdmin){
        return(
            <AdminDashboard/>
        )
    }else if(currentUser.isEmployer){
        return(
            <EmployerDashboard/>
        )
    }else{
        return(
            <CandidateDashboard/>
        )
    }
}