import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Navbar from '../shared-components/navbar'
import SideBar from '../shared-components/sidebar'
import {Button} from 'react-bootstrap';
import { ModalBody, Modal } from 'reactstrap';
import moment from 'moment'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import { useAuths } from '../AuthContext';
import Paginating from "../pagination"
import CreateEmployer from './Forms/CreateEmployer'
import { toast, Slide } from 'react-toastify'
const url = process.env.REACT_APP_SERVER_URL

toast.configure()




function CreateComapanyModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title  id="contained-modal-title-vcenter">
         <h5>Create Company</h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
    <CreateEmployer />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}



export default function Companylist() {
  const [create_company_modal, set_create_company_modal] = useState(false)
  const [user_id, set_user_id] = React.useState("")
  const [deleteModal, setDeleteModal] = React.useState(false) 
  const [disableModal, setDisableModal] = React.useState(false)
  const [enableModal, setEnableModal] = React.useState(false)
  const [companies, set_companies] = useState([])
  const {isLoading, notify} = useAuths()
  const [currentPage, setCurrentPage] = React.useState(1)
const [dataPerPage] = React.useState(5)

const indexOfLastPost = currentPage * dataPerPage;
const indexOfFirstPage = indexOfLastPost - dataPerPage;
const currentData = companies.slice(indexOfFirstPage, indexOfLastPost);
  
const paginate = (pageNumber) => {
  setCurrentPage(pageNumber)
}
const previousPage = () => {
  if(currentPage - 1 > 0){
    setCurrentPage(currentPage - 1)
  }
}
const nextPage = (dataNumber) => {
  if(currentPage + 1 <= dataNumber){
    setCurrentPage(currentPage + 1)
  }
}


   const enable_success = () => {
    toast.success('User Enabled Successfully', {position: toast.POSITION.TOP_RIGHT, theme: "colored", transition: Slide,  hideProgressBar: true})
  }

  const enable_error = () => {
      toast.error('Could Not Enable User, Please try again', {position: toast.POSITION.TOP_RIGHT, theme: "colored", transition: Slide,  hideProgressBar: true})
  }

  const disable_success = () => {
    toast.success('User Disabled Successfully', {position: toast.POSITION.TOP_RIGHT, theme: "colored", transition: Slide,  hideProgressBar: true})
  }

  const disable_error = () => {
      toast.error('Could Not Disable User, Please try again', {position: toast.POSITION.TOP_RIGHT, theme: "colored", transition: Slide,  hideProgressBar: true})
  }

  const delete_success = () => {
    toast.success('User Deleted Successfully', {position: toast.POSITION.TOP_RIGHT, theme: "colored", transition: Slide,  hideProgressBar: true})
  }

  const delete_error = () => {
      toast.error('Could Not Delete User, Please try again', {position: toast.POSITION.TOP_RIGHT, theme: "colored", transition: Slide,  hideProgressBar: true})
  }



  const get_company = (limit) => {
    isLoading(true)
    const token = localStorage.getItem('token')
    axios.get(`${url}getallemployers/${limit}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      },

    }).then(
      res => {
        set_companies(res.data)
        isLoading(false)
      }
    ).catch(err => {
      isLoading(false)
    })
  }


    
      function DisableUserModal(props) {
  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      bg="transparent"
    >
      <ModalBody style={{backgroundColor: "#f2edf3"}}>
          <div className="text-center m-3 h6">Are you sure you want to disable user?.</div>
        <div className="row justify-content-space-between m-3">
    <Button block onClick={(e) => {disableUser(e)}} className="btn btn-danger">Confirm</Button>
    <Button block onClick={() => setDisableModal(false)} className="btn btn-primary">Cancel</Button>
    </div>
      </ModalBody>
    </Modal>
  );
}

const disableUser = (e) => {
  isLoading(true)
  const token = localStorage.getItem('token')
  e.preventDefault();
  axios.put(`${url}disable-user/${user_id}`,{}, {
    headers: {
        'Authorization': `Bearer ${token}`
      }
  }).then(res => {
    get_company()
    disable_success()
    setDisableModal(false)
    isLoading(false)
  }).catch(err => {
    notify("error" , "Could not disable user")
    isLoading(false)
  })
}

 function EnableUserModal(props) {
  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      bg="transparent"
    >
      <ModalBody style={{backgroundColor: "#f2edf3"}}>
          <div className="text-center m-3 h6">Are you sure you want to enable user?.</div>
        <div className="row justify-content-space-between m-3">
    <Button block onClick={(e) => {enableUser(e)}} className="btn btn-danger">Confirm</Button>
    <Button block onClick={() => setEnableModal(false)} className="btn btn-primary">Cancel</Button>
    </div>
      </ModalBody>
    </Modal>
  );
}

const enableUser = (e) => {
  e.preventDefault();
  isLoading(true)
  const token = localStorage.getItem('token')
  axios.put(`${url}enable-user/${user_id}`,{}, {
    headers: {
        'Authorization': `Bearer ${token}`
      }
  }).then(res => {
    get_company()
    enable_success()
    setEnableModal(false)
    isLoading(false)
  }).catch(err => {
    enable_error()
    isLoading(false)
  })
}

    function DeleteUserModal(props) {
  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      bg="transparent"
    >
      <ModalBody style={{backgroundColor: "#f2edf3"}}>
          <div className="text-center m-3 h6">Are you sure you want to delete User?. <br/>Note: This action is not reversable </div>
        <div className="row justify-content-space-between m-3">
    <Button block onClick={(e) => {deleteUser(e)}} className="btn btn-danger">Confirm</Button>
    <Button block onClick={() => setDeleteModal(false)} className="btn btn-primary">Cancel</Button>
    </div>
      </ModalBody>
    </Modal>
  );
}

const deleteUser = (e) => {
  e.preventDefault();
  isLoading(true)
  const token = localStorage.getItem('token')
  axios.delete(`${url}deleteusertemporary/${user_id}`,{
    headers: {
        'Authorization': `Bearer ${token}`
      }
  }).then(data => {
    get_company()
    delete_success()
    setDeleteModal(false)
    isLoading(false)
  }).catch(err => {
    delete_error()
    isLoading(false)
  })
}

  useEffect(() => {
    let mounted = true
    if(mounted){
      get_company()
    }
    return () => {
      mounted = false
    }
  }, [])

 
    return(
        <div>
          <Helmet>
            <title>Employers - Job Board | StriveHR </title>
            </Helmet>
        <Navbar/>
            <SideBar/>
            <div className="dashboard-main-container mt-25 mt-lg-31" id="dashboard-body">
        <div className="container">
        <div className="mb-14">
          <div className="bg-white shadow-8 pt-7 rounded pb-8 px-11">
          <div className="row mb-11 align-items-center">
            <div className="col-lg-6 mb-lg-0 mb-4">
              <h3 className="font-size-6 mb-0">Registered Companies</h3>
            </div>
            <div className="col-lg-6">
              <div className="d-flex flex-wrap align-items-center justify-content-lg-end">
                    {/*<a href="" onClick={e => {e.preventDefault(); set_create_company_modal(true)}} className="btn btn-green">+ Create Company</a>*/}
              </div>
            </div>
          </div>
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th scope="col" className="border-0 font-size-4 font-weight-normal">Company Name</th>
                    <th scope="col" className="border-0 font-size-4 font-weight-normal">Industry</th>
                    <th scope="col" className="border-0 font-size-4 font-weight-normal">Registered On</th>
                    <th scope="col" className="border-0 font-size-4 font-weight-normal">Action</th>
                    <th scope="col" className="border-0 font-size-4 font-weight-normal"></th>
                    <th scope="col" className="border-0 font-size-4 font-weight-normal"></th>
                  </tr>
                </thead>
                <tbody>
                  {currentData.map(i => (!i.isDelete ? <tr className="border border-color-2">
                    <th scope="row" className="pl-6 border-0 py-7 pr-0">
                      <div className="media min-width-px-140 align-items-center">
                        <div className="circle-36 mr-6">
                          {i.image ? <a href={i.image}><img src={i.image} alt="" className="w-100 circle-36 bg-primary" /></a> : <div className="circle-36 bg-primary"></div>}
                        </div>
                        <h4 className="font-size-4 mb-0 font-weight-semibold text-black-2">{i.company_name}</h4>
                      </div>
                    </th>
                    <td className="table-y-middle py-7 min-width-px-140 pr-0">
                      <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">{i.industry ? i.industry.name : "Not Available"}</h3>
                    </td>
                    <td className="table-y-middle py-7 min-width-px-140 pr-0">
                      <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">{moment(new Date(i.createdAt)).format("MMMM Do YYYY")}</h3>
                    </td>
                    <td className="table-y-middle py-7 min-width-px-80 pr-0">
                      <div className=""><Link to={{pathname: `/company-profile/${i.userId}`}} className="font-size-3 font-weight-bold text-blue text-uppercase">View</Link></div>
                    </td>
                    {/*<td className="table-y-middle py-7 min-width-px-80 pr-0">
                      <div className=""><a href="contact.html" className="font-size-3 font-weight-bold text-green text-uppercase">Edit</a></div>
                    </td>*/}
                     <td className="table-y-middle py-7 min-width-px-90 pr-0">
                      <div className="">{i.isDisable ? <a onClick={e => {e.preventDefault(); set_user_id(i._id); setEnableModal(true)}} href="contact.html" className="font-size-3 font-weight-bold text-green text-uppercase">Enable</a> : 
                        <a onClick={e => {e.preventDefault(); set_user_id(i._id); setDisableModal(true)}} href="contact.html" className="font-size-3 font-weight-bold text-green text-uppercase">Disable</a>}</div>
                    </td>
                    <td className="table-y-middle py-7 min-width-px-90 pr-0">
                      <div className=""><a onClick={e => {e.preventDefault(); set_user_id(i._id); setDeleteModal(true)}} href="" className="font-size-3 font-weight-bold text-red-2 text-uppercase">Delete</a></div>
                    </td>
                  </tr> : null))}
                </tbody>
              </table>
            </div>
            <CreateComapanyModal
              show={create_company_modal}
              onHide={() =>set_create_company_modal(false)}
            />
            <DeleteUserModal
              isOpen={deleteModal}
              onClosed={() =>{setDeleteModal(false); set_user_id("")}}
            />
            <DisableUserModal
              isOpen={disableModal}
              onClosed={() =>{setDisableModal(false); set_user_id("")}}
            />
            <EnableUserModal
              isOpen={enableModal}
              onClosed={() =>{setEnableModal(false); set_user_id("")}}
            />
            <Paginating dataPerPage={dataPerPage} totalData={companies.length} paginate={paginate} currentPage={currentPage} nextPage={nextPage} previousPage={previousPage} />
            
          </div>
        </div>
      </div>
      </div></div>
    )
}