export const countries =  [{
            value: "Nigeria",
            country: "Nigeria",
            currency: "₦",
            ISO: "NGN"
         },
         {
            value: "Algeria",
            country: "Algeria",
            currency: "	د.ج",
            ISO: "DZD"
         },
         {
            value: "Angola",
            country: "Angola",
            currency: "Kz",
            ISO: "AOA"
         },
         {
            value: "Benin",
            country: "Benin",
            currency: "CFA",
            ISO: "XOF"
         },
         {
            value: "Botswana",
            country: "Botswana",
            currency: "P",
            ISO: "BWP"
         },
         {
            value: "Burkina Faso",
            country: "Burkina Faso",
            currency: "CFA",
            ISO: "XOF"
         },
         {
            value: "Burundi",
            country: "Burundi",
            currency: "FBu",
            ISO: "BIF"
         },
         {
            value: "Cameroon",
            country: "Cameroon",
            currency: "CFA",
            ISO: "XAF"
         },
         {
            value: "Cape Verde",
            country: "Cape Verde",
            currency: "Esc",
            ISO: "CVE"
         },
         {
            value: "Chad",
            country: "Chad",
            currency: "CFA",
            ISO: "XAF"
         },
         {
            value: "Côte d'Ivoire",
            country: "Côte d'Ivoire",
            currency: "CFA",
            ISO: "XAF"
         },
         {
            value: "Djibouti",
            country: "Djibouti",
            currency: "Fdj",
            ISO: "DJF"
         },
         {
            value: "Egypt",
            country: "Egypt",
            currency: "E£",
            ISO: "EGP"
         },
         {
            value: "Equatorial Guinea",
            country: "Equatorial Guinea",
            currency: "CFA",
            ISO: "XAF"
         },
         {
            value: "Eritrea",
            country: "Eritrea",
            currency: "Nfa",
            ISO: "ERN"
         },
         {
            value: "Ethiopia",
            country: "Ethiopia",
            currency: "Br",
            ISO: "ETB"
         },
         {
            value: "Gabon",
            country: "Gabon",
            currency: "CFA",
            ISO: "XAF"
         },
         {
            value: "Ghana",
            country: "Ghana",
            currency: "₵",
            ISO: "GHS"
         },
         {
            value: "Guinea",
            country: "Guinea",
            currency: "FG",
            ISO: "GNF"
         },
         {
            value: "Guinea-Bissau",
            country: "Guinea-Bissau",
            currency: "CFA",
            ISO: "XOF"
         },
         {
            value: "Kenya",
            country: "Kenya",
            currency: "KSh",
            ISO: "KES"
         },
         {
            value: "Lesotho",
            country: "Lesotho",
            currency: "L",
            ISO: "LSL"
         },
         {
            value: "Liberia",
            country: "Liberia",
            currency: "L$",
            ISO: "LRD"
         },
         {
            value: "Libya",
            country: "Libya",
            currency: "ل.د",
            ISO: "LD"
         },
         {
            value: "Madagascar",
            country: "Madagascar",
            currency: "Ar",
            ISO: "MGA"
         },
         {
            value: "Malawi",
            country: "Malawi",
            currency: "MK",
            ISO: "MWK"
         },
         {
            value: "Mali",
            country: "Mali",
            currency: "CFA",
            ISO: "XOF"
         },
         {
            value: "Mauritania",
            country: "Mauritania",
            currency: "UM",
            ISO: "MRO"
         },
         {
            value: "Mauritius",
            country: "Mauritius",
            currency: "Ɍs",
            ISO: "MUR"
         },
         {
            value: "Mayotte",
            country: "Mayotte",
            currency: "€",
            ISO: "EUR"
         },
         {
            value: "Morocco",
            country: "Morocco",
            currency: "د.م.",
            ISO: "MAD"
         },
         {
            value: "Mozambique",
            country: "Mozambique",
            currency: "MT",
            ISO: "MZN"
         },
         {
            value: "Rwanda",
            country: "Rwanda",
            currency: "RF",
            ISO: "RWF"
         },
         {
            value: "Senegal",
            country: "Senegal",
            currency: "CFA",
            ISO: "XOF"
         },
         {
            value: "Seychelles",
            country: "Seychelles",
            currency: "Ɍs",
            ISO: "SCR"
         },
         {
            value: "Sierra Leone",
            country: "Sierra Leone",
            currency: "Le",
            ISO: "SLL"
         },
         {
            value: "Somalia",
            country: "Namibia",
            currency: "So. Sh.",
            ISO: "SOS"
         },
         {
            value: "South Africa",
            country: "South Africa",
            currency: "R",
            ISO: "ZAR"
         },
         {
            value: "South Sudan",
            country: "South Sudan",
            currency: "£Sd",
            ISO: "SDG"
         },
         {
            value: "Swaziland",
            country: "Swaziland",
            currency: "L",
            ISO: "SZL"
         },
         {
            value: "Togo",
            country: "Togo",
            currency: "CFA",
            ISO: "XOF"
         },
         {
            value: "Tunisia",
            country: "Tunisia",
            currency: "	د.ت",
            ISO: "TND"
         },
         {
            value: "Uganda",
            country: "Uganda",
            currency: "USh",
            ISO: "UGX"
         },
         {
            value: "Zambia",
            country: "Zambia",
            currency: "ZK",
            ISO: "ZMK"
         },
         {
            value: "Zimbabwe",
            country: "Zimbabwe",
            currency: "Z$",
            ISO: "ZWL"
         }
    ]

