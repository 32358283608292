import React, { useContext, useEffect, useReducer } from "react";
import axios from 'axios'
import { post as postt } from 'axios'
import { EditorState, convertToRaw, ContentState, convertFromHTML } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import DOMPurify from "dompurify";
import { employer_payments, handleCreateJobSubmit, getOnejob, updateQuestion, handleUpdateJob, deleteQuestion } from "../utilities";
import moment from "moment";
import { toast, Slide } from 'react-toastify'
const AuthContext = React.createContext()
const url = process.env.REACT_APP_SERVER_URL
toast.configure()

export const typed_plans = [
    {
      name: "Basic Plan",
      info: "For a Single Job Post",
      amount: "8,000",
      cond: "/Per Job Post",
      comments: [
        "Access to resumes from qualified candidates",
        "View candidates profile",
        "Post jobs anonymously"
      ],
      canceled: [
        "Receive applications directly in your email address",
        "Have your job ad published on our social platforms",
        "Get your company referred to qualified candidates",
        "Get access to over 1,000 qualified candidates streamlined to various roles"
      ],
      price: 8000,
      recommended: false,
      id: "basicplan8000"
    },
    {
      name: "Standard Plan",
      info: "Unlimited Job Post",
      amount: "55,000",
      cond: "/Month",
      comments: [
        "Access to resumes from qualified candidates",
        "View candidates profile",
        "Post jobs anonymously",
        "Receive applications directly in your email address",
        "Have your job ad published on our social platforms"
      ],
      canceled: [
        "Get your company referred to qualified candidates",
        "Get access to over 1,000 qualified candidates streamlined to various roles"
      ],
      price: 55000,
      recommended: true,
      id: "standardplan55000"
    },
    {
      name: "Unlimited Plan",
      amount: "600,000",
      info: "Unlimited Job Post",
      cond: "/Year",
      comments: [
        "Access to resumes from qualified candidates",
        "View candidates profile",
        "Post jobs anonymously",
        "Receive applications directly in your email address",
        "Have your job ad published on our social platforms",
        "Get your company referred to qualified candidates",
        "Get access to over 1,000 qualified candidates streamlined to various roles"
      ],
      canceled: [
      
      ],
      price: 600000,
      recommended: false,
      id: "unlimited600000"
    }
  ]

export const ACTIONS = {
    SET_JOB_CATEGORIES: "SET_JOB_CATEGORIES",
    SET_EMPLOYER_JOBS: "SET_EMPLOYER_JOBS",
    SET_INDUSTRIES: "SET_INDUSTRIES",
    SET_JOB_TYPES: "SET_JOB_TYPES",
    SET_SKILLS: "SET_SKILLS",
    SET_CAREER_LEVEL: "SET_CAREER_LEVEL",
    SET_JOBS: "SET_JOBS",
    SET_LOADING: "SET_LOADING",
    SET_TOTAL_APPLICANT: "SET_TOTAL_APPLICANT",
    SET_APPLICANTS: "SET_APPLICANTS",
    SET_SEARCH_APPLICANTS: "SET_SEARCH_APPLICANTS",
    SET_MODAL_DATA: "SET_MODAL_DATA",
    SET_MODAL: "SET_MODAL",
    SET_CURRENT_PLAN: "SET_CURRENT_PLAN",
    SET_PAYMENT_HISTORY: "SET_PAYMENT_HISTORY",
    SET_EDIT_DATA: "SET_EDIT_DATA",
    SET_JOBS_MEMORY: "SET_JOBS_MEMORY",
    SET_NUMBER_OF_USERS: "SET_NUMBER_OF_USERS",
    SET_SELECTED_SKILLS: "SET_SELECTED_SKILLS",
    SET_JOB_ROLE: "SET_JOB_ROLE",
    SET_JOB_CATEGORY: "SET_JOB_CATEGORY",
    SET_JOB_ANONYMOUS: "SET_JOB_ANONYMOUS",
    SET_JOB_DESCRIPTION: "SET_JOB_DESCRIPTION",
    SET_EDITOR_STATE: "SET_EDITOR_STATE",
    SET_JOB_SALARY_RANGE: "SET_JOB_SALARY_RANGE",
    SET_JOB_TYPE: "SET_JOB_TYPE",
    SET_JOB_EXPERIENCE_LEVEL: "SET_JOB_EXPERIENCE_LEVEL",
    SET_JOB_COUNTRY: "SET_JOB_COUNTRY",
    SET_JOB_STATE: "SET_JOB_STATE",
    SET_JOB_CLOSING_DATE: "SET_JOB_CLOSING_DATE",
    SET_QUESTION: "SET_QUESTION",
    SET_QUESTION_ID: "SET_QUESTION_ID",
    SET_JOB_ID: "SET_JOB_ID",
    SET_QUESTIONS: "SET_QUESTIONS",
    SET_FORM: "SET_FORM",
    SET_POSTED_JOBS: "SET_POSTED_JOBS",
    SET_JOB_URL_ID: "SET_JOB_URL_ID",
    SET_BOOK_MARKS: "SET_BOOK_MARKS",
    SET_LATEST_JOBS: "SET_LATEST_JOBS",
    SET_JOB_SEARCH_TEXT: "SET_JOB_SEARCH_TEXT",
    SET_COUNTRY_SEARCH_TEXT: "SET_COUNTRY_SEARCH_TEXT",
    SET_FEATURED_JOBS: "SET_FEATURED_JOBS",
    SET_CAREER_LEVEL_SEARCH_ARR: "SET_CAREER_LEVEL_SEARCH_ARR",
    SET_INDUSTY_SEARCH_ARR: "SET_INDUSTY_SEARCH_ARR",
    SET_CATEGORY_SEARCH_ARR: "SET_CATEGORY_SEARCH_ARR",
    SET_EMPLOYER: "SET_EMPLOYER",
    SET_JOB_TYPE_SEARCH_ARR: "SET_JOB_TYPE_SEARCH_ARR"
}


export function useAuths() {
    return useContext(AuthContext)
}


const initialState = {
    latest_jobs: [],
    featured_jobs: [],
    job_url_id: "",
    job_role: "",
    employer: {},
    questions: "",
    employer_jobs: [],
    question: [], 
    job_search_text: "",
    country_search_text: "",
    career_level_search_arr: [],
    industy_search_arr: [],
    category_search_arr: [],
    job_type_search_arr: [],
    question_id: "",
    job_id: "",
    form: [],
    job_category: {},
    job_anonymous: false,
    job_description: "",
    selected_skills: [],
    editorState: EditorState.createEmpty(),
    job_salary: "",
    job_type: {},
    job_experience_level: {},
    book_marks: [],
    job_country: "",
    job_state: "",
    job_closing_date: null,
    job_categories: [],
    industries: [],
    current_plan: {},
    job_types: [],
    modal: "",
    edit_data: null,
    modal_data: "",
    jobs: [],
    jobs_memory: [],
    payment_history: [],
    skills: [],
    career_levels: [],
    applicants: [],
    total_applicant: 0,
    posted_jobs: 0,
    number_of_users: 0,
    search_applicants: [],
    loading: false
}

 


function reducer(state, action){
    switch(action.type){
        case ACTIONS.SET_JOB_CATEGORIES:
            return { ...state, job_categories: action.payload}
        case ACTIONS.SET_JOB_ROLE:
            return { ...state, job_role: action.payload}
        case ACTIONS.SET_JOB_ANONYMOUS:
            return { ...state, job_anonymous: action.payload}
        case ACTIONS.SET_QUESTION:
            return { ...state, question: action.payload}
        case ACTIONS.SET_QUESTIONS:
            return { ...state, questions: action.payload}
        case ACTIONS.SET_BOOK_MARKS:
            return { ...state, book_marks: action.payload}
        case ACTIONS.SET_FORM:
            return { ...state, form: action.payload}
        case ACTIONS.SET_JOB_CATEGORY:
            return { ...state, job_category: action.payload}
        case ACTIONS.SET_JOB_DESCRIPTION:
            return { ...state, job_description: action.payload}
        case ACTIONS.SET_EDITOR_STATE:
            return { ...state, editorState: action.payload}
        case ACTIONS.SET_JOB_SALARY_RANGE:
            return { ...state, job_salary: action.payload}
        case ACTIONS.SET_JOB_URL_ID:
            return { ...state, job_url_id: action.payload}
        case ACTIONS.SET_JOB_TYPE:
            return { ...state, job_type: action.payload}
        case ACTIONS.SET_JOB_EXPERIENCE_LEVEL:
            return { ...state, job_experience_level: action.payload}
        case ACTIONS.SET_EMPLOYER:
            return { ...state, employer: action.payload}
        case ACTIONS.SET_JOB_COUNTRY:
            return { ...state, job_country: action.payload}
        case ACTIONS.SET_EMPLOYER_JOBS:
            return { ...state, employer_jobs: action.payload}
        case ACTIONS.SET_JOB_STATE:
            return { ...state, job_state: action.payload}
        case ACTIONS.SET_JOB_CLOSING_DATE:
            return { ...state, job_closing_date: action.payload}
        case ACTIONS.SET_COUNTRY_SEARCH_TEXT:
            return { ...state, country_search_text: action.payload}
        case ACTIONS.SET_LATEST_JOBS:
            return { ...state, latest_jobs: action.payload}
        case ACTIONS.SET_FEATURED_JOBS:
            return { ...state, featured_jobs: action.payload}
        case ACTIONS.SET_INDUSTRIES:
            return { ...state, industries: action.payload}
        case ACTIONS.SET_JOB_TYPES:
            return { ...state, job_types: action.payload}
        case ACTIONS.SET_JOB_SEARCH_TEXT:
            return { ...state, job_search_text: action.payload}
        case ACTIONS.SET_JOB_ID:
            return { ...state, job_id: action.payload}
        case ACTIONS.SET_POSTED_JOBS:
            return { ...state, posted_jobs: action.payload}
        case ACTIONS.SET_QUESTION_ID:
            return { ...state, question_id: action.payload}
        case ACTIONS.SET_CAREER_LEVEL_SEARCH_ARR:
            return { ...state, career_level_search_arr: action.payload}
        case ACTIONS.SET_INDUSTY_SEARCH_ARR:
            return { ...state, industy_search_arr: action.payload}
        case ACTIONS.SET_CATEGORY_SEARCH_ARR:
            return { ...state, category_search_arr: action.payload}
        case ACTIONS.SET_JOB_TYPE_SEARCH_ARR:
            return { ...state, job_type_search_arr: action.payload}
        case ACTIONS.SET_SKILLS:
            return { ...state, skills: action.payload}
        case ACTIONS.SET_CAREER_LEVEL:
            return { ...state, career_levels: action.payload}
        case ACTIONS.SET_JOBS:
            return { ...state, jobs: action.payload}
        case ACTIONS.SET_TOTAL_APPLICANT:
            return { ...state, total_applicant: action.payload}
        case ACTIONS.SET_MODAL:
            return { ...state, modal: action.payload}
        case ACTIONS.SET_JOBS_MEMORY:
            return { ...state, jobs_memory: action.payload}
        case ACTIONS.SET_MODAL_DATA:
            return { ...state, modal_data: action.payload}
        case ACTIONS.SET_LOADING:
            return { ...state, loading: action.payload}
        case ACTIONS.SET_CURRENT_PLAN:
            return { ...state, current_plan: action.payload}
        case ACTIONS.SET_PAYMENT_HISTORY:
            return { ...state, payment_history: action.payload}
        case ACTIONS.SET_APPLICANTS:
            return { ...state, applicants: action.payload}
        case ACTIONS.SET_EDIT_DATA:
            return { ...state, edit_data: action.payload}
        case ACTIONS.SET_SELECTED_SKILLS:
            return { ...state, selected_skills: action.payload}
        case ACTIONS.SET_SEARCH_APPLICANTS:
            return { ...state, search_applicants: action.payload}
        case ACTIONS.SET_NUMBER_OF_USERS:
            return { ...state, number_of_users: action.payload}
        default: 
            return state
    }
}

export function AuthProvider({ children }){ 
     const [currentUser, setCurrentUser] = React.useState()
     const [isLoggedIn, setIsLoggedIn] = React.useState(false)
    const [loading, setLoading] = React.useState(true)
    const [state, dispatch] = useReducer(reducer, initialState)

    const generateGreetings = () => {

  var currentHour = moment().format("HH");

  if (currentHour >= 0 && currentHour < 12){
      return "Good Morning";
  } else if (currentHour >= 12 && currentHour < 16){
      return "Good Afternoon";
  }   else if (currentHour >= 16 && currentHour < 19){
      return "Good Evening";
  } else if (currentHour >= 19 && currentHour < 23){
      return "Good Evening";
  } else {
      return "Hello"
  }

}


const prevIsValid = () => {
    if (state.form.length === 0) {
      return true;
    }

    const someEmpty = state.form.some(
      (item) => item.text === "" 
    );

    if (someEmpty) {
      state.form.map((item, index) => {
        const allPrev = [...state.form];

        if (state.form[index].text === "") {
           notify("inform", "Please enter option")
        }
        dispatch({type: ACTIONS.SET_FORM, payload: allPrev})
      });
    }

    return !someEmpty;
  };

  const handleAddLink = (e) => {
    e.preventDefault();
    const inputState = {
      text: "",
      isAnswer: false,
      errors: {
        text: null,
      },
    };

    if (prevIsValid()) {
        dispatch({type: ACTIONS.SET_FORM, payload: [...state.form, inputState]})
    }
  };

  


  const checkAnswer = state.form.filter(i => {return i.isAnswer === true})
 
  const addQuestion = (e) => {
    e.preventDefault();
    if(!state.questions){
        notify("inform", "Please enter question")
    }else if(state.form.length < 2 || !state.questions){
        notify("inform", "Please enter answer")
    }else if(checkAnswer.length !== 1){
        notify("inform", "Please select an answer")
    }else{
    !state.question_id && dispatch({type: ACTIONS.SET_QUESTION, payload: [...state.question, {questions: state.questions, form : [...state.form]}]})
    state.question_id && update_question()
    dispatch({type: ACTIONS.SET_MODAL, payload: ""})
    dispatch({type: ACTIONS.SET_QUESTION_ID, payload: ""})
    dispatch({type: ACTIONS.SET_FORM, payload: []})
    dispatch({type: ACTIONS.SET_QUESTIONS, payload: ""})
}}

    const onChange = (index, event) => {
    event.preventDefault();
    event.persist();

      const newForm = state.form.map((item, i) => {
        if (i !== index) {
          return item;
        }

        return {
          ...item,
          [event.target.name]: event.target.value,

          errors: {
            ...item.errors,
            [event.target.name]:
              event.target.value.length > 0
                ? null
                : [event.target.name] + " Is required",
          },
        };
      });
      dispatch({type: ACTIONS.SET_FORM, payload: newForm})
    
  };
  
  const addAnswer = (index, e) => {
    e.preventDefault();
    e.persist();
      dispatch({type: ACTIONS.SET_FORM, payload: state.form.map((item, i) => {
        if (i !== index) {
        return {
          ...item,
          [e.target.name]: false,
        }}else{
        return {
          ...item,
          [e.target.name]: true,
        }
        }
      })})
  };

  const handleRemoveField = (index) => {
    dispatch({type: ACTIONS.SET_FORM, payload: state.form.filter((item) => item !== state.form[index])}) 
  };
    

  const removeQuestion = (index) => {
    dispatch({type: ACTIONS.SET_QUESTION, payload: state.question.filter((item) => item !== state.question[index])})
  }
  
const notify = (type, message) =>{
    switch(type){
        case "success": {
        return toast.success(message, {position: toast.POSITION.TOP_RIGHT, theme: "colored", transition: Slide,  hideProgressBar: true})
        }
        case "error": {
        return toast.error(message, {position: toast.POSITION.TOP_RIGHT, theme: "colored", transition: Slide,  hideProgressBar: true})
        }
        case "warning": {
        return toast.warn(message, {position: toast.POSITION.TOP_RIGHT, theme: "colored", transition: Slide,  hideProgressBar: true})
        }
        case "inform": {
        return toast.info(message, {position: toast.POSITION.TOP_RIGHT, theme: "colored", transition: Slide,  hideProgressBar: true})
        }
        case "errorDrop": {
        return toast.error(message, {position: toast.POSITION.BOTTOM_RIGHT, theme: "colored", transition: Slide,  hideProgressBar: false})
        }
        case "successDrop": {
        return toast.success(message, {position: toast.POSITION.BOTTOM_RIGHT, theme: "colored", transition: Slide,  hideProgressBar: false})
        }
        default: {
        return toast.info(message, {position: toast.POSITION.TOP_RIGHT, theme: "colored", transition: Slide,  hideProgressBar: true})
        }
    }
}


const isLoading = (data) => {
    dispatch({type: ACTIONS.SET_LOADING, payload: data})
}

const onEditorStateChange = (editorState) => {
    dispatch({type: ACTIONS.SET_EDITOR_STATE, payload: editorState})
    const dirtyHTML = draftToHtml(convertToRaw(editorState.getCurrentContent()))
    const cleanHTML = DOMPurify.sanitize(dirtyHTML, {
  USE_PROFILES: { html: true },
});
dispatch({type: ACTIONS.SET_JOB_DESCRIPTION, payload: cleanHTML})
   }
   


 
    function jobType(limit){
          isLoading(true)
           const token = localStorage.getItem("token")
         return(
             axios.get(`${url}get-all-job-type?limit=${limit ? limit : ""}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(res => {
            dispatch({type: ACTIONS.SET_JOB_TYPES, payload: res.data})
            isLoading(false)
             }).catch(err => {
               isLoading(false)
             })
         )
    }

    function category(limit){
      isLoading(true)

           const token = localStorage.getItem("token")
         return(
             axios.get(`${url}get-all-job-category?limit=${limit ? limit : ""}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(res => {
            dispatch({type: ACTIONS.SET_JOB_CATEGORIES, payload: res.data})
          isLoading(false)
             }).catch(err => {
              isLoading(false)
            })
         )
    }

    function industry(limit){
      isLoading(true)
           const token = localStorage.getItem("token")
         return(
             axios.get(`${url}get-all-industry?limit=${limit ? limit : ""}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(res => {
            dispatch({type: ACTIONS.SET_INDUSTRIES, payload: res.data})
          isLoading(false)
             }).catch(err => {
              isLoading(false)
            })
         )
    }

    function experienceLevel(limit){
      isLoading(true)
           const token = localStorage.getItem("token")
         return(
             axios.get(`${url}get-all-career-level?limit=${limit ? limit : ""}`,  {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(res => {
            dispatch({type: ACTIONS.SET_CAREER_LEVEL, payload: res.data})
          isLoading(false)
             }).catch(err => {
              isLoading(false)
            })
         )
    }
    
    function getSkill(limit){
      isLoading(true)
           const token = localStorage.getItem("token")
         return(
             axios.get(`${url}get-all-skill?limit=${limit ? limit : ""}`,  {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(res => {
            dispatch({type: ACTIONS.SET_SKILLS, payload: res.data})
          isLoading(false)
             }).catch(err => {
              isLoading(false)
            })
         )
    }

    const getEmployerJobs =  async (limit) => {
        try {
            isLoading(true)
            const token = localStorage.getItem('token')
            await axios.get(`${url}get-employer-jobs/${limit}?jb=here`,{ headers: {
                         'Authorization': `Bearer ${token}`
                     }}).then(
              data => {
              dispatch({type: ACTIONS.SET_EMPLOYER_JOBS, payload: data.data.jobs})
              dispatch({type: ACTIONS.SET_JOBS_MEMORY, payload: data.data.jobs})
              dispatch({type: ACTIONS.SET_POSTED_JOBS, payload: data.data.total.length})
            isLoading(false)

              }
            )  
        } catch (error) {
            isLoading(false)
            
        }
     
    }

    const getEmployerApplicants = (limit) => {
       isLoading(true)
        const token = localStorage.getItem('token')
        axios.get(`${url}get-employer-application?limit=${limit ? limit : ""}`,{ headers: {
                   'Authorization': `Bearer ${token}`
               }}).then(
        data => {
          console.log(data.data)
         dispatch({type: ACTIONS.SET_APPLICANTS, payload: data.data})
        // dispatch({type: ACTIONS.SET_TOTAL_APPLICANT, payload: data.data})
         dispatch({type: ACTIONS.SET_SEARCH_APPLICANTS, payload: data.data})
         isLoading(false)
        }
      ).catch(error => {
        isLoading(false)
      })
      }
     

      const getEmployerNumberOfUser = () => {
        const token = localStorage.getItem('token')
        axios.get(`${url}get-admin-percentage`,{ headers: {
                     'Authorization': `Bearer ${token}`
                 }}).then(
          data => {
            dispatch({type: ACTIONS.SET_NUMBER_OF_USERS, payload: parseInt(data.data.total)})
          }
        )
      }

      const setModal = (data, type, id) => {
        dispatch({type: ACTIONS.SET_MODAL, payload: data+type})
        dispatch({type: ACTIONS.SET_MODAL_DATA, payload: id})
      }
      const deleteData = async (data) => {
        const token = localStorage.getItem("token")
          if(data === "job"){
            isLoading(true)
            axios.delete(`${url}delete-job-temp/${state.modal_data}`, { headers: {'Authorization': `Bearer ${token}`}}).then(res => {
                if(res){
                    notify("success", "Job deleted successfully")
                    getEmployerJobs()
                    isLoading(false)
                }
            }).catch(err => {
                    notify("error", "Could not delete job")
                    isLoading(false)
            })
          }else if(data === "application"){

          }else{
              return
          }

      }

      const search_jobs = async (limit) => {
        try {
          const { job_search_text, country_search_text, career_level_search_arr, industy_search_arr, category_search_arr, job_type_search_arr} = state
          if(job_search_text || country_search_text || career_level_search_arr.length || industy_search_arr.length || category_search_arr.length || job_type_search_arr.length){
      try {
      isLoading(true)
     const res = await axios.get(`${url}search-all-job`, { params: {text: job_search_text, country: country_search_text, level: career_level_search_arr, type: job_type_search_arr, industry: industy_search_arr, category: category_search_arr, limit}})
     dispatch({type: ACTIONS.SET_JOBS, payload: res.data})
     isLoading(false)   
      } catch (error) {
       alert(error)   
       isLoading(false)
      }
          }else{
              get_jobs(limit)
            } 
        } catch (error) {
          console.log(error)
        }
      
      }

      const createBookMark = ({e, job_id}) => {     
        e.preventDefault();
        const token = localStorage.getItem('token')
        axios.post(`${url}create-bookmark`, {job_id},{
          headers: {
            'Authorization': `Bearer ${token}`
          },
    
        }).then(
          res => {
            getBookMarkjobs()
           notify("success", "Job book marked")
           
          }
        ).catch(error => {
           notify("error", "Could not save job")
        })
      }

      const getBookMarkjobs = () => {
        const token = localStorage.getItem('token')
         axios.get(`${url}get-user-bookmark`, {
           headers: {
             'Authorization': `Bearer ${token}`
           },
         }).then(res => {
           dispatch({type: ACTIONS.SET_BOOK_MARKS, payload: res.data})
         })
       }

       const deleteBookMark = ({e, id}) => {
        e.preventDefault();
        const token = localStorage.getItem('token')
        axios.delete(`${url}deleteBookMark/${id}`,{
          headers: {
            'Authorization': `Bearer ${token}`
          },
    
        }).then(
          res => {
            getBookMarkjobs()
            notify("success", "Bookmark removed")
          }
        ).catch(error => {
            notify("error", "An error occured")
        })
      }

      const updateData = async (data, type, id) => {
          const date = moment().add(1, "week")
          const token = localStorage.getItem("token")
          isLoading(true)
          if(data === "job" && type === "open"){
          await axios.put(`${url}open-job/${state.modal_data}`,{close_date: new Date(date)}, { headers: {'Authorization': `Bearer ${token}`}}).then(res => {
            if(res){
                notify("success", "Job opened successfully")
                dispatch({type: ACTIONS.SET_MODAL, payload: ""})
                getEmployerJobs()
            }
        }).catch(err => {
            notify("error", "Could not open job")
            isLoading(false)
        })
          }else if(data === "job" && type === "close"){
          await axios.put(`${url}close-job/${state.modal_data}`,{}, { headers: {'Authorization': `Bearer ${token}`}}).then(res => {
            if(res){
                notify("success", "Job closed successfully")
                dispatch({type: ACTIONS.SET_MODAL, payload: ""})
                getEmployerJobs()
            }
        }).catch(err => {
            notify("error", "Could not close job")
            isLoading(false)
        })
          }else if(data === "application" && type === "accept"){
            await axios.put(`${url}accept-application/${id}`, {}, { headers: {'Authorization': `Bearer ${token}`}}).then(res => {
                if(res){
                    notify("success", "Application accepted successfully")
                    dispatch({type: ACTIONS.SET_MODAL, payload: ""})
                    getEmployerApplicants(5)
                }
            }).catch(err => {
                notify("error", "Could not accept application")
                isLoading(false)
            })
          }else if(data === "application" && type === "reject"){
            await axios.put(`${url}reject-application/${id}`, {}, { headers: {'Authorization': `Bearer ${token}`}}).then(res => {
                if(res){
                    notify("success", "Application rejected successfully")
                    dispatch({type: ACTIONS.SET_MODAL, payload: ""})
                    getEmployerApplicants(5)
                }
            }).catch(err => {
                notify("error", "Could not reject application")
                isLoading(false)
            })
        }else{
              return  isLoading(false)
          }

      }
      const editData = async (data) => {
        if(data === "job"){
          
        }else if(data === "application"){

        }else{
            return
        }

    }

    
    

    const create_job_default = () => {
        dispatch({type: ACTIONS.SET_JOB_ROLE, payload: ""})
        dispatch({type: ACTIONS.SET_JOB_CATEGORY, payload: {}})
        dispatch({type: ACTIONS.SET_JOB_DESCRIPTION, payload: ""})
        dispatch({type: ACTIONS.SET_EDITOR_STATE, payload: EditorState.createEmpty()})
        dispatch({type: ACTIONS.SET_SELECTED_SKILLS, payload: []})
        dispatch({type: ACTIONS.SET_JOB_SALARY_RANGE, payload: ""})
        dispatch({type: ACTIONS.SET_JOB_TYPE, payload: {}})
        dispatch({type: ACTIONS.SET_JOB_EXPERIENCE_LEVEL, payload: {}})
        dispatch({type: ACTIONS.SET_JOB_STATE, payload: ""})
        dispatch({type: ACTIONS.SET_JOB_COUNTRY, payload: ""})
        dispatch({type: ACTIONS.SET_JOB_CLOSING_DATE, payload: null})
        dispatch({type: ACTIONS.SET_JOB_ANONYMOUS, payload: false})
        dispatch({type: ACTIONS.SET_QUESTIONS, payload: ""})
        dispatch({type: ACTIONS.SET_JOB_ID, payload: ""})
        dispatch({type: ACTIONS.SET_QUESTION, payload: []})
        dispatch({type: ACTIONS.SET_FORM, payload: []})
    }

    const handleSelectSkill = (data) => {
        dispatch({type: ACTIONS.SET_SELECTED_SKILLS, payload: data})
      }

    const pages_clean_up = (page) => {
        if(page === "employer/dashboard"){
            dispatch({type: ACTIONS.SET_JOBS, payload: []})
            dispatch({type: ACTIONS.SET_APPLICANTS, payload: []})
            dispatch({type: ACTIONS.SET_TOTAL_APPLICANT, payload: 0})
            dispatch({type: ACTIONS.SET_SEARCH_APPLICANTS, payload: []})
            dispatch({type: ACTIONS.SET_NUMBER_OF_USERS, payload: 0})
        }else if(page === "employer/postedjob"){
            dispatch({type: ACTIONS.SET_JOBS, payload: []})
            dispatch({type: ACTIONS.SET_JOBS_MEMORY, payload: []})

        }else if(page === "employer/application"){
            dispatch({type: ACTIONS.SET_JOBS, payload: []})
            dispatch({type: ACTIONS.SET_APPLICANTS, payload: []})
            dispatch({type: ACTIONS.SET_TOTAL_APPLICANT, payload: 0})
            dispatch({type: ACTIONS.SET_SEARCH_APPLICANTS, payload: []})
        }else if(page === "employer/createjob"){
            create_job_default()
        }
    }
      const filterApplicantData = (value, type) => {
        if(value == "none" && type === "application") return dispatch({type: ACTIONS.SET_APPLICANTS, payload: state.search_applicants})
        if(value == "none" && type === "job") return dispatch({type: ACTIONS.SET_EMPLOYER_JOBS, payload: state.jobs_memory})

          isLoading(true)
          if(type === "application"){
            const filterApplicant = state.applicants.filter(
                contact => {
                    let contactLowerCase = (
                        contact.job.jobrole + ' ' +
                        contact.job.jobrole).toLowerCase();
                        let findTermLowerCase = value.toLowerCase();
                        return contactLowerCase.indexOf(findTermLowerCase) > -1
                    })
                   !filterApplicant.length && notify("inform", "No applicant found")
                   dispatch({type: ACTIONS.SET_APPLICANTS, payload: filterApplicant.length ? filterApplicant : state.search_applicants})
                   isLoading(false)
          }else if (type === "job"){
            const filterJob = state.employer_jobs.filter(
                contact => {
                    let contactLowerCase = (
                        contact.type.type).toLowerCase();
                        let findTermLowerCase = value.toLowerCase();
                        return contactLowerCase.indexOf(findTermLowerCase) > -1
                    })
                   !filterJob.length && notify("inform", "No job found")
                   dispatch({type: ACTIONS.SET_EMPLOYER_JOBS, payload: filterJob.length ? filterJob : state.jobs_memory})
                   isLoading(false)
          }else{
          return isLoading(false)
        }
        
    }

    function signOut(){
          localStorage.removeItem('token')
          dispatch({type: "", state: {}})
        return(
             setIsLoggedIn(false))}

    async function signIn({email, password}){
          const { data } = await postt(`${url}userlogintoken`, {email, password})
        const token = data
           await axios.get(`${url}verifytokenandgetuser`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }}).then( (response) => {
                setIsLoggedIn(true)
                localStorage.setItem('token', token)
                 setCurrentUser(response.data.user)
                 get_jobs();
                 experienceLevel();
                 category();
                 industry();
                 jobType();
                 getSkill();
                 setLoading(false)
           }).catch(error =>{
                setIsLoggedIn(false)
                setLoading(false)
                return error
            }) 
       }

       //deleteQuestion
    const update_question = async()=> {
       const upp = await updateQuestion(notify, state, isLoading) 
       if(upp){
           await getOneJob(upp)
       }
    }

    const getLatestJobs = (limit) => {
        axios.get(`${url}get-few-jobs/${limit}`).then(res => {
        dispatch({type: ACTIONS.SET_LATEST_JOBS, payload: res.data})
      })
      }
    const getFeaturedJobs = () => {
        axios.get(`${url}get-featured-jobs`).then(res => {
            dispatch({type: ACTIONS.SET_FEATURED_JOBS, payload: res.data})
          })
    }
      
    const delete_question = async(id)=> {
        const upp = await deleteQuestion(notify, state, id) 
        if(upp){
            await getOneJob(upp)
        }
     }
       const loadPaymentData = async () => {
         await employer_payments(dispatch, isLoading, currentUser)
        }
       const create_job = async (e) => {
           e.preventDefault()
        await handleCreateJobSubmit(state, isLoading, notify, currentUser, create_job_default)
       }

       const update_job = async (e) => {
        e.preventDefault()
        await handleUpdateJob(state, isLoading, notify, currentUser, create_job_default)
        await getOneJob(state.job_url_id)
        }
       const getOneJob = async (id) => {
          isLoading(true)
          const job_data = await getOnejob(id, notify, isLoading)
          job_data._id ? dispatch({type: ACTIONS.SET_JOB_ID, payload: job_data._id}) : dispatch({type: ACTIONS.SET_JOB_ID, payload: ""})
          job_data.owner && currentUser.isAdmin ? dispatch({type: ACTIONS.SET_EMPLOYER, payload: job_data.owner}) : dispatch({type: ACTIONS.SET_EMPLOYER, payload: {}})
          job_data.jobId ? dispatch({type: ACTIONS.SET_JOB_URL_ID, payload: job_data.jobId}) : dispatch({type: ACTIONS.SET_JOB_URL_ID, payload: ""})
          job_data.jobrole ? dispatch({type: ACTIONS.SET_JOB_ROLE, payload: job_data.jobrole}) : dispatch({type: ACTIONS.SET_JOB_ROLE, payload: ""})
          job_data.category ? dispatch({type: ACTIONS.SET_JOB_CATEGORY, payload: job_data.category}): dispatch({type: ACTIONS.SET_JOB_CATEGORY, payload: {}})
          job_data.skill && job_data.skill.length ? dispatch({type: ACTIONS.SET_SELECTED_SKILLS, payload: job_data.skill}) : dispatch({type: ACTIONS.SET_SELECTED_SKILLS, payload: []})
          job_data.payment ? dispatch({type: ACTIONS.SET_JOB_SALARY_RANGE, payload: job_data.payment}) : dispatch({type: ACTIONS.SET_JOB_SALARY_RANGE, payload: ""})
          job_data.type ? dispatch({type: ACTIONS.SET_JOB_TYPE, payload: job_data.type}) : dispatch({type: ACTIONS.SET_JOB_TYPE, payload: {}})
          job_data.career_level ? dispatch({type: ACTIONS.SET_JOB_EXPERIENCE_LEVEL, payload: job_data.career_level}) : dispatch({type: ACTIONS.SET_JOB_EXPERIENCE_LEVEL, payload: {}})
          job_data.state ? dispatch({type: ACTIONS.SET_JOB_STATE, payload: job_data.state}) : dispatch({type: ACTIONS.SET_JOB_STATE, payload: ""})
          job_data.country ? dispatch({type: ACTIONS.SET_JOB_COUNTRY, payload: job_data.country}) : dispatch({type: ACTIONS.SET_JOB_COUNTRY, payload: ""})
          job_data.close_date ? dispatch({type: ACTIONS.SET_JOB_CLOSING_DATE, payload: job_data.close_date}) : dispatch({type: ACTIONS.SET_JOB_CLOSING_DATE, payload: null})
          dispatch({type: ACTIONS.SET_JOB_ANONYMOUS, payload: job_data.anonymous})
          job_data.question && job_data.question.length && dispatch({type: ACTIONS.SET_QUESTION, payload: job_data.question})
          if(job_data.jobDescription){
            dispatch({type: ACTIONS.SET_JOB_DESCRIPTION, payload: job_data.jobDescription})
            const blocksFromHTML = convertFromHTML(job_data.jobDescription)
            const content = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap)
            dispatch({type: ACTIONS.SET_EDITOR_STATE, payload: EditorState.createWithContent(content)})
          }else{
            dispatch({type: ACTIONS.SET_JOB_DESCRIPTION, payload: ""}) 
            dispatch({type: ACTIONS.SET_EDITOR_STATE, payload: EditorState.createEmpty()})
          }
          isLoading(false)
        
       }

    function signInToken({token}){
            axios.get(`${url}verifytokenandgetuser`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
                 }).then( (response) => {
                 setIsLoggedIn(true)
                 setCurrentUser(response.data.user)
                 get_jobs();
                 experienceLevel();
                 category();
                 industry();
                 jobType();
                 getSkill();
                 setLoading(false)
           }).catch(error =>{
                setIsLoggedIn(false)
                setLoading(false)
            })}


     async function get_jobs(limit) {
         try {
         isLoading(true)
         const res = await axios.get(`${url}get-all-job?limit=${limit ? limit : ""}`)
         dispatch({type: ACTIONS.SET_JOBS, payload: res.data})
         isLoading(false)
         } catch (error) {
             isLoading(false)
         console.log(error)
         }
     }
        
     function signUp({first_name, last_name, email, password, company_email, company_name, phone}){
        if(company_name && !first_name && !last_name){
        return axios.post(`${url}createmployer`, {email, password, company_email, company_name, phone}).then(
        response => {
            const token = response.data
              axios.get(`${url}verifytokenandgetuser`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(async(response) => {
             localStorage.setItem('token', token)
                 const user = response.data.user
                 setIsLoggedIn(true)
                 setCurrentUser(user)
                 setLoading(false)
           }).catch(err => {
               setIsLoggedIn(false)
               setLoading(false)
               return err
           })})
        }else{
        return axios.post(`${url}createcandidate`, {first_name, last_name, email, password, phone}).then(
        response => {
            const token = response.data
              axios.get(`${url}verifytokenandgetuser`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then((response) => {
             localStorage.setItem('token', token)
                 const user = response.data.user
                 setIsLoggedIn(true)
                 setCurrentUser(user)
                 setLoading(false)
           }).catch(err => {
               setIsLoggedIn(false)
               setLoading(false)
               return err
           })})}}

    const getUser = () => {
        const token = localStorage.getItem("token")
        axios.get(`${url}verifytokenandgetuser`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }}).then( (response) => {
                 const user = response.data.user
                 const userId = user._id
                 setIsLoggedIn(true)
                 setCurrentUser(user)
                 setLoading(false)
           }).catch(err => {
               if(err)
               setIsLoggedIn(false)
               setLoading(false)
           })
    }


     useEffect(() => {
        getUser(); 
        experienceLevel();
        category();
        industry();
        jobType();
        getSkill();
    }, [])




const value = {
    currentUser,
    signIn,
    getEmployerJobs,
    industry,
    state,
    jobType,
    category,
    isLoading,
    updateData,
    handleAddLink,
    addQuestion,
    experienceLevel,
    removeQuestion,
    delete_question,
    getSkill,
    onChange,
    update_question,
    addAnswer, 
    handleRemoveField,
    deleteData,
    createBookMark,
    getLatestJobs,
    getFeaturedJobs,
    deleteBookMark,
    getBookMarkjobs,
    onEditorStateChange,
    handleSelectSkill,
    signInToken,
    getEmployerApplicants,
    signOut,
    pages_clean_up,
    update_job,
    loadPaymentData,
    create_job,
    updateData,
    notify,
    search_jobs,
    getOneJob,
    getEmployerNumberOfUser,
    getUser,
    dispatch,
    filterApplicantData,
    isLoggedIn,
    signUp,
    time: generateGreetings(),
} 

return ( <AuthContext.Provider value={value}>
        {!loading && children}
    </AuthContext.Provider>
)
}
