import React, {useState} from 'react'
import { toast, Slide } from 'react-toastify'
import axios from 'axios'
import  { useAuths }  from '../../AuthContext';
import { Helmet } from 'react-helmet'
import 'react-toastify/dist/ReactToastify.css';
const url = process.env.REACT_APP_SERVER_URL


toast.configure()

const success = () => {
    toast.success('Admin Added Successfully', {position: toast.POSITION.TOP_RIGHT, theme: "colored", transition: Slide,  hideProgressBar: true})
  }

const required = () => {
    toast.warn('All Field Required', {position: toast.POSITION.TOP_CENTER, theme: "colored", transition: Slide,  hideProgressBar: true})
  }

const create_admin_error = () => {
    toast.error('Email Already Exist, Please Use Another Email', {position: toast.POSITION.TOP_RIGHT, theme: "colored", transition: Slide,  hideProgressBar: true})
  }

const error = () => {
    toast.error('Could Not Create Sub Administrator, Please Try Again', {position: toast.POSITION.TOP_RIGHT, theme: "colored", transition: Slide,  hideProgressBar: true})
  }




  export default function CreateAdminModal({loadData}){
  const [email, set_email] = useState("")
  const { notify } = useAuths()
  const [first_name, set_first_name] = useState("")
  const [last_name, set_last_name] = useState("")
  
    

  const handle_submit = (e) => {
    e.preventDefault();
    if(!email || !first_name || !last_name){
      required()
    }else{
    const token = localStorage.getItem('token')
    axios.post(`${url}create-sub-admin-by-super-admin`, {email, first_name, last_name}, {headers: {
                'Authorization': `Bearer ${token}`
            }}).then(
      res => {
        success()
        loadData()
      }).catch(err => {
       if(err.response && err.response.status && err.response.status === 400){
        notify("error", "Email has already been used")
       }else{
        error()}
    })}
  }



  return(
    <div>
          <div className="login-modal-main bg-white rounded-8 overflow-hidden">
            <div className="no-gutters">
              <div>
                    <h4 className="text-center">Create Sub Administrator</h4>
                <div className="bg-white-2 h-100 px-11 pt-6 pb-7">  
                
                  <form action="/">
                    <div className="form-group">
                      <label
                        className="
                          font-size-4
                          text-black-2
                          font-weight-semibold
                          line-height-reset
                        "
                        >First Name</label>
                      <input
                        onChange={e => {set_first_name(e.target.value)}}
                        type="text"
                        className="form-control"
                        placeholder="e.g Simi"
                        id="text"
                      />
                    </div>
                     <div className="form-group">
                      <label
                        className="
                          font-size-4
                          text-black-2
                          font-weight-semibold
                          line-height-reset
                        "
                        >Last Name</label>
                      <input
                        onChange={e => {set_last_name(e.target.value)}}
                        type="text"
                        className="form-control"
                        placeholder="e.g Adeola"
                        id="text"
                      />
                    </div>
                     <div className="form-group">
                      <label
                        className="
                          font-size-4
                          text-black-2
                          font-weight-semibold
                          line-height-reset
                        "
                        >Email</label>
                      <input
                        onChange={e => {set_email(e.target.value)}}
                        type="email"
                        className="form-control"
                        placeholder="e.g hr@strivehumanresources.com"
                        id="email"
                      />
                    </div>
                    <div className="form-group mb-8">
                      <button
                       onClick={e => {handle_submit(e)}}
                        className="
                          btn btn-primary btn-medium
                          w-100
                          rounded-5
                          text-uppercase
                        "
                      >
                        Create Administrator
                      </button>
                    </div>
                  
                  </form>
                </div>
              </div>
            </div>
          </div>
    </div>
  )
}


