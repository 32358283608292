import React, {useEffect} from 'react'
import axios from 'axios'
import Navbar from '../shared-components/navbar'
import pdf from "../image/svg/pdf.svg"
import  { useAuths }  from '../AuthContext';
import {useHistory, useParams} from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { toast, Slide } from 'react-toastify'
import ReactLoading from "react-loading";
//import Quiz from 'react-quiz-component';
import 'react-toastify/dist/ReactToastify.css';
const url = process.env.REACT_APP_SERVER_URL


toast.configure()







export default function ApplyNow ({location}){
  const [resume_text, set_resume_text] = React.useState("")
  const [loading, setLoading] = React.useState(true)
  const [jobInformation, setJobInformation] = React.useState({
    applicant: [],
    skill: [],
    country: "",
    jobId: "",
    state: "",
    jobrole: "",
    close_date: "",
    jobDescription: "",
    createdAt: "",
    anonymous: true
  })
  const [displayQuestion, setDisplayQuestion] = React.useState(false)
  const [question, setQuestion] = React.useState([])
  const [jobType, setJobType] = React.useState({
    type: "",
    information: ""
  })
  const [career_level, set_career_level] = React.useState({
    title: "",
    years: "",
    information: ""
  })
  const [category, set_category] = React.useState({
    category: "",
    information: ""
  })
  const [industry, set_industry] = React.useState({
    name: "",
    information: ""
  })
  const [owner, setOwner] = React.useState({
    company_name: "",
    _id: ""
  })
const param = useParams()
const param_token = param.token
const param_data = param.data
const query_data = param_token+'/'+param_data
const [selected_resume, set_selected_resume] = React.useState("")
const [answer_data, set_answer_data] = React.useState([])
const { currentUser, isLoggedIn } = useAuths()
const history = useHistory()
const token = localStorage.getItem('token')
const getJobData = () => {
   axios.get(`${url}get-one-job`,{
      params: {id: query_data},
      headers: {
                'Authorization': `Bearer ${token}`
   }}).then(
              response => {
                setJobInformation({
                  ...jobInformation,
                  country: response.data.country,
                  state: response.data.state,
                  jobrole: response.data.jobrole,
                  jobId: response.data._id,
                  close_date: response.data.close_data,
                  jobDescription: response.data.jobDescription,
                  anonymous: response.data.anonymous
                })
                setQuestion(response.data.question)
                setJobType({
                  ...jobType,
                  type: response.data.type && response.data.type.type,
                  information: response.data.type && response.data.type.information
                })
                if(response.data.category){
                set_category({
                  ...category,
                  category: response.data.category.category,
                  information: response.data.category.information,
                })}
                set_career_level({
                  ...career_level,
                  title: response.data.career_level.title,
                  years: response.data.career_level.year,
                  information: response.data.career_level.information
                })
                if(response.data.industry){
                set_industry({
                  ...industry,
                  name: response.data.industry.name,
                  information: response.data.industry.information
                })}
                setOwner({
                  ...owner,
                  company_name: response.data.owner.company_name,
                  _id: response.data.owner._id
                })
                if(response.data.question){
                if(response.data.question.length > 0){
                  setDisplayQuestion(true)
                }}
                setTimeout(() => {
                  setLoading(false)
                }, 1000)
              }
            )
 }

 
 
  useEffect(() => {
    let mounted = true

    if(mounted){
      isLoggedIn ?
      getJobData() : history.push('/login')
    }

    return() => {
      mounted = false
    }
  }, [])

  const subMitApplication = (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token')
    if(question.length !== answer_data.length){
      required()
    }else if(!selected_resume){
      required_cv()
    }else{
      axios.post(`${url}create-application`,  {
      company: owner._id,
      job: jobInformation.jobId,
      resume: selected_resume,
      answers: answer_data
    }, {
      headers: {
                'Authorization': `Bearer ${token}`
            }}).then(data =>{
      if(data)
      {success()
        history.push('/applied-jobs')
      }
    }).catch(err =>{
      error()
    })
    }
  }


  const answer = (e, index, questionId) => {
    e.preventDefault();
    const candidateAns = e.target.value
    const data = {candidateId: currentUser._id, questionId: questionId, candidateAnswer: candidateAns, index: index}
    
    
  }
  
  const success = () => {
    toast.success('Application Submitted Successfully', {position: toast.POSITION.TOP_RIGHT, theme: "colored", transition: Slide,  hideProgressBar: true})
  }

 const required = () => {
    toast.warn('Please answer all question', {position: toast.POSITION.TOP_RIGHT, theme: "colored", transition: Slide,  hideProgressBar: true})
  }

  const required_cv = () => {
    toast.warn('You have no resume to apply for this job', {position: toast.POSITION.TOP_RIGHT, theme: "colored", transition: Slide,  hideProgressBar: true})
  }

  const error = () => {
   toast.error('Could Not Submit Application. Please try again', {position: toast.POSITION.TOP_RIGHT, theme: "colored", transition: Slide,  hideProgressBar: true})
  }

  /*


  

  const quiz =  {
  "quizTitle": "React Quiz Component Demo",
  "quizSynopsis": "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim",
  "questions": [
    {
      "question": "How can you access the state of a component from inside of a member function?",
      "questionType": "text",
      "questionPic": "https://dummyimage.com/600x400/000/fff&text=X", // if you need to display Picture in Question
      "answerSelectionType": "single",
      "answers": [
        "this.getState()",
        "this.prototype.stateValue",
        "this.state",
        "this.values"
      ],
      "correctAnswer": "3",
      "messageForCorrectAnswer": "Correct answer. Good job.",
      "messageForIncorrectAnswer": "Incorrect answer. Please try again.",
      "explanation": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      "point": "20"
    },
    {
      "question": "ReactJS is developed by _____?",
      "questionType": "text",
      "answerSelectionType": "single",
      "answers": [
        "Google Engineers",
        "Facebook Engineers"
      ],
      "correctAnswer": "2",
      "messageForCorrectAnswer": "Correct answer. Good job.",
      "messageForIncorrectAnswer": "Incorrect answer. Please try again.",
      "explanation": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      "point": "20"
    },
    {
      "question": "ReactJS is an MVC based framework?",
      "questionType": "text",
      "answerSelectionType": "single",
      "answers": [
        "True",
        "False"
      ],
      "correctAnswer": "2",
      "messageForCorrectAnswer": "Correct answer. Good job.",
      "messageForIncorrectAnswer": "Incorrect answer. Please try again.",
      "explanation": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      "point": "10"
    },
    {
      "question": "Which of the following concepts is/are key to ReactJS?",
      "questionType": "text",
      "answerSelectionType": "single",
      "answers": [
        "Component-oriented design",
        "Event delegation model",
        "Both of the above",
      ],
      "correctAnswer": "3",
      "messageForCorrectAnswer": "Correct answer. Good job.",
      "messageForIncorrectAnswer": "Incorrect answer. Please try again.",
      "explanation": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      "point": "30"
    },
    {
      "question": "Lorem ipsum dolor sit amet, consectetur adipiscing elit,",
      "questionType": "photo",
      "answerSelectionType": "single",
      "answers": [
        "https://dummyimage.com/600x400/000/fff&text=A",
        "https://dummyimage.com/600x400/000/fff&text=B",
        "https://dummyimage.com/600x400/000/fff&text=C",
        "https://dummyimage.com/600x400/000/fff&text=D"
      ],
      "correctAnswer": "1",
      "messageForCorrectAnswer": "Correct answer. Good job.",
      "messageForIncorrectAnswer": "Incorrect answer. Please try again.",
      "explanation": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      "point": "20"
    },
    {
      "question": "What are the advantages of React JS?",
      "questionType": "text",
      "answerSelectionType": "multiple",
      "answers": [
        "React can be used on client and as well as server side too",
        "Using React increases readability and makes maintainability easier. Component, Data patterns improves readability and thus makes it easier for manitaining larger apps",
        "React components have lifecycle events that fall into State/Property Updates",
        "React can be used with any other framework (Backbone.js, Angular.js) as it is only a view layer"
      ],
      "correctAnswer": [1, 2, 4],
      "messageForCorrectAnswer": "Correct answer. Good job.",
      "messageForIncorrectAnswer": "Incorrect answer. Please try again.",
      "explanation": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      "point": "20"
    },
  ]
} 
*/
    const answer_question = (answer, id) => {
     const filter_answer = answer_data.filter(i => { return id !== i.id})
     set_answer_data([...filter_answer, {answer: answer, id}])
    }


    const select_resume = (data) => {
      const get_data = JSON.parse(data)
      set_selected_resume(get_data.link)
      set_resume_text(get_data.name)
    }


  return(
    <div>
       <Helmet>
            <title>Apply - Job Board | StriveHR </title>
            </Helmet>
      <Navbar/>
    {loading ? <div className="center-sign-in-loading dashboard-main-container" style={{backgroundColor: "#fff", width: "100%", height: "100%", justifyContent: "center", alignItems: "center"}}>
      <ReactLoading className="center-sign-in-loading " type="bars" color="#8B3300" height={'20%'} width={'10%'} />
      </div> : <div
    className="jobDetails-section bg-default-1 pt-28 pt-lg-27 pb-xl-25 pb-12"
  >
    <div className="container">
      <div className="row justify-content-center">
        <div
          className="
            col-xl-10 col-lg-11
            mt-4
            ml-xxl-32 ml-xl-15
            dark-mode-texts
          "
        >
          <div className="mb-9">
            <a 
              className="d-flex align-items-center ml-4"
              href=""
              onClick={e => {e.preventDefault(); history.goBack()}}
            >
              <i
                className="
                  icon icon-small-left
                  bg-white
                  circle-40
                  mr-5
                  font-size-7
                  text-black
                  font-weight-bold
                  shadow-8
                "
              >
              </i><span
                className="
                  text-uppercase
                  font-size-3 font-weight-bold
                  text-gray
                "
                >Back to job details</span ></a>
          </div>
        </div>
        <div className="col-xl-9 col-lg-11 mb-8 px-xxl-15 px-xl-0">
          <div className="bg-white rounded-4 border border-mercury shadow-9">
            {!jobInformation.anonymous ? <div
              className="
                pt-9
                pl-sm-9 pl-5
                pr-sm-9 pr-5
                pb-8
                border-bottom border-width-1 border-default-color
                light-mode-texts
              "
            >
              <div className="row">
                <div className="col-md-6">
                  <div className="media align-items-center">
                      <h3 className="font-size-6 mb-0">Apply to {owner.company_name}</h3>
                  </div>
                </div>
              </div>
              
            </div>: null}
            
            <div
              className="
                job-details-content
                pt-8
                pl-sm-9 pl-6
                pr-sm-9 pr-6
                pb-10
                light-mode-texts
              "
            >
              <div className="row">
                <div className="col-xl-11 col-md-12 pr-xxl-9 pr-xl-10 pr-lg-20">
                  <div className="">
                    <p className="mb-4 font-size-4 text-gray">
                      Apply with this resume.
                    </p>
                       <div className="d-flex align-items-start p-5 border border-rd-5 d-inline-block">
                        <img
                          src={pdf}
                          className="mr-1"
                          width="50"
                          height="50"
                        />
                        <div className="flex-grow-1 ml-3">
                         {selected_resume ? <a href={selected_resume} target = "_blank"><b>{resume_text}</b> 
                          <div className="small">
                            Saved resume file
                          </div></a>:
                          <div><b>You have not selected a resume</b> 
                          <div className="small">
                           No Saved resume file
                          </div></div>}
                        </div><div>
                               <div className="col-lg-12">
                          <div className="form-group">
                            <label
                              className="
                                d-block
                                text-black-2
                                font-size-4 font-weight-semibold
                                mb-4
                              "
                            >
                              Your Resumes</label>
                          <select className="form-control h-px-48" id="businesstype" onChange={e => {select_resume(e.target.value)}}>
                              <option value="" selected>Choose...</option>
                              {currentUser.resume.map( i => (<option key={i._id} value={JSON.stringify(i)}>{i.name}</option>))}
                            </select>
                          </div>
                        </div>
                        </div> 
                      </div>
                      <div className="small pt-5 pb-5">
                      Make sure your resume is up to date before applying
                          </div>
                  </div>
                </div>
              </div>
            </div>

            {displayQuestion ?<div
              className="
                pt-9
                pl-sm-9 pl-5
                pr-sm-9 pr-5
                pb-8
                border-top border-width-1 border-default-color
                light-mode-texts
              "
            >
              <div className="row pb-5">
                <div className="col-md-6">
                  <div className="media align-items-center">
                      <h3 className="font-size-6 mb-0">Additional Questions from {owner.company_name}</h3>
                  </div>
                </div>
              </div>
              <div>
                <form action="/">
                <div className="row mb-xl-1 mb-9 m-5">
                  <ol>
                    {question.map((i, index) => (<li>
                    <div className="form-group">
                            <label
                              for="namedash"
                              className="
                                d-block
                                text-black-2
                                font-size-4 font-weight-semibold
                                p-0
                                col-lg-12
                              "
                            >{i.question}</label>
                          
                          {i.options.map(j => (<div className="form-check mb-4">
                            <input className="form-check-input" value={j} onChange={e => {const answer = e.target.value; const id = i._id; answer_question(answer, id)}} name={index} type="radio" id="radio-119" />
                            <label className="form-check-label" for="radio-119">{j}</label>
                          </div>))}
                    </div>
                    </li>))}
                    <button
                    onClick={e => {subMitApplication(e)}}
                            type="button"
                            className="
                              btn btn-green btn-h-60
                              text-white
                              min-width-px-210
                              rounded-5
                              text-uppercase
                              mt-20
                            "
                          >Submit Application</button>
                  </ol>
                    </div>
                  <div/>
                </form>
                
                  {/*<Quiz showDefaultResult={false} quiz={quiz} showInstantFeedback={false} shuffle={false}/>*/}
              </div>
            </div> : <div
              className="
                pt-9
                pl-sm-9 pl-5
                pr-sm-9 pr-5
                pb-8
                border-top border-width-1 border-default-color
                light-mode-texts
              "
            ><button
            onClick={e => {subMitApplication(e)}}
            type="button"
            className="
            btn btn-green btn-h-60
            text-white
            min-width-px-210
            rounded-5
            text-uppercase
            mt-20
            "
            >Submit Application</button>
              </div>}
          </div>
        </div>
      </div>
    </div>
  </div>}
    </div>
  )
}