import React, {useEffect, useState} from "react";
import git from '../image/c-home/github-logo.png'
import Navbar from '../shared-components/navbar'
import SideBar from '../shared-components/sidebar'
import CreateJob from './Forms/CreateJob'
import EditJob from './Forms/EditJob'
import {Button, Modal, ModalFooter, ModalBody, ModalHeader, ModalTitle} from 'reactstrap';
import axios from 'axios'
import {Link} from 'react-router-dom'
import { Helmet } from 'react-helmet'
import moment from 'moment'
import Paginating from "../pagination"
import { toast, Slide } from 'react-toastify'
import { useAuths } from "../AuthContext"
import 'react-toastify/dist/ReactToastify.css';
const url = process.env.REACT_APP_SERVER_URL

export default function Joblist() {

const [createJobModal, setCreateJobModal] = useState(false)
const [jobId, setJobId] = useState("")
const {isLoading} = useAuths()
const [editModal, setEditModal] = useState(false)
const [deleteModal, setDeleteModal] = useState(false)
const [jobs, setJob] = useState([])
const [job_type_id, set_job_type_id] = useState("")


const [currentPage, setCurrentPage] = React.useState(1)
const [dataPerPage] = React.useState(5)
const indexOfLastPost = currentPage * dataPerPage;
const indexOfFirstPage = indexOfLastPost - dataPerPage;
const currentData = jobs.slice(indexOfFirstPage, indexOfLastPost);
  
const paginate = (pageNumber) => {
  setCurrentPage(pageNumber)
}
const previousPage = () => {
  if(currentPage - 1 > 0){
    setCurrentPage(currentPage - 1)
  }
}
const nextPage = (dataNumber) => {
  if(currentPage + 1 <= dataNumber){
    setCurrentPage(currentPage + 1)
  }
}


const get_jobs = (limit) => {
  isLoading(true)
  const token = localStorage.getItem("token")
  axios.get(`${url}get-few-jobs/${limit}`,{
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(res => {
          setJob(res.data)
          isLoading(false)
        }).catch(err => {
          isLoading(false)
        })
}



    const delete_job_type_success = () => {
    toast.success('Job Type Deleted Successfully', {position: toast.POSITION.TOP_RIGHT, theme: "colored", transition: Slide,  hideProgressBar: true})
  }

const job_type_delete_error = () => {
    toast.error('Could Not Delete Job Type, Please Try Again', {position: toast.POSITION.TOP_RIGHT, theme: "colored", transition: Slide,  hideProgressBar: true})
  }

    function DeleteJobModal(props) {
  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      bg="transparent"
    >
      <ModalBody style={{backgroundColor: "#f2edf3"}}>
          <div className="text-center m-3 h6">Are you sure you want to delete Job Type?. <br/>Note: This action is not reversable </div>
        <div className="row justify-content-space-between m-3">
    <Button block onClick={(e) => {deleteJob(e)}} className="btn btn-danger">Confirm</Button>
    <Button block onClick={() => setDeleteModal(false)} className="btn btn-primary">Cancel</Button>
    </div>
      </ModalBody>
    </Modal>
  );
}
    
const deleteJob = (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token')
    axios.delete(`${url}delete-job-type/${job_type_id}`, {headers: {
                'Authorization': `Bearer ${token}`
            }}).then(res => {
        delete_job_type_success()
        setDeleteModal(false)
        get_jobs(jobs.length+1)
    }).catch(err => {
        job_type_delete_error()
    })
}

    useEffect(() => {
        let mounted = true
        if (mounted){
            get_jobs(5)
        }
        return() => {
            mounted = false
        }
    }, [])

   


    return(
        <div>
          <Helmet>
            <title> Posted Jobs - Job Board | StriveHR </title>
            </Helmet>
            <Navbar/>
            <SideBar/>
       
        <div className="dashboard-main-container mt-25 mt-lg-31" id="dashboard-body">
        <div className="container">
        <div className="mb-14">
          <div className="bg-white shadow-8 pt-7 rounded pb-8 px-11">
          <div className="row mb-11 align-items-center">
            <div className="col-lg-6 mb-lg-0 mb-4">
              <h3 className="font-size-6 mb-0">Posted Jobs</h3>
            </div>
            <div className="col-lg-6">
              <div className="d-flex flex-wrap align-items-center justify-content-lg-end">
                    <a href="" onClick={e => {e.preventDefault(); setCreateJobModal(true)}} className="btn btn-green">+ Create Job</a>
              </div>
            </div>
          </div>
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th scope="col" className="border-0 font-size-4 font-weight-normal">Company</th>
                    <th scope="col" className="border-0 font-size-4 font-weight-normal">Job Title</th>
                    <th scope="col" className="border-0 font-size-4 font-weight-normal">Job Type</th>
                    <th scope="col" className="border-0 font-size-4 font-weight-normal">Posted Date</th>
                    <th scope="col" className="border-0 font-size-4 font-weight-normal">Status</th>
                    <th scope="col" className="border-0 font-size-4 font-weight-normal">Action</th>
                    <th scope="col" className="border-0 font-size-4 font-weight-normal"></th>
                  </tr>
                </thead>
                <tbody>
                  {currentData.map(i => (<tr className="border border-color-2">
                    <th scope="row" className="pl-6 border-0 py-7 pr-0">
                      <div className="media min-width-px-130 align-items-center">
                        <div className="circle-36 mr-6">
                          {i.owner.image ? <img src={i.owner.image} alt="" className="circle-36 w-100" /> : <img src={git} alt="" className="w-100" />}
                        </div>
                        <h4 className="font-size-4 mb-0 font-weight-semibold text-black-2">{i.owner.company_name}</h4>
                      </div>
                    </th>
                    <td className="table-y-middle py-7 min-width-px-110 pr-0">
                      <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">{i.jobrole}</h3>
                    </td>
                    <td className="table-y-middle py-7 min-width-px-110 pr-0">
                      <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">{i.type ? i.type.type : ""}</h3>
                    </td>
                    <td className="table-y-middle py-7 min-width-px-110 pr-0">
                      <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">{moment(new Date(i.createdAt)).format("MMMM Do YYYY")}</h3>
                    </td>
                    <td className="table-y-middle py-7 min-width-px-110 pr-0">
                      <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">{!i.closed ? "Active" : "Not Active"}</h3>
                    </td>
                    <td className="table-y-middle py-7 min-width-px-60 pr-0">
                      <div className=""><a href=""  onClick={e => {e.preventDefault(); setJobId(i.jobId); setEditModal(true)}} className="font-size-3 font-weight-bold text-green text-uppercase">Edit</a></div>
                    </td>
                    <td className="table-y-middle py-7 min-width-px-70 pr-0">
                      <div className=""><Link to={{pathname: `/jobDetails/${i.jobId}`}} className="font-size-3 font-weight-bold text-red-2 text-uppercase">View</Link></div>
                    </td>
                  </tr>))}
                </tbody>
              </table>
            </div>
           
            <Modal
     isOpen={createJobModal}
     size="xl"
    >
      <ModalBody>
    <CreateJob />
      </ModalBody>
      <ModalFooter>
        <Button onClick={() => setCreateJobModal(false)}>Close</Button>
      </ModalFooter>
    </Modal>

    <Modal
     isOpen={editModal}
     size="xl"
      onClosed={e => {setJobId("")}}
    >

      <ModalBody>
    <EditJob id={jobId} />
      </ModalBody>
      <ModalFooter>
        <Button onClick={() => setEditModal(false)}>Close</Button>
      </ModalFooter>
    </Modal>
    <div className="">
                 <Paginating dataPerPage={dataPerPage} totalData={jobs.length} paginate={paginate} currentPage={currentPage} nextPage={nextPage} previousPage={previousPage} />
              
            </div>
          </div>
        </div> </div>
      </div>
      </div>
    )
}