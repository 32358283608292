import React from 'react';
import blackstrivehrlogo from '../image/logo-main-black.png';
import {Link, useHistory} from 'react-router-dom'
import  { useAuths }  from '../AuthContext';
import { Helmet } from 'react-helmet'
import { Button, Spinner } from 'react-bootstrap'
import { toast, Slide } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
toast.configure()

    export default function EmployerSignUp (){
      const [company_name, set_company_name] = React.useState("")
      const [company_email, set_company_email] = React.useState("")
      const [email, set_email] = React.useState("")
      const [phone, set_phone] = React.useState("")
      const [password, set_password] = React.useState("")
      const [confirm_password, set_confirm_password] = React.useState("")
      const [btn_loading, set_btn_loading] = React.useState(false)


 const passwordError = () => {
   toast.error('Password Not Correct', {position: toast.POSITION.TOP_CENTER, theme: "colored", transition: Slide,  hideProgressBar: true})
  }

  const passwordToLess = () => {
   toast.error('Password Cannot be Less Than 8 Character', {position: toast.POSITION.TOP_CENTER, theme: "colored", transition: Slide,  hideProgressBar: true})
  }

  const emailError = () => {
   toast.error('Email Already Exist', {position: toast.POSITION.TOP_CENTER, theme: "colored", transition: Slide,  hideProgressBar: true})
  }

  const errors = () => {
   toast.error('Could Not Sign You Up', {position: toast.POSITION.TOP_CENTER, theme: "colored", transition: Slide,  hideProgressBar: true})
  }

  const required = () => {
    toast.error('All Fields Are Required', {position: toast.POSITION.TOP_CENTER, theme: "colored", transition: Slide,  hideProgressBar: true})
  }


      const { signUp } = useAuths()
      const history = useHistory()


      
        const handlesubmit = async (e) => {
            e.preventDefault();
            set_btn_loading(true)
        if(!company_name || !phone || !password || !email || !confirm_password){
          required()
          set_btn_loading(false)
        }else if (password !== confirm_password){
            passwordError()
            set_btn_loading(false)
        }else if (password.trim().length < 8 || confirm_password.trim().length < 8){
            passwordToLess()
            set_btn_loading(false)
        }else{
             try {
         await signUp({company_name, company_email, phone, password, email})
         await history.push('/verify-email')
       
       } catch (error) {
         if (error.response.status === 400) {
             emailError()
             set_btn_loading(false)
         } else {
           errors()
           set_btn_loading(false)
         }
       }}
        }

        return(
         
     <div className="site-wrapper overflow-hidden">
        <Helmet>
            <title> Employer Sign Up - Job Board | StriveHR </title>
            </Helmet>
             <div className="">
        <div className="  min-h-100vh flex-all-center pt-lg-15 pt-xxl-17 pt-27 pb-lg-0 pb-18">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xxl-6 col-xl-7 col-lg-8 text-center">
              <img src={blackstrivehrlogo} alt="" className="p-7"/>
                <div className="mb-15 text-center bg-white px-9 pt-9 pb-7 shadow-8 rounded-4" >
                <div className="row">
                      
                      <div className="col-xs-12 mb-8">
                    
                    <div className="row">
                      
                      <div className="col-xs-12 mb-8">
                        <h2 className="font-size-9 mb-4">
                          Create an account
                        </h2>
                      </div>
                    </div>
                        <div>
                            <form>
                            <div className="form-group text-left row pt-6">
                        <label
                          className="
                            font-size-4
                            text-black-2
                            font-weight-semibold
                            line-height-reset
                          "
                          >Company Name</label>
                        <input
                          onChange={e => {set_company_name(e.target.value)}}
                          type="text"
                          className="form-control"
                          placeholder="Registered Company Name"
                          id="companyName"
                        />
                      </div>
                       {/*<div className="form-group text-left row pt-6">
                        <label
                          className="
                            font-size-4
                            text-black-2
                            font-weight-semibold
                            line-height-reset
                          "
                          >Company Email</label>
                        <input
                          onChange={e => {set_company_email(e.target.value)}}
                          type="text"
                          className="form-control"
                          placeholder="Registered Company Name"
                        />
                      </div>*/}
                      <div className="form-group text-left row pt-6">
                        <label
                          className="
                            font-size-4
                            text-black-2
                            font-weight-semibold
                            line-height-reset
                          "
                          >Official Email</label>
                        <input
                          onChange={e => {set_email(e.target.value)}}
                          type="email"
                          className="form-control"
                          placeholder="Registered Official Email"
                        />
                      </div>
                      <div className="form-group text-left row pt-6">
                        <label
                          className="
                            font-size-4
                            text-black-2
                            font-weight-semibold
                            line-height-reset
                          "
                          >Phone Number</label>
                        <input
                          onChange={e => {set_phone(e.target.value)}}
                          type="number"
                          className="form-control"
                          placeholder="Phone Number"
                        />
                      </div>
                      <div className="form-group text-left row pt-6">
                        <label
                          className="
                            font-size-4
                            text-black-2
                            font-weight-semibold
                            line-height-reset
                          "
                          >Password</label>
                          <input
                            onChange={e => {set_password(e.target.value)}}
                            type="password"
                            className="form-control"
                            placeholder="Enter password"
                          />
                        
                        </div>
                      <div className="form-group text-left row pt-6">
                        <label
                          className="
                            font-size-4
                            text-black-2
                            font-weight-semibold
                            line-height-reset
                          "
                          >Confirm Password</label>
                          <input
                            onChange={e => {set_confirm_password(e.target.value)}}
                            type="password"
                            className="form-control"
                            placeholder="Repeat password"
                          />
                      
                        </div>
                      <div className="text-center">
                        <p className="font-size-3  heading-default-color ">
                          By clicking Create Account, you agree to the 
                          <a href="https://strivehumanresources.com/terms/" target="_blank" className="text-primary"
                            >Terms of Use</a> <br/> and
                          <a href="https://strivehumanresources.com/policy/" target="_blank" className="text-primary"
                            >Privacy Policy</a> of StriveHR
                        </p>
                      </div>
                      <div className="form-group mb-8">
                        {!btn_loading ? <button
                          onClick={e => {handlesubmit(e)}}
                          className="
                            btn btn-primary btn-medium
                            w-100
                            rounded-5
                            text-uppercase
                          "
                        >
                          Create Account
                        </button> :  <Button className="btn btn-primary btn-medium
                                    w-100
                                    rounded-5
                                    text-uppercase" disabled>
                                  <Spinner
                                  as="span"
                                  variant="light"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                  animation="border"/>
                                  
                              </Button>}
                        
                      </div>
                      <div className="text-center">
                        <p className="font-size-4  heading-default-color">
                          Already have an account?
                            <Link to="/login" className="text-primary"
                            >Sign in</Link>
                        </p></div>
                    
                            </form>
                        </div>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
    </div>
    </div>
        </div>
        )
    }



    /*


    */
