import React, {useEffect} from 'react'
import Navbar from '../shared-components/navbar'
import moment from 'moment'
import {  Link, useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import {Button, Spinner} from 'react-bootstrap';
import { Modal, ModalBody } from "reactstrap"
import Paginating from "../pagination"
import { useAuths, ACTIONS } from '../AuthContext'
import emptybox from '../image/c-home/empty-box.png'
import Sidebar from '../shared-components/sidebar'
import 'react-toastify/dist/ReactToastify.css';



export default function PostedJobs () { 
  const location = useLocation();

    
    const { getEmployerJobs, state, jobType, filterApplicantData, updateData, dispatch, deleteData, pages_clean_up} = useAuths()
 

    const [currentPage, setCurrentPage] = React.useState(1)
const [dataPerPage] = React.useState(5)

const indexOfLastPost = currentPage * dataPerPage;
const indexOfFirstPage = indexOfLastPost - dataPerPage;
const currentData = state.employer_jobs.slice(indexOfFirstPage, indexOfLastPost);


const paginate = (pageNumber) => {
  setCurrentPage(pageNumber)
}
const previousPage = () => {
  if(currentPage - 1 > 0){
    setCurrentPage(currentPage - 1)
  }
}
const nextPage = (dataNumber) => {
  if(currentPage + 1 <= dataNumber){
    setCurrentPage(currentPage + 1)
  }
}

 useEffect(() => {
  let mounted = true
  if(mounted){
    getEmployerJobs()
    jobType()
  }
  return() => {
    pages_clean_up("employer/postedjob")
  }
 }, [])



    return (
        <div>
           <Helmet>
            <title> Posted Jobs - Job Board | StriveHR </title>
            </Helmet>
            <Navbar/>
            <Sidebar/> 
        {!state.employer_jobs.length ?
             <div>
        <div className="dashboard-main-container mt-25 mt-lg-31" id="dashboard-body">
        {/* <!-- Messaging System --> */}
        <div className="container">
          <div className="mb-14">
            <div className="col-12 col-xl-10 col-lg-8">
              {/* <!-- Top Start --> */}
              <div className="mb-5">
                <h3 className=" mb-9">Empty tables</h3>
                
                {/* Empty data  */}
                <div className="row justify-content-center">
                  <div className="col-lg-6 col-md-6 col-sm-11 mb-9 text-center">
                    <img className="w-50" src={emptybox} alt="" />
                    <h2 className="pt-15">No data found</h2>
                    <p>Your data will appear here once you start posting jobs.</p>
                  </div>
                </div>

              </div>
              {/* <!-- Top End --> */}
              
            </div>
          </div>
        </div>
      </div>
        </div> : 
       
            <div className="dashboard-main-container mt-25 mt-lg-31" id="dashboard-body">
        <div className="container">
        <div className="mb-18">
          <div className="row mb-11 align-items-center">
            <div className="col-lg-6 mb-lg-0 mb-4">
              <h3 className="font-size-6 mb-0">Posted Jobs ({state.employer_jobs.length})</h3>
            </div>
            <div className="col-lg-6">
              <div className="d-flex flex-wrap align-items-center justify-content-lg-end">
                <p className="font-size-4 mb-0 mr-6 py-2">Filter by Job:</p>
                <div className="h-px-48">
                  <select name="country" id="country"onChange={e => {filterApplicantData(e.target.value, "job")}}  className="nice-select pl-7 h-100 arrow-3 arrow-3-black min-width-px-273 font-weight-semibold text-black-2">
                  <option value="none" data-display="Product Designer">None</option>
                    {state.job_types.map(i => (<option value={i.type} >{i.type}</option>))}
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-white shadow-8 pt-7 rounded pb-9 px-11">
            <div className="table-responsive ">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th scope="col" className="pl-0 border-0 font-size-4 font-weight-normal">Position</th>
                    <th scope="col" className="pl-4 border-0 font-size-4 font-weight-normal">Job Type</th>
                    <th scope="col" className="pl-4 border-0 font-size-4 font-weight-normal">Posted on</th>
                    <th scope="col" className="pl-4 border-0 font-size-4 font-weight-normal">Closes on</th>
                    <th scope="col" className="pl-4 border-0 font-size-4 font-weight-normal">Status</th>
                    <th scope="col" className="pl-4 border-0 font-size-4 font-weight-normal"></th>
                    <th scope="col" className="pl-4 border-0 font-size-4 font-weight-normal"></th>
                  </tr>
                </thead>
                <tbody>
                  {currentData.map(i => {return (!i.isDeleted ? <tr className="border border-color-2">
                    <th scope="row" className="pl-6 border-0 py-7 min-width-px-235">
                      <div className="table-y-middle py-7 ">
                        <Link to={{pathname: `/JobDetails/${i.jobId}`}} className="font-size-4 mb-0 font-weight-semibold text-black-2">{i.jobrole}</Link>
                      </div>
                    </th>
                    <td className="table-y-middle py-7 min-width-px-135">
                      <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">{i.type ? i.type.type : ""}</h3>
                    </td>
                    <td className="table-y-middle py-7 min-width-px-125">
                      <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">{moment(new Date(i.createdAt)).format("MMMM Do YYYY")}</h3>
                    </td>
                    <td className="table-y-middle py-7 min-width-px-155">
                      <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">{moment(new Date(i.close_date)).format("MMMM Do YYYY")}</h3>
                    </td>
                    <td className="table-y-middle py-7 min-width-px-125  ">
                      
                      <div
                    className="
                      bg-spray-opacity-1
                      font-size-7
                      text-center
                      rounded-pill
                    "
                  > {i.closed ? <h3 className="font-size-4 font-weight-bold text-turquoise mb-0 p-5 text-center">Closed</h3> : 
                  <h3 className="font-size-4 font-weight-bold text-turquoise mb-0 p-5 text-center">Active</h3> }</div>
                    </td>
                    <td className="table-y-middle py-7 min-width-px-80">
                      <Link to={{pathname: "/edit-job", state:{jobId: i.jobId}}} className="font-size-3 font-weight-bold text-green text-uppercase">Edit</Link>
                    </td>
                    {i.closed ?
                     <td className="table-y-middle py-7 min-width-px-80">
                      <a href="" onClick={e => {e.preventDefault(); dispatch({type: ACTIONS.SET_MODAL_DATA, payload: i._id}); dispatch({type: ACTIONS.SET_MODAL, payload: location.pathname+"open"})}} className="font-size-3 font-weight-bold text-green text-uppercase">Open</a>
                    </td> :  <td className="table-y-middle py-7 min-width-px-80">
                      <a href="" onClick={e => {e.preventDefault(); dispatch({type: ACTIONS.SET_MODAL_DATA, payload: i._id}); dispatch({type: ACTIONS.SET_MODAL, payload: location.pathname+"close"})}} className="font-size-3 font-weight-bold text-green text-uppercase">Close</a>
                    </td>}
                    <td className="table-y-middle py-7 min-width-px-100">
                      <a href="" onClick={e => {e.preventDefault(); dispatch({type: ACTIONS.SET_MODAL_DATA, payload: i._id}); dispatch({type: ACTIONS.SET_MODAL, payload: location.pathname+"delete"})}} className="font-size-3 font-weight-bold text-red-2 text-uppercase">Delete</a>
                    </td>
                  </tr> : null)})}
                  
                </tbody>
              </table>
            </div>
            <div className="">
     <Paginating dataPerPage={dataPerPage} totalData={state.employer_jobs.length} paginate={paginate} currentPage={currentPage} nextPage={nextPage} previousPage={previousPage} />
            </div>
          </div>
        </div>
          
        </div>
      </div>}
      <Modal
isOpen={state.modal === location.pathname+"close"}
onClosed={() => {dispatch({type: ACTIONS.SET_MODAL_DATA, payload: ""}); dispatch({type: ACTIONS.SET_MODAL, payload: ""})}}
size="sm"
aria-labelledby="contained-modal-title-vcenter"
centered
bg="transparent"
>
<ModalBody style={{backgroundColor: "#f2edf3"}}>
    <div className="text-center m-3 h6">Are you sure you want to Close Job?. <br/>Note: This action is not reversable </div>
  <div className="row justify-content-space-between m-3">
<Button block onClick={(e) => {e.preventDefault(); updateData("job", "close", "")}} className="btn btn-danger">Confirm</Button>
<Button block onClick={() => dispatch({type: ACTIONS.SET_MODAL, payload: ""})} className="btn btn-primary">Cancel</Button>
</div>
</ModalBody>
</Modal>

<Modal
isOpen={state.modal === location.pathname+"open"}
onClosed={() => {dispatch({type: ACTIONS.SET_MODAL_DATA, payload: ""}); dispatch({type: ACTIONS.SET_MODAL, payload: ""})}}
size="sm"
aria-labelledby="contained-modal-title-vcenter"
centered
bg="transparent"
>
<ModalBody style={{backgroundColor: "#f2edf3"}}>
    <div className="text-center m-3 h6">Are you sure you want to Open Job?. <br/>Note: This action is not reversable </div>
  <div className="row justify-content-space-between m-3">
<Button block onClick={(e) => {e.preventDefault(); updateData("job", "open", "")}} className="btn btn-danger">Confirm</Button>
<Button block onClick={() => dispatch({type: ACTIONS.SET_MODAL, payload: ""})} className="btn btn-primary">Cancel</Button>
</div>
</ModalBody>
</Modal>


<Modal
isOpen={state.modal === location.pathname+"delete"}
onClosed={() => {dispatch({type: ACTIONS.SET_MODAL_DATA, payload: ""}); dispatch({type: ACTIONS.SET_MODAL, payload: ""})}}
size="sm"
aria-labelledby="contained-modal-title-vcenter"
centered
bg="transparent"
>
<ModalBody style={{backgroundColor: "#f2edf3"}}>
    <div className="text-center m-3 h6">Are you sure you want to delete Job?. <br/>Note: This action is not reversable </div>
  <div className="row justify-content-space-between m-3">
<Button block onClick={(e) => {deleteData("job")}} className="btn btn-danger">Confirm</Button>
<Button block onClick={() => dispatch({type: ACTIONS.SET_MODAL, payload: ""})} className="btn btn-primary">Cancel</Button>
</div>
</ModalBody>
</Modal>
        </div>
    )
}