import React, {useState} from 'react'
import { toast, Slide } from 'react-toastify'
import axios from 'axios'
import { Helmet } from 'react-helmet'
import  { useAuths }  from '../../AuthContext';
import 'react-toastify/dist/ReactToastify.css';
const url = process.env.REACT_APP_SERVER_URL


toast.configure()


const required = () => {
    toast.warn('All Field Required', {position: toast.POSITION.TOP_CENTER, theme: "colored", transition: Slide,  hideProgressBar: true})
  }

const industry_success = () => {
    toast.success('Industry Updated Successfully', {position: toast.POSITION.TOP_RIGHT, theme: "colored", transition: Slide,  hideProgressBar: true})
  }

const industry_error = () => {
    toast.error('Could Not Update Industry, Please Try Again', {position: toast.POSITION.TOP_RIGHT, theme: "colored", transition: Slide,  hideProgressBar: true})
  }

const career_success = () => {
    toast.success('Career Level Updated Successfully', {position: toast.POSITION.TOP_RIGHT, theme: "colored", transition: Slide,  hideProgressBar: true})
  }

const skill_success = () => {
    toast.success('Skill Updated Successfully', {position: toast.POSITION.TOP_RIGHT, theme: "colored", transition: Slide,  hideProgressBar: true})
}

const career_error = () => {
    toast.error('Could Not Update Career Level, Please Try Again', {position: toast.POSITION.TOP_RIGHT, theme: "colored", transition: Slide,  hideProgressBar: true})
  }

const job_category_success = () => {
    toast.success('Category Updated Successfully', {position: toast.POSITION.TOP_RIGHT, theme: "colored", transition: Slide,  hideProgressBar: true})
  }

const job_category_error = () => {
    toast.error('Could Not Update Category, Please Try Again', {position: toast.POSITION.TOP_RIGHT, theme: "colored", transition: Slide,  hideProgressBar: true})
  }

const job_type_success = () => {
    toast.success('Job Type Updated Successfully', {position: toast.POSITION.TOP_RIGHT, theme: "colored", transition: Slide,  hideProgressBar: true})
  }

const job_type_error = () => {
    toast.error('Could Not Update Job Type, Please Try Again', {position: toast.POSITION.TOP_RIGHT, theme: "colored", transition: Slide,  hideProgressBar: true})
  }


export function EditSkillModal({skillText, skillId}){
  const [skill, set_skill] = useState(skillText)
  const {industryLimted} = useAuths()
  
    

  const handle_submit = (e) => {
    e.preventDefault();
    if(!skill){
      required()
    }else{
    const token = localStorage.getItem('token')
    axios.put(`${url}update-skill/${skillId}`, {title: skill}, {headers: {
                'Authorization': `Bearer ${token}`
            }}).then(
      res => {
        if(res){
        skill_success()
        industryLimted()}
      }
    ).catch(err => {
        industry_error()
    })}
  }

  return(
    <div>
          <div className="login-modal-main bg-white rounded-8 overflow-hidden">
            <div className="no-gutters">
              <div>
                <div className="bg-white-2 h-100 px-11 pt-11 pb-7">
                  <form action="/">
                    <div className="form-group">
                      <label
                        className="
                          font-size-4
                          text-black-2
                          font-weight-semibold
                          line-height-reset
                        "
                        >Enter Skill</label>
                      <input
                        onChange={e => {set_skill(e.target.value)}}
                        type="text"
                        className="form-control"
                        placeholder="e.g Team Player"
                        id="text"
                        value={skill}
                      />
                    </div>
                 
                    <div className="form-group mb-8">
                      <button
                       onClick={e => {handle_submit(e)}}
                        className="
                          btn btn-primary btn-medium
                          w-100
                          rounded-5
                          text-uppercase
                        "
                      >
                        Update Skill
                      </button>
                    </div>
                  
                  </form>
                </div>
              </div>
            </div>
          </div>
    </div>
  )
}

export function EditIndustryModal({industryText, industryId}){
  const [industry, set_industry] = useState(industryText)
  const {industryLimted} = useAuths()
  
    

  const handle_submit = (e) => {
    e.preventDefault();
    if(!industry){
      required()
    }else{
    const token = localStorage.getItem('token')
    axios.put(`${url}update-industry/${industryId}`, {name: industry}, {headers: {
                'Authorization': `Bearer ${token}`
            }}).then(
      res => {
        if(res){
        industry_success()
        industryLimted()}
      }
    ).catch(err => {
        industry_error()
    })}
  }

  return(
    <div>
          <div className="login-modal-main bg-white rounded-8 overflow-hidden">
            <div className="no-gutters">
              <div>
                <div className="bg-white-2 h-100 px-11 pt-11 pb-7">
                  <form action="/">
                    <div className="form-group">
                      <label
                        className="
                          font-size-4
                          text-black-2
                          font-weight-semibold
                          line-height-reset
                        "
                        >Enter Industry Name</label>
                      <input
                        onChange={e => {set_industry(e.target.value)}}
                        type="text"
                        className="form-control"
                        placeholder="e.g Oil & Gas"
                        id="text"
                        value={industry}
                      />
                    </div>
                 
                    <div className="form-group mb-8">
                      <button
                       onClick={e => {handle_submit(e)}}
                        className="
                          btn btn-primary btn-medium
                          w-100
                          rounded-5
                          text-uppercase
                        "
                      >
                        Update Industry
                      </button>
                    </div>
                  
                  </form>
                </div>
              </div>
            </div>
          </div>
    </div>
  )
}


export function EditCareerLevel({careerText, careerId}){
const [career_level, set_career_level] = useState(careerText)
const {experienceLevelLimited} = useAuths()


const handle_submit = (e) => {
    e.preventDefault();
    if(!career_level){
      required()
    }else{
    const token = localStorage.getItem('token')
    axios.put(`${url}update-career-level/${careerId}`, {title: career_level}, {headers: {
                'Authorization': `Bearer ${token}`
            }}).then(
      res => {
        if(res){
        career_success()
        experienceLevelLimited()}
      }
    ).catch(err => {
        career_error()
    })}
  }



  return(
    <div>
          <div className="login-modal-main bg-white rounded-8 overflow-hidden">
            <div className="no-gutters">
              <div>
                <div className="bg-white-2 h-100 px-11 pt-11 pb-7">
                  <form action="/">
                    <div className="form-group">
                      <label
                        className="
                          font-size-4
                          text-black-2
                          font-weight-semibold
                          line-height-reset
                        "
                        >Enter Career Level</label>
                      <input
                        onChange={e => {set_career_level(e.target.value)}}
                        type="text"
                        className="form-control"
                        placeholder="e.g Senior Level"
                        id="text"
                        value={career_level}
                      />
                    </div>
                 
                    <div className="form-group mb-8">
                      <button
                       onClick={e => {handle_submit(e)}}
                        className="
                          btn btn-primary btn-medium
                          w-100
                          rounded-5
                          text-uppercase
                        "
                      >
                        Create Career Level
                      </button>
                    </div>
                  
                  </form>
                </div>
              </div>
            </div>
          </div>
    </div>
  )
}

export function EditJobType({jobTypeText, jobTypeId}){
const [job_type, set_job_type] = useState(jobTypeText)
const {jobTypeLimited} = useAuths()


const handle_submit = (e) => {
    e.preventDefault();
    if(!job_type){
      required()
    }else{
    const token = localStorage.getItem('token')
    axios.put(`${url}update-job-type/${jobTypeId}`, {type: job_type}, {headers: {
                'Authorization': `Bearer ${token}`
            }}).then(
      res => {
        if(res){
        job_type_success()
        jobTypeLimited()}
      }
    ).catch(err => {
        job_type_error()
    })}
  }

  return(
    <div>
          <div className="login-modal-main bg-white rounded-8 overflow-hidden">
            <div className="no-gutters">
              <div>
                <div className="bg-white-2 h-100 px-11 pt-11 pb-7">
                  <form action="/">
                    <div className="form-group">
                      <label
                        className="
                          font-size-4
                          text-black-2
                          font-weight-semibold
                          line-height-reset
                        "
                        >Edit Job Type</label>
                      <input
                        onChange={e => {set_job_type(e.target.value)}}
                        type="text"
                        className="form-control"
                        placeholder="e.g Full Time"
                        id="text"
                        value={job_type}
                      />
                    </div>
                 
                    <div className="form-group mb-8">
                      <button
                      onClick={e => {handle_submit(e)}}
                        className="
                          btn btn-primary btn-medium
                          w-100
                          rounded-5
                          text-uppercase
                        "
                      >
                        Update Job Type
                      </button>
                    </div>
                  
                  </form>
                </div>
              </div>
            </div>
          </div>
    </div>
  )
}


export function EditJobCategory({jobCategoryText, jobCategoryId}){
const [job_category, set_job_category] = useState(jobCategoryText)
const {jobCategoryLimited} = useAuths()


const handle_submit = (e) => {
    e.preventDefault();
    if(!job_category){
      required()
    }else{
    const token = localStorage.getItem('token')
    axios.put(`${url}update-job-category/${jobCategoryId}`, {category: job_category}, {headers: {
                'Authorization': `Bearer ${token}`
            }}).then(
      res => {
        if(res){
        job_category_success()
        jobCategoryLimited()}
      }
    ).catch(err => {
      if(err){
        job_category_error()}
    })}
  }

  return(
    <div>
          <div className="login-modal-main bg-white rounded-8 overflow-hidden">
            <div className="no-gutters">
              <div>
                <div className="bg-white-2 h-100 px-11 pt-11 pb-7">
                  <form action="/">
                    <div className="form-group">
                      <label
                        className="
                          font-size-4
                          text-black-2
                          font-weight-semibold
                          line-height-reset
                        "
                        >Edit Job category</label>
                      <input
                        onChange={e => {set_job_category(e.target.value)}}
                        type="text"
                        className="form-control"
                        placeholder="e.g Full Time"
                        id="text"
                        value={job_category}
                      />
                    </div>
                 
                    <div className="form-group mb-8">
                      <button
                      onClick={e => {handle_submit(e)}}
                        className="
                          btn btn-primary btn-medium
                          w-100
                          rounded-5
                          text-uppercase
                        "
                      >
                        Update Job category
                      </button>
                    </div>
                  
                  </form>
                </div>
              </div>
            </div>
          </div>
    </div>
  )
}