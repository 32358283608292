import React, {useEffect} from 'react'
import ReactLoading from "react-loading";
import blackstrivehrlogo from '../image/logo-main-black.png';
import { Helmet } from 'react-helmet'

export default function ConfirmEmail() {
    const [loading, setLoading] = React.useState(true)

    useEffect(() => {
      setTimeout(() => {
         setLoading(false)
     }, 1000)
    }, [])

  if(loading){
  return(
    <div className="center-sign-in-loading" style={{backgroundColor: "#fff", width: "100%", height: "100%", justifyContent: "center", alignItems: "center"}}>
      <ReactLoading className="center-sign-in-loading" type="bars" color="#8B3300" height={'20%'} width={'10%'} />
      </div>
  )
}
    return(
          <div className="site-wrapper overflow-hidden">
             <Helmet>
            <title>Email Verification Sent - Job Board | StriveHR </title>
            </Helmet>
        <div className="">
        <div className=" min-h-100vh flex-all-center pt-lg-15 pt-xxl-17  pb-lg-0 pb-18">
        <div className="container">
        <div className="row justify-content-center">
        <div className="col-xxl-6 col-xl-7 col-lg-8 text-center">
        <img src={blackstrivehrlogo} alt="" className="p-7"/>
          <div className="mb-15 text-center bg-white px-9 pt-9 pb-7 shadow-8 rounded-4" >
          <div className="row">
                
                <div className="col-xs-12 mb-8">
                  <h2 className="font-size-9 mb-4">
                    Email Verification
                  </h2>
                  <p className="font-size-4  heading-default-color mb-10">
                          We just sent you a verification email, 
                          kindly check your email inbox and click on 
                          the verification link to verify your account.
                        </p>
                  <div>
                      <form>
                      <div className="form-group mb-20">
                                <a
                                href="https://mail.google.com/mail/u/0/#inbox"
                                target="_blank"
                                  className="
                                    btn btn-primary btn-medium
                                    w-100
                                    rounded-5
                                    text-uppercase
                                  "
                                >
                                  Check Inbox
                                </a>
                                
                              </div>

                              <div className="text-center">
                                <p className="font-size-4  heading-default-color">
                                  Need help?
                                  <a href="tel:+2348149333305" className="text-primary"
                                    >call us</a>
                                </p>
                              </div>
                      </form>
                  </div>
                </div>
              </div>
          </div>
        </div>
        </div>
        </div>
        </div>
        </div>
        </div>
    )
} 