import CandidateSetting from '../candidate_pages/setting'
import EmployerSetting from '../employer_pages/setting'
import  { useAuths }  from '../AuthContext';

export default function Dashboard() {
    const {currentUser} = useAuths()

    if(currentUser.isAdmin){
        return(
            <div>Admin</div>
        )
    }else if(currentUser.isEmployer){
        return(
            <EmployerSetting/>
        )
    }else{
        return(
            <CandidateSetting/>
        )
    }
}