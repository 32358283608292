import React, {useEffect} from 'react'
import Navbar from '../shared-components/navbar'
import SideBar from '../shared-components/sidebar'
import { useAuths } from '../AuthContext'
import axios from 'axios'
import Piechart from './Charts/pie_chart'
import Barchart from './Charts/bar_chart'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
const url = process.env.REACT_APP_SERVER_URL

export default function DashboardAdmin() {
  const [num_of_employers, set_num_of_employers] = React.useState(Number)
  const [num_of_candidate, set_num_of_candidate] = React.useState(Number)
  const [num_of_jobs, set_num_of_jobs] = React.useState(Number)
  const [limit, setLimit] = React.useState(3)
  const [user_limit, set_user_limit] = React.useState(3)
  const [jobs, setJobs] = React.useState([])
  const [users, setusers] = React.useState([])
  const { time, isLoading } = useAuths()


  useEffect(() => {
    const token = localStorage.getItem('token')
    isLoading(true)
    axios.get(`${url}getallemployernum`, {headers: {
        'Authorization': `Bearer ${token}`
      }}).then(res => {
      set_num_of_employers(res.data.text)
    })
  }, [])

  useEffect(() => {
    const token = localStorage.getItem('token')
    axios.get(`${url}get-num-of-jobs`, {headers: {
        'Authorization': `Bearer ${token}`
      },}).then(res => {
      set_num_of_jobs(res.data.text)
    })
  }, [])

   useEffect(() => {
    const token = localStorage.getItem('token')
    user(5)
    job(5)
    axios.get(`${url}getallcandidatenum`, {headers: {
        'Authorization': `Bearer ${token}`
      },}).then(res => {
      set_num_of_candidate(res.data.text)
    })
  }, [])

  const job = (limit) => {
           const token = localStorage.getItem("token")
       axios.get(`${url}get-few-jobs/${limit}`,{
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(res => {
          setJobs(res.data)
          isLoading(false)
        })
    }
const user = (limit) => {
           const token = localStorage.getItem("token")
       axios.get(`${url}getallusers/${limit}`,{
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(res => {
          setusers(res.data)
        })
      }




    return(
        <div>
          <Helmet>
            <title> Administrator Dashboard - Job Board | StriveHR </title>
            </Helmet>
            <Navbar/>
            <SideBar/>
            <a
        className="sidebar-mobile-button"
        data-toggle="collapse"
        href="#sidebar"
        role="button"
        aria-expanded="false"
        aria-controls="sidebar"
      >
        
        <i className="icon icon-sidebar-2"></i>
      </a>
      <div className="dashboard-main-container mt-25 mt-lg-31" id="dashboard-body">
        <div className="container pb-15">
          <div className="mb-7 ">
            <h3>{time} 👋</h3>
            <p>Here is what’s been happening on your Job Board:</p>
          </div>
          <div className="row mb-7">
            <div className="col-lg-6 col-xl-4">
              <div className=" media bg-white rounded-4  pl-8 pt-9 pb-9 pr-7 hover-shadow-1  mb-9 shadow-8">
              <div
                  className="text-white bg-blue circle-56 font-size-6 mr-7"
                > <i className="fa fa-users"></i>
                </div>
              <Link to="/candidates" className="">
                  <h5
                    className="
                      font-size-8 font-weight-semibold
                      text-black-2
                      line-height-reset
                      font-weight-bold
                      mb-1
                    "
                  >
                    <span className="counter">{num_of_candidate}</span>
                  </h5>
                  <p className="font-size-4 font-weight-normal text-gray mb-0">
                  Registered Candidates
                  </p>
                </Link>
              </div>
            </div>
            <div className="col-lg-6 col-xl-4">
              <div className=" media bg-white rounded-4  pl-8 pt-9 pb-9 pr-7 hover-shadow-1  mb-9 shadow-8">
              <div
                  className="text-white bg-red circle-56 font-size-6 mr-7"
                > <i className="fa fa-building"></i>
                </div>
              <Link to="/companies" className="">
                  <h5
                    className="
                      font-size-8 font-weight-semibold
                      text-black-2
                      line-height-reset
                      font-weight-bold
                      mb-1
                    "
                  >
                    <span className="counter">{num_of_employers}</span>
                  </h5>
                  <p className="font-size-4 font-weight-normal text-gray mb-0">
                  Registered Employers
                  </p>
                </Link>
              </div>
            </div>
            <div className="col-lg-6 col-xl-4">
              <div className=" media bg-white rounded-4  pl-8 pt-9 pb-9 pr-7 hover-shadow-1  mb-9 shadow-8">
              <div
                  className="text-white bg-orange circle-56 font-size-6 mr-7"
                > <i className="fa fa-briefcase"></i>
                </div>
              <Link to="/Jobs" className="">
                  <h5
                    className="
                      font-size-8 font-weight-semibold
                      text-black-2
                      line-height-reset
                      font-weight-bold
                      mb-1
                    "
                  >
                    <span className="counter">{num_of_jobs}</span>
                  </h5>
                  <p className="font-size-4 font-weight-normal text-gray mb-0">
                  Active Jobs
                  </p>
                </Link>
              </div>
            </div>
          </div>
          <div className="row mb-7">
            <div className="col-lg-4">
              <div className="bg-white shadow-8 pt-6 rounded pb-5 px-5">
                <div className="table-responsive">
                  <h4>Top Packages</h4>
                  <table className="table table-striped"> 
                  <Piechart/>
                  </table>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
            <div className="bg-white shadow-8 pt-6 rounded pb-5 px-5">
                <div className="table-responsive">
                  <h4>Top Companies</h4>
                  <table className="table table-striped"> 
                  <Barchart/>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div className="row mb-7">
            <div className=" col-lg-6 col-md-6 col-sm-10 col-xs-11 mb-9 mb-lg-0">
            <div className="bg-white shadow-8 pt-7 rounded pb-8 px-11"> 
                  <div className="table-responsive">
                    <h4>Recent Registered Users</h4>
                    <table className="table table-striped">
                      <tbody>
                        {users.slice(0, 5).map(i => (<tr className="border border-color-2">
                          <th scope="row" className="pl-6 border-0 py-7 pr-0">

                              <h4
                                className="
                                  font-size-4
                                  mb-0
                                  font-weight-semibold
                                  text-black-2
                                "
                              >
                               <i className="fa fa-address-book pr-5 font-size-3"></i>{i.isCandidate ? i.first_name+' '+i.last_name : i.company_name}
                              </h4>
                          </th>
                          <td className="table-y-middle py-7 min-width-px-170 pr-0">
                            <h3
                              className="
                                font-size-4 font-weight-normal
                                text-black-2
                                mb-0
                              "
                            >
                              {i.country ? i.country : "Not Available"}
                            </h3>
                          </td>
                        </tr>))}
                      </tbody>
                    </table>
                    {/*<div className="row ">
                <div className="col-lg-6 mb-lg-0 mb-4">
                <a href="" onClick={e => {e.preventDefault(); user(user.length+5)}} className="btn btn-link">View More</a>
                </div>
            </div>*/}
                  </div>
            </div>
            </div>

            <div className=" col-lg-6 col-md-6 col-sm-10 col-xs-11 mb-9 mb-lg-0">
            <div className="bg-white shadow-8 pt-7 rounded pb-8 px-11"> 
                  <div className="table-responsive">
                    <h4>Recent Jobs</h4>
                    <table className="table table-striped">
                      <tbody>
                        {jobs.slice(0, 5).map(i => (<tr className="border border-color-2">
                          <th scope="row" className="pl-6 border-0 py-7 pr-0">

                              <h4
                                className="
                                  font-size-4
                                  mb-0
                                  font-weight-semibold
                                  text-black-2
                                "
                              >
                               <i className="fa fa-briefcase pr-5 font-size-3"></i>{i.jobrole}
                              </h4>
                          </th>
                          <td className="table-y-middle py-7 min-width-px-100 pr-0">
                            <h3
                              className="
                                font-size-4 font-weight-normal
                                text-black-2
                                mb-0
                              "
                            >
                              StriveHR 
                            </h3>
                          </td>
                          <td className="table-y-middle py-7 min-width-px-120 pr-0">
                            <h3
                              className="
                                font-size-4 font-weight-normal
                                text-black-2
                                mb-0
                              "
                            >
                              Lagos, Nigeria 
                            </h3>
                          </td>
                        </tr>))}
                        
                       
                        
                      </tbody>
                    </table>
                    {/*<div className="row ">
                <div className="col-lg-6 mb-lg-0 mb-4">
                <a href="" onClick={e => {e.preventDefault(); job(jobs.length+5)}} className="btn btn-link">View more Jobs</a>
                </div>
            </div>*/}
                  </div>
            </div>
            </div>
          </div>
 
        </div>
      </div>
        </div>
    )
}   