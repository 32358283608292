import React, {useState, useEffect} from 'react'
import Navbar from '../shared-components/navbar'
import Airbnb from "../image/profile/airbnb-logo-red.png"
import pin from "../image/svg/icon-loaction-pin-black.svg"
import ReactLoading from "react-loading";
import axios from 'axios'
import {useHistory, useParams} from 'react-router-dom'
import moment from 'moment'
import { Helmet } from 'react-helmet'
const url = process.env.REACT_APP_SERVER_URL


export default function CandidateProfile ({location}) {
     const param = useParams()
    const param_token = param.token
     const [message, setMessage] = React.useState("")
     const [loading, setLoading] = React.useState(true)
     const [candidate, set_candidate] = useState({
        first_name: "",
        last_name: "",
        headine: "",
        social_media: {
          faceBook: "",
          linkedIn: "",
          twitter: "",
          instagram: ""
        },
        about: "",
        email: "",
        country: "",
        phone: "",
        id: "",
        image: "",
        state: "",
        skill: [],
        web_link: ""
    })
    const [experience, set_experience] = useState([])
    const [education, set_education] = useState([])

    const history = useHistory()
    useEffect(() => {
        axios.get(`${url}getoneuser/${param_token}`).then(
            res => {
                set_candidate({
                      ...candidate,
                      id: res.data._id,
                      first_name: res.data.first_name,
                      last_name: res.data.last_name,
                      social_media: res.data.social_media,
                      about: res.data.about,
                      email: res.data.email,
                      country: res.data.country,
                      phone: res.data.phone,
                      headine: res.data.headline,
                      image: res.data.image,
                      skill: res.data.skill ? res.data.skill : [],
                      state: res.data.state,
                      web_link: res.data.web_link
                })
                 set_experience(res.data.experience)
                 set_education(res.data.education)
               // console.log(res)
               
            }
        )
        setTimeout(() => {
  setLoading(false)
}, 2000)
  
    }, [])


    const sendMessage = (e) => {
      e.preventDefault();
      const token = localStorage.getItem('token')
      if(message){
      axios.post(`${url}create-chat/`, {receiverId: candidate.id, text: message, conversationId: undefined},{ headers: {
                'Authorization': `Bearer ${token}`
            }}).then(
        data => 
        {}
      )
    }

    }

    if(loading){
  return(
    <div className="center-sign-in-loading" style={{backgroundColor: "#fff", width: "100%", height: "100%", justifyContent: "center", alignItems: "center"}}>
      <ReactLoading className="center-sign-in-loading" type="bars" color="#8B3300" height={'20%'} width={'10%'} />
      </div>
  )
}
    return (
        <div>
           <Helmet>
            <title> {candidate.first_name+' '+candidate.last_name} Profile - Job Board | StriveHR </title>
            </Helmet>
            <Navbar/>
            <div className="bg-default-2 pt-22 pt-lg-25 pb-13 pb-xxl-32">
      <div className="container">
        {/* <!-- back Button --> */}
        <div className="row justify-content-center">
          <div className="col-12 dark-mode-texts">
            <div className="mb-9">
              <a className="d-flex align-items-center ml-4" onClick={e => {e.preventDefault(); history.goBack()}} href=""> <i
              className="icon icon-small-left bg-white circle-40 mr-5 font-size-7 text-black font-weight-bold shadow-8">
            </i><span className="text-uppercase font-size-3 font-weight-bold text-gray">Back</span></a>
            </div>
          </div>
        </div>
        {/* <!-- back Button End --> */}
        <div className="row">
          {/* <!-- Left Sidebar Start --> */}
          <div className="col-12 col-xxl-3 col-lg-4 col-md-5 mb-11 mb-lg-0">
            <div className="pl-lg-5">
              {/* <!-- Top Start --> */}
              <div className="bg-white shadow-9 rounded-4">
                <div className="px-5 py-11 text-center border-bottom border-mercury">
                  <a className="mb-4" href="#">{candidate.image ? <img className="circle-54" src={candidate.image} alt=""/> : 
                  <i className="fa fa-user fa-user-profile" aria-hidden="true"></i>}</a>
                  <h4 className="mb-0"><a className="text-black-2 font-size-6 font-weight-semibold" >{candidate.first_name+' '+candidate.last_name}</a></h4>
                  <p className="mb-8"><a className="text-gray font-size-4" >{candidate.headine}</a></p>
                  {candidate.social_media ? <div className="icon-link d-flex align-items-center justify-content-center flex-wrap">
                    {candidate.social_media.linkedIn ? <a className="text-smoke circle-32 bg-concrete mr-5 hover-bg-green" target="_blank" href={"https://"+candidate.social_media.linkedIn}><i className="fab fa-linkedin-in"></i></a> : null}
                    {candidate.social_media.faceBook ? <a className="text-smoke circle-32 bg-concrete mr-5 hover-bg-green" target="_blank" href={'https://'+candidate.social_media.faceBook}><i className="fab fa-facebook-f"></i></a> : null}
                    {candidate.social_media.twitter ? <a className="text-smoke circle-32 bg-concrete mr-5 hover-bg-green" target="_blank" href={'https://'+candidate.social_media.twitter}><i className="fab fa-twitter"></i></a> : null}
                    {candidate.social_media.instagram ? <a className="text-smoke circle-32 bg-concrete mr-5 hover-bg-green" target="_blank" href={'https://'+candidate.social_media.instagram}><i className="fab fa-instagram"></i></a> : null}
                  </div> : null}
                </div>
                <div className="px-9 pt-lg-5 pt-9 pt-xl-9 pb-5">
                  <h5 className="text-black-2 mb-8 font-size-5">Contact Info</h5>
                  <div className="mb-7">
                    <p className="font-size-4 mb-0">Location</p>
                    <h5 className="font-size-4 font-weight-semibold mb-0 text-black-2 text-break">{candidate.country}</h5>
                  </div>
                  <div className="mb-7">
                    <p className="font-size-4 mb-0">E-mail</p>
                    <h5 className="font-size-4 font-weight-semibold mb-0"><a className="text-black-2 text-break" href="mailto:David4ty@gmail.com">{candidate.email}</a></h5>
                  </div>
                  {/* <!-- Single List -->
                  <!-- Single List --> */}
                  <div className="mb-7">
                    <p className="font-size-4 mb-0">Phone</p>
                    <h5 className="font-size-4 font-weight-semibold mb-0"><a className="text-black-2 text-break" href="tel:+2346793029255">{candidate.phone}</a></h5>
                  </div>
                  {/* <!-- Single List -->
                  <!-- Single List --> */}
                  <div className="mb-7">
                    <p className="font-size-4 mb-0">Website Linked</p>
                    <h5 className="font-size-4 font-weight-semibold mb-0"><a className="text-break"  href={'https://'+candidate.web_link}>{candidate.web_link}</a></h5>
                  </div>
                  {/* <!-- Single List --> */}
                </div>
                {/* <!-- Bottom End --> */}
              </div>
            </div>
          </div>
          {/* <!-- Left Sidebar End --> */}
          {/* <!-- Middle Content --> */}
          <div className="col-12 col-xxl-6 col-lg-8 col-md-7 order-2 order-xl-1">
            <div className="bg-white rounded-4 shadow-9">
              {/* <!-- Tab Section Start --> */}
              <ul className="nav border-bottom border-mercury pl-12" id="myTab" role="tablist">
                <li className="tab-menu-items nav-item pr-12">
                  <a className="active text-uppercase font-size-3 font-weight-bold text-default-color py-3" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">Overview</a>
                </li>
                <li className="tab-menu-items nav-item pr-12">
                  <a className="text-uppercase font-size-3 font-weight-bold text-default-color py-3" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">Contact</a>
                </li>
              </ul>
              {/* <!-- Tab Content --> */}
              <div className="tab-content" id="myTabContent">
                <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                  {/* <!-- Excerpt Start --> */}
                  <div className="pr-xl-0 pr-xxl-14 p-5 px-xs-12 pt-7 pb-5">
                    <h4 className="font-size-6 mb-7 mt-5 text-black-2 font-weight-semibold">About</h4>
                    <p className="font-size-4 mb-8">{candidate.about}</p>
                  </div>
                  {/* <!-- Excerpt End --> */}
                  {/* <!-- Skills --> */}
                  <div className="border-top pr-xl-0 pr-xxl-14 p-5 pl-xs-12 pt-7 pb-5">
                    <h4 className="font-size-6 mb-7 mt-5 text-black-2 font-weight-semibold">Skills</h4>
                    <ul className="list-unstyled d-flex align-items-center flex-wrap">
                      {candidate.skill.map(skil => {return (<li>
                        <a className="bg-polar text-black-2  mr-6 px-7 mt-2 mb-2 font-size-3 rounded-3 min-height-32 d-flex align-items-center">{skil.title}</a>
                      </li>)})}  
                    </ul>
                  </div>
                  {/* <!-- Skills End --> */}
                  {/* <!-- Card Section Start --> */}
                  <div className="border-top p-5 pl-xs-12 pt-7 pb-5">
                    <h4 className="font-size-6 mb-7 mt-5 text-black-2 font-weight-semibold">Work Exprerience</h4>
                    {/* <!-- Single Card --> */}
                    {experience.map(i => (<div className="w-100">
                      <div className="d-flex align-items-center pr-11 mb-9 flex-wrap flex-sm-nowrap">
                        <div className="square-72 d-block mr-8 mb-7 mb-sm-0">
                          {i.image ? <img src={Airbnb} alt=""/> : <i className="fas fa-briefcase font-size-11"></i>}
                        </div>
                        <div className="w-100 mt-n2">
                          <h3 className="mb-0">
                            <a className="font-size-6 text-black-2 font-weight-semibold" href="#">{i.company_role}</a>
                          </h3>
                          <a href="#" className="font-size-4 text-default-color line-height-2">{i.company_name}</a>
                          <div className="d-flex align-items-center justify-content-md-between flex-wrap">
                            <a  className="font-size-3 text-gray mr-5">{moment(new Date(i.company_start_date)).format("MMMM YYYY")} - {moment(new Date(i.company_end_date)).format("MMMM YYYY")} 
                              { moment(i.company_start_date).diff(moment(i.company_end_date),'years') !== 0 ?  moment(i.company_start_date).diff(moment(i.company_end_date),'years')+' '+'years' :
                               moment(i.company_start_date).diff(moment(i.company_end_date), 'months') !== 0 ?  moment(i.company_start_date).diff(moment(i.company_end_date), 'months')+' '+'months' :
                              moment(i.company_start_date).diff(moment(i.company_end_date), 'days')+' '+'days'}</a>
                            <a href="" className="font-size-3 text-gray">
                              <span className="mr-4" style={{marginTop: "-2px"}}><img src={pin} alt=""/></span>{i.state ? i.state+', '+i.country : "Location Unkwown"}</a>
                          </div>
                        </div>
                      </div>
                    </div>))}
                  </div>
                  {/* <!-- Card Section End --> */}
                  {/* <!-- Card Section Start --> */}
                  <div className="border-top p-5 pl-xs-12 pt-7 pb-5">
                    <h4 className="font-size-6 mb-7 mt-5 text-black-2 font-weight-semibold">Education</h4>
                    {/* <!-- Single Card --> */}
                    {education.map(i => (<div className="w-100">
                      <div className="d-flex align-items-center pr-11 mb-9 flex-wrap flex-sm-nowrap">
                        <div className="square-72 d-block mr-8 mb-7 mb-sm-0">
                          {i.image ? <img src={i.image} alt=""/> : <i className="fas fa-user-graduate font-size-11"></i>}
                        </div>
                        <div className="w-100 mt-n2">
                          <h3 className="mb-0">
                            <a className="font-size-6 text-black-2" href="#">{i.education_degree+' in '+i.education_course}</a>
                          </h3>
                          <a href="#" className="font-size-4 text-default-color line-height-2">{i.education_school}</a>
                          <div className="d-flex align-items-center justify-content-md-between flex-wrap">
                            <a href="" className="font-size-3 text-gray mr-5"> {moment(new Date(i.education_start_day)).format("MMMM YYYY")} - {moment(new Date(i.education_end_day)).format("MMMM YYYY")} 
                              { moment(i.education_start_day).diff(moment(i.education_end_day),'years') !== 0 ?  moment(i.education_start_day).diff(moment(i.education_end_day),'years')+' '+'years' :
                               moment(i.education_start_day).diff(moment(i.education_end_day), 'months') !== 0 ?  moment(i.education_start_day).diff(moment(i.education_end_day), 'months')+' '+'months' :
                              moment(i.education_start_day).diff(moment(i.education_end_day), 'days')+' '+'days'}</a>
                            <a href="" className="font-size-3 text-gray">
                              <span className="mr-4" style={{marginTop: "-2px"}}><img src={pin} alt=""/></span>{i.education_country ? i.education_country : "Location Unknown"}</a>
                          </div>
                        </div>
                      </div>
                    </div>))}
                  </div>
                  {/* <!-- Card Section End --> */}
                </div>
                <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                  {/* <!-- Excerpt Start --> */}
                  <div className="pr-xl-11 p-5 pl-xs-12 pt-9 pb-11">
                    <form action="/">
                      <div className="row">
                        
                        <div className="col-lg-12 mb-7">
                          <label for="message3" className="font-size-4 font-weight-semibold text-black-2 mb-5 line-height-reset">Start a conversation</label>
                          <textarea onChange={e => {setMessage(e.target.value)}} name="message" id="message3" placeholder="Type your message" className="form-control h-px-144"></textarea>
                        </div>
                        <div className="col-lg-12 pt-4">
                          <button onClick={e => {sendMessage(e)}} className="btn btn-primary text-uppercase w-100 h-px-48">Send Now</button>
                        </div>
                      </div>
                    </form>
                  </div>
                  {/* <!-- Excerpt End --> */}
                </div>
              </div>
              {/* <!-- Tab Content End --> */}
              {/* <!-- Tab Section End --> */}
            </div>
          </div>
          {/*<div className="col-12 col-xxl-3 col-md-4 offset-xxl-0 offset-lg-4 offset-md-5 order-3 order-xl-2 mt-xxl-0 mt-md-12">
            <div className="pl-lg-5">
              <h4 className="font-size-6 font-weight-semibold mb-0">Other experts</h4>
              <ul className="list-unstyled">
                <li className="border-bottom">
                  <a className="media align-items-center py-9 flex-wrap" href="#">
                    <div className="mr-7">
                      <img className="square-72 rounded-3" src={profile1} alt=""/>
                    </div>
                    <div className="">
                      <h4 className="mb-0 font-size-5 font-weight-semibold">David Herison</h4>
                      <p className="mb-0 font-size-3 heading-default-color">UX/UI Designer</p>
                      <span className="font-size-3 text-smoke"><img className="mr-2" src={pin} alt=""/>New York, USA</span>
                    </div>
                  </a>
                </li>
                <li className="border-bottom">
                  <a className="media align-items-center py-9 flex-wrap" href="#">
                    <div className="mr-7">
                      <img className="square-72 rounded-3" src={profile2} alt=""/>
                    </div>
                    <div className="">
                      <h4 className="mb-0 font-size-5 font-weight-semibold">Mark Zanitos</h4>
                      <p className="mb-0 font-size-3 heading-default-color">Lead Product Designer</p>
                      <span className="font-size-3 text-smoke"><img className="mr-2" src={pin} alt=""/>New York, USA</span>
                    </div>
                  </a>
                </li>
                <li className="border-bottom">
                  <a className="media align-items-center py-9 flex-wrap" href="#">
                    <div className="mr-7">
                      <img className="square-72 rounded-3" src={profile3} alt=""/>
                    </div>
                    <div className="">
                      <h4 className="mb-0 font-size-5 font-weight-semibold">Anna Frankin</h4>
                      <p className="mb-0 font-size-3 heading-default-color">Visual Designer</p>
                      <span className="font-size-3 text-smoke"><img className="mr-2" src={pin} alt=""/>New York, USA</span>
                    </div>
                  </a>
                </li>
                <li className="border-bottom">
                  <a className="media align-items-center py-9 flex-wrap" href="#">
                    <div className="mr-7">
                      <img className="square-72 rounded-3" src={profile4} alt=""/>
                    </div>
                    <div className="">
                      <h4 className="mb-0 font-size-5 font-weight-semibold">Jhony Vino</h4>
                      <p className="mb-0 font-size-3 heading-default-color">Creative Director</p>
                      <span className="font-size-3 text-smoke"><img className="mr-2" src={pin} alt=""/>New York, USA</span>
                    </div>
                  </a>
                </li>
                <li className="">
                  <a className="media align-items-center py-9 flex-wrap" href="#">
                    <div className="mr-7">
                      <img className="square-72 rounded-3" src={profile5} alt=""/>
                    </div>
                    <div className="">
                      <h4 className="mb-0 font-size-5 font-weight-semibold">Aniasta Hemberg</h4>
                      <p className="mb-0 font-size-3 heading-default-color">Creative Director</p>
                      <span className="font-size-3 text-smoke"><img className="mr-2" src={pin} alt=""/>New York, USA</span>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </div>*/}
        </div>
      </div>
    </div>
        </div>
    )
}