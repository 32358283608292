import React from 'react';
import CandidateSignUp from './components/authentication_pages/candidate-sign-up'
import EmployerSignUp from './components/authentication_pages/employer-sign-up'
import VerifyEmail from './components/authentication_pages/verify-email'
import VerifyAccount from './components/authentication_pages/VerifyPages'
import Reset_password_success from './components/authentication_pages/reset-password-success'
import Reset_link_success from './components/authentication_pages/Reset-link-successful'
import EmailNotVerified from './components/authentication_pages/EmailNotConfirmed'
import SubAdminCreate from './components/authentication_pages/SubAdminCreate'
import Newpassword from './components/authentication_pages/Newpassword'
import SignIn from './components/authentication_pages/Login'
import JobSearch from './components/candidate_pages/job_search'
import SideBar from './components/shared-components/sidebar'
import AuthenticatedRoutes from './private_route'
import HomePage from './components/home_page'
import Posted_Jobs from './components/employer_pages/posted_jobs'
import Pricing from './components/pricing'
import CandidateProfile from './components/employer_pages/candidate_profile'
import Bookmark from './components/candidate_pages/bookmarks'
import JobSaved from './components/candidate_pages/SavedJobs'
import Messaging from './components/shared-components/shared_messaging'
import JobDetails from './components/candidate_pages/job_details'
import AppliedJobs from './components/candidate_pages/applied_jobs'
import AdminRoute from './admin_private_route'
import Success from './components/success-page'
import Edit_Job from './components/employer_pages/edit_posted_job'
import ApplicationQuestionPage from './components/candidate_pages/application'
import Dashboard from './components/shared-components/shared_dashboard'
import Setting from './components/shared-components/shared_setting'
import Create_Job from './components/employer_pages/create_job'
import Applicants from './components/employer_pages/applicants'
import PageNotFound from './components/page-not-found'
import Candidates from './components/administrator_pages/Candidates'
import Datas from './components/administrator_pages/Datas'
import Companies from './components/administrator_pages/Companies'
import Jobs from './components/administrator_pages/Jobs'
import Packages from './components/administrator_pages/Packages'
import Admins from './components/administrator_pages/Users'
import Profile from './components/shared-components/shared_profile'
import CompanyProfile from './components/candidate_pages/company_profile'
import Billing from './components/employer_pages/billing'
import ForgotPassWord from './components/authentication_pages/forgot-password'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import {AuthProvider} from './components/AuthContext'
import './App.css';
import './mainmap.css'
import './messaging.css'
//import './bootstrapmap.css'

function App() {
  return (
    <div>
      <Router>
        <AuthProvider>
        <Switch>
            <Route path="/login" component={SignIn}/>
            <Route path="/employer-sign-up" component={EmployerSignUp} />
            <Route path="/candidate-sign-up" component={CandidateSignUp} />
            <Route path="/forgot-password" component={ForgotPassWord} />
            <Route path="/verify-email" component={VerifyEmail} />
            <Route exact path="/" component={HomePage} />
            <Route path="/JobSearch" component={JobSearch} />
            <Route path="/verify/account/:token" component={VerifyAccount} />
            <Route path="/email-not-verified" component={EmailNotVerified} />
            <Route path="/JobDetails/:token/:data" component={JobDetails} />
            <Route path="/pricing" component={Pricing} />
            <Route path="/reset-password-link-success" component={Reset_link_success} />
            <Route path="/reset-password-success" component={Reset_password_success} />
            <Route path="/reset/new-password/:token" component={Newpassword} />
            <Route path="/set/new-password/:token" component={SubAdminCreate} />
            <Route path="/sidebar" component={SideBar} />
            <Route path="/company-profile/:token" component={CompanyProfile} />
            <Route path="/candidate-profile/:token" component={CandidateProfile} />
            <AuthenticatedRoutes path="/job-questions/:token/:data" component={ApplicationQuestionPage} />
            <AuthenticatedRoutes path="/dashboard" component={Dashboard} />
            <AuthenticatedRoutes path="/Success" component={Success} />
            <AuthenticatedRoutes path="/create-jobs" component={Create_Job} />
            <AuthenticatedRoutes path="/applied-jobs" component={AppliedJobs} />
            <AuthenticatedRoutes path="/setting" component={Setting} />
            <AuthenticatedRoutes path="/job-bookmarks" component={Bookmark} />
            <AuthenticatedRoutes path="/applicants" component={Applicants} />
            <AuthenticatedRoutes path="/profile" component={Profile} />
            <AuthenticatedRoutes path="/conversation" component={Messaging} />
            <AuthenticatedRoutes path="/billing" component={Billing} />
            <AuthenticatedRoutes path="/posted-jobs" component={Posted_Jobs} />
            <AuthenticatedRoutes path="/saved-jobs" component={JobSaved} />
            <AuthenticatedRoutes path="/edit-job" component={Edit_Job} />
            <AdminRoute path="/datas" component={Datas} />
            <AdminRoute path="/Candidates" component={Candidates} />
            <AdminRoute path="/Companies" component={Companies} />
            <AdminRoute path="/Jobs" component={Jobs} />
            <AdminRoute path="/packages" component={Packages} />
            <AdminRoute path="/admins" component={Admins} />
            <Route path="*" component={PageNotFound} />
        </Switch>
        </AuthProvider>
      </Router>
    </div>
  );
}

export default App;
