import React from 'react'
import logo from '../image/strive-footer-logo.png' 
import  { useAuths }  from '../AuthContext';
import {Link} from 'react-router-dom'

export default function Footer () {
const {isLoggedIn, signOut, currentUser} = useAuths()

    return(
        <footer className="footer bg-ebony-clay dark-mode-texts">
        <div className="container pt-12 pt-lg-19 pb-10 pb-lg-19">
          <div className="row">
            <div className="col-lg-4 col-sm-6 mb-lg-0 mb-9">
              {/* <!-- footer logo start --> */}
              <img
                src={logo}
                alt=""
                className="footer-logo mb-14"
              />
              {/* <!-- footer logo End --> */}
            </div>
            <div className="col-lg-8 col-md-6">
              <div className="row">
                <div className="col-lg-4 col-md-6 col-sm-3 col-xs-6">
                  <div className="footer-widget widget2 mb-md-0 mb-13">
                    {/* <!-- footer widget title start --> */}
                    <p className="widget-title font-size-4 text-gray mb-md-8 mb-7">
                      Company
                    </p>
                    {/* <!-- footer widget title end --> */}
                    {/* <!-- widget social menu start --> */}
                    <ul
                      className="widget-links pl-0 list-unstyled list-hover-primary"
                    >
                      <li className="mb-6">
                        <a
                          className="
                            heading-default-color
                            font-size-4 font-weight-normal
                          "
                          target="_blank"
                          rel="noreferrer"
                          href="https://strivehumanresources.com/about-us/"
                          >About us</a
                        >
                      </li>
                      <li className="mb-6">
                        <a
                          className="
                            heading-default-color
                            font-size-4 font-weight-normal
                          "
                          target="_blank"
                          rel="noreferrer"
                          href="https://strivehumanresources.com/contact-us/"
                          >Contact us</a
                        >
                      </li>
                      <li className="mb-6">
                        <Link
                          className="
                            heading-default-color
                            font-size-4 font-weight-normal
                          "
                          to="/pricing"
                          >Pricing</Link>
                      </li>
                    </ul>
                    {/* <!-- widget social menu end --> */}
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-3 col-xs-6">
                  <div className="footer-widget widget3 mb-sm-0 mb-13">
                    {/* <!-- footer widget title start --> */}
                    <p className="widget-title font-size-4 text-gray mb-md-8 mb-7">
                      Top Category
                    </p>
                    {/* <!-- footer widget title end -->
                    <!-- widget social menu start --> */}
                    <ul
                      className="widget-links pl-0 list-unstyled list-hover-primary"
                    >
                      <li className="mb-6">
                        <Link
                          className="
                            heading-default-color
                            font-size-4 font-weight-normal
                          "
                          to="/jobSearch"
                          >Human Resources
                        </Link>
                      </li>
                      <li className="mb-6">
                        <Link
                          className="
                            heading-default-color
                            font-size-4 font-weight-normal
                          "
                          to="/jobSearch"
                          >Development</Link>
                      </li>
                      <li className="mb-6">
                        <Link
                          className="
                            heading-default-color
                            font-size-4 font-weight-normal
                          "
                          to="/jobSearch"
                          >Sales</Link>
                      </li>
                      <li className="mb-6">
                        <Link
                          className="
                            heading-default-color
                            font-size-4 font-weight-normal
                          "
                          to="/jobSearch"
                          >Customer Service</Link>
                      </li>
                    </ul>
                    {/* <!-- widget social menu end --> */}
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-3 col-xs-6">
                  <div className="footer-widget widget4">
                    {/* <!-- footer widget title start --> */}
                    <p className="widget-title font-size-4 text-gray mb-md-8 mb-7">
                      Legal
                    </p>
                    {/* <!-- footer widget title end --> */}
                    <ul
                      className="widget-links pl-0 list-unstyled list-hover-primary"
                    >
                      <li className="mb-6">
                        <a
                          className="
                            heading-default-color
                            font-size-4 font-weight-normal
                          "
                          target="_blank"
                          rel="noreferrer"
                          href="https://strivehumanresources.com/policy/"
                          >Privacy Policy</a
                        >
                      </li>
                      <li className="mb-6">
                        <a
                          className="
                            heading-default-color
                            font-size-4 font-weight-normal
                          "
                          target="_blank"
                          rel="noreferrer"
                          href="https://strivehumanresources.com/terms/"
                          >Terms of Service</a
                        >
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    )
} 