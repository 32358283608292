import React, {useState} from 'react'
// import blackstrivehrlogo from '../image/logo-main-black.png';
import {Link, useHistory} from 'react-router-dom'
import  { useAuths }  from '../../AuthContext';
import { Helmet } from 'react-helmet'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Spinner } from 'react-bootstrap'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { toast, Slide } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';


export default function  CandidateLogin() {


    const [showPassword, setShowPassword] = useState(false)
    const [btn_loading, set_btn_loading] = useState(false)
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const { signIn } = useAuths()
    const history = useHistory()




const passwordError = () => {
toast.error('Password Not Correct', {position: toast.POSITION.TOP_CENTER, theme: "colored", transition: Slide,  hideProgressBar: true})
}

const disabled_account = () => {
toast.error('This account has be disabled, Please contact the administrator', {position: toast.POSITION.TOP_RIGHT, theme: "colored", transition: Slide,  hideProgressBar: true})
}

const emailError = () => {
toast.error('Email Not Found', {position: toast.POSITION.TOP_CENTER, theme: "colored", transition: Slide,  hideProgressBar: true})
}

const serverError = () => {
toast.error('Could Not Sign You In', {position: toast.POSITION.TOP_CENTER, theme: "colored", transition: Slide,  hideProgressBar: true})
}

const required = () => {
toast.error('All Fields Are Required', {position: toast.POSITION.TOP_CENTER, theme: "colored", transition: Slide,  hideProgressBar: true})
}

const internet = () => {
toast.error('Internet error', {position: toast.POSITION.TOP_CENTER, theme: "colored", transition: Slide,  hideProgressBar: true})
}

const handlesubmit = async (e) => {
    e.preventDefault();
    set_btn_loading(true)
    if(!email || !password ){
        required()
        set_btn_loading(false)
    }else{
         try { 
        await signIn({ password, email})  
        await history.push('/dashboard')
   } catch (error) {
       if(!error){
         internet()
          set_btn_loading(false)
       }
      else if (error.response.status === 401) {
         passwordError()
          set_btn_loading(false)
     } else if(error.response.status === 404){
        emailError()
         set_btn_loading(false)
     }else if(error.response.status === 400){
         disabled_account()
         set_btn_loading(false)
     }else{
       serverError()
        set_btn_loading(false)
     }
   }}
    }
const viewPassword = () => {
    showPassword ? setShowPassword(false) : setShowPassword(true)
}

const reveilPassword = () => {
    if( showPassword){
        return "text"
    }else{
       return "password"}
}




  return (
    <div className="mb-15 text-center bg-white px-9 pt-9 pb-7 shadow-8 rounded-4" >
                <div className="row">
                <Helmet>
            <title> Candidate Sign In - Job Board | StriveHR </title>
            </Helmet>
                      
                      <div className="col-xs-12 mb-8">
                        <h2 className="font-size-9 mb-4">
                          Candidate Login
                        </h2>
                        <p className="font-size-4  heading-default-color">
                          New here?&nbsp;
                          <Link to="/candidate-sign-up" className="text-primary">
                           Create Account</Link>
                        </p>
                        <div>
                            <form>
                            <div className="form-group text-left">
                                <label
                                className="
                                    font-size-4
                                    text-black-2
                                    font-weight-semibold
                                    line-height-reset
                                "
                                >E-mail</label>
                                <input
                                onChange={e => {setEmail(e.target.value)}}
                                type="email"
                                className="form-control"
                                placeholder="john@gmail.com"
                                id="email"
                                />
                            </div>
                            <div className="form-group text-left">
                                <label
                                className="
                                    font-size-4
                                    text-black-2
                                    font-weight-semibold
                                    line-height-reset
                                "
                                >Password</label>
                                <div className="position-relative">
                               <input
                                  type={reveilPassword()}
                                  className="form-control"
                                  placeholder="Enter password"
                                  onChange={e => {setPassword(e.target.value)}}
                                />
                                
                                     {!showPassword ? <FontAwesomeIcon 
                          className="
                              show-password
                              pos-abs-cr
                              fas
                              mr-6
                              text-black-2
                            "
                          onClick={() => {viewPassword()}}
                            icon={faEye}/> : 
                             <FontAwesomeIcon 
                          className="
                              show-password
                              pos-abs-cr
                              fas
                              mr-6
                              text-black-2
                            "
                          onClick={() => {viewPassword()}}
                            icon={faEyeSlash}/>}
                                
                                </div>
                            </div>
                            <div className="form-group d-flex flex-wrap justify-content-between">
                                <label for="terms-check" className="gr-check-input d-flex  mr-3">
                                <input className="d-none" type="checkbox" id="terms-check"/>
                                <span className="checkbox mr-5"></span>
                                <span className="font-size-3 mb-0 line-height-reset mb-1 d-block">Remember password</span>
                                </label>
                                <Link to="/forgot-password" className="font-size-3 text-brown line-height-reset">Forget Password</Link>
                            </div>
                            <div className="form-group mb-8">
                                {!btn_loading ? <button
                                 onClick={(e) => {handlesubmit(e)}}
                                className="
                                    btn btn-primary btn-medium
                                    w-100
                                    rounded-5
                                    text-uppercase
                                "
                                >
                                Log in
                                </button> :
                                <Button className="btn btn-primary btn-medium
                                    w-100
                                    rounded-5
                                    text-uppercase" disabled>
                                  <Spinner
                                  as="span"
                                  variant="light"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                  animation="border"/>
                                  
                              </Button>}
                                
                            </div>
                            <p className="font-size-4 text-center heading-default-color">Want to hire? <Link to="/employer-sign-up" className="text-primary">Sign up here</Link></p>
                           
                            </form>
                        </div>
                      </div>
                    </div>
                </div>
  )
}
