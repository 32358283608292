import React, { useEffect } from 'react'
import axios from 'axios'
import Navbar from '../shared-components/navbar'
import Sidebar from '../shared-components/sidebar'
import { Link } from 'react-router-dom'
import moment from 'moment'
import Paginating from "../pagination"
import { Helmet } from 'react-helmet'
import { useAuths } from '../AuthContext'
import {Button, Spinner} from 'react-bootstrap';
import emptybox from '../image/c-home/empty-box.png'

const url = process.env.REACT_APP_SERVER_URL



//get-candidate-application

export default function ApplicantsEmployer () {
  const {state, getEmployerApplicants, filterApplicantData, getEmployerJobs, pages_clean_up, updateData } = useAuths()


  const [currentPage, setCurrentPage] = React.useState(1)
  const [dataPerPage] = React.useState(5)
  
  const indexOfLastPost = currentPage * dataPerPage;
  const indexOfFirstPage = indexOfLastPost - dataPerPage;
  const currentData = state.applicants.slice(indexOfFirstPage, indexOfLastPost);
  
  
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber)
  }
  const previousPage = () => {
    if(currentPage - 1 > 0){
      setCurrentPage(currentPage - 1)
    }
  }
  const nextPage = (dataNumber) => {
    if(currentPage + 1 <= dataNumber){
      setCurrentPage(currentPage + 1)
    }
  }
  

 useEffect(() => {
  let mounted = true
  if(mounted){
    getEmployerApplicants()
    getEmployerJobs()
 
  }
  return() => {
    pages_clean_up("employer/application")
    mounted = false
  }
 }, [])



    return (
        <div>
           <Helmet>
            <title> Applications - Job Board | StriveHR </title>
            </Helmet>
            <Navbar/>
            <Sidebar/>

         <div>{ state.applicants.length > 0 ?
      <div className="dashboard-main-container mt-25 mt-lg-31" id="dashboard-body">
        <div className="container">
            <div className="mb-14">
          <div className="row mb-11 align-items-center">
            <div className="col-lg-6 mb-lg-0 mb-4">
              <h3 className="font-size-6 mb-0">Applicants List ({state.applicants.length})</h3>
            </div>
            <div className="col-lg-6">
              <div className="d-flex flex-wrap align-items-center justify-content-lg-end">
                <p className="font-size-4 mb-0 mr-6 py-2">Filter by Job:</p>
                <div className="h-px-48">
                 <select name="country" id="country" onChange={e => {filterApplicantData(e.target.value, "application")}} className="nice-select pl-7 h-100 arrow-3 arrow-3-black min-width-px-273 font-weight-semibold text-black-2">
                 <option value="none" data-display="Product Designer">None</option>
                    {state.employer_jobs.map(i => (<option value={i.jobrole} data-display="Product Designer">{i.jobrole}</option>))}
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-white shadow-8 pt-7 rounded pb-8 px-11">
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th scope="col" className="pl-0  border-0 font-size-4 font-weight-normal">Name</th>
                    <th scope="col" className="border-0 font-size-4 font-weight-normal">Applied as</th>
                    <th scope="col" className="border-0 font-size-4 font-weight-normal">Applied on</th>
                    <th scope="col" className="border-0 font-size-4 font-weight-normal"></th>
                    <th scope="col" className="border-0 font-size-4 font-weight-normal"></th>
                    <th scope="col" className="border-0 font-size-4 font-weight-normal"></th>
                  </tr>
                </thead>
                <tbody>
                  {currentData.map(i => { if(i.owner) return (<tr className="border border-color-2">
                    <th scope="row" className="pl-6 border-0 py-7 pr-0">
                      <Link to={{pathname: `/candidate-profile/${i.owner.userId}`}} className="media min-width-px-235 align-items-center">
                        {i.owner.image ? <div className="circle-36 mr-6">
                          <img src={i.owner.image} alt="" className="w-100 circle-36 mr-6" />
                        </div> : <div className="circle-36 mr-6 bg-primary">
                          <div  className="w-100" ></div>
                        </div>}
                        <h4 className="font-size-4 mb-0 font-weight-semibold text-black-2">{i.owner.first_name+' '+i.owner.last_name}</h4>
                      </Link>
                    </th>
                    <td className="table-y-middle py-7 min-width-px-235 pr-0">
                      <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">{i.job.jobrole}</h3>
                    </td>
                    <td className="table-y-middle py-7 min-width-px-170 pr-0">
                      <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">{moment(new Date(i.createdAt)).format("MMMM Do YYYY")}</h3>
                    </td>
                    <td className="table-y-middle py-7 min-width-px-170 pr-0">
                      <div className="">{i.resume ? <a href={i.resume} target = "_blank" className="font-size-3 font-weight-bold text-black-2 text-uppercase" >View Resume</a>: 
                      <div  className="font-size-3 font-weight-bold text-black-2 text-uppercase" data-toggle="modal" data-target="#employe-profile">No Resume</div>}</div>
                    </td>
                    <td className="table-y-middle py-7 min-width-px-110 pr-0">
                      <div className=""><Link  to={{pathname: "/conversation", state : {receiverId: i.owner._id}}} className="font-size-3 font-weight-bold text-green text-uppercase">Contact</Link></div>
                    </td>
                    {i.accepted ? <td className="table-y-middle py-7 min-width-px-100 pr-0">
                      <div className=""><div className="font-size-3 font-weight-bold text-green-2 text-uppercase">Accepted</div></div>
                    </td> : i.rejected ?  <td className="table-y-middle py-7 min-width-px-100 pr-0">
                      <div className=""><div className="font-size-3 font-weight-bold text-red-2 text-uppercase">Rejected</div></div>
                    </td>: <div> <td className="table-y-middle py-7 min-width-px-100 pr-0">
                      <div className=""><label onClick={() => {updateData("application", "accept", i._id)}} className="font-size-3 font-weight-bold text-primary-2 text-uppercase">Accept</label></div>
                    </td>  <td className="table-y-middle py-7 min-width-px-100 pr-0">
                      <div className=""><label onClick={() => {updateData("application", "reject", i._id)}} className="font-size-3 font-weight-bold text-red-2 text-uppercase">Reject</label></div>
                    </td></div>}
                  </tr>)})}
                </tbody>
              </table>
            </div>
            <div className="">
     <Paginating dataPerPage={dataPerPage} totalData={state.applicants.length} paginate={paginate} currentPage={currentPage} nextPage={nextPage} previousPage={previousPage} />
            </div>
          </div>
        </div>

        </div>
        </div>
       
        :
        
        <div className="dashboard-main-container mt-25 mt-lg-31" id="dashboard-body">
        {/* <!-- Messaging System --> */}
        <div className="container">
          <div className="mb-14">
            <div className="col-12 col-xl-10 col-lg-8">
              {/* <!-- Top Start --> */}
              <div className="mb-5">
                <h3 className=" mb-9">Empty tables</h3>
                
                {/* Empty data  */}
                <div className="row justify-content-center">
                  <div className="col-lg-6 col-md-6 col-sm-11 mb-9 text-center">
                    <img className="w-50" src={emptybox} alt="" />
                    <h2 className="pt-15">No data found</h2>
                    <p>Your data will appear here once you start posting jobs.</p>
                  </div>
                </div>

              </div>
              
            </div>
          </div>
        </div></div>}
        </div>
        </div>
      )
    
}