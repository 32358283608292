import React, {useEffect} from 'react'
import {Link} from 'react-router-dom'
import profile from "../image/profile/building.png"
import Multiselect from "multiselect-react-dropdown";
import {countries} from '../Countries'
import Navbar from '../shared-components/navbar'
import Footer from '../shared-components/footer'
import  { useAuths, ACTIONS }  from '../AuthContext';
import { Button, Spinner} from 'react-bootstrap';
import { Helmet } from 'react-helmet'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';

toast.configure()



 //skill, level, type, industry, category, education, limit
export default function Dashboard () {

const {isLoggedIn, state, createBookMark, deleteBookMark, getBookMarkjobs, search_jobs, dispatch} = useAuths()

useEffect(() => {
  let mounted = true
  if(mounted){
   getBookMarkjobs()
   search_jobs(5)
  }
  return () => {
    mounted = false
  }

}, [])


const handleJobType = (data) => {
  const getId = data.map(i => { return i._id})
  dispatch({type: ACTIONS.SET_JOB_TYPE_SEARCH_ARR, payload: getId})
}

const handleCareerLevel = (data) => {
  const getId = data.map(i => { return i._id})
  dispatch({type: ACTIONS.SET_CAREER_LEVEL_SEARCH_ARR, payload: getId})
}

const handleIndustry = (data) => {
  console.log(data)
  const getId = data.map(i => { return i._id})
  dispatch({type: ACTIONS.SET_INDUSTY_SEARCH_ARR, payload: getId})
}

const handleCategory = (data) => {
  const getId = data.map(i => { return i._id})
  dispatch({type: ACTIONS.SET_CATEGORY_SEARCH_ARR, payload: getId})
}


return(
    <div>
       <Helmet>
            <title> Search Jobs - Job Board | StriveHR </title>
            </Helmet>
          <div >
        <header
        className="
          site-header site-header--menu-right
          bg-default
          dynamic-sticky-bg
          py-7 py-lg-0
          site-header--absolute site-header--sticky
        "
      >
     <Navbar/>
      </header>
      <div className="bg-default-1 pt-26 pt-lg-28 pb-13 pb-lg-25">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-4 col-xs-8">
              <div className="widgets mb-11">
                <h4 className="font-size-6 font-weight-semibold mb-6">Job Type</h4>
                <Multiselect
            options={state.job_types} 
            selectedValues={state.job_type_search_arr.type} 
            onSelect={(data) => {handleJobType(data)}}
            onRemove={(data) => {handleJobType(data)}}
            displayValue="type"
            />
              </div>
              <div className="widgets mb-11">
                <h4 className="font-size-6 font-weight-semibold mb-6">
                  Career Level
                </h4>
                 <Multiselect
            options={state.career_levels} 
            selectedValues={state.career_level_search_arr.title} 
            onSelect={(data) => {handleCareerLevel(data)}}
            onRemove={(data) => {handleCareerLevel(data)}}
            displayValue="title"
            />
              </div>
                 <div className="widgets mb-11">
                <h4 className="font-size-6 font-weight-semibold mb-6">
                  Industry
                </h4>
                 <Multiselect
            options={state.industries} 
            selectedValues={state.industy_search_arr.name} 
            onSelect={(data) => {handleIndustry(data)}}
            onRemove={(data) => {handleIndustry(data)}}
            displayValue="name"
            />
              </div>

                 <div className="widgets mb-11">
                <h4 className="font-size-6 font-weight-semibold mb-6">
                  Category
                </h4>
                 <Multiselect
            options={state.job_categories} 
            selectedValues={state.category_search_arr.category} 
            onSelect={(data) => {handleCategory(data)}}
            onRemove={(data) => {handleCategory(data)}}
            displayValue="category"
            />
              </div>

              
            </div>
            <div className="col-12 col-md-8 col-xs-12">
              <form
                action="/"
                className="search-form search-2-adjustment ml-lg-0 ml-md-15"
              >
                <div
                  className="
                    filter-search-form-2
                    bg-white
                    rounded-sm
                    shadow-7
                    pr-6
                    py-6
                    pl-6
                  "
                >
                  <div className="filter-inputs">
                    <div
                      className="
                        form-group
                        position-relative
                        w-lg-45 w-xl-40 w-xxl-45
                      "
                    >
                      <input
                        className="form-control focus-reset pl-13"
                        type="text"
                        onChange={(e) => {dispatch({type: ACTIONS.SET_JOB_SEARCH_TEXT, payload: e.target.value})}}
                        id="keyword"
                        placeholder="UI Designer"
                        value={state.job_search_text}
                      />
                      <span
                        className="
                          h-100
                          w-px-50
                          pos-abs-tl
                          d-flex
                          align-items-center
                          justify-content-center
                          font-size-6
                        "
                      >
                        <i
                          className="icon icon-zoom-2 text-primary font-weight-bold"
                        ></i>
                      </span>
                    </div>
                    
                    <div
                      className="
                        form-group
                        position-relative
                        w-lg-55 w-xl-60 w-xxl-55
                      "
                    >
                      
                      <select
                        className="
                          shadow-none
                          border-0
                          pl-13
                          h-100
                          arrow-3
                          font-size-4
                        "
                        onChange={(e) => dispatch({type: ACTIONS.SET_COUNTRY_SEARCH_TEXT, payload: e.target.value})}
                        value={state.country_search_text}
                      >
                       <option value="" selected>Select Country</option>
                              {countries.map(i => {return (<option key={i.ISO} selected={i.value === state.country_search_text} value={i.value}>{i.country}</option>)})}
                      </select>
                      <span
                        className="
                          h-100
                          w-px-50
                          pos-abs-tl
                          d-flex
                          align-items-center
                          justify-content-center
                          font-size-6
                        "
                        ><i
                          className="icon icon-pin-3 text-primary font-weight-bold"
                        ></i ></span>
                    </div>
                  </div>
                  <div className="button-block">
                   {!state.loading ? <button
                      className="
                        btn btn-primary
                        line-height-reset
                        h-100
                        btn-submit
                        w-100
                        text-uppercase
                      "
                      onClick={(e) => {e.preventDefault(); search_jobs(5)}}
                    >
                      Search
                    </button>:  <Button className="btn btn-primary
                        line-height-reset
                        h-100
                        btn-submit
                        w-100
                        text-uppercase" disabled>
                    <Spinner
                    as="span"
                    variant="light"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    animation="border"/>
                    Searching...
                   </Button>}
                  </div>
                </div>
              </form>
              <div className="pt-12 ml-lg-0 ml-md-15">
                <div className="d-flex align-items-center justify-content-between">
      {state.job_search_text || state.country_search_text || state.career_level_search_arr || state.industy_search_arr || state.category_search_arr || state.job_type_search_arr ? 
    <h5 className="font-size-4 font-weight-normal text-default-color">
                    Showing
                    <span className="ml-2 mr-2 heading-default-color">{state.jobs.length}</span>
                    jobs for
                    <span className="ml-2 heading-default-color">{state.job_search_text ? state.job_search_text : state.country_search_text ? state.country_search_text :"Selected"}</span>
                  </h5> : <span className="ml-2 mr-2 heading-default-color">{state.jobs.length} jobs</span>}
                </div>
                <div className="pt-6">
                  <div className="row justify-content-center">
                   {state.jobs.map( i => { return( <div className="col-12 col-lg-6">
                      <div
                        className="
                          bg-white
                          px-8
                          pt-9
                          pb-7
                          rounded-4
                          mb-9
                          feature-cardOne-adjustments
                        "
                      >
                        {!i.anonymous ? <div className="d-block mb-7">
                          <img
                              style={{width: "80px"}}
                              src={i.owner.image ? i.owner.image : profile}
                              alt=""
                          />
                        </div>: null}
                        {!i.anonymous ? <Link to={{pathname: `/company-profile/${i.owner.userId}`}} className="font-size-3 d-block mb-0 text-gray"
                          >{i.owner.company_name}</Link> : null}
                        <h2 className="mt-4">
                          <Link
                          to={{pathname: `/JobDetails/${i.jobId}`}}
                            className="
                              font-size-7
                              text-black-2
                              font-weight-bold
                              mb-4
                            " 
                            >{i.jobrole}</Link>
                        </h2>
                        <ul className="list-unstyled mb-1 card-tag-list">
                          <li>
                            <a
                              href=""
                              className="
                                bg-regent-opacity-15
                                text-denim
                                font-size-3
                                rounded-3
                              "
                            >
                              <i
                                className="icon icon-pin-3 mr-2 font-weight-bold"
                              ></i>
                              {i.state+', '+i.country}
                            </a>
                          </li>
                          <li>
                            <Link
                              to={{pathname: `/company-profile/${i.owner.userId}`}}
                              className="
                                bg-regent-opacity-15
                                text-orange
                                font-size-3
                                rounded-3
                              "
                            >
                              <i
                                className="fa fa-briefcase mr-2 font-weight-bold"
                              ></i>
                              {i.type ? i.type.type: " "}
                            </Link>
                          </li>
                          <li>
                            <a
                              href=""
                              className="
                                bg-regent-opacity-15
                                text-eastern
                                font-size-3
                                rounded-3
                              "
                            >
                             {i.payment}
                            </a>
                          </li>
                        </ul>
                        <p className="mb-7 font-size-4 text-gray">
                         {
                           i.information
                         }
                        </p>
                        <div className="card-btn-group">
                          <Link
                          to={{pathname: `/JobDetails/${i.jobId}`}}
                            className="
                              btn btn-green
                              text-uppercase
                              btn-medium
                              rounded-3
                            "
                            >Apply Now</Link>
                          {isLoggedIn ? <>{state.book_marks.some(b => b === i._id) ?<button  
                            className="
                              btn btn-outline-mercury
                              text-black-2 text-uppercase
                              btn-medium
                              rounded-3
                            "
                            onClick={e => {const id = i._id; deleteBookMark({e, id})}}
                            ><div><i
                              className="
                                icon icon-bookmark-2
                                font-weight-bold
                                mr-4
                                font-size-4
                              "
                            ></i>
                            Unsave</div></button>: <button  
                            className="
                              btn btn-outline-mercury
                              text-black-2 text-uppercase
                              btn-medium
                              rounded-3
                            "
                            onClick={e => {const job_id = i._id; createBookMark({e, job_id})}}
                            ><div><i
                              className="
                                icon icon-bookmark-2
                                font-weight-bold
                                mr-4
                                font-size-4
                              "
                            ></i>
                            Save It</div></button>}</> : null}
                        </div>
                      </div>
                    </div>)})}
                  </div>
                </div>
                <div className="text-center pt-5 pt-lg-13">
                 
                      {!state.loading ? <Button
                    className="
                      text-white
                      font-weight-bold
                      text-uppercase
                      font-size-3
                      d-flex
                      align-items-center
                      justify-content-center
                    "
                    onClick={(e) => {e.preventDefault(); search_jobs(state.jobs.length + 5) }}
                    >Load More
                    <i className="fas fa-sort-down ml-3 mt-n2 font-size-4"></i>
                  </Button> : 
                  <Button className="text-white
                      font-weight-bold
                      text-uppercase
                      font-size-3
                      d-flex
                      align-items-center
                      justify-content-center" disabled>
                    <Spinner
                    as="span"
                    variant="light"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    animation="border"/>    
                   </Button>}

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
     <Footer/>
    </div>
    </div>
    )


} 


