import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import { format } from 'timeago.js';
import axios from 'axios'
const url = process.env.REACT_APP_SERVER_URL


export default function ViewApplication({id}) {
    const [application, setApplication] = React.useState({
        company_image: "",
       company_industry: "",
       company_name: "",
       job_type: "",
       career_level: "",
       company_num_of_employee: "",
       createdAt: new Date,
       isrecruitingactive: false
    })

useEffect(() => {
    const token = localStorage.getItem('token')
    axios.get(`${url}get-one-application/${id}`, {
        headers: {
                'Authorization': `Bearer ${token}`
      }
    }).then(res => {
        setApplication({
            ...application,
             company_image: res.data.company_image,
       company_industry:  res.data.company_industry,
       company_name:  res.data.company_name,
       job_type:  res.data.job_type,
       career_level:  res.data.career_level,
       company_num_of_employee:  res.data.company_num_of_employee,
       createdAt:  new Date(res.data.createdAt),
       isrecruitingactive:  res.data.isrecruitingactive
        })
    })
}, [])

    return(
        <div className="form-modal" id="receipt" aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <button type="button" className="circle-32 btn-reset bg-white pos-abs-tr mt-md-n6 mr-lg-n6 focus-reset z-index-supper" data-dismiss="modal"><i className="fas fa-times"></i></button>
  
          <div className=" login-modal-main bg-white rounded-8 overflow-hidden">
            <div className="row no-gutters">
        
                
            <div className="bg-white-2 h-100 pt-10 pb-7 col-12">
                <div className="container">
                      
                <div
                  className="
                    d-xs-flex
                    align-items-center
                    pl-xs-12
                    mb-8
                    text-center text-xs-left
                  "
                >
                  <a className="mr-xs-7 mb-5 mb-xs-0" href="#">
                    {application.company_image ? <img
                      className="square-72 rounded-6"
                      src={application.company_image}
                      alt=""
                    /> : 
                      <i className="fas fa-user font-size-11"></i>
                    }
                  </a>
                  <div className="">
                    <h2 className="mt-xs-n5">
                      <Link className="font-size-6 text-black-2 font-weight-semibold" to='#'>{application.company_name}</Link>
                    </h2>
                    <span className="mb-0 text-gray font-size-4">{application.company_industry}</span>
                  </div>
                </div>
                
                      <div className="col-6 col-xs-12 d-flex align-items-center  p-5 ml-5">
                          <i className="fa fa-briefcase pr-5"></i>
                          <p className="m-0">{application.job_type}</p>&nbsp;<p className="m-0">{application.career_level}</p>
                      </div>
                      
                      <div className="col-6 col-xs-12 d-flex align-items-center  p-5 ml-5">
                          <i className="fa fa-building pr-5"></i>
                          <p className="m-0">{application.company_num_of_employee}</p>&nbsp;<p className="m-0">{application.company_industry}</p>
                      </div>

                      <div className="col-6 col-xs-12 d-flex align-items-center  p-5 ml-5">
                          {/*<img src={currentUser.image ? currentUser.image : null} alt="" />*/}
                          <p className="m-0">Your profile matches this job </p>
                      </div>

                      <div className="col-6 col-xs-12 d-flex align-items-center  p-5 ml-5 border-bottom">
                        <i className="fa fa-minus text-red pr-5"></i>
                          {!application.isrecruitingactive ? <p className="m-0 text-red">No longer accepting applications. </p> :
                          <p className="m-0 text-green">Still Recruiting. </p> }
                      </div>

                      <div className="col-6 col-xs-12 p-5 pt-10 ml-5 ">
                       <h4>Job Activity</h4>
                          <p className="m-0 font-size-3">Application Submitted <span className="text-blue">{format(application.createdAt)}</span> </p>
                      </div>
                    </div>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    )
}