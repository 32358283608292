import React, {useEffect} from 'react'
import loginhero from "../image/c-home/login-hero.png";
import  { useAuths }  from '../AuthContext';
import {useHistory} from 'react-router-dom'
import { Helmet } from 'react-helmet'
import ReactLoading from "react-loading";
import axios from 'axios'
import blackstrivehrlogo from '../image/logo-main-black.png';
const url = process.env.REACT_APP_SERVER_URL

   


export default function ConfirmEmail() {
    const {isLoggedIn, currentUser} = useAuths()
    const [loading, setLoading] = React.useState(true)
    const history = useHistory()

useEffect(() => {
    setTimeout(() => {
         setLoading(false)
     }, 1000)
if(!isLoggedIn){
    history.push('/login')
}
})
const get_activation_link = () => {
    axios.post(`${url}get-activation-link`,{email: currentUser.email}).then(res => {if(res.data){
        history.push('/verify-email')
    }})
}


if(loading){
  return(
    <div className="center-sign-in-loading" style={{backgroundColor: "#fff", width: "100%", height: "100%", justifyContent: "center", alignItems: "center"}}>
      <ReactLoading className="center-sign-in-loading" type="bars" color="#8B3300" height={'20%'} width={'10%'} />
      </div>
  )
}

  return(
          <div className="site-wrapper overflow-hidden">
               <Helmet>
            <title> Email Not Confirmed - Job Board | StriveHR </title>
            </Helmet>
        <div className="">
        <div className=" min-h-100vh flex-all-center pt-lg-15 pt-xxl-17  pb-lg-0 pb-18">
        <div className="container">
        <div className="row justify-content-center">
        <div className="col-xxl-6 col-xl-7 col-lg-8 text-center">
        <img src={blackstrivehrlogo} alt="" className="p-7"/>
          <div className="mb-15 text-center bg-white px-9 pt-9 pb-7 shadow-8 rounded-4" >
          <div className="row">
                
                <div className="col-xs-12 mb-8">
                  <h2 className="font-size-9 mb-4">
                    Email Not Verified
                  </h2>
                  <p className="font-size-4  heading-default-color mb-10">
                          Please click on the get email verification link button below, to get a new email verification link.
                        </p>
                  <div>
                      <form>
                      <div className="form-group mb-20">
                                <a
                                onClick={e => {e.preventDefault(); get_activation_link()}}
                                href=""
                                target="_blank"
                                  className="
                                    btn btn-primary btn-medium
                                    w-100
                                    rounded-5
                                    text-uppercase
                                  "
                                >
                                  Get Email Verification Link
                                </a>
                                
                              </div>

                              <div className="text-center">
                                <p className="font-size-4  heading-default-color">
                                  Need help?
                                  <a href="tel:+2348149333305" className="text-primary"
                                    >call us</a>
                                </p>
                              </div>
                      </form>
                  </div>
                </div>
              </div>
          </div>
        </div>
        </div>
        </div>
        </div>
        </div>
        </div>
    )
} 