import React, {useEffect, useState} from 'react'
import Navbar from '../shared-components/navbar'
import Sidebar from '../shared-components/sidebar'
import backgroundImage from '../image/svg/upload-file.svg'
import  { useAuths }  from '../AuthContext';
import axios from 'axios'
import {Button, Modal} from 'react-bootstrap';
import {countries} from '../Countries'
import { Helmet } from 'react-helmet'
import {useHistory} from 'react-router-dom'
import pdf from "../image/svg/pdf.svg"
import ReactLoading from "react-loading";
import { toast, Slide } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import Multiselect from "multiselect-react-dropdown";
import moment from 'moment'
const url = process.env.REACT_APP_SERVER_URL

export default function Profile () {
    const [loading, setLoading] = useState(true)
    const {currentUser, getUser, time, color} = useAuths()
    const [selectedFile, setSelectedFile] = useState(undefined)
    const [selectedResume, setSelectedResume] = useState(undefined)
    const [first_name, set_first_name] = useState("")
    const [last_name, set_last_name] = useState("")
    const [email, set_email] = useState("")
    const [headline, set_headline] = useState("") 
    const [phone, set_phone] = useState("")
    const [country, set_country] = useState("")
    const [web_link, set_web_link] = useState("")
    const [about, set_about] = useState("")
    const [company_name, set_company_name] = useState("")
    const [company_role, set_company_role] = useState("")
    const [company_start_date, set_company_start_date] = useState(new Date())
    const [company_end_date, set_company_end_date] = useState(new Date())
    const [education_school, set_education_school] = useState("")
    const [education_course, set_education_course] = useState("")
    const [education_degree, set_education_degree] = useState("")
    const [add_resume, set_add_resume] = useState(false)
    const [education_country, set_education_country] = useState("")
    const [education_start_day, set_education_start_day] = useState(new Date())
    const [education_end_day, set_education_end_day] = useState(new Date()) 
    const [showExperienceForm, setShowExperienceForm] = useState(false)
    const [education_edit, set_education_edit] = useState(false)
    const [experience_id, set_experience_id] = useState("")
    const [education_id, set_education_id] = useState("")
    const [resume_name, set_resume_name] = useState("")
    const [experience_edit, set_experience_edit] = useState(false)
    const [show_education_delete, set_education_delete] = useState(false)
    const [show_experience_delete, set_experience_delete] = useState(false)
    const [showEducationForm, setShowEducation] = useState(false)
    const [experience_list, set_experience_list] = useState([])
    const [education_list, set_education_list] = useState([])
    const [social_profile, set_social_profile] = useState({
      faceBook: "",
      twitter: "",
      instagram: "",
      linkedin : "",
      _id: ""
    })
    const [faceBook, setFaceBook] = useState("")
    const [twitter, setTwitter] = useState("")
    const [instagram, setInstagram] = useState("")
    const [user_resume, set_user_resume] = useState([])
    const [linkedIn, setLinkedIn] = useState("")
    const [pdfBtn, setPdfBtn] = useState(false)
    const [all_skills, set_all_skills] = useState([])
    const [skill, set_skill] = useState([])
    const [selecteSkill, setSelectedSkill] = useState([])

    const history = useHistory()
    
    function upload(e){
        e.preventDefault();
    const token = localStorage.getItem('token')
    let formData = new FormData();
    formData.append("file", selectedFile);
    return axios.post(`${url}upload-image`, formData, {
      headers: {
        'Authorization': `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },

    }).then(
        res => {getUser()
          success()
        setSelectedFile(undefined); setPdfBtn(false)}
        ).catch(err => {
          error()
        })
    }

  const loadSkills = () => {
    axios.get(`${url}get-all-skill`).then(
      res => {
        set_all_skills(res.data)
      }
    )
  }

    const handleJobType = (data) => {
    const getId = data.map(i => { return i._id})
    setSelectedSkill(getId)
  }

  const handleRemoveJobType = (data) => {
    const getId = data.map(i => { return i._id})
    setSelectedSkill(getId)
  }

      function update_profile(e){
        e.preventDefault();
    const token = localStorage.getItem('token')

    if(!first_name || !last_name || !email || !phone || currentUser.headline && !headline || currentUser.country && !country || currentUser.web_link && !web_link || currentUser.about && !about){
      fillError()
    }else{
     axios.put(`${url}updateuser`, {
      first_name,
      last_name,
      phone,
      email,
      web_link,
      country,
      about,
      headline,
      skill: selecteSkill
    }, {
      
      headers: {
        'Authorization': `Bearer ${token}`,
      },

    }).then(
        res => {success()
          getUser()
        history.push('/dashboard')}
        ).catch(err => {
          error()
        })}
    }
    const renderSkill = all_skills.filter((i) => !skill.find(({ _id }) => i._id === _id));
   // const renderSkill = all_skills.filter(i => {return !skill._id.include(i._id)})
    useEffect(() => {
      let mounted = true
      if(mounted){
        loadSkills()
      }
      return () => {
        mounted = false
      }
    }, [])

  
    useEffect(() => {
        set_first_name(currentUser.first_name)
        set_last_name(currentUser.last_name)
        set_email(currentUser.email)
        set_phone(currentUser.phone)
        set_country(currentUser.country)
        set_web_link(currentUser.web_link)
        set_about(currentUser.about)
        set_headline(currentUser.headline)
        set_skill(currentUser.skill)
        const ids = skill.map(i => {return i._id})
        setSelectedSkill(ids)
         if(currentUser.social_media){
      setFaceBook(currentUser.social_media.faceBook)
      setInstagram(currentUser.social_media.instagram)
      setTwitter(currentUser.social_media.twitter)
      set_user_resume(currentUser.resume)
      setLinkedIn(currentUser.social_media.linkedIn)}
        get_experience()
        get_education()
        get_social()
       setTimeout(() => {
      setLoading(false)
    }, 2000)
      
    }, [])

    const get_experience = () => {
      const token = localStorage.getItem('token')
      axios.get(`${url}get-user-experience`,{
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    }).then(
        res => {
          set_experience_list(res.data)
        }
      )
    }

    const get_education = () => {
      const token = localStorage.getItem('token')
       axios.get(`${url}get-user-education`,{  
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    }).then(
        res => {
          set_education_list(res.data)
        }
      )
    }

    const get_social = () => {
      const token = localStorage.getItem('token')
       axios.get(`${url}get-user-social-media`,{   
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    }).then(
        res => {
          set_social_profile({
            ...social_profile,
            faceBook: res.data.faceBook,
            linkedin: res.data.linkedIn,
            twitter: res.data.twitter,
            instagram: res.data.instagram,
            _id: res.data._id
          })
        }
      )
    }

      function DeleteEducationModal(props) {
  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      bg="transparent"
    >
      <Modal.Body style={{backgroundColor: "#f2edf3"}}>
          <div className="text-center m-3 h6">Are you sure you want to delete Education?. <br/>Note: This action is not reversable </div>
        <div className="row justify-content-space-between m-3">
    <Button block onClick={(e) => {deleteEducation(e)}} className="btn btn-danger">Confirm</Button>
    <Button block onClick={() => set_education_delete(false)} className="btn btn-primary">Cancel</Button>
    </div>
      </Modal.Body>
    </Modal>
  );
}


const deleteEducation = (e) => {
  e.preventDefault();
  const token = localStorage.getItem('token')
  const id = education_id
  axios.delete(`${url}delete-education/${id}`,{  
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    }).then(res => {
    deleteEducationSuccess()
    get_education()
  }).catch(err => {
    deleteEducationError()
  })
}

  function DeleteExperienceModal(props) {
  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      bg="transparent"
    >
      <Modal.Body style={{backgroundColor: "#f2edf3"}}>
          <div className="text-center m-3 h6">Are you sure you want to delete Experience?. <br/>Note: This action is not reversable </div>
        <div className="row justify-content-space-between m-3">
    <Button block onClick={(e) => {deleteExperience(e)}} className="btn btn-danger">Confirm</Button>
    <Button block onClick={() => set_experience_delete(false)} className="btn btn-primary">Cancel</Button>
    </div>
      </Modal.Body>
    </Modal>
  );
}

const deleteExperience = (e) => {
  e.preventDefault();
   const token = localStorage.getItem("token")
   const id = experience_id
  axios.delete(`${url}delete-experience/${id}`,{  
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    }).then(res => {
    deleteExperienceSuccess()
    get_experience()
  }).catch(err => {
    deleteExperienceError()
  })
}

    const create_experience = (e) => {
      e.preventDefault();
      const token = localStorage.getItem('token')
      if(!company_name || !company_role || !company_start_date || !company_end_date){

      }else{
        axios.post(`${url}create-experience`, {company_name, company_role, company_start_date, company_end_date}, { headers: {
        'Authorization': `Bearer ${token}`,
      }}).then(res => {
        get_experience()
        set_experience_edit(false)
      }).catch(err => {

      })
      }
    }

    const create_education = (e) => {
       e.preventDefault();
       const token = localStorage.getItem('token')
       if(!education_school || !education_course || !education_start_day || !education_end_day || !education_degree || !education_country){

      }else{
        axios.post(`${url}create-education`, {education_school, education_course, education_start_day, education_end_day, education_degree, education_country},
        { headers: {
        'Authorization': `Bearer ${token}`,
      }}).then(
        res => {
          get_education()
        }
      ).catch(
        error => {

        }
      )
      }

    }


    const update_experience = (e) => {
         e.preventDefault();
         const token = localStorage.getItem('token')
          if(!company_name || !company_role || !company_start_date || !company_end_date){
           fillError()
      }else{
        const id = experience_id
        axios.put(`${url}update-experience/${id}`, {company_name, company_role, company_start_date, company_end_date},
        { headers: {
        'Authorization': `Bearer ${token}`,
      }}).then(
        res => {
          success()
          get_experience()
        }
      ).catch( err => {
        alert(err)
      })
      }

    }

    
    const create_resume = (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token')
    let formData = new FormData();
    formData.append("file", selectedResume);
          if(!selectedResume || !resume_name){
            fillError()
      }else{
        axios.post(`${url}create-resume`, formData ,
        { params: {name: resume_name},
         headers: {
        'Authorization': `Bearer ${token}`}
      }).then(
        res => {
          success()
          getUser()
        }
      ).catch( err => {
        error()
      })
      }
    }

    const update_education = (e) => {
         e.preventDefault();
         const token = localStorage.getItem('token')
          if(!education_school || !education_course || !education_start_day || !education_end_day){
            fillError()
      }else{
         const id = education_id
        axios.put(`${url}update-education/${id}`, {education_school, education_course, education_start_day, education_end_day, education_degree, education_country},{ headers: {
        'Authorization': `Bearer ${token}`,
      }}).then(
        res => {
          success()
          get_education()
        }
      ).catch( err => {
        error()
      })
      }
    }

    function createSocialProfile(e){
      e.preventDefault();
      const token = localStorage.getItem("token")
     
        if(!twitter || !faceBook || !instagram || !linkedIn){
          fillError()
        }else{
         axios.post(`${url}create-social-media`, {twitter, faceBook, instagram, linkedIn},
         {
           headers: {
        'Authorization': `Bearer ${token}`
      },
         }).then(
          res => {
            alert('Social Profile Created')
            getUser()
          }
        ).catch(
          err => {
            alert(err)
          }
        )}
      }
    
 const success = () => {
    toast.success('Profile Updated Successfully', {position: toast.POSITION.TOP_RIGHT, theme: "colored", transition: Slide,  hideProgressBar: true})
  }

  const fillError = () => {
    toast.warn('Please fill up the spaces you already have', {position: toast.POSITION.TOP_CENTER, theme: "colored", transition: Slide,  hideProgressBar: true})
  }

  const error = () => {
   toast.error('Could Not Update Profile. Please try again', {position: toast.POSITION.TOP_RIGHT, theme: "colored", transition: Slide,  hideProgressBar: true})
  }

const deleteExperienceSuccess = () => {
    toast.success('Experience Deleted Successfully', {position: toast.POSITION.TOP_RIGHT, theme: "colored", transition: Slide,  hideProgressBar: true})

}

const deleteEducationSuccess = () => {
    toast.success('Education Deleted Successfully', {position: toast.POSITION.TOP_RIGHT, theme: "colored", transition: Slide,  hideProgressBar: true})

}
const deleteExperienceError = () => {
   toast.error('Could Not Delete Experience. Please try again', {position: toast.POSITION.TOP_RIGHT, theme: "colored", transition: Slide,  hideProgressBar: true})
  }
  const deleteEducationError = () => {
   toast.error('Could Not Delete Education. Please try again', {position: toast.POSITION.TOP_RIGHT, theme: "colored", transition: Slide,  hideProgressBar: true})
  }
 

    return (
        <div>
           <Helmet>
            <title> Profile Page - Job Board | StriveHR </title>
            </Helmet>
            <Navbar/>
            <Sidebar/>
          <div className="dashboard-main-container mt-24 mt-lg-31" id="dashboard-body">
        <div className="container">
          <div className="mb-15 mb-lg-23">
            <div className="row">
              <div className="col-xxxl-9 px-lg-13 px-6">
                <h5 className="font-size-6 font-weight-semibold mb-11">
                  Edit Profile
                </h5>
                <div
                  className="
                    contact-form
                    bg-white
                    shadow-8
                    rounded-4
                    pl-sm-10 pl-4
                    pr-sm-11 pr-4
                    pt-15
                    pb-13
                  "
                >
                    
                  <div className="upload-file mb-16 text-center">
                    { !currentUser.image && !selectedFile ? <div id="userActions" className="square-215 m-auto px-6 mb-7" style={{backgroundImage: "url(" + backgroundImage + ")"}}>
                      <label 
                        htmlFor="fileUploadImage"
                        className="mb-0 font-size-4 text-smoke">Add Photo</label>   
                    <input type="file" id="fileUploadImage" hidden onChange={(e) => {setSelectedFile(e.target.files[0])}} className="" />
                    </div>: <div> {selectedFile ? <img className="square-215 m-auto px-6 mb-7 rounded" src={URL.createObjectURL(selectedFile)}/> : <a href={currentUser.image} id="userActions" className="square-215 m-auto px-6 mb-7" style={{backgroundImage: "url(" + currentUser.image + ")"}}>
                    </a>}
                     <input type="file" id="updateImage" hidden onChange={(e) => {setSelectedFile(e.target.files[0])}}
                            /><label
                                htmlFor="updateImage"
                               className="
                                mb-0
                                font-size-4
                                btn btn-outline-gray
                                min-width-px-210
                                rounded-pill
                                mb-5
                                mt-5
                              ">Update Image</label> {selectedFile ?
                               <button    className="
                                mb-0
                                font-size-4
                                btn btn-outline-gray
                                min-width-px-210
                                rounded-pill
                                mb-5
                                mt-5
                              " onClick={(e) => upload(e)}>Upload</button> : null }</div>}
                  </div>
                  <form>
                    <fieldset>
                      <div className="row mb-xl-1 mb-9">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label
                              className="
                                d-block
                                text-black-2
                                font-size-4 font-weight-semibold
                                mb-4
                              "
                            >
                             First Name</label>
                            <input
                              onChange={e =>  {set_first_name(e.target.value)}}
                              value={first_name}
                              type="text"
                              className="form-control h-px-48"
                              id="namedash"
                              placeholder="eg. Johnson"
                            />
                          </div>
                        </div>
                              <div className="col-lg-6">
                          <div className="form-group">
                            <label
                              className="
                                d-block
                                text-black-2
                                font-size-4 font-weight-semibold
                                mb-4
                              "
                            >
                             Last Name</label>
                            <input
                               onChange={e => set_last_name(e.target.value)}
                              value={last_name}
                              type="text"
                              className="form-control h-px-48"
                              id="namedash"
                              placeholder="eg. Johnson"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label
                              className="
                                d-block
                                text-black-2
                                font-size-4 font-weight-semibold
                                mb-4
                              "
                            >
                              Email</label>
                            <input
                               onChange={e => set_email(e.target.value)}
                              type="email"
                              value={email}
                              contentEditable={true}
                              className="form-control h-px-48"
                              id="email"
                              placeholder="eg. Johnson@gmail.com"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label
                              className="
                                d-block
                                text-black-2
                                font-size-4 font-weight-semibold
                                mb-4
                              "
                            >
                              Headline</label>
                            <input
                               onChange={e => set_headline(e.target.value)}
                              value={headline}
                              type="text"
                              className="form-control h-px-48"
                              id="headline"
                              placeholder="eg. Product Designer"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label
                              className="
                                d-block
                                text-black-2
                                font-size-4 font-weight-semibold
                                mb-4
                              "
                            >
                              Phone</label>
                            <input
                             onChange={e => set_phone(e.target.value)}
                              value={phone}
                              type="tel"
                              className="form-control h-px-48"
                              id="phone"
                              placeholder="eg. +234890920763"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label
                              className="
                                d-block
                                text-black-2
                                font-size-4 font-weight-semibold
                                mb-4
                              "
                            >
                              Country</label>
                          <select className="form-control h-px-48" id="businesstype" value={country} onChange={e => {set_country(e.target.value)}}>
                              <option selected>Choose...</option>
                              {countries.map( i => (<option key={i.ISO} value={i.value}>{i.country}</option>))}
                            </select>
                          </div>
                        </div>

                        <div className="col-lg-6">
                          <div className="form-group">
                            <label
                              className="
                                d-block
                                text-black-2
                                font-size-4 font-weight-semibold
                                mb-4
                              "
                            >
                              Website link</label>
                            <input
                             onChange={e => set_web_link(e.target.value)}
                              value={web_link}
                              type="text"
                              className="form-control h-px-48"
                              id="website"
                              placeholder="eg. https://example.com"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label
                              className="
                                d-block
                                text-black-2
                                font-size-4 font-weight-semibold
                                mb-4
                              "
                              >About</label>
                            <textarea
                             onChange={e => set_about(e.target.value)}
                              value={about}
                              name="textarea"
                              id="aboutTextarea"
                              cols="30"
                              rows="7"
                              className="
                                border border-mercury
                                text-gray
                                w-100
                                pt-4
                                pl-6
                              "
                              placeholder="Describe about the company what make it unique"
                            > </textarea>
                          </div>
                        </div>
                        <div className="col-md-12">
                     
                          <div
                            className="
                              border-top
                              pr-xl-0 pr-xxl-14
                              p-5
                              pl-xs-12
                              pt-7
                              pb-5
                            "
                          >
                            <h4
                              className="
                                font-size-6
                                mb-7
                                mt-5
                                text-black-2
                                font-weight-semibold
                              "
                            >
                              Skills
                            </h4>
                            <Multiselect
                            options={all_skills} 
                            selectedValues={skill} 
                            onSelect={(data) => {handleJobType(data)}}
                            onRemove={(data) => {handleRemoveJobType(data)}}
                            displayValue="title"
                            />
                            <ul
                              className="
                                list-unstyled
                                d-flex
                                align-items-center
                                flex-wrap
                              "
                            >
                              {skill.map(i => { return ( <li>
                                <a
                                  className="
                                    bg-polar
                                    text-black-2
                                    mr-6
                                    px-7
                                    mt-2
                                    mb-2
                                    font-size-3
                                    rounded-3
                                    min-height-32
                                    d-flex
                                    align-items-center
                                  "
                                  >{i.title}</a>
                              </li> )} )}
                             
                            </ul>         
                          </div>
                              <button
                            onClick={e => {update_profile(e)}}
                            type="button"
                            className="
                              btn btn-green btn-h-60
                              text-white
                              min-width-px-210
                              rounded-5
                              text-uppercase
                              mb-4
                            "
                          >Update Profile</button>
                           <div className="border-top p-5 pl-xs-12 pb-5">
                           <div className="form-group mb-11">
                            <h4 className="font-size-6 mb-7 text-black-2 font-weight-semibold">Resume</h4>
                           <div className="d-flex align-items-start p-5 border border-rd-5 d-inline-block">
                       
                        <div className="ml-3 col">
                         {currentUser.resume.length > 0 ? <>
                         {currentUser.resume.map(i => (<div className="mt-4 border-top">
                           
                          <a href={i.link} target = "_blank">  <img
                          src={pdf}
                          className="mr-1"
                          width="45"
                          height="45"
                        /><b>{i.name}</b> 
                          <div className="small">
                            Saved resume file
                          </div></a></div> ))} </>:
                          <div><b>You have no resume</b> 
                          <div className="small">
                           No Saved resume file
                          </div></div>}
                        </div><div>
                           <input type="file" id="fileUploadImage" hidden onChange={(e) => {setSelectedFile(e.target.files[0])}} className="" />
                        </div> 
                        
                      </div>

                            <p className="font-size-3 text-smoke mb-4">
                              Please upload your resume here
                            </p>
                            {!add_resume ? <div> {user_resume.length <= 5 ? <button
                              onClick={e => {e.preventDefault(); set_add_resume(true)}}
                              className="
                                mb-0
                                font-size-4
                                btn btn-outline-gray
                                min-width-px-210
                                rounded-pill
                                mb-5
                                mt-5
                              "
                              >Add Resume</button> : null} </div> : null}
                            {add_resume ? <div className="row mb-xl-1 mb-9">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label
                              className="
                                d-block
                                text-black-2
                                font-size-4 font-weight-semibold
                                mb-4
                              "
                            >Name</label>
                            <input
                              onChange={e =>  {set_resume_name(e.target.value)}}
                              type="text"
                              className="form-control h-px-48"
                              id="namedash"
                              placeholder="eg. my internship resume"
                            />
                          </div>
                        </div>
                         <label
                                htmlFor="fileUploadResume"
                              className="
                                font-size-4
                                btn btn-outline-gray
                                min-width-px-210
                                rounded-pill
                                mt-11
                              "
                              >Select Resume File</label>
                        </div> : null}
                            <p className="text-smoke font-size-2 ml-5">
                              PDF (5MB)
                            </p>

                            <input
                              id="resumeupload"
                              type="file" id="fileUploadResume" hidden onChange={(e) => {setSelectedResume(e.target.files[0]); setPdfBtn(true)}}
                            />{pdfBtn ?  <button    className="
                                mb-0
                                font-size-4
                                btn btn-outline-gray
                                min-width-px-210
                                rounded-pill
                                mb-5
                                mt-5
                              " onClick={(e) => create_resume(e)}>Add Resume</button> : null}
                              {add_resume ? <button
                              onClick={e => {e.preventDefault(); set_add_resume(false)}}
                              className="
                                font-size-4
                                btn btn-outline-gray
                                min-width-px-210
                                rounded-pill
                                ml-4
                              "
                              >Close</button> : null}
                          </div>
                          <div className="border-top w-100">
                    <h4 className="font-size-6 mb-7 mt-5 text-black-2 font-weight-semibold">Work Exprerience</h4>
                    {experience_list.map(i => { return (<div className="w-100">
                      <div className="d-flex align-items-center pr-11 mb-9 flex-wrap flex-sm-nowrap">
                        <div className="square-72 d-block mr-8 mb-7 mb-sm-0">
                          {/*<img src={Airbnb} alt=""/>*/}
                          <i className="fas fa-briefcase font-size-11"></i>
                        </div>
                        <div className="w-100 mt-n2">
                          <h3 className="mb-0">
                            <a className="font-size-6 text-black-2 font-weight-semibold" href="#">{i.company_role}</a>
                          </h3>
                          <a href="#" className="font-size-4 text-default-color line-height-2">{i.company_name}</a>
                          <div className="d-flex align-items-center justify-content-md-between flex-wrap">
                            <div className="font-size-4 text-gray mr-5">
                              {moment(new Date(i.company_start_date)).format("MMMM YYYY")} - {moment(new Date(i.company_end_date)).format("MMMM YYYY")} 
                              { moment(i.company_start_date).diff(moment(i.company_end_date),'years') !== 0 ?  moment(i.company_start_date).diff(moment(i.company_end_date),'years')+' '+'years' :
                               moment(i.company_start_date).diff(moment(i.company_end_date), 'months') !== 0 ?  moment(i.company_start_date).diff(moment(i.company_end_date), 'months')+' '+'months' :
                              moment(i.company_start_date).diff(moment(i.company_end_date), 'days')+' '+'days'}</div>
                            <a href="" onClick={e => {e.preventDefault(); set_company_name(i.company_name); set_company_role(i.company_role); set_company_start_date(i.company_start_date); 
                              set_company_end_date(i.company_end_date); set_experience_id(i._id); set_experience_edit(true); setShowExperienceForm(false)}} className="font-size-3 text-gray">Edit</a>
                             <a href="" onClick={e => {e.preventDefault(); set_experience_id(i._id); set_experience_delete(true)}} className="font-size-3 text-red">Delete</a>
                          </div>
                        </div>
                      </div>
                      
                    </div>)})}
           
                    {!showExperienceForm ? 
                                <button 
                                className="
                                mb-0
                                font-size-4
                                btn btn-outline-gray
                                min-width-px-210
                                rounded-pill
                                mb-5
                                mt-5
                              " onClick={(e) => {e.preventDefault();
                               setShowExperienceForm(true); set_experience_edit(false)}}>Add New Experience</button>
                                : <button 
                                className="
                                mb-0
                                font-size-4
                                btn btn-outline-gray
                                min-width-px-210
                                rounded-pill
                                mb-5
                                mt-5
                              " onClick={(e) => {e.preventDefault(); setShowExperienceForm(false); set_experience_edit(false)}}>Close Form</button> }
                              {experience_edit ? 
                                <button 
                                className="
                                mb-0
                                font-size-4
                                btn btn-outline-gray
                                min-width-px-210
                                rounded-pill
                                mb-5
                                mt-5
                              " onClick={(e) => {e.preventDefault(); set_experience_edit(false)}}>Close Edit</button>
                                : null }
                         {showExperienceForm ? <div className="row mb-xl-1 mb-9">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label
                              className="
                                d-block
                                text-black-2
                                font-size-4 font-weight-semibold
                                mb-4
                              "
                            >
                             Company Name</label>
                            <input
                              onChange={e =>  {set_company_name(e.target.value)}}
                              type="text"
                              className="form-control h-px-48"
                              id="namedash"
                              placeholder="eg. Google"
                            />
                          </div>
                        </div>
                              <div className="col-lg-6">
                          <div className="form-group">
                            <label
                              className="
                                d-block
                                text-black-2
                                font-size-4 font-weight-semibold
                                mb-4
                              "
                            >
                             Your Role</label>
                            <input
                               onChange={e => {set_company_role(e.target.value)}}
                              type="text"
                              className="form-control h-px-48"
                              id="namedash"
                              placeholder="eg. Product Manager"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label
                              className="
                                d-block
                                text-black-2
                                font-size-4 font-weight-semibold
                                mb-4
                              "
                            >
                              Start Date</label>
                            <input
                              onChange={e => set_company_start_date(new Date(e.target.value))}
                              type="date"
                              contentEditable={true}
                              className="form-control h-px-48"
                              id="email"
                              placeholder="eg. Johnson@gmail.com"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label
                              className="
                                d-block
                                text-black-2
                                font-size-4 font-weight-semibold
                                mb-4
                              "
                            >
                              End Date</label>
                            <input
                               onChange={e => set_company_end_date(new Date(e.target.value))}
                              type="date"
                              className="form-control h-px-48"
                              id="headline"
                              placeholder="eg. Product Designer"
                            />
                          </div>
                        </div>
                        <button
                            onClick={e => {create_experience(e)}}
                            type="button"
                            className="
                              btn btn-green btn-h-60
                              text-white
                              min-width-px-210
                              rounded-5
                              text-uppercase
                              mb-6
                            "
                          >Add Experience</button>
                      </div> : null} 
                       {experience_edit ? 

                        <div className="row mb-xl-1 mb-9">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label
                              className="
                                d-block
                                text-black-2
                                font-size-4 font-weight-semibold
                                mb-4
                              "
                            >
                             Company Name</label>
                            <input
                              onChange={e =>  {set_company_name(e.target.value)}}
                              value={company_name}
                              type="text"
                              className="form-control h-px-48"
                              id="namedash"
                              placeholder="eg. Google"
                            />
                          </div>
                        </div>
                              <div className="col-lg-6">
                          <div className="form-group">
                            <label
                              className="
                                d-block
                                text-black-2
                                font-size-4 font-weight-semibold
                                mb-4
                              "
                            >
                             Your Role</label>
                            <input
                               onChange={e => {set_company_role(e.target.value)}}
                               value={company_role}
                              type="text"
                              className="form-control h-px-48"
                              id="namedash"
                              placeholder="eg. Product Manager"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label
                              className="
                                d-block
                                text-black-2
                                font-size-4 font-weight-semibold
                                mb-4
                              "
                            >
                              Start Date</label>
                            <input
                              onChange={e => set_company_start_date(new Date(e.target.value))}
                             // value={company_start_date}
                              type="date"
                              contentEditable={true}
                              className="form-control h-px-48"
                              id="email"
                              placeholder="eg. Johnson@gmail.com"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label
                              className="
                                d-block
                                text-black-2
                                font-size-4 font-weight-semibold
                                mb-4
                              "
                            >
                              End Date</label>
                            <input
                               onChange={e => set_company_end_date(new Date(e.target.value))}
                              // value={new Date(company_end_date)}
                              type="date"
                              className="form-control h-px-48"
                              id="headline"
                              placeholder="eg. Product Designer"
                            />
                          </div>
                        </div>
                        <button
                            onClick={e => {update_experience(e)}}
                            type="button"
                            className="
                              btn btn-green btn-h-60
                              text-white
                              min-width-px-210
                              rounded-5
                              text-uppercase
                              mb-6
                            "
                          >Update Experience</button>
                      </div> : null

                              }</div>
                  <div className="border-top p-5 pt-7 pb-5 w-100">
                    <h4 className="font-size-6 mb-7 mt-5 text-black-2 font-weight-semibold">Education</h4>

                      {education_list.map(i => { return(<div className="w-100">
                      <div className="d-flex align-items-center pr-11 mb-9 flex-wrap flex-sm-nowrap">
                        <div className="square-72 d-block mr-8 mb-7 mb-sm-0">
                          <i className="fas fa-user-graduate font-size-11"></i>
                        </div>
                        <div className="w-100 mt-n2">
                          <h3 className="mb-0">
                            <div className="font-size-6 text-black-2" >{i.education_degree} in {i.education_course}</div>
                          </h3>
                          <div className="font-size-4 text-default-color line-height-2">{i.education_school}</div>
                          <div className="d-flex align-items-center justify-content-md-between flex-wrap">
                           <div className="font-size-4 text-gray mr-5">
                              {moment(new Date(i.education_start_day)).format("MMMM YYYY")} - {moment(new Date(i.education_end_day)).format("MMMM YYYY")} 
                              { moment(i.education_start_day).diff(moment(i.education_end_day),'years') !== 0 ?  moment(i.education_start_day).diff(moment(i.education_end_day),'years')+' '+'years' :
                               moment(i.education_start_day).diff(moment(i.education_end_day), 'months') !== 0 ?  moment(i.education_start_day).diff(moment(i.education_end_day), 'months')+' '+'months' :
                              moment(i.education_start_day).diff(moment(i.education_end_day), 'days')+' '+'days'}</div>
                            
                            <a href="" onClick={e => {e.preventDefault(); set_education_course(i.education_course); set_education_school(i.education_school);
                               set_education_degree(i.education_degree); set_education_country(i.education_country); set_education_id(i._id); setShowEducation(false); set_education_edit(true)}} className="font-size-3 text-gray">Edit</a>
                            <a href="" onClick={e => {e.preventDefault(); set_education_id(i._id); set_education_delete(true)}} className="font-size-3 text-red">Delete</a>
                          </div>
                        </div>
                      </div>
                    </div>)})}
                 
                     {!showEducationForm ? 
                                <button 
                                className="
                                mb-0
                                font-size-4
                                btn btn-outline-gray
                                min-width-px-210
                                rounded-pill
                                mb-5
                                mt-5
                              " onClick={(e) => {e.preventDefault();
                               setShowEducation(true); set_experience_edit(false)}}>Add New Education</button>
                                : <button 
                                className="
                                mb-0
                                font-size-4
                                btn btn-outline-gray
                                min-width-px-210
                                rounded-pill
                                mb-5
                                mt-5
                              " onClick={(e) => {e.preventDefault(); setShowEducation(false); set_education_edit(false)}}>Close Form</button> }
                        {education_edit ? 
                                <button 
                                className="
                                mb-0
                                font-size-4
                                btn btn-outline-gray
                                min-width-px-210
                                rounded-pill
                                mb-5
                                mt-5
                              " onClick={(e) => {e.preventDefault(); set_education_edit(false)}}>Close Edit</button>
                                : null }
                      {showEducationForm ? <div className="row mb-xl-1 mb-9">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label
                              className="
                                d-block
                                text-black-2
                                font-size-4 font-weight-semibold
                                mb-4
                              "
                            >
                             Institution Name</label>
                            <input
                              onChange={e =>  {set_education_school(e.currentTarget.value)}}
                              type="text"
                              className="form-control h-px-48"
                              id="namedash"
                              placeholder="eg. Oxford"
                            />
                          </div>
                        </div>
                              <div className="col-lg-6">
                          <div className="form-group">
                            <label
                              className="
                                d-block
                                text-black-2
                                font-size-4 font-weight-semibold
                                mb-4
                              "
                            >
                             Course of Study</label>
                            <input
                               onChange={e => {set_education_course(e.target.value)}}
                              type="text"
                              className="form-control h-px-48"
                              id="namedash"
                              placeholder="eg. Computer Science"
                            />
                          </div>
                        </div>
                             <div className="col-lg-6">
                          <div className="form-group">
                            <label
                              className="
                                d-block
                                text-black-2
                                font-size-4 font-weight-semibold
                                mb-4
                              "
                            >
                             Degree</label>
                            <input
                               onChange={e => {set_education_degree(e.target.value)}}
                              type="text"
                              className="form-control h-px-48"
                              id="namedash"
                              placeholder="eg. Computer Science"
                            />
                          </div>
                        </div>
                             <div className="col-lg-6">
                          <div className="form-group">
                            <label
                              className="
                                d-block
                                text-black-2
                                font-size-4 font-weight-semibold
                                mb-4
                              "
                            >
                             Country</label>
                            <input
                               onChange={e => {set_education_country(e.target.value)}}
                              type="text"
                              className="form-control h-px-48"
                              id="namedash"
                              placeholder="eg. Computer Science"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label
                              className="
                                d-block
                                text-black-2
                                font-size-4 font-weight-semibold
                                mb-4
                              "
                            > Start Date</label>
                            <input
                               onChange={e => set_education_start_day(new Date(e.target.value))}
                              type="date"
                              contentEditable={true}
                              className="form-control h-px-48"
                              id="email"
                              placeholder="eg. Johnson@gmail.com"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label
                              className="
                                d-block
                                text-black-2
                                font-size-4 font-weight-semibold
                                mb-4
                              "
                            >
                              End Date</label>
                            <input
                               onChange={e => set_education_end_day(new Date(e.target.value))}
                              type="date"
                              className="form-control h-px-48"
                              id="headline"
                              placeholder="eg. Product Designer"
                            />
                          </div>
                        </div>
                        <button
                            onClick={e => {create_education(e)}}
                            type="button"
                            className="
                              btn btn-green btn-h-60
                              text-white
                              min-width-px-210
                              rounded-5
                              text-uppercase
                              mb-6
                            "
                          >Add Institution</button>
                      </div> : null}

                      {education_edit ? <div className="row mb-xl-1 mb-9">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label
                              className="
                                d-block
                                text-black-2
                                font-size-4 font-weight-semibold
                                mb-4
                              "
                            >
                             Institution Name</label>
                            <input
                              onChange={e =>  {set_education_school(e.currentTarget.value)}}
                              type="text"
                              className="form-control h-px-48"
                              id="namedash"
                              placeholder="eg. Oxford"
                              value={education_school}
                            />
                          </div>
                        </div>
                              <div className="col-lg-6">
                          <div className="form-group">
                            <label
                              className="
                                d-block
                                text-black-2
                                font-size-4 font-weight-semibold
                                mb-4
                              "
                            >
                             Course of Study</label>
                            <input
                               onChange={e => {set_education_course(e.target.value)}}
                              type="text"
                              className="form-control h-px-48"
                              id="namedash"
                              placeholder="eg. Computer Science"
                              value={education_course}
                            />
                          </div>
                        </div>
                             <div className="col-lg-6">
                          <div className="form-group">
                            <label
                              className="
                                d-block
                                text-black-2
                                font-size-4 font-weight-semibold
                                mb-4
                              "
                            >
                             Degree</label>
                            <input
                               onChange={e => {set_education_degree(e.target.value)}}
                              type="text"
                              className="form-control h-px-48"
                              id="namedash"
                              placeholder="eg. Computer Science"
                              value={education_degree}
                            />
                          </div>
                        </div>
                             <div className="col-lg-6">
                          <div className="form-group">
                            <label
                              className="
                                d-block
                                text-black-2
                                font-size-4 font-weight-semibold
                                mb-4
                              "
                            >
                             Country</label>
                            <input
                               onChange={e => {set_education_country(e.target.value)}}
                              type="text"
                              className="form-control h-px-48"
                              id="namedash"
                              placeholder="eg. Computer Science"
                              value={education_country}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label
                              className="
                                d-block
                                text-black-2
                                font-size-4 font-weight-semibold
                                mb-4
                              "
                            > Start Date</label>
                            <input
                               onChange={e => set_education_start_day(new Date(e.target.value))}
                              type="date"
                              contentEditable={true}
                              className="form-control h-px-48"
                              id="email"
                              placeholder="eg. Johnson@gmail.com"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label
                              className="
                                d-block
                                text-black-2
                                font-size-4 font-weight-semibold
                                mb-4
                              "
                            >
                              End Date</label>
                            <input
                               onChange={e => set_education_end_day(new Date(e.target.value))}
                              type="date"
                              className="form-control h-px-48"
                              id="headline"
                              placeholder="eg. Product Designer"
                            />
                          </div>
                        </div>
                        <button
                            onClick={e => {update_education(e)}}
                            type="button"
                            className="
                              btn btn-green btn-h-60
                              text-white
                              min-width-px-210
                              rounded-5
                              text-uppercase
                              mb-6
                            "
                          >Update Education</button>
                      </div> : null}

                  
                    {/* <!-- Single Card End --> */}
                  </div>
                  {/* <!-- Card Section End --> */}
                  </div>
                  
                  <div
                  className="
                    contact-form
                    bg-white
                    shadow-8
                    rounded-4
                    pl-sm-10 pl-4
                    pr-sm-11 pr-4
                    pt-15
                    pb-13
                    mt-15
                  "
                >
                  <h5 className="font-size-6 font-weight-semibold mb-11">
                Social Profile
              </h5>
              <form action="/">
                    <fieldset>
                      <div className="row mb-xl-1 mb-9">
                        <div className="col-lg-6">
                            
                          <div className="form-group">
                            <label
                              for="linkedin"
                              className="
                                d-block
                                text-black-2
                                font-size-4 font-weight-semibold
                                mb-4
                              "
                            >
                              Linkedin</label>
                            <input
                              value={linkedIn}
                              onChange={e => {setLinkedIn(e.target.value)}}
                              type="text"
                              className="form-control h-px-48"
                              id="linkedin"
                              placeholder="www.linkedin.com/john"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label
                              for="twitter"
                              className="
                                d-block
                                text-black-2
                                font-size-4 font-weight-semibold
                                mb-4
                              "
                            >
                              Twitter</label
                            >
                            <input
                              value={twitter}
                              onChange={e => {setTwitter(e.target.value)}}
                              type="text"
                              className="form-control h-px-48"
                              id="twitter"
                              placeholder="www.twitter.com/john"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label
                              for="instagram"
                              className="
                                d-block
                                text-black-2
                                font-size-4 font-weight-semibold
                                mb-4
                              "
                            >
                              Instagram</label
                            >
                            <input
                              value={instagram}
                              onChange={e => {setInstagram(e.target.value)}}
                              type="text"
                              className="form-control h-px-48"
                              id="instagram"
                              placeholder="www.instagram.com/john"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label
                              for="facebook"
                              className="
                                d-block
                                text-black-2
                                font-size-4 font-weight-semibold
                                mb-4
                              "
                            >
                              Facebook</label
                            >
                            <input
                              value={faceBook}
                              onChange={e => {setFaceBook(e.target.value)}}
                              type="email"
                              className="form-control h-px-48"
                              id="facebook"
                              placeholder="www.facebook.com/john"
                            />
                          </div>
                        </div>
                        <button
                        onClick={e => {createSocialProfile(e)}}
                            type="button"
                            className="
                              btn btn-green btn-h-60
                              text-white
                              min-width-px-210
                              rounded-5
                              text-uppercase
                            "
                          >Save Changes</button>
                        </div>
                        </fieldset>
                        </form>
              </div>
                        </div>
                      </div>
                    </fieldset>
                  </form>
                  <DeleteExperienceModal
                    show={show_experience_delete}
                    onHide={() =>{set_experience_delete(false); set_experience_id("")}}
                  />
                  <DeleteEducationModal
                    show={show_education_delete}
                    onHide={() =>{set_education_delete(false); set_education_id("")}}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        </div>
    )
}