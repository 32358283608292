import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import  { useAuths }  from './components/AuthContext';
import LoadingOverlay from 'react-loading-overlay';


export default function PrivateRoute({component: Component, ...rest}){
    const {isLoggedIn, currentUser, state} = useAuths()
return(
 
    <Route
    {...rest}
    render={props => {
       return !isLoggedIn ? <Redirect to="login"/> : !currentUser.emailVerified ? <Redirect to="/email-not-verified"/> : 
       <LoadingOverlay
            active={state.loading}
            spinner
            text='Loading...'
            ><Component {...props} /> 
            </LoadingOverlay> 
    }}>
        
    </Route>
)
}