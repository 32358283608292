import React, {useEffect} from "react";
import profile from "../image/profile/profile-img.png"
import Navbar from '../shared-components/navbar'
import SideBar from '../shared-components/sidebar'
import {Link } from 'react-router-dom'
import axios from 'axios'
import {Button, Modal} from 'react-bootstrap';
import moment from 'moment'
import { Helmet } from 'react-helmet'
import { useAuths } from '../AuthContext';
import { toast, Slide } from 'react-toastify'
import Paginating from "../pagination"
import 'react-toastify/dist/ReactToastify.css';
const url = process.env.REACT_APP_SERVER_URL



toast.configure()

export default function Candidatelist() {
  const [candidates, set_candidates] = React.useState([])
  const [user_id, set_user_id] = React.useState("")
  const {isLoading} = useAuths()
  const [deleteModal, setDeleteModal] = React.useState(false) 
  const [disableModal, setDisableModal] = React.useState(false)
  const [enableModal, setEnableModal] = React.useState(false)
  const [currentPage, setCurrentPage] = React.useState(1)
  const [candidatePerPage, setcandidatePerPage] = React.useState(5)
  const [limit, set_limit] = React.useState(5)


  const indexOfLastPost = currentPage * candidatePerPage;
  const indexOfFirstPage = indexOfLastPost - candidatePerPage;
  const currentCandidate = candidates.slice(indexOfFirstPage, indexOfLastPost);


  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber)
  }
  const previousPage = () => {
    if(currentPage - 1 > 0){
      setCurrentPage(currentPage - 1)
    }
  }
  const nextPage = (dataNumber) => {
    if(currentPage + 1 <= dataNumber){
      setCurrentPage(currentPage + 1)
    }
  }


  const enable_success = () => {
    toast.success('User Enabled Successfully', {position: toast.POSITION.TOP_RIGHT, theme: "colored", transition: Slide,  hideProgressBar: true})
  }

  const enable_error = () => {
      toast.error('Could Not Enable User, Please try again', {position: toast.POSITION.TOP_RIGHT, theme: "colored", transition: Slide,  hideProgressBar: true})
  }

  const disable_success = () => {
    toast.success('User Disabled Successfully', {position: toast.POSITION.TOP_RIGHT, theme: "colored", transition: Slide,  hideProgressBar: true})
  }

  const disable_error = () => {
      toast.error('Could Not Disable User, Please try again', {position: toast.POSITION.TOP_RIGHT, theme: "colored", transition: Slide,  hideProgressBar: true})
  }

  const delete_success = () => {
    toast.success('User Deleted Successfully', {position: toast.POSITION.TOP_RIGHT, theme: "colored", transition: Slide,  hideProgressBar: true})
  }

  const delete_error = () => {
      toast.error('Could Not Delete User, Please try again', {position: toast.POSITION.TOP_RIGHT, theme: "colored", transition: Slide,  hideProgressBar: true})
  }

  const get_candidates = (limit) => {
    isLoading(true)
    const token = localStorage.getItem('token')
      axios.get(`${url}getallcandidate/100`, {
      headers: {
        'Authorization': `Bearer ${token}`
      },

    }).then(res => {
        set_candidates(res.data)
        isLoading(false)
      }).catch(err => {
        isLoading(false)
      })
  }


      function DisableUserModal(props) {
  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      bg="transparent"
    >
      <Modal.Body style={{backgroundColor: "#f2edf3"}}>
          <div className="text-center m-3 h6">Are you sure you want to disable user?.</div>
        <div className="row justify-content-space-between m-3">
    <Button block onClick={(e) => {disableUser(e)}} className="btn btn-danger">Confirm</Button>
    <Button block onClick={() => setDisableModal(false)} className="btn btn-primary">Cancel</Button>
    </div>
      </Modal.Body>
    </Modal>
  );
}

const disableUser = (e) => {
  isLoading(true)
  const token = localStorage.getItem('token')
  e.preventDefault();
  axios.put(`${url}disable-user/${user_id}`,{}, {
    headers: {
        'Authorization': `Bearer ${token}`
      }
  }).then(res => {
    get_candidates(candidates.length)
    disable_success()
    setDisableModal(false)
    isLoading(false)
  }).catch(err => {
    disable_error()
    isLoading(false)
  })
}

 function EnableUserModal(props) {
  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      bg="transparent"
    >
      <Modal.Body style={{backgroundColor: "#f2edf3"}}>
          <div className="text-center m-3 h6">Are you sure you want to enable user?.</div>
        <div className="row justify-content-space-between m-3">
    <Button block onClick={(e) => {enableUser(e)}} className="btn btn-danger">Confirm</Button>
    <Button block onClick={() => setEnableModal(false)} className="btn btn-primary">Cancel</Button>
    </div>
      </Modal.Body>
    </Modal>
  );
}

const enableUser = (e) => {
  e.preventDefault();
  isLoading(true)
  const token = localStorage.getItem('token')
  axios.put(`${url}enable-user/${user_id}`,{}, {
    headers: {
        'Authorization': `Bearer ${token}`
      }
  }).then(res => {
    get_candidates(candidates.length)
    enable_success()
    setEnableModal(false)
    isLoading(false)
  }).catch(err => {
    enable_error()
    isLoading(false)
  })
}

    function DeleteUserModal(props) {
  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      bg="transparent"
    >
      <Modal.Body style={{backgroundColor: "#f2edf3"}}>
          <div className="text-center m-3 h6">Are you sure you want to delete User?. <br/>Note: This action is not reversable </div>
        <div className="row justify-content-space-between m-3">
    <Button block onClick={(e) => {deleteUser(e)}} className="btn btn-danger">Confirm</Button>
    <Button block onClick={() => setDeleteModal(false)} className="btn btn-primary">Cancel</Button>
    </div>
      </Modal.Body>
    </Modal>
  );
}

const deleteUser = (e) => {
  e.preventDefault();
  isLoading(true)
  const token = localStorage.getItem('token')
  axios.delete(`${url}deleteusertemporary/${user_id}`,{
    headers: {
        'Authorization': `Bearer ${token}`
      }
  }).then(data => {
    get_candidates(candidates.length+1)
    delete_success()
    setDeleteModal(false)
    isLoading(true)
  }).catch(err => {
    delete_error()
    isLoading(true)
  })
}


  useEffect(() => {
    let mounted = true 
    if(mounted){
      get_candidates(5)
    }

    return () => {
      mounted = false
    }
  },[])
    return(
        <div>
          <Helmet>
            <title> Candidate List - Job Board | StriveHR </title>
            </Helmet>
        <Navbar/>
            <SideBar/>
            <div className="dashboard-main-container mt-25 mt-lg-31" id="dashboard-body">
        <div className="container">
        <div className="mb-14">
          <div className="bg-white shadow-8 pt-7 rounded pb-8 px-11">
          <div className="row mb-11 align-items-center">
            <div className="col-lg-6 mb-lg-0 mb-4">
              <h3 className="font-size-6 mb-0">Registered Candidates</h3>
            </div>
            <div className="col-lg-6">
              <div className="d-flex flex-wrap align-items-center justify-content-lg-end">
                    {/*<a href="" className="btn btn-green">+ Create Candidate</a>*/}
              </div>
            </div>
          </div>
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th scope="col" className="border-0 font-size-4 font-weight-normal">Name</th>
                    <th scope="col" className="border-0 font-size-4 font-weight-normal">Headline</th>
                    <th scope="col" className="border-0 font-size-4 font-weight-normal">Registered On</th>
                    <th scope="col" className="border-0 font-size-4 font-weight-normal">Action</th>
                    <th scope="col" className="border-0 font-size-4 font-weight-normal"></th>
                    <th scope="col" className="border-0 font-size-4 font-weight-normal"></th>
                  </tr>
                </thead>
                <tbody>
                  {currentCandidate.map(i => (!i.isDelete ? <tr className="border border-color-2">
                    <th scope="row" className="pl-6 border-0 py-7 pr-0">
                      <div className="media min-width-px-140 align-items-center">
                        <div className="circle-36 mr-6">
                          {i.image ? <a href={i.image}> <img src={i.image} alt="" className="w-100 circle-36" /> </a>:  <img src={profile} alt="" className="w-100 circle-36" />}
                        </div>
                        <h4 className="font-size-4 mb-0 font-weight-semibold text-black-2">{i.first_name+' '+i.last_name}</h4>
                      </div>
                    </th>
                    <td className="table-y-middle py-7 min-width-px-140 pr-0">
                      <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">{i.headline? i.headline : "Not yet available"}</h3>
                    </td>
                    <td className="table-y-middle py-7 min-width-px-140 pr-0">
                      <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">{moment(new Date(i.createdAt)).format("MMMM Do YYYY")}</h3>
                    </td>
                    <td className="table-y-middle py-7 min-width-px-80 pr-0">
                      <div className=""><Link to={{pathname: `/candidate-profile/${i.userId}`}} className="font-size-3 font-weight-bold text-blue text-uppercase">View</Link></div>
                    </td>
                    <td className="table-y-middle py-7 min-width-px-80 pr-0">
                      <div className="">{i.isDisable ? <a onClick={e => {e.preventDefault(); set_user_id(i._id); setEnableModal(true)}} href="contact.html" className="font-size-3 font-weight-bold text-green text-uppercase">Enable</a> : 
                                <a onClick={e => {e.preventDefault(); set_user_id(i._id); setDisableModal(true)}} href="contact.html" className="font-size-3 font-weight-bold text-green text-uppercase">Disable</a>}</div>
                    </td>
                    <td className="table-y-middle py-7 min-width-px-90 pr-0">
                      <div className=""><a onClick={e => {e.preventDefault(); set_user_id(i._id); setDeleteModal(true)}} href="" className="font-size-3 font-weight-bold text-red-2 text-uppercase">Delete</a></div>
                    </td>
                  </tr> : null))}
                </tbody>
              </table>
            </div>
            <DeleteUserModal
              show={deleteModal}
              onHide={() =>{setDeleteModal(false); set_user_id("")}}
            />
            <DisableUserModal
              show={disableModal}
              onHide={() =>{setDisableModal(false); set_user_id("")}}
            />
            <EnableUserModal
              show={enableModal}
              onHide={() =>{setEnableModal(false); set_user_id("")}}
            />
                <div className="">
                 <Paginating dataPerPage={candidatePerPage} totalData={candidates.length} paginate={paginate} currentPage={currentPage} nextPage={nextPage} previousPage={previousPage} />
              
            </div>
            
          </div>
        </div>
      </div></div></div>
    )
}