import axios from "axios"
import { ACTIONS, typed_plans } from "./components/AuthContext"
const url = process.env.REACT_APP_SERVER_URL


export  async function employer_payments (dispatch, isLoading, currentUser) {
    try {
        const token = localStorage.getItem('token')
        isLoading(true)
        const res = await axios.get(`${url}get-employer-payment`,{ headers: {'Authorization': `Bearer ${token}`}})
        dispatch({type: ACTIONS.SET_PAYMENT_HISTORY, payload: res.data})
       if(currentUser.plan){
       const user_plan = typed_plans.find(i => {return i.id === currentUser.plan})
       dispatch({type: ACTIONS.SET_CURRENT_PLAN, payload: user_plan})} 
       isLoading(false)

    } catch (error) {
        isLoading(false)
    }
    
}

    
export const handleCreateJobSubmit = async (state, isLoading, notify, currentUser, create_job_default) => {
    const token = localStorage.getItem('token')
    const {job_role, job_category, job_description, selected_skills, job_salary, job_type, job_experience_level, job_country, job_state, job_closing_date, job_anonymous, question, employer} = state

     //const {job_type, payment, skills, category, experience_level, country, state, jobDescription, close_date, jobrole} = location.state
    if(!job_role || !job_category || !job_description || !selected_skills.length || !job_salary || !job_type || !job_experience_level || !job_country || !job_state || !job_closing_date){
      notify("inform", "Please input all fields")
    }else if(currentUser.isAdmin && !employer._id){
      notify("inform", "Please select an employer")
    }else if(currentUser.isEmployer && !currentUser.company_name || currentUser.isEmployer && !currentUser.country || currentUser.isEmployer && !currentUser.industry){
      notify("inform", "Please fill in your company information")
    }else{
       isLoading(true)
       await axios.post(`${url}create-job`, {
       type: job_type._id,
       payment: job_salary,
       skill: selected_skills.map(i => (i._id)),
       category: job_category._id,
       career_level: job_experience_level._id,
       country: job_country,
       state: job_state,
       anonymous: job_anonymous,
       jobDescription: job_description,
       close_date: job_closing_date,
       employer: employer._id,
       jobrole: job_role,
       question
     }, { headers: {
                'Authorization': `Bearer ${token}`
            }}).then(data => {
       if(data){
        notify("success", "Job created successfully")
        create_job_default()
        isLoading(false)
       }
     }).catch(err => {
       if(err){
       if(err.response.data.message === "Inactive subscription"){
        notify("error", "In active subscription")
       }else{
        notify("error", "Could not create job")
       }}else{
        notify("error", "Could not connect to server")
       }
       isLoading(false)
     })}
   }

   export const getOnejob = async (id, notify, isLoading) => {
    try {
     const token = localStorage.getItem("token")
     const res = await axios.get(`${url}get-one-job?id=${id}`, {headers: {"Authorization": `Bearer ${token}`}})
     return res.data
    } catch (error) {
        isLoading(false)
     notify("error", "Could not get job")
    }
   }

   export const handleUpdateJob = async (state, isLoading, notify, currentUser, create_job_default) => {
    const token = localStorage.getItem('token')
    const {job_role, job_category, job_description, selected_skills, job_salary, job_type, job_experience_level, job_country, job_state, job_closing_date, job_anonymous, question} = state
     //const {job_type, payment, skills, category, experience_level, country, state, jobDescription, close_date, jobrole} = location.state
    if(!job_role || !job_category || !job_description || !selected_skills.length || !job_salary || !job_type || !job_experience_level || !job_country || !job_state || !job_closing_date){
      notify("inform", "Please input all fields")
    }else if(currentUser.isEmployer && !currentUser.company_name || currentUser.isEmployer && !currentUser.country || currentUser.isEmployer && !currentUser.industry){
      notify("inform", "Please fill in your company information")
    }else{
       isLoading(true)
       await axios.put(`${url}update-job/${state.job_id}`, {
       type: job_type._id,
       payment: job_salary,
       skill: selected_skills.map(i => (i._id)),
       category: job_category._id,
       career_level: job_experience_level._id,
       country: job_country,
       state: job_state,
       anonymous: job_anonymous,
       jobDescription: job_description,
       close_date: job_closing_date,
       jobrole: job_role,
       question
     }, { headers: {
                'Authorization': `Bearer ${token}`
            }}).then(data => {
       if(data){
        notify("success", "Job updated successfully")
        isLoading(false)
       }
     }).catch(err => {
       if(err){
       if(err.response.data.message === "Inactive subscription"){
        notify("error", "In active subscription")
       }else{
        notify("error", "Could not update job")
       }}else{
        notify("error", "Could not connect to server")
       }
       isLoading(false)
     })}
   }

   export const updateQuestion = async (notify, state, isLoading) => {
    try {
        if(!state.question_id) return
        const token = localStorage.getItem("token")
        const getOptions = state.form.map(i => {return i.text})
        const filterAnswer = state.form.filter(i => {return i.isAnswer})
        const getAnswer = filterAnswer.map(i => {return i.text})
        const answer = getAnswer[0]
        const options = getOptions
        const res = await axios.put(`${url}update-question/${state.question_id}`, {question: state.questions, answer, options}, {headers: {"Authorization": `Bearer ${token}`}})
        if(res){
            return state.job_url_id
            //getOnejob(state.job_url_id, notify, isLoading)
        }
       } catch (error) {
        notify("error", "Could not update question")
       } 
   }
   export const deleteQuestion = async (notify, state, id) => {
    try {
      const token = localStorage.getItem("token")
        const res = await axios.delete(`${url}delete-question/${id}`, {headers: {"Authorization": `Bearer ${token}`}})
        if(res){
            return state.job_url_id
            //getOnejob(state.job_url_id, notify, isLoading)
        }
       } catch (error) {
        notify("error", "Could not delete question")
       } 
   }