import React from 'react';
import {Pie, Doughnut} from 'react-chartjs-2';
import axios from 'axios'
const url = process.env.REACT_APP_SERVER_URL

/*const state = */

export default class Piechart extends React.Component {
    constructor(props){
    super(props)
         this.state = {
            bar_chart: {}
         }
    }

componentDidMount() {
axios.get(`${url}get-company-bar-chart-data`).then(res => {
const get_label = res.data.map(i => {
    return i.name
})
const get_percentage = res.data.map(i => {
    return i.percentage
})
const data = {
  labels: ['Free', 'Basic', 'Premium'],
  datasets: [
    {
      label: 'Top Packages',
      backgroundColor: [
        'rgba(99, 102, 241)',
        'rgba(96, 165, 250)',
        'rgba(55, 48, 163)',
      ],
      hoverBackgroundColor: [
      'rgba(79, 70, 229)',
      'rgba(59, 130, 246)',
      'rgba(49, 46, 129)',
      ],
      data: [65, 20, 15]
    }
  ]
}
this.setState({bar_chart: data})
})}

  render() {
    return (
      <div>
         
        <Doughnut
          data={this.state.bar_chart}
          options={{
            title:{
              display:true,
              text:'Top packages',
              fontSize:20
            },
            legend:{
              display:false,
              position:'right'
            }
          }}
        />
        
      </div>
    );
  }
}