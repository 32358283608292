import React, { useEffect } from 'react'
import Navbar from '../shared-components/navbar'
import Sidebar from '../shared-components/sidebar'
import ReactLoading from "react-loading";
import ocolus from '../image/c-home/oculus-logo.png'
import { Helmet } from 'react-helmet'
import google from '../image/c-home/google-logo.png'

export default function Bookmarks () {
  const [loading, setLoading] = React.useState(true)

  useEffect(() => {
    setTimeout(() => {
  setLoading(false)
}, 2000)
  
  }, [])

  if(loading){
  return(
    <div className="center-sign-in-loading" style={{backgroundColor: "#fff", width: "100%", height: "100%", justifyContent: "center", alignItems: "center"}}>
      <ReactLoading className="center-sign-in-loading" type="bars" color="#8B3300" height={'20%'} width={'10%'} />
      </div>
  )
}
    return (
        <div>
           <Helmet>
            <title> Applied Jobs - Job Board | StriveHR </title>
            </Helmet>
        <Navbar/>
        <Sidebar/>
        <div className="dashboard-main-container mt-25 mt-lg-31" id="dashboard-body">
        {/* <!-- Messaging System --> */}
        <div className="container">
          <div className="mb-14">
            <div className="col-12 col-xl-10 col-lg-8">
              {/* <!-- Top Start --> */}
              <div className="mb-5">
                <h4 className="font-size-7 mb-9">Saved Jobs</h4>
                <div className="row justify-content-center">
                  <div className="col-lg-6 col-md-6 col-sm-11 mb-9">
                    {/* <!-- Single Featured Job --> */}
                    <div
                      className="
                        pt-9
                        px-xl-9 px-lg-7 px-7
                        pb-7
                        light-mode-texts
                        bg-white
                        rounded
                        hover-shadow-3
                      "
                    >
                      <div className="media align-items-center">
                        <div className="pr-5">
                          <a href="#"><img src={ocolus} alt=""/></a>
                        </div>
                        <div>
                          <a href="#" className="font-size-3 text-default-color line-height-2">Oculus</a>
                          <h3 className="font-size-6 mb-0">
                            <a className="heading-default-color font-weight-semibold" href="#">Senior Marketing Expert</a>
                          </h3>
                        </div>
                      </div>
                      <div className="d-flex pt-17">
                        <ul className="list-unstyled mb-1 d-flex flex-wrap">
                          <li>
                            <a
                              href="#"
                              className="
                                bg-regent-opacity-15
                                text-denim
                                font-size-3
                                rounded-3
                                min-width-px-100
                                px-3
                                flex-all-center
                                mr-6
                                h-px-33
                                mt-4
                              "
                            >
                              <i
                                className="icon icon-pin-3 mr-2 font-weight-bold"
                              ></i>
                              London
                            </a>
                          </li>
                          <li>
                            <a
                              href="#"
                              className="
                                bg-regent-opacity-15
                                text-orange
                                font-size-3
                                rounded-3
                                min-width-px-100
                                px-3
                                flex-all-center
                                mr-6
                                h-px-33
                                mt-4
                              "
                            >
                              <i
                                className="fa fa-briefcase mr-2 font-weight-bold"
                              ></i>
                              Full-time
                            </a>
                          </li>
                        </ul>
                        <a
                          href="javascript:"
                          className="
                            bookmark-button
                            toggle-item
                            font-size-6
                            ml-auto
                            line-height-reset
                            px-0
                            mt-6
                            text-default-color
                            clicked
                          "
                        >
                        </a>
                      </div>
                    </div>
                    {/* <!-- End Single Featured Job --> */}
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-11 mb-9">
                    {/* <!-- Single Featured Job --> */}
                    <div
                      className="
                        pt-9
                        px-xl-9 px-lg-7 px-7
                        pb-7
                        light-mode-texts
                        bg-white
                        rounded
                        hover-shadow-3
                      "
                    >
                      <div className="media align-items-center">
                        <div className="pr-5">
                          <a href="#"><img src={google} alt=""/></a>
                        </div>
                        <div>
                          <a href="#" className="font-size-3 text-default-color line-height-2">Shark</a>
                          <h3 className="font-size-6 mb-0">
                            <a
                              className="heading-default-color font-weight-semibold"
                              href="#"
                              >3D Artist</a
                            >
                          </h3>
                        </div>
                      </div>
                      <div className="d-flex pt-17">
                        <ul className="list-unstyled mb-1 d-flex flex-wrap">
                          <li>
                            <a
                              href="#"
                              className="
                                bg-regent-opacity-15
                                text-denim
                                font-size-3
                                rounded-3
                                min-width-px-100
                                px-3
                                flex-all-center
                                mr-6
                                h-px-33
                                mt-4
                              "
                            >
                              <i
                                className="icon icon-pin-3 mr-2 font-weight-bold"
                              ></i>
                              California
                            </a>
                          </li>
                          <li>
                            <a
                              href="#"
                              className="
                                bg-regent-opacity-15
                                text-orange
                                font-size-3
                                rounded-3
                                min-width-px-100
                                px-3
                                flex-all-center
                                mr-6
                                h-px-33
                                mt-4
                              "
                            >
                              <i
                                className="fa fa-briefcase mr-2 font-weight-bold"
                              ></i>
                              Remote
                            </a>
                          </li>
                        </ul>
                        <a
                          href="javascript:"
                          className="
                            bookmark-button
                            toggle-item
                            font-size-6
                            ml-auto
                            line-height-reset
                            px-0
                            mt-6
                            text-default-color
                          "
                        >
                        </a>
                      </div>
                    </div>
                    {/* <!-- End Single Featured Job --> */}
                  </div>
                  
                </div>
              </div>
              {/* <!-- Top End --> */}
              
            </div>
          </div>
        </div>
      </div>
        </div>
    )
}