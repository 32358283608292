import React, { useEffect } from 'react'
import Navbar from '../shared-components/navbar'
import Sidebar from '../shared-components/sidebar'
import { Spinner } from 'reactstrap';
import { Modal } from 'react-bootstrap';
import ReactLoading from "react-loading";
import { Helmet } from 'react-helmet'
import {useHistory} from 'react-router-dom'
import { toast, Slide } from 'react-toastify'
import  { useAuths }  from '../AuthContext';

import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
const url = process.env.REACT_APP_SERVER_URL

export default function Settings () {
  const [email, setEmail] = React.useState("")
  const [loading, setLoading] = React.useState(true)
  const [password, setPassword] = React.useState("")
  const {currentUser} = useAuths()
  const [newPassword, setNewPassword] = React.useState("")
  const [confirmNewPassword, setConfirmNewPassword] = React.useState("")
  const history = useHistory()

  useEffect(() => {
   setTimeout(() => {
  setLoading(false)
}, 2000)
  }, [])


const updatePassword = (e) => {
  e.preventDefault();
   const token = localStorage.getItem('token')
  if(!email || !password || !newPassword || !confirmNewPassword){
    required()
  }else if(newPassword.trim().length < 8 || confirmNewPassword.trim().length < 8){
    tooLessPasswordError()
  }else if(newPassword !== confirmNewPassword){
    notMatchPassword()
  }else{
    const id = currentUser._id
  axios.put(`${url}update-password/${id}`, {
    email,
    password,
    newPassword
  }, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },

    }).then(res => {
      success()
      history.push('/dashboard')
  }).catch(error => {
    if(error.response.status === 401){
      inCorrectPasswordError()
    }else if(error.response.status === 403){
      wrongAccEmail()
    }else if(error.response.status === 404){
      inCorrectEmailError()
    }else if(error.response.status === 400){
      samePassword()
    }else{
      serverError()
    }
  })}
}

 const success = () => {
    toast.success('Profile Updated Successfully', {position: toast.POSITION.TOP_RIGHT, theme: "colored", transition: Slide,  hideProgressBar: true})
  }
const required = () => {
   toast.error('All Fields Are Required', {position: toast.POSITION.TOP_CENTER, theme: "colored", transition: Slide,  hideProgressBar: true})
  }
 const tooLessPasswordError = () => {
   toast.error('Password Character Cannot be Less Than Eight', {position: toast.POSITION.TOP_CENTER, theme: "colored", transition: Slide,  hideProgressBar: true})
  }
  const notMatchPassword = () => {
   toast.error('Password Not Match', {position: toast.POSITION.TOP_CENTER, theme: "colored", transition: Slide,  hideProgressBar: true})
  }
  const inCorrectPasswordError = () => {
   toast.error('Password Not Correct. Please try again', {position: toast.POSITION.TOP_CENTER, theme: "colored", transition: Slide,  hideProgressBar: true})
  }
  const inCorrectEmailError = () => {
   toast.error('Incorrect Email. Please try again', {position: toast.POSITION.TOP_RIGHT, theme: "colored", transition: Slide,  hideProgressBar: true})
  }
  const serverError = () => {
   toast.error('An Error Occured. Please try again later', {position: toast.POSITION.TOP_RIGHT, theme: "colored", transition: Slide,  hideProgressBar: true})
  }
  const wrongAccEmail = () => {
   toast.error('The Inputted Email Does Not Belong To This Account', {position: toast.POSITION.TOP_RIGHT, theme: "colored", transition: Slide,  hideProgressBar: true})
  }
  const samePassword = () => {
   toast.error("Your New Password Can't Be Your Old Password", {position: toast.POSITION.TOP_RIGHT, theme: "colored", transition: Slide,  hideProgressBar: true})
  }


    return (
        <div>
           <Helmet>
            <title> Settings Page - Job Board | StriveHR </title>
            </Helmet>
            <Navbar/>
            <Sidebar/>  <div className="dashboard-main-container mt-24 mt-lg-31" id="dashboard-body">
        <div className="container">
          <div className="mb-15 mb-lg-23">
            <div className="row">
              <div className="col-xxxl-9 px-lg-13 px-6">
                <h3 className="mb-11">
                  My Settings
                </h3>
                
                <div
                  className="
                    contact-form
                    bg-white
                    shadow-8
                    rounded-4
                    pl-sm-10 pl-4
                    pr-sm-11 pr-4
                    pt-15
                    pb-13
                    mt-15
                  "
                >
                  <h5 className="font-size-6 font-weight-semibold mb-11">
                Password &#38; Security
              </h5>
              <form action="/">
                    <fieldset>
                      <div className="row mb-xl-1 mb-9">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label
                              for="email"
                              className="
                                d-block
                                text-black-2
                                font-size-4 font-weight-semibold
                                mb-4
                              "
                            >
                              Verify Email</label>
                            <input
                              onChange={e => {setEmail(e.target.value)}}
                              type="text"
                              className="form-control h-px-48"
                              id="email"
                              placeholder="John@gmail.com"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label
                              for="currentpassword"
                              className="
                                d-block
                                text-black-2
                                font-size-4 font-weight-semibold
                                mb-4
                              "
                            >
                              Current Password</label>
                            <input
                              onChange={e => {setPassword(e.target.value)}}
                              type="password"
                              className="form-control h-px-48"
                              id="currentpassword"
                              placeholder=""
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label
                              for="newpassword"
                              className="
                                d-block
                                text-black-2
                                font-size-4 font-weight-semibold
                                mb-4
                              "
                            >
                              New Password</label>
                            <input
                              onChange={e => setNewPassword(e.target.value)}
                              type="password"
                              className="form-control h-px-48"
                              id="newpassword"
                              placeholder=""
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label
                              for="repeatpassword"
                              className="
                                d-block
                                text-black-2
                                font-size-4 font-weight-semibold
                                mb-4
                              "
                            >
                              Repeat New Password</label>
                            <input
                              onChange={e => setConfirmNewPassword(e.target.value)}
                              type="password"
                              className="form-control h-px-48"
                              id="repeatpassword"
                              placeholder=""
                            />
                          </div>
                        </div>
                        <button
                            onClick={e => {updatePassword(e)}}
                            className="
                              btn btn-green btn-h-60
                              text-white
                              min-width-px-210
                              rounded-5
                              text-uppercase
                            ">
                              Save Changes
                          </button>
                        </div>
                        </fieldset>
                        </form>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        </div>
    )
}