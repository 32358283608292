import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import  { useAuths }  from './components/AuthContext';
import LoadingOverlay from 'react-loading-overlay';


export default function PrivateRoute({component: Component, ...rest}){
    const {currentUser, state} = useAuths()
return(
 
    <Route
    {...rest}
    render={props => {
       return currentUser.isAdmin ?
       <LoadingOverlay
       styles={{minHeight: "100vh"}}
       active={state.loading}
       spinner
       text='Loading...'
       >
       <Component {...props} />
       </LoadingOverlay> : <Redirect to="/login"/>
    }}>
        
    </Route>
)
}