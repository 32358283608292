import React, {useEffect, useState} from "react";
import {CreatIndustryModal} from './Forms/CreateIDatas'
import {EditIndustryModal} from './Forms/EditDatas'
import {Button, Modal} from 'react-bootstrap';
import axios from 'axios'
import { toast, Slide } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import  { useAuths }  from '../AuthContext';
import moment from 'moment'
import Paginating from "../pagination"
import { CSVReader } from 'react-papaparse';
const buttonRef = React.createRef();
const url = process.env.REACT_APP_SERVER_URL

toast.configure()




export default function Industry() {
    const [createIndustryModal, setCreateIndustryModal] = useState(false)
    const [editIndustryModal, setEditIndustryModal] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)
    const [industry_text, setIndutryText] = useState("")
    const [industry_id, setIndustryId] = useState("")
    const {state, industry} = useAuths()

    const [currentPage, setCurrentPage] = React.useState(1)
const [dataPerPage] = React.useState(5)

const indexOfLastPost = currentPage * dataPerPage;
const indexOfFirstPage = indexOfLastPost - dataPerPage;
const currentData = state.industries.slice(indexOfFirstPage, indexOfLastPost);

const paginate = (pageNumber) => {
  setCurrentPage(pageNumber)
}
const previousPage = () => {
  if(currentPage - 1 > 0){
    setCurrentPage(currentPage - 1)
  }
}
const nextPage = (dataNumber) => {
  if(currentPage + 1 <= dataNumber){
    setCurrentPage(currentPage + 1)
  }
}


    const delete_industry_success = () => {
    toast.success('Industry Deleted Successfully', {position: toast.POSITION.TOP_RIGHT, theme: "colored", transition: Slide,  hideProgressBar: true})
  }

const delete_industry_error = () => {
    toast.error('Could Delete Industry, Please Try Again', {position: toast.POSITION.TOP_RIGHT, theme: "colored", transition: Slide,  hideProgressBar: true})
  }

  const success_upload = () => {
    toast.success('Upload Industry CSV Successfully', {position: toast.POSITION.TOP_RIGHT, theme: "colored", transition: Slide,  hideProgressBar: true})
  }

  const error_header = () => {
    toast.warn('Please use INDUSTRY as header', {position: toast.POSITION.TOP_CENTER, theme: "colored", transition: Slide,  hideProgressBar: true})
  }

  const error_upload = () => {
    toast.success('Could not upload CSV', {position: toast.POSITION.TOP_RIGHT, theme: "colored", transition: Slide,  hideProgressBar: true})
  }

    function DeleteIndustryModal(props) {
  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      bg="transparent"
    >
      <Modal.Body style={{backgroundColor: "#f2edf3"}}>
          <div className="text-center m-3 h6">Are you sure you want to delete Industry?. <br/>Note: This action is not reversable </div>
        <div className="row justify-content-space-between m-3">
    <Button block onClick={(e) => {deleteIndustry(e)}} className="btn btn-danger">Confirm</Button>
    <Button block onClick={() => setDeleteModal(false)} className="btn btn-primary">Cancel</Button>
    </div>
      </Modal.Body>
    </Modal>
  );
}

const deleteIndustry = (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token')
    axios.delete(`${url}delete-industry/${industry_id}`, {headers: {
                'Authorization': `Bearer ${token}`
            }}).then(res => {
        delete_industry_success()
        industry()
        setDeleteModal(false)
    }).catch(err => {
        delete_industry_error()
    })
}

    useEffect(() => {
        let mounted = true
        if(mounted){
          industry()
        }
        return() => {
            mounted = false
        }
    }, [])

    
function CreateIndustryModal(props) {
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title  id="contained-modal-title-vcenter">
         <h5>Add Industry</h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
    <CreatIndustryModal />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

function EditIndustryModall(props) {
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title  id="contained-modal-title-vcenter">
         <h5>Edit Industry</h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
    <EditIndustryModal industryText={industry_text} industryId={industry_id} />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}
const handleOpenDialog = e => {
    if (buttonRef.current) {
      buttonRef.current.removeFile(e);
      buttonRef.current.open(e);
    }
  };

  const handleOnFileLoad = async (data, e) => {
    try {
      const token = localStorage.getItem('token')
      if (window.confirm('Are you sure you want to upload this sheet')) {
        
        const upload = data
          .filter(
            datum =>
              datum.data['INDUSTRY']
          )
        
          .map(datum => datum.data);
        if (upload.length < 1) {return error_header()}else{
        axios.post(`${url}create-industry`, {name: upload}, 
        {
            headers: {
                'Authorization': `Bearer ${token}`
            }
                 }).then(res => {
          if(res.status === 200){
          success_upload()
          industry(5);
          }
        })
      }}
    } catch (error) {
      error_upload()
    } 
  };

  
    const handleOnError = (err, file, inputElem, reason) => {
    console.log(err);
  };

   const handleOnRemoveFile = data => {
    // console.log('---------------------------');
    // console.log(data);
    // console.log('---------------------------');
  };

    return(
      <div className="col-xs-6 col-sm-6 mb-14">
      <div className=" bg-white shadow-8 pt-7 rounded pb-8 px-11 ">
        <div className="row mb-11 align-items-center">
            <div className="col-lg-6 mb-lg-0 mb-4">
              <h3 className="font-size-6 mb-0">Industry</h3>
            </div>
            <div className="col-lg-6">
              <div className="d-flex flex-wrap align-items-center justify-content-lg-end">
                <div className="row">
                  <CSVReader
                  style={{ display: 'inline-block' }}
                  config={{ header: true }}
                  ref={buttonRef}
                  onFileLoad={handleOnFileLoad}
                  onError={handleOnError}
                  noClick
                  noDrag
                  // addRemoveButton
                  onRemoveFile={handleOnRemoveFile}
                >
                  {({ file }) => (
                    <Button
                      onClick={handleOpenDialog}
                      className="btn btn-secondary"
                    >
                      {file ? file.name : 'Upload CSV'}
                    </Button>
                  )}
                </CSVReader>
                    <button onClick={e => {e.preventDefault(); setCreateIndustryModal(true)}} className="btn btn-green ml-1">+ Add Industry</button>
              </div></div>
            </div>
          </div>
        <div className="table-responsive">
          <table className="table table-striped">
            <thead>
              <tr>
                <th scope="col" className="border-0 font-size-4 font-weight-normal">Name</th>
                <th scope="col" className="border-0 font-size-4 font-weight-normal">Created on</th>
                <th scope="col" className="border-0 font-size-4 font-weight-normal">Action</th>
                <th scope="col" className="border-0 font-size-4 font-weight-normal"></th>
              </tr>
            </thead>
            <tbody>
              {currentData.map( i => (<tr className="border border-color-2">
              <td className="table-y-middle py-7 min-width-px-110 pr-0">
                <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">{i.name}</h3>
              </td>
              <td className="table-y-middle py-7 min-width-px-110 pr-0">
                <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">{moment(new Date(i.createdAt)).format("MMMM Do YYYY")}</h3>
              </td>
              <td className="table-y-middle py-7 min-width-px-60 pr-0">
                      <div className=""><a href="" onClick={e => {e.preventDefault(); setIndutryText(i.name); setIndustryId(i._id); setEditIndustryModal(true)}} className="font-size-3 font-weight-bold text-green text-uppercase">Edit</a></div>
                    </td>
                    <td className="table-y-middle py-7 min-width-px-70 pr-0">
                      <div className=""><a href="" onClick={e => {e.preventDefault(); setIndustryId(i._id); setDeleteModal(true)}} className="font-size-3 font-weight-bold text-red-2 text-uppercase">Delete</a></div>
                    </td>
              </tr>))}
            </tbody>
          </table>
        </div>
         <CreateIndustryModal
        show={createIndustryModal}
        onHide={() =>setCreateIndustryModal(false)}
      /><EditIndustryModall
        show={editIndustryModal}
        onHide={() =>setEditIndustryModal(false)}
      />
      <DeleteIndustryModal
        show={deleteModal}
        onHide={() =>{setDeleteModal(false); setIndustryId("")}}
      />
         <div className="">
                 <Paginating dataPerPage={dataPerPage} totalData={state.industries.length} paginate={paginate} currentPage={currentPage} nextPage={nextPage} previousPage={previousPage} />
              
            </div>
      </div>
      </div>
    )
}