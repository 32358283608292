import CandidateProfile from '../candidate_pages/profile'
import EmployerProfile from '../employer_pages/profile'
import  { useAuths }  from '../AuthContext';

export default function Dashboard() {
    const {currentUser} = useAuths()

    if(currentUser.isAdmin){
        return(
            <div>Admin</div>
        )
    }else if(currentUser.isEmployer){
        return(
            <EmployerProfile/>
        )
    }else{
        return(
            <CandidateProfile/>
        )
    }
}