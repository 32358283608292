import React, { useEffect, useState } from 'react'
import {countries} from '../../Countries'
import { Editor } from "react-draft-wysiwyg";
import moment from 'moment';
import { Link, useLocation } from 'react-router-dom';
import Multiselect from "multiselect-react-dropdown";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { get } from "axios" 
import Switch from "react-switch";
import { useAuths, ACTIONS } from '../../AuthContext'
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

export default function CreateJob () {
  const [employers, setEmployers] = useState([])
  const location = useLocation()

   const {currentUser, state, dispatch, handleSelectSkill, onEditorStateChange, create_job, handleAddLink, addQuestion, onChange, addAnswer, handleRemoveField, removeQuestion} = useAuths()

   const getEmployers = async () => {
     try {
       const token = localStorage.getItem("token")
      const data = await get(`${process.env.REACT_APP_SERVER_URL}getallemployers/100`, {
        headers: {"Authorization": `Bearer ${token}`}
      }) 
      setEmployers(data.data)
     } catch (error) {
       console.log(error)
     }
   }
   useEffect(() => {
     getEmployers()
   },[])

    return (
        <div>
          <h4 className='text-center mt-10'>CREATE JOB</h4>
            <div>
        <div className="">
          <div className="mb-15 mb-lg-23">
            <div className="row">
              <div className="col-xxxl-12 px-lg-13 px-6">
                <div
                  className=" 
                    contact-form
                    bg-white
                    rounded-4
                    pl-sm-10 pl-4
                    pr-sm-11 pr-4
                    pt-5
                    pb-13
                  "
                >
                  <form action="/">
                    <fieldset>
                      <div className="row mb-xl-1 mb-9">
                        <div className="col-lg-12">
                          <div className="form-group">
                            <label
                              for="jobtitle"
                              className="
                                d-block
                                text-black-2
                                font-size-4 font-weight-semibold
                                mb-4
                              "
                            >
                              Job Title</label>
                            <input
                              onChange={e => {dispatch({type: ACTIONS.SET_JOB_ROLE, payload: e.target.value})}}
                              value={state.job_role}
                              type="text"
                              className="form-control h-px-48"
                              id="jobtitle"
                              placeholder="eg. Business Manager"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label
                              for="category"
                              className="
                                d-block
                                text-black-2
                                font-size-4 font-weight-semibold
                                mb-4
                              "
                            >
                              Category</label>
                              <select className="form-control h-px-48" onChange={e => {dispatch({type: ACTIONS.SET_JOB_CATEGORY, payload: JSON.parse(e.target.value)})}} aria-label="Default select example">
                              <option value="" selected disabled>Select Job Category</option>
                              {state.job_categories.map(i => {return (<option key={i._id} selected={i._id === state.job_category._id} value={JSON.stringify(i)}>{i.category}</option>)})}
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label
                              for="category"
                              className="
                                d-block
                                text-black-2
                                font-size-4 font-weight-semibold
                                mb-4
                              "
                            >
                              Employer</label>
                              <select className="form-control h-px-48" onChange={e => {dispatch({type: ACTIONS.SET_EMPLOYER, payload: JSON.parse(e.target.value)})}} aria-label="Default select example">
                              <option value="" selected disabled>Select Employer</option>
                              {employers.map(i => {return (<option key={i._id} selected={i._id === state.employer._id} value={JSON.stringify(i)}>{i.company_name}</option>)})}
                            </select>
                          </div>
                        </div>
                       <div className="col-lg-6">
                          <div className="form-group">
                            <label
                              for="category"
                              className="
                                d-block
                                text-black-2
                                font-size-4 font-weight-semibold
                                mb-4
                              "
                            >
                              Job Anonymous</label>
                <Switch onColor="#8B3300" checkedIcon={true} color='#8B3300' uncheckedIcon={false} height={15} width={35} onChange={() => {dispatch({type: ACTIONS.SET_JOB_ANONYMOUS, payload: !state.job_anonymous})}} checked={state.job_anonymous} />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <label
                              for="aboutTextarea"
                              className="
                                d-block
                                text-black-2
                                font-size-4 font-weight-semibold
                                mb-4
                              "
                              >Job Description</label>
                              {/*<textarea
                              onChange={e => setJobDescription(e.target.value)}
                              name="textarea"
                              id="aboutTextarea"
                              cols="30"
                              rows="7"
                              className="
                                border border-mercury
                                text-gray
                                w-100
                                pt-4
                                pl-6
                              "
                              placeholder="Describe what employee will be doing"
                            ></textarea>*/}
                              <div className="">
                              <Editor
                              editorState={state.editorState}
                              wrapperClassName="wrapper-class"
                              editorClassName="editor-class"
                              toolbarClassName="toolbar-class"
                              toolbar={{
                              options: [
                                'inline',
                                'fontSize',
                                'fontFamily',
                                'textAlign',
                                'list',
                              ],
                              inline: {
                                options: ['italic', 'bold'],
                              },
                              blockType: {
                                className: 'demo-option-custom-wide',
                                dropdownClassName: 'demo-dropdown-custom',
                              },
                              fontSize: { className: 'demo-option-custom-medium' },
                            }}
                              onEditorStateChange={ editorState => {onEditorStateChange(editorState)}}/>
                              </div>      
                         {/*<div  dangerouslySetInnerHTML={{ __html: cleanHTML }} />*/}
                          </div>
                        </div>

                        <div className="col-lg-12">
                          <div className="form-group">
                            <label
                              for="skills"
                              className="
                                d-block
                                text-black-2
                                font-size-4 font-weight-semibold
                                mb-4
                              "
                            >
                              Required Skills</label>
                          {/*<select className="form-control h-px-48" onChange={e => {setSkills(JSON.parse(e.target.value))}} aria-label="Default select example">
                              <option value="" selected>Select Skill</option>
                              {skill.map(i => {return (<option value={JSON.stringify(i)}>{i.title}</option>)})}
                            </select>*/}
                             <Multiselect
                             
                            options={state.skills} 
                            selectedValues={state.selected_skills} 
                            onSelect={(data) => {handleSelectSkill(data)}}
                            onRemove={(data) => {handleSelectSkill(data)}}
                            displayValue="title"
                            />
                          </div>
                        </div>

                        <div className="col-lg-6">
                          <div className="form-group">
                            <label
                              for="website"
                              className="
                                d-block
                                text-black-2
                                font-size-4 font-weight-semibold
                                mb-4
                              "
                            >
                              Salary Range</label>
                            <input
                            value={state.job_salary}
                              onChange={e => dispatch({type:ACTIONS.SET_JOB_SALARY_RANGE, payload: e.target.value})}
                              type="text"
                              className="form-control h-px-48"
                              id="website"
                              placeholder="eg. $5,000 - $50,000"
                            />
                          </div>
                        </div>

                                   <div className="col-lg-6">
                          <div className="form-group">
                            <label
                              for="website"
                              className="
                                d-block
                                text-black-2
                                font-size-4 font-weight-semibold
                                mb-4
                              "
                            >
                              Job Type</label>
                          <select className="form-control h-px-48" onChange={e => {dispatch({type: ACTIONS.SET_JOB_TYPE, payload: JSON.parse(e.target.value)})}} aria-label="Default select example">
                              <option value="" disabled>Select Job Type</option>
                              {state.job_types.map(i => {return (<option key={i._id} selected={i._id === state.job_type._id} value={JSON.stringify(i)}>{i.type}</option>)})}
                            </select>
                          </div>
                        </div>

                        <div className="col-lg-6">
                          <div className="form-group">
                            <label
                              for="website"
                              className="
                                d-block
                                text-black-2
                                font-size-4 font-weight-semibold
                                mb-4
                              "
                            >
                              Experience Level</label>
                          <select className="form-control h-px-48" onChange={e => {dispatch({type: ACTIONS.SET_JOB_EXPERIENCE_LEVEL, payload: JSON.parse(e.target.value)})}} aria-label="Default select example">
                              <option value="" selected disabled>Select Experience Level</option>
                              {state.career_levels.map(i => {return (<option key={i._id} selected={i._id === state.job_experience_level._id} value={JSON.stringify(i)}>{i.title}</option>)})}
                            </select>
                          </div>
                        </div>
                        
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label
                              for="website"
                              className="
                                d-block
                                text-black-2
                                font-size-4 font-weight-semibold
                                mb-4
                              "
                            >
                              Country</label>
                          <select className="form-control h-px-48"  onChange={e => dispatch({type: ACTIONS.SET_JOB_COUNTRY, payload: e.target.value})} aria-label="Default select example">
                              <option value="" selected>Select Country</option>
                              {countries.map(i => {return (<option key={i.ISO} selected={i.value === state.job_country} value={i.value}>{i.country}</option>)})}
                            </select>
                          </div>
                        </div>
                            <div className="col-lg-6">
                          <div className="form-group">
                            <label
                              for="website"
                              className="
                                d-block
                                text-black-2
                                font-size-4 font-weight-semibold
                                mb-4
                              "
                            >
                              State</label>
                            <input
                            value={state.job_state}
                            onChange={e => {dispatch({type: ACTIONS.SET_JOB_STATE, payload: e.target.value})}}
                              type="text"
                              className="form-control h-px-48"
                              id="website"
                              placeholder="eg. Lagos"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label
                              for="date"
                              className="
                                d-block
                                text-black-2
                                font-size-4 font-weight-semibold
                                mb-4
                              "
                            >
                              Closing Date</label>
                            <input
                            value={moment(state.job_closing_date).format('YYYY-MM-DD')}
                              onChange={e => dispatch({type: ACTIONS.SET_JOB_CLOSING_DATE, payload: e.target.valueAsDate})}
                              type="date"
                              className="form-control h-px-48"
                              id="date"
                              placeholder="eg. Lagos, Nigeria"
                            />
                          </div>
                        </div>
                        
                     
                       
                      
                      </div>
                      <div>
                          <button
                         className="
                                mb-0
                                font-size-3
                                btn btn-outline-gray
                                min-width-px-200
                                rounded-pill
                                mb-5
                                mt-5"
                         onClick={e => {e.preventDefault(); dispatch({type: ACTIONS.SET_MODAL, payload: location.pathname+"addquestion"})}} >
                        Add Question
                        </button>
                     
                          </div>
                      {state.question.length ?  <div className='border m-0'>
                     <div className='font-size-6 font-weight-semibold mb-3 text-center'><u>{state.question.length ? "Questions Section" : null}</u> </div>

                        <div className='row'>
                        {
                              state.question.map((i, index) => (<div className="col-lg-5 m-10
                              bg-white
                              shadow-8
                              rounded-4
                              ">
          <a onClick={(e) => {e.preventDefault(); removeQuestion(index)}} style={{cursor: "pointer"}}> <span className="text-dark" aria-hidden="true">&times;</span></a>
                              
                          <div className="form-group">
                            <label
                              for="date"
                              className="
                                d-block
                                text-black-2
                                font-size-4 font-weight-semibold
                                mb-4
                              "
                            >
                              {i.questions}</label>
                              
                                <div key={i._id}>
                                  <ol>
                                  {i.form.map((j) => (<li key={j.isAnswer} className={i.isAnswer ? "d-block text-green font-size-4 mt-3" : "d-block text-black-2 font-size-4 mt-3"}>{j.text}</li>))}
                                  </ol>
                                </div>
                             
                          </div>
                        </div> ))
                      }</div></div> : null}
                      <div>
                          
                           
                            <button
                            onClick={create_job}
                            type="button"
                            className="
                              btn btn-green btn-h-60
                              text-white
                              min-width-px-210
                              rounded-5
                              text-uppercase
                              mt-10
                            "
                          >Create Job</button>
                          </div>                      
                    </fieldset>
                   
                  </form>
                </div>
                
              </div>
            </div>
          </div>
        </div>
        <Modal
        isOpen={state.modal === location.pathname+"addquestion"}
        onClosed={() => {dispatch({type: ACTIONS.SET_MODAL_DATA, payload: ""}); dispatch({type: ACTIONS.SET_MODAL, payload: ""})}}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        bg="transparent"
        >
          <ModalHeader>
          <button onClick={(e) => {e.preventDefault(); dispatch({type: ACTIONS.SET_MODAL, payload: ""})}} className='btn'> <span className="text-dark" aria-hidden="true">&times;</span></button>
          </ModalHeader>
        <ModalBody>
           
        <div className="">
                          <div className="w-75 m-auto">
                           
                          <label
                              for="question"
                              className="
                                text-black-2
                                font-size-4 font-weight-semibold
                              "
                            >
                              Question </label> 
                                <textarea
                                  value={state.questions}
                                  name="question"
                                  id="question"
                                  cols="20"
                                  rows="4"
                                  className="
                                    border border-mercury
                                    text-gray
                                    w-100
                                    pt-4
                                    pl-6
                                  "
                                  placeholder="Type question here"
                                  onChange={e => dispatch({type: ACTIONS.SET_QUESTIONS, payload: e.target.value})}
                                > 
                                </textarea>
                                <label
                              for="options"
                              className="
                                d-block
                                text-black-2
                                font-size-4 
                                pt-5
                              "
                            >
                             Kindly input your options below and check the right answer on the right before submitting.</label>
                              <div className="  mb-3 ">
                                <ol>
                                  {state.form.map((item, index) => (<li className="mt-4" key={`item-${index}`}>
                                    <div className=" d-flex justify-content-start ">
                                      <div className="col-lg-10">

                                        <input
                                        type="text"
                                        className="form-control h-px-48"
                                        name="text"
                                        placeholder="Enter Option"
                                        value={item.text}
                                        onChange={(e) => onChange(index, e)}
                                      />
                                      </div>
                                      <div className="form-check pt-5 pl-10">
                                       <input name="isAnswer" onChange={e => {addAnswer(index, e)}} className="form-check-input " type="radio" />
                                      </div>
                                      <div className="pt-5 pl-5" >
                                        <a href="" onClick={(e) => {e.preventDefault(); handleRemoveField(index)}}><i className="fas fa-times"></i></a> 
                                      </div>
                                   </div>
                                   </li>
                                   ))}
                                   <a href="" className="btn btn-link pt-5" onClick={handleAddLink}><i className="fas fa-plus"></i> Add Option</a>
                           <button className=" border-top
                               btn btn-green btn-h-60 text-white min-width-px-210 rounded-5 text-uppercase mt-10" onClick={addQuestion}>Create Question</button>
                                </ol>
                              </div>
                          </div>
                        </div>
        </ModalBody>
        </Modal>

        <Modal
        isOpen={state.modal === location.pathname+"noprofile"}
        onClosed={() => {dispatch({type: ACTIONS.SET_MODAL_DATA, payload: ""}); dispatch({type: ACTIONS.SET_MODAL, payload: ""})}}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        bg="transparent"
        >
          <ModalHeader>

          </ModalHeader>
        <ModalBody>
           
        <div className="text-center">
              Hi {currentUser.company_name}
              <p>Please help update your profile before you can post jobs</p>
              <Link to="profile" className='btn btn-primary rounded-4'>Go to profile</Link>
          </div>
        </ModalBody>
        </Modal>
      </div>
        </div>
    )
}