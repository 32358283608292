import React from 'react'
import Navbar from './shared-components/navbar'
import Footer from './shared-components/footer'
import error from './image/svg/404.svg'
import {Link} from 'react-router-dom'


export default function Error404 (){
  
    return (
        <div className="site-wrapper overflow-hidden">
      <header
        className="
          site-header site-header--menu-right
          bg-default
          py-7 py-lg-0
          site-header--absolute site-header--sticky
        "
      >
        <Navbar/>
      </header>
        <div className="">
        <div className="404-page  min-h-100vh flex-all-center pt-lg-15 pt-xxl-17 pt-27 pb-lg-0 pb-18">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-7 px-lg-9">
                <div className="card-404 text-center" data-aos="zoom-in" data-aos-duration="800" data-aos-once="true">
                  <img src={error} alt="" className="w-60 px-9"/>
                  <div className="404-texts pt-14">
                    <h3 className="card-title font-size-9 font-weight-bold">Page not found!</h3>
                    <p className="card-text font-size-4 px-xxl-28 px-xs-10 px-sm-13 px-lg-13 px-md-28 px-xl-22 px-0 mb-11">
                      This page you're looking for doesn't seem to exist or might have been taken down.
                    </p>
                    <Link to="/" className="btn btn-green btn-h-60 text-white rounded-5 w-180 m-auto text-uppercase">Back To Home</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
    </div>
    </div>

     <Footer/>
    </div>
    )
}