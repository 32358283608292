import React, {useEffect, useState} from 'react'
import axios from 'axios'
import { format } from "timeago.js";
import Navbar from '../shared-components/navbar'
import profile from "../image/profile/building.png"
import Footer from '../shared-components/footer'
import ocolus from '../image/c-home/oculus-logo.png'
import google from '../image/c-home/google-logo.png'
import { Helmet } from 'react-helmet'
import ReactLoading from "react-loading";
import uber from '../image/c-home/uber-logo.png'
import facebook from '../image/c-home/facebook-logo.png'
import github from '../image/c-home/github-logo.png'
import { Link, useHistory, useParams } from 'react-router-dom';
const url = process.env.REACT_APP_SERVER_URL



export default function CompanyProfile({location}){
    const param = useParams()
    const param_token = param.token
    const [company, setCompany] = useState({
        company_size: "",
        company_started_year: "",
        social_media: {
          linkedIn: "",
          faceBook: "",
          twitter: "",
          instagram: ""
        },
        about: "",
        company_name: "",
        industry: "",
        country: "",
        image: "",
        job: []
    })
    const [loading, setLoading] = React.useState(true)
    const [limit, setLimit] = React.useState(3)
    const [similar_companies, set_similar_companies] = React.useState([])
        const [job, set_jobs] = React.useState([])

        const history = useHistory()
    useEffect(() => {
        axios.get(`${url}getoneuser/${param_token}`).then(
            res => {
                setCompany({
                    ...company,
                    company_size: res.data.num_of_employee,
                    company_started_year: res.data.company_started_year,
                    social_media: res.data.social_media,
                    about: res.data.about,
                    industry: res.data.industry && res.data.industry.name,
                    company_name: res.data.company_name,
                    country: res.data.country,
                    image: res.data.image,
                    job: res.data.job
                })
            }
        )
        setTimeout(() => {
      setLoading(false)
    }, 2000)
  
    }, [])


useEffect(() => {
  axios.get(`${url}get_similar_company/${param_token}`).then(
     data => {
      set_similar_companies(data.data)
     }
   )
}, [])

const get_more = (e) => {
  e.preventDefault();
  setLimit(limit + 3)
  axios.get(`${url}get-employer-jobs-by-guest/${param_token}/${limit}`).then(
     data => {
      set_jobs(data.data)
     }
   )}

 useEffect(() => {

   axios.get(`${url}get-employer-jobs-by-guest/${param_token}/${limit}`).then(
     data => {
      set_jobs(data.data)
      
     }
   )
 }, [])
 if(loading){
  return(
    <div className="center-sign-in-loading" style={{backgroundColor: "#fff", width: "100%", height: "100%", justifyContent: "center", alignItems: "center"}}>
      <ReactLoading className="center-sign-in-loading" type="bars" color="#8B3300" height={'20%'} width={'10%'} />
      </div>
  )
}
    return(
         <div>
           <Helmet>
            <title> {company.company_name} Profile - Job Board | StriveHR </title>
            </Helmet>
        <Navbar/>
        <div className="bg-default-2 pt-16 pt-lg-22 pb-lg-27">
        <div className="container">
            
          <div className="row justify-content-center">
            <div className="col-12 mt-13 dark-mode-texts">
              <div className="mb-9">
                <a
                href=""
                onClick={e => {e.preventDefault(); history.goBack()}}
                  className="d-flex align-items-center ml-4"
                >
                  <i
                    className="
                      icon icon-small-left
                      bg-white
                      circle-40
                      mr-5
                      font-size-7
                      text-black
                      font-weight-bold
                      shadow-8
                    "
                  >
                  </i><span
                    className="
                      text-uppercase
                      font-size-3 font-weight-bold
                      text-gray
                    "
                    >Back</span></a>
              </div>
            </div>
          </div>
          {/* <!-- back Button End --> */}
          <div className="row">
            {/* <!-- Company Profile --> */}
            <div className="col-12 col-xl-9 col-lg-8">
              <div className="bg-white rounded-4 pt-11 shadow-9">
                <div
                  className="
                    d-xs-flex
                    align-items-center
                    pl-xs-12
                    mb-8
                    text-center text-xs-left
                  "
                >
                  <div className="mr-xs-7 mb-5 mb-xs-0">
                    {company.image ? <img
                      className="square-72 rounded-6"
                      src={company.image}
                      alt=""/> :
                      <img className="square-72 rounded-3" src={profile} alt=""/>  }
                  </div>
                  <div className="">
                    <h2 className="mt-xs-n5">
                      <Link className="font-size-6 text-black-2 font-weight-semibold" to='#'>{company.company_name}</Link>
                    </h2>
                    <span className="mb-0 text-gray font-size-4">{company.industry}</span>
                  </div>
                </div>
                {/* <!-- Tab Section Start --> */}
                <ul
                  className="nav border-bottom border-mercury pl-12"
                  id="myTab"
                  role="tablist"
                >
                  <li className="tab-menu-items nav-item pr-12">
                    <a
                      className="
                        active
                        text-uppercase
                        font-size-3 font-weight-bold
                        text-default-color
                        py-3
                      "
                      id="home-tab"
                      data-toggle="tab"
                      href="#home"
                      role="tab"
                      aria-controls="home"
                      aria-selected="true"
                      >Company</a >
                  </li>
                  <li className="tab-menu-items nav-item pr-12">
                    <a
                      className="
                        text-uppercase
                        font-size-3 font-weight-bold
                        text-default-color
                        py-3
                      "
                      id="profile-tab"
                      data-toggle="tab"
                      href="#profile"
                      role="tab"
                      aria-controls="profile"
                      aria-selected="false"
                      >Jobs</a>
                  </li>
                </ul>
                {/* <!-- Tab Content --> */}
                <div
                  className="tab-content pl-12 pt-10 pb-7 pr-12 pr-xxl-24"
                  id="myTabContent"
                >
                  <div
                    className="tab-pane fade show active"
                    id="home"
                    role="tabpanel"
                    aria-labelledby="home-tab"
                  >
                    {/* <!-- Middle Body Start --> */}
                    <div className="row">
                      {/* <!-- Single Widgets Start --> */}
                      <div className="col-12 col-lg-4 col-md-4 col-xs-6">
                        <div className="mb-8">
                          <p className="font-size-4">Company size</p>
                          <h5
                            className="font-size-4 font-weight-semibold text-black"
                          >
                              {company.company_size ? company.company_size : "Not Available yet"}
                          </h5>
                        </div>
                        <div className="mb-8">
                          <p className="font-size-4">Est. Since</p>
                          <h5
                            className="font-size-4 font-weight-semibold text-black"
                          >
                            {company.company_started_year ? company.company_started_year : "Not Available yet" }
                          </h5>
                        </div>
                      </div>
                      {/* <!-- Single Widgets End -->
                      <!-- Single Widgets Start --> */}
                      <div className="col-12 col-lg-4 col-md-4 col-xs-6">
                        <div className="mb-8">
                          <p className="font-size-4">Type of corporation</p>
                          <h5
                            className="font-size-4 font-weight-semibold text-black"
                          >
                           {company.industry ? company.industry : "Not Available yet"}
                          </h5>
                        </div>
                        {company.social_media !== undefined ? <div className="mb-8">
                          <p className="font-size-4">Social Media</p>
                          <div className="icon-link d-flex align-items-center">
                            {company.social_media.linkedIn ?<a
                              className="
                                text-smoke
                                circle-32
                                bg-concrete
                                mr-5
                                hover-bg-green
                              "
                              target="_blank"
                              rel="noreferrer"
                              href={`https://${company.social_media.linkedIn}`}
                            >
                              <i className="fab fa-linkedin-in"></i>
                            </a> : null}
                            {company.social_media.faceBook ? <a
                              className="
                                text-smoke
                                circle-32
                                bg-concrete
                                mr-5
                                hover-bg-green
                              "
                              target="_blank"
                              rel="noreferrer"
                              href={`https://${company.social_media.faceBook}`}
                            >
                              <i className="fab fa-facebook-f"></i>
                            </a> : null}
                            {company.social_media.twitter ? <a
                              className="
                                text-smoke
                                circle-32
                                bg-concrete
                                mr-5
                                hover-bg-green
                              "
                              target="_blank"
                              rel="noreferrer"
                              href={`https://${company.social_media.twitter}`}
                            >
                              <i className="fab fa-twitter"></i>
                            </a> : null}
                            {company.social_media.instagram ? <a
                              className="
                                text-smoke
                                circle-32
                                bg-concrete
                                mr-5
                                hover-bg-green
                              "
                              target="_blank"
                              rel="noreferrer"
                              href={`https://${company.social_media.instagram}`}
                            >
                              <i className="fab fa-instagram"></i>
                            </a>: null}
                          </div> 
                        </div>: null}
                      </div>
                      {/* <!-- Single Widgets End -->
                      <!-- Single Widgets Start --> */}
                      <div className="col-12 col-lg-4 col-md-4 col-xs-6">
                        <div className="mb-8">
                          <p className="font-size-4">Location</p>
                          <h5
                            className="font-size-4 font-weight-semibold text-black"
                          >
                            {company.country}
                          </h5>
                        </div>
                      </div>
                      {/* <!-- Single Widgets End --> */}
                    </div>
                    {/* <!-- Middle Body End -->
                    <!-- Excerpt Start --> */}
                    <h4
                      className="font-size-6 mb-7 text-black-2 font-weight-semibold"
                    >
                      About {company.company_name}
                    </h4>
                    <div className="pt-5">
                      <p className="font-size-4 mb-8">
                        {company.about}
                      </p>
                    </div>
                    {/* <!-- Excerpt End --> */}
                  </div>

                  <div
                    className="tab-pane fade"
                    id="profile"
                    role="tabpanel"
                    aria-labelledby="profile-tab"
                  >
                    {/* <!-- Middle Body Start --> */}
                    <div className="pt-9 px-xl-9 px-lg-7 px-7 pb-7">
                     {job.map(i => {return( <div
                        className="mb-8"
                        data-aos="fade-right"
                        data-aos-duration="800"
                        data-aos-once="true"
                      >
                        <div
                          className="
                            pt-9
                            px-xl-9 px-lg-7 px-7
                            pb-7
                            light-mode-texts
                            bg-athens
                            rounded
                            hover-shadow-3
                          "
                        >
                          <div className="row">
                            <div className="col-md-6">
                              <div className="media align-items-center">
                                <div>
                                  <h3 className="mb-0">
                                    <Link to={{pathname: `/JobDetails/${i.jobId}`}}
                                      className="font-size-6 heading-default-color">
                        
                                      {i.jobrole}</Link>
                                  </h3>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6 text-right pt-7 pt-md-5">
                              <div className="media justify-content-md-end">
                                <p
                                  className="
                                    font-weight-bold font-size-7
                                    text-hit-gray
                                    mb-0
                                  "
                                >
                                <Link to={{pathname: `/JobDetails/${i.jobId}`}}><span className="text-black-2">{i.payment}</span></Link>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="row pt-8">
                            <div className="col-md-7">
                             <ul className="d-flex list-unstyled mr-n3 flex-wrap ">
                             {i.skill.map(j => {return (<li>
                                  <div
                                    className="
                                      bg-regent-opacity-15
                                      min-width-px-96
                                      mr-3
                                      text-center
                                      rounded-3
                                      px-6
                                      py-1
                                      font-size-3
                                      text-black-2
                                      mt-2
                                    "
                                    >{j.title}</div>
                                </li>)})}
                                
                              </ul>
                            </div>
                            <div className="col-md-5">
                              <ul
                                className="
                                  d-flex
                                  list-unstyled
                                  mr-n3
                                  flex-wrap
                                  mr-n8
                                  justify-content-md-end
                                "
                              >
                                <li
                                  className="
                                    mt-2
                                    mr-8
                                    font-size-small
                                    text-black-2
                                    d-flex
                                  "
                                >
                                  <span className="mr-4" style={{marginTop: "-2px"}}>
                                  <i className=" text-green pt-1 fa fa-suitcase mr-2 font-weight-bold"></i>
                                 </span>
                                  
                                  <span className="font-weight-semibold"
                                    >{i.type.type}</span>
                                </li>
                                <li
                                  className="
                                    mt-2
                                    mr-8
                                    font-size-small
                                    text-black-2
                                    d-flex
                                  "
                                >
                                  <span className="mr-4" style={{marginTop: "-2px"}}
                                    >
                                      <i className=" text-green pt-1 fa fa-clock mr-2 font-weight-bold"></i>
                                    </span>
                                  <span className="font-weight-semibold">{format(i.createdAt)}</span >
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>)})}

                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- Company Profile End --> */}
            {/* <!-- Sidebar --> */}
            <div className="col-12 col-xl-3 col-lg-4 col-md-5 col-sm-6">
              <div className="pt-11 pt-lg-0 pl-lg-5">
                <h4 className="font-size-6 font-weight-semibold mb-0">
                  Similar Companies
                </h4>
                <ul className="list-unstyled">
                  {/* <!-- Single List --> */}
                  {similar_companies.map(i => (
                    <li className="border-bottom">
                    <Link to={{pathname: `/company-profile/${i.userId}`, state: {companyId: i._id}}} className="media align-items-center py-9" >
                      <div className="mr-7">
                        {i.image ? <img
                          className="square-72 rounded-5"
                          src={i.image}
                          alt=""/> :
                          <img className="square-72 rounded-3" src={profile} alt=""/> 
                           }
                      </div>
                      <div className="mt-n4">
                        <h4 className="mb-0 font-size-6 font-weight-semibold">
                          {i.company_name}
                        </h4>
                        <p className="mb-0 font-size-4">{i.industry && i.industry.name}</p>
                      </div>
                    </Link>
                  </li>))}
                 
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
      </div>
    )
}