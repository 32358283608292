import React from 'react';
import blackstrivehrlogo from '../image/logo-main-black.png';
import {Link, useHistory} from 'react-router-dom'
import axios from 'axios'
import { Helmet } from 'react-helmet'
import { toast, Slide } from 'react-toastify'
const url = process.env.REACT_APP_SERVER_URL

toast.configure()

 const error = () => {
toast.error('Could not send reset link', {position: toast.POSITION.BOTTOM_LEFT, theme: "colored", transition: Slide,  hideProgressBar: true})
}

const not_found = () => {
toast.error('Email Not Found', {position: toast.POSITION.BOTTOM_LEFT, theme: "colored", transition: Slide,  hideProgressBar: true})
}

const required = () => {
    toast.warn('All fields required', {position: toast.POSITION.TOP_CENTER, theme: "colored", transition: Slide,  hideProgressBar: true})
  }

    export default function CandidateSignUp (){
      const [email, set_email] = React.useState("")
      const history = useHistory()

      
      const handleSubmit = (e) => {
        e.preventDefault();
        if(!email){
          required()
        }else{
        axios.post(`${url}forgot-password-link`, {email}).then(res => {
          history.push('/reset-password-link-success')
        }).catch(err => {
          if(err.response.status === 404){
            not_found()
          }else{
          error()
        }})}
      }
        return(
        <div className="site-wrapper overflow-hidden">
           <Helmet>
            <title> Forgot Password - Job Board | StriveHR </title>
            </Helmet>
        <div className="">
        <div className=" min-h-100vh flex-all-center pt-lg-15 pt-xxl-17  pb-lg-0 pb-18">
        <div className="container">
        <div className="row justify-content-center">
        <div className="col-xxl-6 col-xl-7 col-lg-8 text-center">
        <img src={blackstrivehrlogo} alt="" className="p-7"/>
          <div className="mb-15 text-center bg-white px-9 pt-9 pb-7 shadow-8 rounded-4" >
          <div className="row">
                
                <div className="col-xs-12 mb-8">
                  <h2 className="font-size-9 mb-4">
                    Password Recovery
                  </h2>
                  <p className="font-size-4  heading-default-color">
                                  Happens all the time no need to fret. Enter your email,
                                  and we’ll send you a link to recover your account.

                                </p>
                  <div>
                      <form>
                      <div className="form-group text-left">
                          <label
                          className="
                              font-size-4
                              text-black-2
                              font-weight-semibold
                              line-height-reset
                          "
                          >E-mail</label>
                          <input
                          onChange={e => {set_email(e.target.value)}}
                          type="email"
                          className="form-control"
                          placeholder="john@gmail.com"
                          id="email"
                          />
                      </div>
                      <div className="form-group mb-20">
                                <a
                                href=""
                                onClick={e => {handleSubmit(e)}}
                                  className="
                                    btn btn-primary btn-medium
                                    w-100
                                    rounded-5
                                    text-uppercase
                                  "
                                >
                                  Send Link
                                </a>
                                
                              </div>

                              <div className="text-center">
                                <p className="font-size-4  heading-default-color">
                                  Need help?
                                  <a href="tel:+2348149333305" className="text-primary"
                                    >call us</a>
                                </p>
                              </div>
                      </form>
                  </div>
                </div>
              </div>
          </div>
        </div>
        </div>
        </div>
        </div>
        </div>
        </div>
        )
    }