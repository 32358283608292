import React, {useEffect} from 'react'
import Navbar from '../shared-components/navbar'
import Sidebar from '../shared-components/sidebar'
import Paginating from "../pagination"
import { Link } from 'react-router-dom'
import moment from 'moment'
import { Helmet } from 'react-helmet'
import emptybox from '../image/c-home/empty-box.png'
import { Button, Spinner } from 'react-bootstrap';
import { useAuths } from '../AuthContext'
const url = process.env.REACT_APP_SERVER_URL


export default function DashboardEmployer () {
  const {currentUser, state, getEmployerApplicants, filterApplicantData, getEmployerNumberOfUser, getEmployerJobs, pages_clean_up} = useAuths()


  const [currentPage, setCurrentPage] = React.useState(1)
  const [dataPerPage] = React.useState(5)
  
  const indexOfLastPost = currentPage * dataPerPage;
  const indexOfFirstPage = indexOfLastPost - dataPerPage;
  const currentData = state.applicants.slice(indexOfFirstPage, indexOfLastPost);
  
  
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber)
  }
  const previousPage = () => {
    if(currentPage - 1 > 0){
      setCurrentPage(currentPage - 1)
    }
  }
  const nextPage = (dataNumber) => {
    if(currentPage + 1 <= dataNumber){
      setCurrentPage(currentPage + 1)
    }
  }

 

   useEffect(() => {
     let mounted = true
     if(mounted){
    getEmployerApplicants()
    getEmployerJobs()
    getEmployerNumberOfUser()}
    return () => {
      pages_clean_up("employer/dashboard")
      mounted = false

    }
     }, [])


 
    return (
        <div>
            <Helmet>
            <title>Employer Dashboard - Job Board | StriveHR </title>
            </Helmet>
            <Navbar/>
            <Sidebar/>
         
          <div>
            <div className="dashboard-main-container mt-25 mt-lg-31" id="dashboard-body">
        <div className="container">
          {/* <div className="mb-7">
            <h3>Hello Go,</h3>
            <p>Good evening</p>
          </div> */}
          <div className="row mb-7">
            <div className="col-xxl-3 col-xl-4 col-lg-6 col-sm-6">
              {/* <!-- Single Category --> */}
              <div
                className="
                  media
                  bg-white
                  rounded-4
                  pl-8
                  pt-9
                  pb-9
                  pr-7
                  hover-shadow-1
                  mb-9
                  shadow-8
                "
              >
                <div
                  className="text-blue bg-blue-opacity-1 circle-56 font-size-6 mr-7"
                >
                  <i className="fas fa-briefcase"></i>
                </div>
                {/* <!-- Category Content --> */}
                <div className="">
                  <h5
                    className="
                      font-size-8 font-weight-semibold
                      text-black-2
                      line-height-reset
                      font-weight-bold
                      mb-1
                    "
                  >
                    <span className="counter">{state.posted_jobs}</span>
                  </h5>
                  <p className="font-size-4 font-weight-normal text-gray mb-0">
                    Posted Jobs
                  </p>
                </div>
              </div>
              {/* <!-- End Single Category --> */}
            </div>

            <div className="col-xxl-3 col-xl-4 col-lg-6 col-sm-6">
              {/* <!-- Single Category --> */}
              <div
                className="media bg-white rounded-4 pl-8 pt-9 pb-9 pr-7 hover-shadow-1 mb-9 shadow-8"
              >
                <div
                  className="text-pink bg-pink-opacity-1 circle-56 font-size-6 mr-7
                  "
                >
                  <i className="fas fa-user"></i>
                </div>
                {/* <!-- Category Content --> */}
                <div className="">
                  <h5
                    className="
                      font-size-8 font-weight-semibold
                      text-black-2
                      line-height-reset
                      font-weight-bold
                      mb-1
                    "
                  >
                    <span className="counter">{state.applicants.length}</span>
                  </h5>
                  <p className="font-size-4 font-weight-normal text-gray mb-0">
                    Total Applicants
                  </p>
                </div>
              </div>
              {/* <!-- End Single Category --> */}
            </div>
            <div className="col-xxl-3 col-xl-4 col-lg-6 col-sm-6">
              {/* <!-- Single Category --> */}
              <div
                className="media bg-white rounded-4 pl-8 pt-9 pb-9 pr-7 hover-shadow-1 mb-9 shadow-8"
              >
                <div
                  className="text-orange bg-orange-opacity-1 circle-56 font-size-6 mr-7"
                >
                  <i className="fas fa-eye"></i>
                </div>
                {/* <!-- Category Content --> */}
                <div className="">
                  <h5
                    className="
                      font-size-8 font-weight-semibold
                      text-black-2
                      line-height-reset
                      font-weight-bold
                      mb-1
                    "
                  >
                    <span className="counter">{currentUser.viewJob.length}</span>
                  </h5>
                  <p className="font-size-4 font-weight-normal text-gray mb-0">
                    Jobs View
                  </p>
                </div>
              </div>
              {/* <!-- End Single Category --> */}
            </div>
            <div className="col-xxl-3 col-xl-4 col-lg-6 col-sm-6">
              {/* <!-- Single Category --> */}
              <div
                className="media bg-white rounded-4 pl-8 pt-9 pb-9 pr-7 hover-shadow-1 mb-9 shadow-8">
                <div
                  className="text-egg-blue bg-egg-blue-opacity-1 circle-56 font-size-6 mr-7"
                >
                  <i className="fas fa-mouse-pointer"></i>
                </div>
                {/* <!-- Category Content --> */}
                <div className="">
                  <h5
                    className="
                      font-size-8 font-weight-semibold
                      text-black-2
                      line-height-reset
                      font-weight-bold
                      mb-1
                    "
                  >
                    <span className="counter">{state.number_of_users}</span>%
                  </h5>
                  <p className="font-size-4 font-weight-normal text-gray mb-0">
                  Applied Rate
                  </p>
                </div>
              </div>
              {/* <!-- End Single Category --> */}
            </div>
          </div>
          
            <div className=" mt-25 mt-lg-6" id="dashboard-body">

        {state && state.employer_jobs.length ? <div className="container">
            <div className="mb-14">
          <div className="row mb-11 align-items-center">
            <div className="col-lg-6 mb-lg-0 mb-4">
              <h3 className="font-size-6 mb-0">Applicants List ({state.applicants ? state.applicants.length : 0})</h3>
            </div>
            <div className="col-lg-6">
              <div className="d-flex flex-wrap align-items-center justify-content-lg-end">
                <p className="font-size-4 mb-0 mr-6 py-2">Filter by Job:</p>
                <div className="h-px-48">
                  <select name="country" id="country" onChange={e => {filterApplicantData(e.target.value, "application")}} className="nice-select pl-7 h-100 arrow-3 arrow-3-black min-width-px-273 font-weight-semibold text-black-2">
                  <option value="none" data-display="Product Designer">None</option>
                    {state.employer_jobs.map(i => (<option value={i.jobrole} data-display="Product Designer">{i.jobrole}</option>))}
                  </select>
                </div>
                
              </div>
            </div>
          </div>
          <div className="bg-white shadow-8 pt-7 rounded pb-8 px-11">
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th scope="col" className="pl-0  border-0 font-size-4 font-weight-normal">Name</th>
                    <th scope="col" className="border-0 font-size-4 font-weight-normal">Applied as</th>
                    <th scope="col" className="border-0 font-size-4 font-weight-normal">Applied on</th>
                    <th scope="col" className="border-0 font-size-4 font-weight-normal"></th>
                    <th scope="col" className="border-0 font-size-4 font-weight-normal"></th>
                    <th scope="col" className="border-0 font-size-4 font-weight-normal"></th>
                  </tr>
                </thead>
                <tbody>
                  {currentData.map(i => { if(i.owner) return (i.job ? <tr key={i._id} className="border border-color-2">
                    <th scope="row" className="pl-6 border-0 py-7 pr-0">
                      <Link to={{pathname: `/candidate-profile/${i.owner.userId}`}} className="media min-width-px-235 align-items-center">
                        {i.owner.image ? <div className="circle-36 mr-6">
                          <img src={i.owner.image} alt="" className="circle-36 w-100" />
                        </div> : <div className="circle-36 mr-6 bg-primary">
                          <div  className="w-100" ></div>
                        </div>}
                        <h4 className="font-size-4 mb-0 font-weight-semibold text-black-2">{i.owner.first_name+' '+i.owner.last_name}</h4>
                      </Link>
                    </th>
                    <td className="table-y-middle py-7 min-width-px-235 pr-0">
                      <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">{i.job.jobrole}</h3>
                    </td>
                    <td className="table-y-middle py-7 min-width-px-170 pr-0">
                      <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">{moment(new Date(i.createdAt)).format("MMMM Do YYYY")}</h3>
                    </td>
                    <td className="table-y-middle py-7 min-width-px-170 pr-0">
                      <div className="">{i.resume ? <a href={i.resume} target = "_blank" className="font-size-3 font-weight-bold text-black-2 text-uppercase">View Resume</a>: 
                      <div  className="font-size-3 font-weight-bold text-black-2 text-uppercase" data-toggle="modal" data-target="#employe-profile">No Resume</div>}</div>
                    </td>
                    <td className="table-y-middle py-7 min-width-px-110 pr-0">
                      <div className=""><Link  to={{pathname: "/conversation", state : {receiverId: i.owner._id}}} className="font-size-3 font-weight-bold text-green text-uppercase">Contact</Link></div>
                    </td>
                    {i.accepted ? <td className="table-y-middle py-7 min-width-px-100 pr-0">
                      <div className=""><div className="font-size-3 font-weight-bold text-green-2 text-uppercase">Accepted</div></div>
                    </td> : <td className="table-y-middle py-7 min-width-px-100 pr-0">
                      <div className=""><div className="font-size-3 font-weight-bold text-red-2 text-uppercase">Reject</div></div>
                    </td>}
                  </tr> : null)})}
                </tbody>
              </table>
            </div>
            <div className="">
     <Paginating dataPerPage={dataPerPage} totalData={state.applicants.length} paginate={paginate} currentPage={currentPage} nextPage={nextPage} previousPage={previousPage} />
            </div>
          </div>
        </div>
        </div> :  <div>
        <div className="dashboard-main-container mt-25 mt-lg-31" id="dashboard-body">
        {/* <!-- Messaging System --> */}
        <div className="container">
          <div className="mb-14">
            <div className="col-12 col-xl-10 col-lg-8">
              {/* <!-- Top Start --> */}
              <div className="mb-5">
                <h3 className=" mb-9">Empty tables</h3>
                
                {/* Empty data  */}
                <div className="row justify-content-center">
                  <div className="col-lg-6 col-md-6 col-sm-11 mb-9 text-center">
                    <img className="w-50" src={emptybox} alt="" />
                    <h2 className="pt-15">No data found</h2>
                    <p>Your data will appear here once you start posting jobs.</p>
                  </div>
                </div>

              </div>
              {/* <!-- Top End --> */}
              
            </div>
          </div>
        </div>
      </div>
        </div>}

        </div>
        </div></div></div>
        </div>
    )
}