import React from 'react';
import {Bar} from 'react-chartjs-2';
import axios from 'axios'
const url = process.env.REACT_APP_SERVER_URL
//


export default class Barchart extends React.Component {
    constructor(props){
    super(props)
         this.state = {
            bar_chart: {}
         }
    }


componentDidMount() {
axios.get(`${url}get-company-bar-chart-data`).then(res => {
const get_label = res.data.map(i => {
    return i.name
})
const get_percentage = res.data.map(i => {
    return i.percentage
})
const data = {
  labels: get_label,
  datasets: [
    {
      label: 'Top Applied Companies',
      backgroundColor: [
          'rgb(99, 102, 241)',
          'rgb(55, 48, 163)',
          'rgb(56, 189, 248)',
          'rgb(52, 211, 153)',
          'rgb(148, 163, 184)',
        ],
      data: get_percentage,
    }
  ]}
this.setState({bar_chart: data})
})}

  render() {
    return (
      <div>
        <Bar
          data={this.state.bar_chart}
          options={{
            title:{
              display:true,
              text:'Top companies and their job posts',
              fontSize:20
            },
            legend:{
              display:true,
              position:'right'
            }
          }}
        />
      </div>
    );
  }
}