import React, {useEffect, useState} from "react";
import {CreateCategory} from './Forms/CreateIDatas'
import {EditJobCategory} from './Forms/EditDatas'
import {Button, Modal} from 'react-bootstrap';
import axios from 'axios'
import moment from 'moment'
import Paginating from "../pagination"
import { toast, Slide } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import  { useAuths }  from '../AuthContext';
const url = process.env.REACT_APP_SERVER_URL


export default function Jobtype() {
const [createJobTypeModal, setCreateCategoryModal] = useState(false)
const [editJobTypeModal, setEditJobCategoryModal] = useState(false)
const [deleteModal, setDeleteModal] = useState(false)
const [job_category_text, set_job_category_text] = useState("")
const [job_category_id, set_job_category_id] = useState("")
const {category, state} = useAuths()

const [currentPage, setCurrentPage] = React.useState(1)
const [dataPerPage] = React.useState(5)

const indexOfLastPost = currentPage * dataPerPage;
const indexOfFirstPage = indexOfLastPost - dataPerPage;
const currentData = state.job_categories.slice(indexOfFirstPage, indexOfLastPost);

const paginate = (pageNumber) => {
  setCurrentPage(pageNumber)
}
const previousPage = () => {
  if(currentPage - 1 > 0){
    setCurrentPage(currentPage - 1)
  }
}
const nextPage = (dataNumber) => {
  if(currentPage + 1 <= dataNumber){
    setCurrentPage(currentPage + 1)
  }
}



    const delete_job_category_success = () => {
    toast.success('Job Category Deleted Successfully', {position: toast.POSITION.TOP_RIGHT, theme: "colored", transition: Slide,  hideProgressBar: true})
  }

const job_category_delete_error = () => {
    toast.error('Could Not Delete Job Category, Please Try Again', {position: toast.POSITION.TOP_RIGHT, theme: "colored", transition: Slide,  hideProgressBar: true})
  }

    function DeleteJobTypeModal(props) {
  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      bg="transparent"
    >
      <Modal.Body style={{backgroundColor: "#f2edf3"}}>
          <div className="text-center m-3 h6">Are you sure you want to delete Job Category?. <br/>Note: This action is not reversable </div>
        <div className="row justify-content-space-between m-3">
    <Button block onClick={(e) => {deleteJobType(e)}} className="btn btn-danger">Confirm</Button>
    <Button block onClick={() => setDeleteModal(false)} className="btn btn-primary">Cancel</Button>
    </div>
      </Modal.Body>
    </Modal>
  );
}
    
const deleteJobType = (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token')
    axios.delete(`${url}delete-job-category/${job_category_id}`, {headers: {
                'Authorization': `Bearer ${token}`
            }}).then(res => {
        delete_job_category_success()
        setDeleteModal(false)
        category()
    }).catch(err => {
        job_category_delete_error()
    })
}

    useEffect(() => {
        let mounted = true
        if (mounted){
        category()
        }
        return() => {
            mounted = false
        }
    }, [])

   
function CreateJobTypeModal(props) {
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title  id="contained-modal-title-vcenter">
         <h5>Add Job Category</h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
    <CreateCategory />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

function EditJobTypeModal(props) {
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title  id="contained-modal-title-vcenter">
         <h5>Edit Job Category</h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
    <EditJobCategory jobCategoryText={job_category_text} jobCategoryId={job_category_id} />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}



    return(
      <div className="col-xs-6 col-sm-6 mb-14">
      <div className=" bg-white shadow-8 pt-7 rounded pb-8 px-11 ">
        <div className="row mb-11 align-items-center">
            <div className="col-lg-6 mb-lg-0 mb-4">
              <h3 className="font-size-6 mb-0">Job Category</h3>
            </div>
            <div className="col-lg-6">
              <div className="d-flex flex-wrap align-items-center justify-content-lg-end">
                    <button onClick={e => {e.preventDefault();  setCreateCategoryModal(true)}} className="btn btn-green">+ Add Category</button>
              </div>
            </div>
          </div>
        <div className="table-responsive">
          <table className="table table-striped">
            <thead>
              <tr>
                <th scope="col" className="border-0 font-size-4 font-weight-normal">Category</th>
                <th scope="col" className="border-0 font-size-4 font-weight-normal">Created on</th>
                <th scope="col" className="border-0 font-size-4 font-weight-normal">Action</th>
                <th scope="col" className="border-0 font-size-4 font-weight-normal"></th>
              </tr>
            </thead>
            <tbody>
              {currentData.map(i => (<tr className="border border-color-2">
              <td className="table-y-middle py-7 min-width-px-110 pr-0">
                <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">{i.category}</h3>
              </td>
              <td className="table-y-middle py-7 min-width-px-110 pr-0">
                <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">{moment(new Date(i.createdAt)).format("MMMM Do YYYY")}</h3>
              </td>
              <td className="table-y-middle py-7 min-width-px-60 pr-0">
                      <div className=""><a href="" onClick={e => {e.preventDefault();  set_job_category_id(i._id); set_job_category_text(i.category); setEditJobCategoryModal(true)}}  className="font-size-3 font-weight-bold text-green text-uppercase">Edit</a></div>
                    </td>
                    <td className="table-y-middle py-7 min-width-px-70 pr-0">
                      <div className=""><a href="" onClick={e => {e.preventDefault(); set_job_category_id(i._id); setDeleteModal(true)}} className="font-size-3 font-weight-bold text-red-2 text-uppercase">Delete</a></div>
                    </td>
              </tr>))}
            </tbody>
          </table>
        </div>
         <CreateJobTypeModal
        show={createJobTypeModal}
        onHide={() =>setCreateCategoryModal(false)}
      />
      <EditJobTypeModal
        show={editJobTypeModal}
        onHide={() =>setEditJobCategoryModal(false)}
      /><DeleteJobTypeModal
        show={deleteModal}
        onHide={() =>{setDeleteModal(false); set_job_category_id("")}}
      />
        <div className="">
                 <Paginating dataPerPage={dataPerPage} totalData={state.job_categories.length} paginate={paginate} currentPage={currentPage} nextPage={nextPage} previousPage={previousPage} />
              
            </div>
      </div>
      </div>
    )
}