import React, {useEffect, useState} from 'react'
import Navbar from '../shared-components/navbar'
import moment from 'moment'
import Sidebar from '../shared-components/sidebar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faBriefcase, faEye, faBookmark, faUser, faEnvelope, faPaperPlane, faFile, faLock, faCheck, faCamera} from '@fortawesome/free-solid-svg-icons'
import  { useAuths }  from '../AuthContext';
import ReactLoading from "react-loading";
import { Helmet } from 'react-helmet'
import axios from 'axios'
const url = process.env.REACT_APP_SERVER_URL




export default function Dashboard () {
const {currentUser, time, getMessage, getUser, isLoading} = useAuths()
const [loading, setLoading] = useState(true)
const [limit, setLimit] = React.useState(4)
const [bookmarks, setBookmarks] = React.useState([])
const [recentActivity, setRecentActivity] = React.useState([])
const [currentUserData, setCurrentUserData] = React.useState({
  first_name: "",
  bookmarks: [],
  application: []
})



const getBookMarkjobs = async () => {
  try {
    isLoading(true)
    const token = localStorage.getItem('token')
     await axios.get(`${url}get-user-bookmark`, {
       headers: {
         'Authorization': `Bearer ${token}`
       },
     }).then(res => {
       setBookmarks(res.data)
    isLoading(false)

     })

  } catch (error) {
    isLoading(false)
    
  }
 
  }


const loadActivity = () => {
 const token = localStorage.getItem('token')
  axios.get(`${url}get-one-user-recent-activity/${currentUser._id}/${limit}`, {
      headers: {
                'Authorization': `Bearer ${token}`
            }}).then(
              response => {
                setRecentActivity(response.data)
              }
            )
}

useEffect(() => {
   let mounted = true
 if(mounted){
   setCurrentUserData({
    ...currentUserData,
    first_name: currentUser.first_name,
    bookmarks: currentUser.bookmarks,
    application: currentUser.application
  })
   loadActivity()
   getBookMarkjobs()
   getUser()
  
 }
 return() => {
   mounted = false
 }
 
}, [])




/*if(loading){
  return(
    <div className="center-sign-in-loading" style={{backgroundColor: "#fff", width: "100%", height: "100%", justifyContent: "center", alignItems: "center"}}>
      <ReactLoading className="center-sign-in-loading" type="bars" color="#8B3300" height={'20%'} width={'10%'} />
      </div>
  )
}*/


    return(
         <div>
            <Helmet>
            <title> {currentUser.first_name+' '+currentUser.last_name} Profile - Job Board | StriveHR </title>
            </Helmet>
           <Navbar/>
           <Sidebar/>
           {/* <div>
             <a
        className="sidebar-mobile-button"
        data-toggle="collapse"
        href="#sidebar"
        role="button"
        aria-expanded="false"
        aria-controls="sidebar"
      >
        
        <i className="icon icon-sidebar-2"></i>
      </a>
      </div> */}
      <div className="dashboard-main-container mt-25 mt-lg-31" id="dashboard-body">
        <div className="container">
          <div className="mb-7 ">
            <h3>Hello {currentUserData.first_name},</h3>
            <p>{time}</p>
          </div>
          <div className="row mb-7">
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-sm-6">
              <div
                className="
                  media
                  bg-white
                  rounded-4
                  pl-8
                  pt-9
                  pb-9
                  pr-7
                  hover-shadow-1
                  mb-9
                  shadow-8
                "
              >
                <div
                  className="text-blue bg-blue-opacity-1 circle-56 font-size-6 mr-7"
                ><FontAwesomeIcon  icon={faBriefcase}/>
                </div>
                <div className="">
                  <h5
                    className="
                      font-size-8 font-weight-semibold
                      text-black-2
                      line-height-reset
                      font-weight-bold
                      mb-1
                    "
                  >
                    <span className="counter">{currentUserData.application.length}</span>
                  </h5>
                  <p className="font-size-4 font-weight-normal text-gray mb-0">
                    Applied Jobs
                  </p>
                </div>
              </div>
            </div>

            <div className="col-xxl-4 col-xl-4 col-lg-6 col-sm-6">
              <div
                className="
                  media
                  bg-white
                  rounded-4
                  pl-8
                  pt-9
                  pb-9
                  pr-7
                  hover-shadow-1
                  mb-9
                  shadow-8
                "
              >
                <div
                  className="
                    text-orange
                    bg-orange-opacity-1
                    circle-56
                    font-size-6
                    mr-7
                  "
                ><FontAwesomeIcon  icon={faEye}/>
                </div>
                <div className="">
                  <h5
                    className="
                      font-size-8 font-weight-semibold
                      text-black-2
                      line-height-reset
                      font-weight-bold
                      mb-1
                    "
                  >
                    <span className="counter">{currentUser.viewJob.length}</span>
                  </h5>
                  <p className="font-size-4 font-weight-normal text-gray mb-0">
                    Viewed Jobs
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-sm-6">
              <div
                className="
                  media
                  bg-white
                  rounded-4
                  pl-8
                  pt-9
                  pb-9
                  pr-7
                  hover-shadow-1
                  mb-9
                  shadow-8
                "
              >
                <div
                  className="
                    text-egg-blue
                    bg-egg-blue-opacity-1
                    circle-56
                    font-size-6
                    mr-7
                  "
                ><FontAwesomeIcon  icon={faBookmark}/>
                </div>
                <div className="">
                  <h5
                    className="
                      font-size-8 font-weight-semibold
                      text-black-2
                      line-height-reset
                      font-weight-bold
                      mb-1
                    "
                  >
                    <span className="counter">{bookmarks.length}</span>
                  </h5>
                  <p className="font-size-4 font-weight-normal text-gray mb-0">
                    Jobs Saved
                  </p>
                </div>
              </div>
            </div>
            {/*<div className="col-xxl-3 col-xl-4 col-lg-6 col-sm-6">
              <div
                className="
                  media
                  bg-white
                  rounded-4
                  pl-8
                  pt-9
                  pb-9
                  pr-7
                  hover-shadow-1
                  mb-9
                  shadow-8
                "
              >
                <div
                  className="
                    text-olive
                    bg-olive-opacity-1
                    circle-56
                    font-size-6
                    mr-7
                  "
                ><FontAwesomeIcon  icon={faEnvelope}/>
                </div>
                <div className="">
                  <h5
                    className="
                      font-size-8 font-weight-semibold
                      text-black-2
                      line-height-reset
                      font-weight-bold
                      mb-1
                    "
                  >
                    <span className="counter">{getMessage}</span>
                  </h5>
                  <p className="font-size-4 font-weight-normal text-gray mb-0">
                    Messages
                  </p>
                </div>
              </div>
            </div>*/}
          </div>
     
          <div className="row mb-11 align-items-center pl-7">
            <div className="col-lg-6 mb-lg-0 mb-4">
              <h3 className="font-size-6 mb-0">Recent Activities</h3>
            </div>
          </div>
          <div className="container">
            <div className="row mb-14">
              <div
                className="
                  col-lg-12 col-md-12 col-sm-10 col-xs-11
                  mb-9 mb-lg-0
                  aos-init aos-animate
                "
              >
                <div className="bg-white shadow-8 pt-7 rounded pb-8 px-11">
                  <div className="table-responsive">
                    <table className="table table-striped">
                      <tbody>
                        {recentActivity.map(activity => {return(<tr className="border border-color-2">
                          <th scope="row" className="pl-6 border-0 py-7 pr-0">
                            <div className="media align-items-center">
                              <div
                                className={
                                activity.type === "Profile-resume" ? 
                                "text-white bg-blue circle-56 font-size-6 mr-7" : 
                                activity.type === "Application-submit" ?
                                "text-white bg-red circle-56 font-size-6 mr-7" :
                                activity.type === "Profile-password" ?
                                "text-white bg-spray circle-56 font-size-6 mr-7" :
                                activity.type === "Email-verify" ?
                                "text-white bg-yellow circle-56 font-size-6 mr-7" :
                                activity.type === "Profile-image" ?
                                "text-white bg-spray circle-56 font-size-6 mr-7" : 
                                activity.type === "Profile-update" ?
                                "text-white bg-spray circle-56 font-size-6 mr-7" : null} 
                              >{//Profile-image, Profile-resume, Email-verify, Profile-update, Profile-password, Application-submit
                                <FontAwesomeIcon  icon={activity.type === "Profile-resume" ? faFile : 
                               activity.type === "Application-submit" ? faPaperPlane : activity.type === "Profile-password" 
                               ? faLock : activity.type === "Email-verify"  ? faCheck: activity.type === "Profile-image" ? faCamera :
                                activity.type === "Profile-update" ? faUser : null}/>}
                              </div>
                              <h4
                                className="
                                  font-size-4
                                  mb-0
                                  font-weight-semibold
                                  text-black-2
                                "
                              >
                               {activity.narration}
                              </h4></div>
                          </th>
                          <td className="table-y-middle py-7 min-width-px-170 pr-0">
                            <h3
                              className="
                                font-size-4 font-weight-normal
                                text-black-2
                                mb-0
                              "
                            >
                              {moment(new Date(activity.createdAt)).format("MMMM Do YYYY")}
                            </h3>
                          </td>
                        </tr>)})}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {/*<div
                className="
                  col-xl-4 col-lg-6 col-md-8 col-xs-10
                  aos-init aos-animate
                "
              >
                <img src={random} alt="" />
              </div>*/}
            </div>
          </div>
        </div>
      </div>
         </div>
    )


} 

